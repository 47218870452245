import { colors } from 'constants/colorsBase';
import {
  ERequestAdvance,
  EStatusApproveRequest,
  ETransactionHookStatus,
  ETransactionStatus,
} from 'constants/constants';
import { IRequestAdvance } from '@pages/CompanySite/ApproveSalaryAdvance/useApproveSalaryAdvance';
import { CONST_ATTENDANCE_COMPANY } from 'constants/language';

const useStatusAutomaticItem = (element: IRequestAdvance) => {
  const statusToColor = () => {
    if (element.message_error) {
      return colors.failedAdvance;
    } else {
      if (
        element.is_force_cancel === ERequestAdvance.CANCELED ||
        element.request_status === EStatusApproveRequest.STAFF_CANCEL
      ) {
        return colors.cancelAdvance;
      }
      if (
        element.transaction_history_status === ETransactionStatus.ERROR ||
        element.transaction_history_transfer_status === ETransactionHookStatus.ERROR
      ) {
        return colors.failedAdvance;
      }
      return colors.dayShift;
    }
  };

  const statusToText = () => {
    if (element.message_error) {
      return CONST_ATTENDANCE_COMPANY.FAILED_TRANSFER;
    } else {
      if (element.is_force_cancel === ERequestAdvance.CANCELED) {
        return CONST_ATTENDANCE_COMPANY.CANCEL_ALREADY;
      }
      // success and is_seven_bank
      if (
        (element.request_status === EStatusApproveRequest.COMPLETE_SUCCESS ||
          element.request_status === EStatusApproveRequest.END_FLOW) &&
        element.transaction_history_status === ETransactionStatus.SUCCESS &&
        element.is_seven_bank
      ) {
        return CONST_ATTENDANCE_COMPANY.SUCCESS_TRANSFER;
      }
      // failed
      if (
        element.transaction_history_status === ETransactionStatus.ERROR ||
        element.transaction_history_transfer_status === ETransactionHookStatus.ERROR
      ) {
        return CONST_ATTENDANCE_COMPANY.FAILED_TRANSFER;
      }

      // cancel
      if (element.request_status === EStatusApproveRequest.STAFF_CANCEL) {
        return CONST_ATTENDANCE_COMPANY.CANCEL;
      }
      // processing, requested to bank
      return CONST_ATTENDANCE_COMPANY.PROCESSING_TRANSFER;
    }
  };

  return {
    statusToText,
    statusToColor,
  };
};

export default useStatusAutomaticItem;
