import { useQuery } from 'react-query';

import {
  AGENCY_INFO_INVOICE_ID,
  AGENCY_INVOICE_DETAIL_ID,
  AGENCY_LIST_COMPANY,
  AGENCY_LIST_INVOICE,
} from 'constants/keyQuery';

import { apiAdminGetListCompany, apiGetListInvoice } from 'api/agency';
import { apiGetAgencyInfoInvoice } from 'api';
import { apiAgencyInvoiceDetail } from 'api/operator';

export const useHookListCompany = () => {
  const { data, isLoading } = useQuery<any>([AGENCY_LIST_COMPANY], async () => {
    const response = await apiAdminGetListCompany();
    return response;
  });

  return { ...data, isLoading };
};

export const useHookListInvoice = (params: any) => {
  const { data, isLoading } = useQuery<any>([AGENCY_LIST_INVOICE, params], async () => {
    const response = await apiGetListInvoice(params);
    return response;
  });

  return { ...data, isLoading };
};

export const useGetAgencyInfoInvoice = (invoiceId: number) => {
  const { data, isLoading } = useQuery<any>([AGENCY_INFO_INVOICE_ID, invoiceId], async () => {
    const response = await apiGetAgencyInfoInvoice(invoiceId);
    return response;
  });

  return { ...data, isLoading };
};

export const useAgencyInvoiceDetail = (invoiceId: number, params: any) => {
  const { data, isLoading } = useQuery<any>(
    [AGENCY_INVOICE_DETAIL_ID, { ...params, invoiceId }],
    async () => {
      const response = await apiAgencyInvoiceDetail(invoiceId, params);
      return response;
    },
    { enabled: !params.isLoading }
  );

  return { ...data, isLoading };
};
