import React from 'react';
import { SpaceBase } from 'styles';
import { IRequestAdvance } from '@pages/CompanySite/ApproveSalaryAdvance/useApproveSalaryAdvance';
import { DoneProcessing } from '../StatusApproveItem/statusApproveItemStyle';
import useStatusAutomaticItem from '../StatusAutomaticItem/useStatusAutomaticItem';

export const StatusAutomaticItem = ({ element }: { element: IRequestAdvance }) => {
  const { statusToText, statusToColor } = useStatusAutomaticItem(element);

  return (
    <React.Fragment>
      <SpaceBase width={3.5} />
      <DoneProcessing color={statusToColor()}>{statusToText()}</DoneProcessing>
    </React.Fragment>
  );
};
