export const CONST_CREATE_AGENCY = {
  TITLE: '代理店アカウント 発行',
  NAME_AGENCY: '企業名',
  REPRESENTATIVE_NAME: '代表者名',
  FULL_NAME_PLACEHOLDER: 'お名前',
  FIRST_NAME: '姓',
  LAST_NAME: 'お名前(カナ)',
  PHONE_NUMBER: '電話番号',
  ADDRESS_EMAIL: 'メールアドレス',
  REGISTER: '登　録',
  AGENCY_PAGE: '企業/管理者アカウント一覧',
  AGENCY_ISSUANCE: '企業/管理者アカウント発行',
  HOLDER_PHONE: '090-111-2222',
  HOLDER_EMAIL: 'aaa.bbb@example.com',
  BROKERAGE_FEE_SETTING: '仲介手数料設定',
  DEPOSIT: 'デポジット',
  ADVANCE: '立替',
  //
  EMPTY_COMPANY_NAME: '企業名は必須です',
  EMPTY_FIRST_NAME: '代表者名は必須です',
  EMPTY_FULL_NAME: '代表者名は必須です',
  EMPTY_LAST_NAME: 'お名前(カナ)は必須です',
  EMPTY_PHONE: '電話番号は必須です',
  EMPTY_MAIL: 'メールアドレスは必須です',
  EMPTY_DEPOSIT_FEE: '仲介手数料は必要です',
  EMPTY_BROKERAGE_FEE: '仲介手数料は必須です',
  INTEGER_BROKERAGE_FEE: '仲介手数料は整数のみ有効です',
  NOT_NUMBER_DEPOSIT_FEE: 'デポジットは整数のみ有効です',
  EMPTY_ADVANCE: '仲介手数料は必要です',
  NOT_NUMBER_ADVANCE: '立替は整数のみ有効です',
  VALIDATE_MAIL: 'メールアドレスが無効な値です',
  VALIDATE_PHONE: '電話番号は整数のみ有効です',
  //
  SAME_EMAIL: 'メールアドレスが既に存在しています',
  AGENCY_ISSUED: '代理店発行',
};
