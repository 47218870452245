import React from 'react';
import useSalaryAdvance from './useSalaryAdvance';
import { ContainerWrapper } from '../BankSetting/bankSettingStyle';
import { SalaryAdvanceDetail } from '@containers/CompanySite/SalaryAdvance/SalaryAdvanceDetail';
import ModalCommon from '@components/Modal/ModalCommon';
import { CONST_SETTING_SALARY } from 'constants/language';

const SalaryAdvance = ({ firstTime = false }: { firstTime: boolean }) => {
  const {
    form,
    salaryAdvanceData,
    isLoading,
    navigate,
    handleStringToNumber,
    handleTrimSpaceInput,
    onFinishForm,
    handleOnChange,
    BREADS,
    isSalaryDayEndMonth,
    onChangeSalaryInputEndMonth,
    isOpen,
    setIsOpen,
    salaryType,
    changeTypeSalary,
  } = useSalaryAdvance({
    firstTime,
  });

  return (
    <ContainerWrapper padding={'0'} height={firstTime ? '100vh' : ''}>
      <ModalCommon
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClickOk={() => setIsOpen(false)}
        onCancel={() => setIsOpen(true)}
        isShowBtnCancel={false}
        _className="alert_setting_salary"
      >
        <div className="">{CONST_SETTING_SALARY.ALERT_SETTING_SALARY1}</div>
        <div className="">{CONST_SETTING_SALARY.ALERT_SETTING_SALARY2}</div>
        <div className="">{CONST_SETTING_SALARY.ALERT_SETTING_SALARY3}</div>
      </ModalCommon>

      <SalaryAdvanceDetail
        BREADS={BREADS}
        form={form}
        firstTime={firstTime}
        salaryAdvanceData={salaryAdvanceData}
        handleSubmitForm={onFinishForm}
        isLoadingBtn={isLoading}
        handleStringToNumber={handleStringToNumber}
        handleTrimSpaceInput={handleTrimSpaceInput}
        handleOnChange={handleOnChange}
        navigate={navigate}
        isSalaryDayEndMonth={isSalaryDayEndMonth}
        onChangeSalaryInputEndMonth={onChangeSalaryInputEndMonth}
        salaryType={salaryType}
        changeTypeSalary={changeTypeSalary}
      />
    </ContainerWrapper>
  );
};

export default SalaryAdvance;
