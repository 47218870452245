import React from 'react';
import styled from 'styled-components';
import DropdownCustom from '@components/DropdownCustom/DropdownCustom';
import { MAX_LENGTH, OPTION_ADD } from 'constants/constants';
import InputCard from '@components/Input/InputCard';
import { ErrorOperator } from '@pages/OperatorSite/Operators/CreateOperator/createOperatorStyle';
import { TickedIcon } from './CompanyStyle';
import images from '@assets/images-base';

const WrapperList = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -8px;
`;
const WrapperOption = styled.div`
  display: flex !important;
  width: 100% !important;
  padding-left: 0px !important;
  margin-top: 10px;
`;
const WrapperSelect = styled.div`
  display: flex !important;
  width: 100% !important;
  padding-left: 0px !important;
  margin-bottom: 18px;
  margin-left: 10px;
`;
interface IInputOperator {
  marginTop?: number;
}
const WrapperInputOption = styled.div<IInputOperator>`
  width: 100% !important;
  padding-left: 15px !important;
  margin-bottom: ${(props) => (props?.marginTop ? `${props?.marginTop}px` : '15px')};
`;
const WrapperIcon = styled.div`
  display: flex !important;
  width: 100% !important;
  padding-left: 10px !important;
  margin-top: -17px;
  align-items: center;
`;
const UnitCharge = styled.div`
  margin-left: -8px;
  margin-top: -20px;
  display: flex;
  align-items: center;
`;

const CompanyOptionsAdd = ({
  listOptionsAdd,
  onChangeCharge,
  onChangeTypeAdd,
  error,
  companyData,
  onRemove,
  listAdd,
}: any) => {
  return (
    <>
      <WrapperList>
        {listOptionsAdd.map((_optionAdd: any, index: number) => {
          return (
            <WrapperOption key={index}>
              <WrapperSelect>
                <DropdownCustom
                  options={listAdd}
                  defaultValue={companyData.companyOptions?.[index]?.type}
                  onSelect={(e) => onChangeTypeAdd(e, index)}
                  value={OPTION_ADD.find((option) => option.value === companyData.companyOptions?.[index]?.type)?.label}
                />
              </WrapperSelect>
              <WrapperInputOption marginTop={!error[`charge${index}`] ? 15 : -6}>
                <InputCard
                  width={8}
                  height={2.5}
                  type="tel"
                  isShadow={true}
                  maxLength={MAX_LENGTH.INPUT_NUMBER}
                  value={companyData.companyOptions?.[index]?.charge}
                  onChange={(e) => onChangeCharge(e, index)}
                  placeholder=""
                />
                <ErrorOperator width={9}>{error[`charge${index}`]}</ErrorOperator>
              </WrapperInputOption>
              <UnitCharge className="unit">円</UnitCharge>
              <WrapperIcon>
                <TickedIcon src={images.company.deleteCompany} alt="" onClick={() => onRemove(index)} />
              </WrapperIcon>
            </WrapperOption>
          );
        })}
      </WrapperList>
    </>
  );
};
export default CompanyOptionsAdd;
