import React from 'react';

import Loading from '@components/Loading';
import BreadCrumb from '@components/Breadcrumb/BreadCrumb';
import useInvoiceDetailCompany from './useInvoiceDetailCompany';
import PaginationRecord from '@components/CompanySite/AttendanceRecord/PaginationRecord/PaginationRecord';

import { CONST_COMMON, CONST_LIST_INVOICE_COMPANY } from 'constants/language';
import { Container } from '@components/Style/Style';
import { SpaceBase } from 'styles';
import { FootstepsHistoryCard } from '@pages/OperatorSite/FootstepsHistory/footstepsHistoryStyle';
import { GrantCompanyWrapper } from '@pages/OperatorSite/Companies/CreateCompany/createCompanyStyle';
import { ControlHeaderWrapper } from '@pages/CompanySite/AttendanceRecord/attendanceRecordStyle';
import {
  ButtonExport,
  DateInvoice,
  HeaderWrapper,
  InvoiceDetailWrapper,
  TitleHeader,
} from './invoiceDetailCompanyStyle';
import images from '@assets/images-base';
import CompanyInvoiceViewTable from '@components/InvoiceTable/InvoiceViewTable/CompanyInvoiceViewTable';
import CompanyInvoiceExportTable, {
  getMaxItemPageOneCompany,
} from '@components/InvoiceTable/InvoiceExportTable/CompanyInvoiceExportTable';
import { LoadingInvoice } from '@pages/CompanySite/InvoiceDetailSiteCompany/invoiceDetailSiteCompanyStyle';
import { MAX_ITEM_PAGE_ONE_COMPANY, MAX_ITEM_PAGE_OTHER_COMPANY } from 'constants/invoice';
import { getTextInvoiceCreateAt, getTextInvoiceCreateAtDown, isNotDateDueYetDetail } from 'helper';
import { Select } from 'antd';
import styled from 'styled-components';
import { getColorSite } from 'helper/colorSite';
import { colors } from 'constants/colorsBase';
import { IconDown } from 'components/DropdownCustom/dropdownStyle';
import { useParams } from 'react-router-dom';

export const WrapperText = styled.div`
  display: flex;
  align-items: center;
  .label-down {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const ListCompanyInvoice = styled(Select)`
  background: ${getColorSite()};
  box-shadow: 0 0.313rem 0.313rem rgba(0, 0, 0, 0.25);
  border-radius: 0.625rem;
  width: 42.563rem;
  height: 3.125rem;
  margin: 1rem 3rem;
  .ant-select-selector {
    background-color: unset !important;
    border: none !important;
    height: 2.563rem !important;
  }
  span.ant-select-selection-item {
    font-style: normal;
    font-weight: 700;
    font-size: 1.375rem;
    line-height: 2rem;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${colors.white} !important;
  }
  .circle {
    border-radius: 2.188rem;
    width: 2.188rem;
    height: 2.188rem;
    min-width: 2.188rem;
    border: 1px solid ${colors.white};
    margin-right: 0.625rem;
    margin-top: 0.425rem;
    margin-left: 0.525rem;
    background-color: ${colors.white};
  }
`;
const { Option } = Select;

export const InvoiceDetailCompany = () => {
  const {
    BREADS,
    invoiceDetail,
    invoiceAllDetail,
    isLoading,
    page,
    changePaging,
    exportInvoicePDF,
    isLoadingExport,
    invoiceInfo,
    isLoadingInvoiceAllDetail,
    isLoadingInvoiceDetail,
    listInvoiceCompany,
    changeCompanyInvoice,
  } = useInvoiceDetailCompany();
  const { id }: any = useParams();

  return (
    <GrantCompanyWrapper>
      <BreadCrumb breads={BREADS} />
      <Container>
        <FootstepsHistoryCard between={true}>
          <div>
            <HeaderWrapper>
              <div>
                <div className="title-page">
                  <img className="header-icon" src={images.sideBar.usageInformation} alt="" />
                  <TitleHeader>
                    {invoiceInfo?.company_name}　{CONST_LIST_INVOICE_COMPANY.INVOICE_CONTENT}
                  </TitleHeader>
                </div>
                {invoiceInfo && <DateInvoice>{getTextInvoiceCreateAt(isLoading, invoiceInfo)}</DateInvoice>}
              </div>
              {invoiceInfo && (
                <ControlHeaderWrapper padding="1rem 0 1rem 33rem">
                  <PaginationRecord
                    right="50rem"
                    current={page.page}
                    total={page.total + (MAX_ITEM_PAGE_OTHER_COMPANY - MAX_ITEM_PAGE_ONE_COMPANY)}
                    pageSize={MAX_ITEM_PAGE_OTHER_COMPANY}
                    onChange={changePaging}
                  />
                  {!isLoadingInvoiceAllDetail && invoiceInfo && (
                    <ButtonExport
                      margin="0 0 0 4.313rem"
                      loading={isLoadingExport}
                      disabled={isNotDateDueYetDetail(isLoading, invoiceInfo)}
                      onClick={exportInvoicePDF}
                    >
                      {CONST_LIST_INVOICE_COMPANY.EXPORT_INVOICE}
                    </ButtonExport>
                  )}
                </ControlHeaderWrapper>
              )}
            </HeaderWrapper>
            {listInvoiceCompany.length > 0 && (
              <ListCompanyInvoice
                id="ListCompanyInvoice"
                suffixIcon={<IconDown width={1.3} src={images.company.whiteDown} alt="" />}
                onSelect={(e) => changeCompanyInvoice(e)}
                defaultValue={id}
                dropdownStyle={{ minWidth: '42.563rem', left: '11.582rem' }}
              >
                {listInvoiceCompany.map((element: any) => (
                  <Option
                    key={element.value}
                    value={element.value}
                    disabled={element.color === '#BEBEBE'}
                    className="title-page"
                  >
                    <WrapperText>
                      {element.color === '#BEBEBE' ? (
                        <IconDown width={2.5} src={images.company.warningVoice} alt="" />
                      ) : (
                        <div className="circle" />
                      )}
                      <span className="label-down">{element.label}</span>
                    </WrapperText>
                  </Option>
                ))}
              </ListCompanyInvoice>
            )}
            <InvoiceDetailWrapper>
              {!invoiceInfo && !isLoading && <div className="not-found">{CONST_COMMON.NOT_FOUND}</div>}

              {isLoadingInvoiceDetail && (
                <LoadingInvoice>
                  <SpaceBase height={5} />
                  <Loading />
                </LoadingInvoice>
              )}
              {!isLoadingInvoiceDetail && invoiceInfo && (
                <CompanyInvoiceViewTable
                  invoiceDetail={invoiceDetail || []}
                  invoiceInfo={invoiceInfo}
                  currentPage={page.page}
                  pageSize={page.page === 1 ? getMaxItemPageOneCompany(invoiceInfo) : MAX_ITEM_PAGE_OTHER_COMPANY}
                  isOperatorCompany
                  txtRequestDate={getTextInvoiceCreateAtDown(isLoading, invoiceInfo)}
                  idInvoice={id}
                />
              )}
              {!isLoadingInvoiceAllDetail && invoiceInfo && (
                <CompanyInvoiceExportTable
                  invoiceDetail={invoiceAllDetail || []}
                  invoiceInfo={invoiceInfo}
                  isOperatorCompany
                />
              )}
            </InvoiceDetailWrapper>
          </div>
        </FootstepsHistoryCard>
      </Container>
    </GrantCompanyWrapper>
  );
};
