import { useQuery } from 'react-query';

import {
  COMPANY_APPROVED_SALARY,
  COMPANY_HISTORY_ALL_STAFF,
  COMPANY_LIST_ACCOUNT_ADMIN,
  COMPANY_LIST_STAFF,
  COMPANY_LIST_STAFF_ATTENDANCE,
  COMPANY_LIST_INVOICE,
  COMPANY_LIST_HISTORY_ACTION,
  COMPANY_SETTING_SALARY_DETAIL,
  COMPANY_HISTORY_ADVANCE_ALL_DATA_STAFF,
  COMPANY_HISTORY_ADVANCE_DATA_OF_STAFF,
  COMPANY_ADMIN_GET_ATTENDANCE,
  COMPANY_INFO_INVOICE_ID,
  COMPANY_INVOICE_DETAIL_ID,
  COMPANY_SETTING_SALARY_LAST,
} from 'constants/keyQuery';

import {
  apiAdminGetAttendance,
  apiAdminGetAttendanceOneStaff,
  apiCompanyAccountList,
  apiCompanyFootstepsHistory,
  apiCompanyListStaff,
  apiCompanySalaryDetail,
  apiCompanySettingSalaryLast,
  apiGetHistoryAdvanceAllDataStaffExport,
  apiGetHistoryAdvanceDataOfStaffExport,
  apiGetHistoryAllStaff,
  apiGetListInvoice,
  apiGetRequestSalary,
} from 'api/company';
import { apiGetCompanyInfoInvoice } from 'api';
import { apiCompanyInvoiceDetail } from 'api/operator';

export const useHookListStaff = () => {
  const { data, isLoading } = useQuery<any>([COMPANY_LIST_STAFF], async () => {
    const response = await apiCompanyListStaff();
    return response;
  });

  return { ...data, isLoading };
};

export const useHookCompanyAccountList = () => {
  const { data, isLoading } = useQuery<any>([COMPANY_LIST_ACCOUNT_ADMIN], async () => {
    const response = await apiCompanyAccountList();
    return response;
  });

  return { ...data, isLoading };
};

export const useHookHistoryAllStaff = (parmas: any) => {
  const { data, isLoading, isFetching } = useQuery<any>([COMPANY_HISTORY_ALL_STAFF, parmas], async () => {
    const response = await apiGetHistoryAllStaff(parmas);
    return response;
  });

  return { ...data, isLoading, isFetching };
};

export const useHookStaffAttendance = (parmas: any) => {
  const { data, isLoading } = useQuery<any>([COMPANY_LIST_STAFF_ATTENDANCE, parmas], async () => {
    const response = await apiAdminGetAttendance(parmas);
    return response;
  });

  return { ...data, isLoading };
};

export const useHookListInvoice = (parmas: any) => {
  const { data, isLoading } = useQuery<any>([COMPANY_LIST_INVOICE, parmas], async () => {
    const response = await apiGetListInvoice(parmas);
    return response;
  });

  return { ...data, isLoading };
};

export const useHookRequestSalary = (parmas: any) => {
  const { data, isLoading, isFetching } = useQuery<any>([COMPANY_APPROVED_SALARY, parmas], async () => {
    const response = await apiGetRequestSalary(parmas);
    return response;
  });

  return { ...data, isLoading, isFetching };
};

export const useHookCompanyFootstepsHistory = (parmas: any) => {
  const { data, isLoading, isFetching } = useQuery<any>([COMPANY_LIST_HISTORY_ACTION, parmas], async () => {
    const response = await apiCompanyFootstepsHistory(parmas);
    return response;
  });

  return { ...data, isLoading, isFetching };
};

export const useHookHistoryAdvanceAllData = (month: string) => {
  const { data, isLoading } = useQuery<any>([COMPANY_HISTORY_ADVANCE_ALL_DATA_STAFF, month], async () => {
    const response = await apiGetHistoryAdvanceAllDataStaffExport({
      month,
    });

    return response;
  });

  return { ...data, isLoading };
};

export const useHookHistoryAdvanceDataOfStaff = (month: string, staffId: any) => {
  const { data, isLoading } = useQuery<any>([COMPANY_HISTORY_ADVANCE_DATA_OF_STAFF, month, staffId], async () => {
    const response = await apiGetHistoryAdvanceDataOfStaffExport({ month }, staffId);
    return response;
  });

  return { ...data, isLoading };
};

export const useHookCompanySalaryDetail = () => {
  const { data, isLoading, isFetching } = useQuery<any>([COMPANY_SETTING_SALARY_DETAIL], async () => {
    const response = await apiCompanySalaryDetail();
    return response;
  });

  return { ...data, isLoading, isFetching };
}

export const useAdminGeListAttendance = (parmas: any) => {
  const { data, isLoading } = useQuery<any>([COMPANY_ADMIN_GET_ATTENDANCE, parmas], async () => {
    const response = await apiAdminGetAttendanceOneStaff(parmas);
    return response;
  });

  return { ...data, isLoading };
};

export const useGetCompanyInfoInvoice = (invoiceId: any) => {
  const { data, isLoading } = useQuery<any>([COMPANY_INFO_INVOICE_ID, invoiceId], async () => {
    const response = await apiGetCompanyInfoInvoice(invoiceId);
    return response;
  });

  return { ...data, isLoading };
};

export const useCompanyInvoiceDetail = (invoiceId: any, params: any) => {
  const { data, isLoading } = useQuery<any>(
    [COMPANY_INVOICE_DETAIL_ID, { ...params, invoiceId }],
    async () => {
      const response = await apiCompanyInvoiceDetail(invoiceId, params);
      return response;
    },
    { enabled: !params.isLoading }
  );

  return { ...data, isLoading };
};

export const useCompanySettingSalaryLast = (companyId: number) => {
  const { data, isLoading, isFetching } = useQuery<any>(
    [COMPANY_SETTING_SALARY_LAST, companyId],
    async () => {
      const response = await apiCompanySettingSalaryLast();
      return response;
    },
    { enabled: !!companyId }
  );

  return { ...data, isLoading, isFetching };
};
