import { useState, useCallback } from 'react';

import { colors } from 'constants/colorsBase';
import { ESettingTimer, ETimeShift, ETimeShiftFormat, ETimeShiftKeys } from 'constants/constants';
import { responseSuccess } from 'helper/responseSuccess/responseSuccess';
import { ITimeAttendance } from '@pages/CompanySite/AttendanceRecord/interface';
import { apiAdminSetTimeAttendance } from 'api/company';
import { checkAdminHasTimekeeping, checkCanChangeByRootAdmin, checkOverDate } from 'helper/attendanceRecord';
import { CONST_ATTENDANCE_COMPANY } from 'constants/language';
import { ERROR_CODE_COMMON, VALIDATE_ERROR_CODE } from 'constants/errorCode';
import { message } from 'antd';
import { Dayjs } from 'dayjs';
import { checkTextConfirm } from '../AttendanceTable/useAttendanceTable';

const useMemberTable = (isRootAdmin: boolean, setDetailData: any, detailData: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowConfirm, setIsShowConfirm] = useState<boolean>(false);
  const [confirmData, setConfirmData] = useState<ITimeAttendance | null>(null);
  const [confirmChanging, setConfirmChanging] = useState<string>('');
  const [confirmDate, setConfirmDate] = useState<string>('');
  const [textConfirm, setTextConfirm] = useState('');

  const handleAnalysisColorDay = (
    changeData: ITimeAttendance,
    dayShift: ETimeShift.ACTIVITY | ETimeShift.NOT_ACTIVITY,
    bgColorActive: string,
    shift: string,
    date: string,
    forceInActive?: boolean,
    isSetting?: number | null
  ) => {
    const data = {
      color: colors.mainText,
      shadow: colors.shadowAttendance,
      background: colors.white,
    };
    if (dayShift === ETimeShift.ACTIVITY) {
      data.background = bgColorActive;
      data.shadow = 'none';
      data.color = colors.white;
    }
    if (!checkCanChangeByRootAdmin(changeData, shift, date, isRootAdmin, isSetting) || forceInActive) {
      data.shadow = colors.shadowInsetFor;
    }
    return data;
  };

  const checkIsConfirmed = (date: string | Dayjs, changing: string, isTimeClosing: number) => {
    if (isTimeClosing === 1) {
      if (
        !checkAdminHasTimekeeping(date, ETimeShiftFormat.DATE_NIGHT, false) &&
        (changing === ETimeShiftKeys.DAY_SHIFT || changing === ETimeShiftKeys.MORNING_HALF)
      )
        return true;
    } else {
      if (
        !checkAdminHasTimekeeping(date, ETimeShiftFormat.DATE_NOON, false) &&
        (changing === ETimeShiftKeys.DAY_SHIFT || changing === ETimeShiftKeys.MORNING_HALF)
      )
        return true;
    }
    if (
      !checkAdminHasTimekeeping(date, ETimeShiftFormat.DATE_NIGHT, false) &&
      (changing === ETimeShiftKeys.NIGHT_SHIFT || changing === ETimeShiftKeys.AFTERNOON_HALF)
    ) {
      return true;
    }
    return false;
  };

  const attendanceMemberData = useCallback(
    async (changeData: ITimeAttendance, changing: string) => {
      try {
        const newData = { ...changeData };
        if ((changing === ETimeShiftKeys.DAY_SHIFT || changing === ETimeShiftKeys.NIGHT_SHIFT) && newData[changing]) {
          newData.leave = ETimeShift.NOT_ACTIVITY;
        }
        if (changing === ETimeShiftKeys.LEAVE && newData[changing]) {
          newData.day_shift = ETimeShift.NOT_ACTIVITY;
          newData.night_shift = ETimeShift.NOT_ACTIVITY;
        }
        let _newData = { ...newData };
        if (_newData.morning_haft_shift) {
          _newData.day_shift = ETimeShift.NOT_ACTIVITY;
        }
        if (_newData.afternoon_haft_shift) {
          _newData.night_shift = ETimeShift.NOT_ACTIVITY;
        }

        const response = await apiAdminSetTimeAttendance(_newData);
        if (responseSuccess(response)) {
          // updateAttendanceMemberData(newData);
          let dataResponse = { ...response?.data };
          if (dataResponse.morning_haft_shift) {
            dataResponse.day_shift = ETimeShift.ACTIVITY;
          }
          if (_newData.afternoon_haft_shift) {
            dataResponse.night_shift = ETimeShift.ACTIVITY;
          }
          setDetailData(
            detailData?.map((element: ITimeAttendance) => {
              if (element.date === response?.data?.date) {
                return { ...dataResponse, salary_type: newData.salary_type };
              }
              return element;
            })
          );
          setIsLoading(false);
        } else {
          if (response?.response?.data?.code === VALIDATE_ERROR_CODE.ATTENDANCE_REQUEST_DATE_INVALID) {
            message.error(CONST_ATTENDANCE_COMPANY.ATTENDANCE_REQUEST_DATE_INVALID);
          }
          if (response?.response?.data?.code === VALIDATE_ERROR_CODE.ATTENDANCE_STAFF_DISCONNECTED) {
            message.error(CONST_ATTENDANCE_COMPANY.ATTENDANCE_STAFF_DISCONNECTED);
          }
          if (response?.response?.data?.code === VALIDATE_ERROR_CODE.ATTENDANCE_STAFF_NOT_ALLOW_REQUEST_SALARY) {
            message.error(CONST_ATTENDANCE_COMPANY.ATTENDANCE_STAFF_NOT_ALLOW_REQUEST_SALARY);
          }
          if (
            response?.response?.data?.code === VALIDATE_ERROR_CODE.ATTENDANCE_STAFF_BLOCK ||
            response?.response?.data?.code === VALIDATE_ERROR_CODE.NOT_ACCEPTTABLE_CODE
          ) {
            message.error(CONST_ATTENDANCE_COMPANY.ATTENDANCE_STAFF_BLOCK);
          }
          if (response?.response?.data?.code === VALIDATE_ERROR_CODE.ATTENDANCE_LIFE_CYCLE) {
            message.error(ERROR_CODE_COMMON.company_pair_b2b.D007);
          }
          if (response?.response?.data?.code === VALIDATE_ERROR_CODE.ATTENDANCE_CYCLE_OLD) {
            message.error(ERROR_CODE_COMMON.company_pair_b2b.D008);
          }
          if (response?.response?.data?.code === VALIDATE_ERROR_CODE.ATTENDANCE_WHEN_LEAVE) {
            message.error(ERROR_CODE_COMMON.company_pair_b2b.D009);
          }
        }
      } catch (error) {
        //
      } finally {
        setIsLoading(false);
      }
    },
    [detailData, setDetailData]
  );

  const handleOkConfirm = useCallback(() => {
    if (!confirmData || !confirmChanging) return;
    setIsLoading(true);
    attendanceMemberData(confirmData, confirmChanging);
    setIsShowConfirm(false);
  }, [confirmChanging, confirmData, attendanceMemberData]);

  const handleCancelConfirm = () => {
    setIsShowConfirm(false);
    setConfirmData(null);
    setConfirmChanging('');
    setConfirmDate('');
  };

  const changeTime = async (changeData: ITimeAttendance, changing: string, date: string, isSetting: number) => {
    let _changeData = { ...changeData };
    if (_changeData.morning_haft_shift) {
      _changeData.day_shift = _changeData.morning_haft_shift;
    }
    if (_changeData.afternoon_haft_shift) {
      _changeData.night_shift = _changeData.afternoon_haft_shift;
    }

    if (!checkCanChangeByRootAdmin(_changeData, changing, date, isRootAdmin, isSetting)) return;
    if (isLoading) return;
    // if current date is over date, show confirm popup
    if (isSetting === ESettingTimer.SETTING_TIMER) {
      if (
        (checkOverDate(date, ETimeShiftFormat.DATE_NIGHT, false) &&
          (changing === ETimeShiftKeys.DAY_SHIFT ||
            (changing === ETimeShiftKeys.MORNING_HALF && _changeData.morning_haft_shift === 0))) ||
        (checkOverDate(date, ETimeShiftFormat.DATE_NIGHT, false) &&
          (changing === ETimeShiftKeys.NIGHT_SHIFT ||
            (changing === ETimeShiftKeys.AFTERNOON_HALF && _changeData.afternoon_haft_shift === 0)))
      ) {
        checkTextConfirm(changing, _changeData, setTextConfirm);
        setConfirmData(_changeData);
        setConfirmChanging(changing);
        setConfirmDate(date);
        setIsShowConfirm(true);
      } else {
        setIsLoading(true);
        attendanceMemberData(_changeData, changing);
      }
    } else {
      if (
        (checkOverDate(date, ETimeShiftFormat.DATE_NOON, false) &&
          (changing === ETimeShiftKeys.DAY_SHIFT ||
            (changing === ETimeShiftKeys.MORNING_HALF && _changeData.morning_haft_shift === 0))) ||
        (checkOverDate(date, ETimeShiftFormat.DATE_NIGHT, false) &&
          (changing === ETimeShiftKeys.NIGHT_SHIFT ||
            (changing === ETimeShiftKeys.AFTERNOON_HALF && _changeData.afternoon_haft_shift === 0)))
      ) {
        checkTextConfirm(changing, _changeData, setTextConfirm);
        setConfirmData(_changeData);
        setConfirmChanging(changing);
        setConfirmDate(date);
        setIsShowConfirm(true);
      } else {
        setIsLoading(true);
        attendanceMemberData(_changeData, changing);
      }
    }
  };

  return {
    handleAnalysisColorDay,
    changeTime,
    isLoading,
    isShowConfirm,
    setIsShowConfirm,
    handleOkConfirm,
    handleCancelConfirm,
    confirmChanging,
    confirmData,
    confirmDate,
    checkIsConfirmed,
    textConfirm,
  };
};

export default useMemberTable;
