import { useEffect, useMemo, useRef, useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import { IStaff } from './interface';
import { IBread } from '@components/Breadcrumb/BreadCrumb';
import { getMaxPage } from 'helper/attendanceRecord';
import { DAY_OF_WEEK } from 'constants/company';
import { getAllDayOfMonth } from 'helper/getAllDayOfMonth/getAllDayOfMonth';
import { CONST_ATTENDANCE_COMPANY } from 'constants/language';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { EKeyCode } from 'constants/constants';
import { getDayjsByTimeZone, isRegexMonth } from 'helper/date';
import { useHookStaffAttendance } from '@hooks/useCompany';
import { _isNumber } from 'helper';

dayjs.extend(weekOfYear);

const BREADS: IBread[] = [
  {
    name: CONST_ATTENDANCE_COMPANY.ATTENDANCE_RECORD,
    path: '',
  },
];
const defaultPage = {
  per_page: 5,
  page: 1,
  total: 0,
};

const useAttendanceRecord = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const paramMonth = query.get('month') ?? '';
  const paramKeyWord = query.get('key_word') ?? '';
  const paramPage = _isNumber(query.get('page'), 1);

  const ref = useRef<any>();
  const [page, setPage] = useState(1);
  const [pageStaff, setPageStaff] = useState(defaultPage);
  const [listMember, setListMember] = useState<IStaff[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [valueSearch, setValueSearch] = useState<string>('');

  const [paramSearch, setParamsearch] = useState<any>({
    month: isRegexMonth(paramMonth) ? paramMonth : dayjs().format('YYYY-MM'),
    per_page: defaultPage.per_page,
    page: 1,
    key_word: '',
  });

  const [month, setMonth] = useState<Dayjs>(getDayjsByTimeZone(paramSearch.month));
  const [monthInput, setMonthInput] = useState<Dayjs | undefined>(getDayjsByTimeZone(paramSearch.month));
  const { data: dataListMember, isLoading: isLoadingListMember } = useHookStaffAttendance(paramSearch);

  useEffect(() => {
    setListMember(dataListMember?.data);
    setPageStaff((page) => ({
      ...page,
      total: dataListMember?.total ?? 0,
    }));
  }, [dataListMember]);

  useEffect(() => {
    setIsLoading(isLoadingListMember);
  }, [isLoadingListMember]);

  useEffect(() => {
    setParamsearch((param: any) => ({
      ...param,
      key_word: paramKeyWord,
    }));
    setValueSearch(paramKeyWord);
  }, [paramKeyWord]);

  useEffect(() => {
    setParamsearch((param: any) => ({
      ...param,
      page: paramPage,
    }));
    setPageStaff((page) => ({
      ...page,
      page: paramPage,
    }));
  }, [paramPage]);

  useEffect(() => {
    let _month = isRegexMonth(paramMonth) ? getDayjsByTimeZone(paramMonth) : getDayjsByTimeZone();
    setMonth(_month);
    setMonthInput(_month);
  }, [paramMonth]);

  const maxPage = useMemo(() => {
    return getMaxPage(month);
  }, [month]);

  const listDate = useMemo(() => {
    return getAllDayOfMonth(month);
  }, [month]);

  const dayInWeek = useMemo(() => {
    return [...listDate].slice(DAY_OF_WEEK * (page - 1), DAY_OF_WEEK * page);
  }, [page, listDate]);

  const preWeek = async () => {
    if (page <= 1) return;
    setPage(page - 1);
  };
  const nextWeek = async () => {
    if (page >= maxPage) return;
    setPage(page + 1);
  };

  const onChangeMonth = (e: Dayjs | any) => {
    if (e) {
      setMonth(e);
      setMonthInput(e);
      setParamsearch((param: any) => ({
        ...param,
        month: e.format('YYYY-MM'),
      }));
      navigate({
        pathname: `/attendance`,
        search: `?${createSearchParams({ ...paramSearch, month: e.format('YYYY-MM'), page: 1 })}`,
      });
    } else {
      setMonthInput(undefined);
    }
  };

  const onSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === EKeyCode.ENTER) {
      navigate({
        pathname: `/attendance`,
        search: `?${createSearchParams({ ...paramSearch, key_word: valueSearch, page: 1 })}`,
      });
    }
  };

  const changePaging = (pageChange: number) => {
    setPageStaff({
      ...pageStaff,
      page: pageChange,
    });
    navigate({
      pathname: `/attendance`,
      search: `?${createSearchParams({ ...paramSearch, page: pageChange })}`,
    });
  };

  const toSetting = () => {
    navigate('detail');
  };

  return {
    ref,
    month,
    monthInput,
    page,
    BREADS,
    maxPage,
    listDate,
    dayInWeek,
    pageStaff,
    isLoading,
    listMember,
    defaultPage,
    setPage,
    preWeek,
    nextWeek,
    toSetting,
    changePaging,
    onChangeMonth,
    onSearch,
    setValueSearch,
    valueSearch,
  };
};

export default useAttendanceRecord;
