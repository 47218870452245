import React, { forwardRef, useImperativeHandle, useRef, useEffect } from 'react';

import dayjs from 'dayjs';
import ShiftItem from '@components/CompanySite/AttendanceRecord/ShiftItem/ShiftItem';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import { indexToDay } from 'helper/attendanceRecord';
import { DayWrapper } from '../WeekChange/weekChangeStyle';
import { DAY_OF_WEEK } from 'constants/company';
import { defaultTimeAttendance, IShiftItem, IStaff, IWeekType } from '@pages/CompanySite/AttendanceRecord/interface';
import {
  CarouselContainer,
  LineMemberShift,
  LineShift,
  ListShiftItemWrapper,
  MemberItem,
  MemberWrapper,
  WrapperItem,
} from './listShiftItemStyle';
import { getDayjsByTimeZone } from 'helper/date';
import { Tooltip } from 'antd';

dayjs.extend(weekOfYear);
const ListShiftItem: React.ForwardRefRenderFunction<any, any> = (
  { listDate, listMember, maxPage, setPage, month, page },
  ref
) => {
  const listCarousel = Array.from({ length: maxPage }, (_, i) => i + 1);
  const refRoute = useRef<any>();

  useImperativeHandle(ref, () => {
    return {
      nextPage: () => {
        refRoute.current.next();
      },
      prePage: () => {
        refRoute.current.prev();
      },
      toFirst: () => {
        refRoute.current.goTo(0);
      },
      toPage: (page: number) => {
        refRoute.current.goTo(page);
      },
    };
  });

  useEffect(() => {
    refRoute.current.goTo(page - 1);
  }, [page]);

  useEffect(() => {
    const currentWeekOfMonth = dayjs().week() - dayjs().startOf('month').week() + 1;
    if (getDayjsByTimeZone().format('YYYY-MM') === month.format('YYYY-MM')) {
      setPage(currentWeekOfMonth);
      refRoute.current.goTo(page - 1);
    } else {
      setPage(1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, setPage]);

  const getTimeAttendanceInWeek: any = (week: number) => {
    const newArr = [...listDate]?.slice(DAY_OF_WEEK * week, DAY_OF_WEEK * (week + 1)) || [];
    return newArr;
  };

  const getItemShift = (date: number | null, listShift: IShiftItem[]) => {
    if (!date) {
      return null;
    }
    const find = listShift.find((element: IShiftItem) => Number(element?.date?.split('-')[2]) === date);
    if (find) {
      return find;
    }
    return defaultTimeAttendance;
  };

  const shouldDisplayMember = (member: IStaff, month: dayjs.Dayjs) => {
    const disconnectTime = member.staff_disconnect_time;
    const isCanDisplay =
      disconnectTime && getDayjsByTimeZone(disconnectTime).format('YYYY-MM') >= month.format('YYYY-MM');
    return member?.time_attendance?.length || !disconnectTime || isCanDisplay;
  };
  const listMemberShow = listMember?.filter((member: IStaff) => shouldDisplayMember(member, month));

  return (
    <ListShiftItemWrapper>
      <MemberWrapper>
        {listMemberShow?.map((member: IStaff, index: number) => (
          <React.Fragment key={String(member.staff_id)}>
            <WrapperItem>
              <MemberItem>
                <Tooltip title={member.name} placement="topLeft">
                  {member.name}
                </Tooltip>
              </MemberItem>
            </WrapperItem>
            {listMemberShow?.length - 1 !== index && <LineMemberShift />}
          </React.Fragment>
        ))}
      </MemberWrapper>

      <CarouselContainer ref={refRoute}>
        {listCarousel?.map((element, indexCarousel: number) => (
          <div key={String(element)}>
            {listMemberShow?.map((member: IStaff, indexStaff: number) => (
              <React.Fragment key={String(member.staff_id)}>
                <DayWrapper>
                  {getTimeAttendanceInWeek(indexCarousel)?.map((itemTime: number | null, indexTime: IWeekType) => {
                    return (
                      <React.Fragment key={`${itemTime}${indexToDay(indexTime)}`}>
                        <ShiftItem shiftElement={getItemShift(itemTime, member.time_attendance)} />
                      </React.Fragment>
                    );
                  })}
                </DayWrapper>
                {listMemberShow?.length - 1 !== indexStaff && <LineShift />}
              </React.Fragment>
            ))}
          </div>
        ))}
      </CarouselContainer>
    </ListShiftItemWrapper>
  );
};

export default forwardRef(ListShiftItem);
