import { useMemo, useRef, useState, useEffect, useCallback } from 'react';

import dayjs, { Dayjs } from 'dayjs';

import { getMaxPageCycle } from 'helper/attendanceRecord';
import { DAY_OF_WEEK } from 'constants/company';
import { getAllDayOfMonthCycle } from 'helper/getAllDayOfMonth/getAllDayOfMonth';
import { EKeyCode } from 'constants/constants';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useCompanySettingSalaryLast, useHookHistoryAdvanceAllData, useHookHistoryAllStaff } from '@hooks/useCompany';
import { formatDateJP, getDayjsByTimeZone, isRegexMonth } from 'helper/date';
import { _isNumber } from 'helper';
import {  exportCSVFile } from 'helper/export/exportCsv';
import { ICycleCompanySettingSalary } from '../AttendanceRecord/interface';
import { useAppSelector } from '@hooks/useSelector/useAppSelector';

dayjs.extend(weekOfYear);
const defaultPage = {
  per_page: 5,
  page: 1,
  total: 0,
};

export interface IStaffHistoryItem {
  staff_id: number;
  total_salary: string;
  request_date: string;
  transaction_fee: number;
  transaction_fee_tax_floor: number;
  system_fee: number;
  system_fee_tax: number;
  system_fee_tax_floor: number;
  transfer_dates: string;
  request_times: string;
}
export interface IStaffHistory {
  amount_limit_1: number;
  amount_limit_2: number;
  email: string;
  id: number;
  name: string;
  name_kana: string;
  phone: string;
  salary_type: number;
  staff_salary_advance: IStaffHistoryItem[];
  salary_remain: number;
  code: string;
  deleted_at: string | null;
  staff_disconnect_time: string | null;
}
const useAttendanceHistory = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const paramMonth = query.get('month') ?? '';
  const paramName = query.get('name') ?? '';
  const paramPage = _isNumber(query.get('page'), 1);

  const ref = useRef<any>();
  const [page, setPage] = useState(1);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageStaff, setPageStaff] = useState(defaultPage);
  const [listMember, setListMember] = useState<IStaffHistory[] | null>();
  const [valueSearch, setValueSearch] = useState<string>('');

  const companyIdLeague = useAppSelector((state) => state.auth.companyIdLeague);
  const authInfo = useAppSelector((state) => state.auth.authInfo);
  const companyId = companyIdLeague ? companyIdLeague : authInfo?.company?.id;

  const [paramSearch, setParamsearch] = useState<any>({
    month: isRegexMonth(paramMonth) ? paramMonth : dayjs().format('YYYY-MM'),
    per_page: defaultPage.per_page,
    page: 1,
    name: '',
  });

  const [month, setMonth] = useState<Dayjs>(getDayjsByTimeZone(paramSearch.month));
  const [monthInput, setMonthInput] = useState<Dayjs | undefined>(getDayjsByTimeZone(paramSearch.month));
  const {
    data: dataSettingLast,
    isLoading: isLoadingSettingLast,
    isFetching: isFetchingSettingLast,
  } = useCompanySettingSalaryLast(Number(companyId));
  const { data: dataListMember, isLoading: isLoadingListMember, isFetching } = useHookHistoryAllStaff(paramSearch);
  const { data: dataExport, isLoading: isLoadingDataExport } = useHookHistoryAdvanceAllData(paramSearch.month);
  const dataExportFilter = dataExport?.filter((item: any) => {
    return item?.staff_salary_advance_detail_request?.length > 0;
  });

  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
  const [cycleCompanySettingSalary, setCycleCompanySettingSalary] = useState<ICycleCompanySettingSalary>({
    start_date: '',
    end_date: '',
  });

  useEffect(() => {
    if (!paramMonth && dataSettingLast) {
      if (getDayjsByTimeZone().format('YYYY-MM-DD') >= dataSettingLast.start_date) {
        setParamsearch((param: any) => ({
          ...param,
          month: getDayjsByTimeZone(dataSettingLast.end_date).format('YYYY-MM'),
        }));
        setMonth(getDayjsByTimeZone(dataSettingLast.end_date));
        setMonthInput(getDayjsByTimeZone(dataSettingLast.end_date));
      }
    }
  }, [paramMonth, dataSettingLast, isFetchingSettingLast]);

  useEffect(() => {
    setListMember(dataListMember?.data);
    setCycleCompanySettingSalary({
      start_date: dataListMember ? dataListMember?.start_date : '',
      end_date: dataListMember ? dataListMember?.end_date : '',
    });

    setPageStaff((page) => ({
      ...page,
      total: dataListMember?.total ?? 0,
    }));
  }, [dataListMember]);

  useEffect(() => {
    setIsLoading(isLoadingListMember || isFetching || isLoadingSettingLast || isFetchingSettingLast);
  }, [isLoadingListMember, isFetching, isLoadingSettingLast, isFetchingSettingLast]);

  useEffect(() => {
    let _month = isRegexMonth(paramMonth) ? getDayjsByTimeZone(paramMonth) : getDayjsByTimeZone();
    setParamsearch((param: any) => ({
      ...param,
      month: _month.format('YYYY-MM'),
    }));
    setMonth(_month);
    setMonthInput(_month);
  }, [paramMonth]);

  useEffect(() => {
    setParamsearch((param: any) => ({
      ...param,
      name: paramName,
    }));
    setValueSearch(paramName);
  }, [paramName]);

  useEffect(() => {
    setParamsearch((param: any) => ({
      ...param,
      page: paramPage,
    }));
    setPageStaff((page) => ({
      ...page,
      page: paramPage,
    }));
  }, [paramPage]);

  const maxPage = useMemo(() => {
    return getMaxPageCycle(cycleCompanySettingSalary.start_date, cycleCompanySettingSalary.end_date);
  }, [cycleCompanySettingSalary]);

  const listDate = useMemo(() => {
    return getAllDayOfMonthCycle(cycleCompanySettingSalary.start_date, cycleCompanySettingSalary.end_date);
  }, [cycleCompanySettingSalary]);

  const dayInWeek = useMemo(() => {
    return [...listDate].slice(DAY_OF_WEEK * (page - 1), DAY_OF_WEEK * page);
  }, [page, listDate]);

  const onChangeMonth = (e: Dayjs | any) => {
    if (e) {
      setMonth(e);
      setMonthInput(e);
      navigate({
        pathname: `/history`,
        search: `?${createSearchParams({ ...paramSearch, month: e.format('YYYY-MM'), page: 1 })}`,
      });
    } else {
      setMonthInput(undefined);
    }
  };

  const preWeek = async () => {
    if (page <= 1) return;
    setPage(page - 1);
  };
  const nextWeek = async () => {
    if (page >= maxPage) return;
    setPage(page + 1);
  };

  const onSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === EKeyCode.ENTER) {
      navigate({
        pathname: `/history`,
        search: `?${createSearchParams({ ...paramSearch, name: valueSearch, page: 1 })}`,
      });
    }
  };

  const changePaging = (pageChange: number) => {
    setPageStaff({
      ...pageStaff,
      page: pageChange,
    });
    navigate({
      pathname: `/history`,
      search: `?${createSearchParams({ ...paramSearch, page: pageChange })}`,
    });
  };

  const exportCSV = useCallback(() => {
    if (!dataExportFilter || dataExportFilter?.length === 0 || isLoadingExport || isLoadingDataExport) return;
    setIsLoadingExport(true);

    let dataCSVAll: any = [
      {
        first: '期間',
        second: cycleCompanySettingSalary.start_date,
        third: '〜',
        fourth: cycleCompanySettingSalary.end_date,
      },
      {
        first: '番号',
        second: '従業員コード',
        third: '従業員名',
        fourth: '申請額',
        fifth: 'システム利用料',
        sixth: '振込手数料',
        seventh: '合計金額',
      },
    ];

    // create per file csv per staff
    dataExportFilter.forEach((item: any, index: number) => {
      const request = item?.staff_salary_advance_detail_request;
      const totalSalary = request?.reduce((acc: number, curr: any) => {
        return acc + curr?.total_salary;
      }, 0);
      const totalSystemFee = request?.reduce((acc: number, curr: any) => {
        return acc + curr?.system_fee_tax_floor;
      }, 0);
      const totalTransactionFee = request?.reduce((acc: number, curr: any) => {
        return acc + curr?.transaction_fee_tax_floor;
      }, 0);

      const dataCSV = {
        first: index + 1,
        second: item?.code,
        third: item?.name,
        fourth: totalSalary,
        fifth: totalSystemFee,
        sixth: totalTransactionFee,
        seventh: totalSalary + totalSystemFee + totalTransactionFee,
      };
      dataCSVAll.push(dataCSV);
    });

    exportCSVFile(dataCSVAll, `${formatDateJP(paramSearch.month, false, false)}`, false);

    setTimeout(() => {
      setIsLoadingExport(false);
    }, 1000);
  }, [dataExportFilter, isLoadingExport, isLoadingDataExport, paramSearch.month, cycleCompanySettingSalary]);

  return {
    onChangeMonth,
    month,
    monthInput,
    page,
    maxPage,
    preWeek,
    nextWeek,
    dayInWeek,
    listDate,
    setPage,
    ref,
    listMember,
    defaultPage,
    changePaging,
    pageStaff,
    isLoading,
    onSearch,
    setValueSearch,
    valueSearch,
    exportCSV,
    isLoadingExport,
    isLoadingDataExport,
    dataExportLength: dataExportFilter?.length,
    cycleCompanySettingSalary,
  };
};

export default useAttendanceHistory;
