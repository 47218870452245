import { useState, useEffect, useCallback } from 'react';

import { IBread } from '@components/Breadcrumb/BreadCrumb';
import { CONST_EXPORT_INVOICE, CONST_LIST_INVOICE_COMPANY } from 'constants/language';
import { createInvoicePDF } from 'helper/export';
import { useNavigate, useParams } from 'react-router-dom';
import { apiGetCompanyInfoInvoice } from 'api';
import { apiGetInvoiceDetail, apiListInvoiceCompanyDetail } from 'api/company';
import { responseSuccess } from 'helper/responseSuccess/responseSuccess';
import { formatDateCommon } from 'helper/date';
import {
  ICompanyBasicOperatorInvoice,
  IFilterPage,
  IInvoiceDetailSiteCompany,
  MAX_ITEM_PAGE_ONE_COMPANY,
  MAX_ITEM_PAGE_OTHER_COMPANY,
  defaultPageInvoiceDetailCompany,
} from 'constants/invoice';
import { colors } from 'constants/colorsBase';

const useInvoiceDetailSiteCompany = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingAllDetail, setIsLoadingAllDetail] = useState<boolean>(false);
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
  const [invoiceInfo, setInvoiceInfo] = useState<ICompanyBasicOperatorInvoice>();
  const [invoiceDetail, setInvoiceDetail] = useState<IInvoiceDetailSiteCompany[]>();
  const [invoiceAllDetail, setInvoiceAllDetail] = useState<IInvoiceDetailSiteCompany[]>();
  const [page, setPage] = useState<IFilterPage>(defaultPageInvoiceDetailCompany);
  const [listInvoiceCompany, setListInvoiceCompany] = useState<any>([]);

  const BREADS: IBread[] = [
    {
      name: CONST_LIST_INVOICE_COMPANY.LIST_INVOICE,
      path: '/invoices',
    },
    {
      name: CONST_LIST_INVOICE_COMPANY.INVOICE_CONTENT,
      path: '',
    },
  ];

  const exportInvoicePDF = useCallback(() => {
    if (isLoadingExport || !invoiceInfo) {
      return;
    }
    let infoInvoices = {
      isGeneral: false,
      patternId: invoiceInfo.pattern_id,
    };
    if (id && id.includes('all')) {
      infoInvoices.isGeneral = true;
    }
    setIsLoadingExport(true);
    setTimeout(async () => {
      await createInvoicePDF(
        invoiceInfo.id,
        CONST_EXPORT_INVOICE.REQUEST_DATE + formatDateCommon(invoiceInfo?.request_date),
        CONST_EXPORT_INVOICE.EXPORT_FILE_NAME,
        true,
        false,
        infoInvoices
      );
      setIsLoadingExport(false);
    }, 100);
  }, [invoiceInfo, isLoadingExport, id]);

  const changePaging = useCallback(
    (pageChange: number) => {
      if (pageChange > 1) {
        setPage({
          ...page,
          page: pageChange,
          per_page: MAX_ITEM_PAGE_OTHER_COMPANY,
          offset: MAX_ITEM_PAGE_ONE_COMPANY + MAX_ITEM_PAGE_OTHER_COMPANY * (pageChange - 2),
        });
      } else {
        setPage({
          ...page,
          page: pageChange,
          per_page: MAX_ITEM_PAGE_ONE_COMPANY,
          offset: 0,
        });
      }
    },
    [page]
  );

  const getInvoiceInfo = async (invoiceId: any) => {
    try {
      const response = await apiGetCompanyInfoInvoice(invoiceId);
      if (responseSuccess(response)) {
        setInvoiceInfo(response.data);
      }
    } catch (error) {
      //
    }
  };

  const getInvoiceAllDetail = useCallback(async () => {
    try {
      setIsLoadingAllDetail(true);
      const response = await apiGetInvoiceDetail(id, { per_page: page.total });
      if (responseSuccess(response)) {
        setInvoiceAllDetail(response.data?.data);
      }
    } catch (error) {
      //
    } finally {
      setIsLoadingAllDetail(false);
    }
  }, [id, page.total]);

  const getInvoiceDetail = useCallback(
    async (filterPage: IFilterPage) => {
      if (isLoading) {
        return false;
      }
      try {
        setIsLoading(true);
        const response = await apiGetInvoiceDetail(id, {
          per_page: filterPage.per_page,
          offset: filterPage.offset,
        });
        if (responseSuccess(response)) {
          setInvoiceDetail(response.data?.data);
          if (filterPage.offset) {
            setPage({
              ...page,
              page: filterPage.page,
            });
          } else {
            setPage({
              ...page,
              page: filterPage.page,
              total: response.data?.total,
            });
          }
        }
      } catch (error) {
        //
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line
    [isLoading, id]
  );

  useEffect(() => {
    getInvoiceInfo(id);
  }, [id]);
  const getListCompany = async (invoiceInfo: any) => {
    let params: any = {
      start_date: invoiceInfo.start_date,
      end_date: invoiceInfo.end_date,
    };
    params.company_id = invoiceInfo.company_id;
    const response = await apiListInvoiceCompanyDetail(params);
    let listCompany = [];
    listCompany.push({
      value: `${params.company_id}_${invoiceInfo.usage_plan}_${invoiceInfo.start_date}_${invoiceInfo.end_date}_all`,
      label: '総括',
      color: colors.mainColorCompany,
    });
    listCompany.push({
      value: `${params.company_id}_${invoiceInfo.usage_plan}_${invoiceInfo.start_date}_${invoiceInfo.end_date}`,
      label: response?.data?.[0]?.company_name ? response?.data?.[0].company_name : invoiceInfo.company_name,
      color: colors.mainColorCompany,
    });
    response.data?.forEach((company: any) => {
      listCompany.push({
        value: `${company.company_paired_id}_${company.company_plan}_${invoiceInfo.start_date}_${invoiceInfo.end_date}`,
        label: company.company_paired_name,
        color: company.company_paired_plan === company.company_plan ? colors.mainColorCompany : colors.lightGray,
      });
    });
    setListInvoiceCompany(listCompany);
  };
  useEffect(() => {
    if (invoiceInfo && invoiceInfo.pattern_id === 0) {
      getListCompany(invoiceInfo);
    }
  }, [invoiceInfo]);

  useEffect(() => {
    if (page.total) {
      getInvoiceAllDetail();
    }
    // eslint-disable-next-line
  }, [page.total]);

  useEffect(() => {
    getInvoiceDetail(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, page.page]);
  const navigate = useNavigate();
  const changeCompanyInvoice = (e: any) => {
    setPage(defaultPageInvoiceDetailCompany);
    navigate(`/invoices/detail/${e}`);
  };
  return {
    BREADS,
    invoiceDetail,
    page,
    changePaging,
    isLoading,
    exportInvoicePDF,
    invoiceAllDetail,
    isLoadingExport,
    invoiceInfo,
    isLoadingAllDetail,
    listInvoiceCompany,
    changeCompanyInvoice,
  };
};

export default useInvoiceDetailSiteCompany;
