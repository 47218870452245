import { useCallback, useEffect, useState } from 'react';
import { responseSuccess } from 'helper/responseSuccess/responseSuccess';
import textHelpers from 'helper/text';
import { apiCompanyCreateAdmin, apiCompanyEditAdmin } from 'api/company';
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useHookCompanyAccountList } from '@hooks/useCompany';
import { COMPANY_LIST_ACCOUNT_ADMIN } from 'constants/keyQuery';
import { useQueryClient } from 'react-query';

export interface IAdminAccount {
  id: number;
  full_name: string;
  name_kana: string;
  type?: number;
  email: string;
  created_at: string;
}

export interface IFormDataAdminAccount {
  id?: number;
  full_name: string;
  name_kana: string;
  email: string;
}

export const STATE_PAGE = {
  VIEW: 1,
  EDIT: 2,
  CREATE: 3,
};

const useListAdminAccount = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const [listAdminAccount, setListAdminAccount] = useState<IAdminAccount[]>([]);
  const [selected, setSelected] = useState<IAdminAccount | null>(null);
  const [isLoadingCreateAdminAccount, setIsLoadingCreateAdminAccount] = useState<boolean>(false);
  const [isLoadingEditAdminAccount, setIsLoadingEditAdminAccount] = useState<boolean>(false);

  const [paramSearch, setParamsearch] = useState<any>({
    key_word: '',
  });

  const [statePage, setStatePage] = useState<number>(STATE_PAGE.VIEW);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const { data: allListAdminAccount, isLoading } = useHookCompanyAccountList();
  const { id } = useParams();
  const query = new URLSearchParams(location.search);
  const paramName = query.get('key_word') ?? '';

  useEffect(() => {
    if (!id) {
      setSelected(null);
    }
  }, [id, allListAdminAccount]);

  const updateListAccount = useCallback(
    async (searchText: string) => {
      let dataSearch: IAdminAccount[] = [];
      if (allListAdminAccount?.length) {
        const listSearch = [...allListAdminAccount].map((element: IAdminAccount) => element.full_name);
        dataSearch = allListAdminAccount.filter(
          (_: IAdminAccount, index: number) =>
            listSearch[index]
              .replaceAll(/\s/g, '')
              .toLowerCase()
              .includes(searchText.toLowerCase().replaceAll(/\s/g, '')) ||
            textHelpers.searchTextInLongText({
              text: searchText,
              longText: listSearch[index],
            }) ||
            searchText === ''
        );
      }
      setListAdminAccount(dataSearch);
    },
    [allListAdminAccount]
  );

  useEffect(() => {
    setListAdminAccount(allListAdminAccount);
  }, [allListAdminAccount]);

  useEffect(() => {
    setParamsearch((prevPage: any) => ({
      ...prevPage,
      key_word: paramName,
    }));
    setSearchText(paramName);
    updateListAccount(paramName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(paramName)]);

  const onSearch = (searchText: string) => {
    updateListAccount(searchText);
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams({ ...paramSearch, key_word: searchText })}`,
    });
  };

  const createAdminAccount = async (formData: IFormDataAdminAccount) => {
    try {
      setIsLoadingCreateAdminAccount(true);
      const response = await apiCompanyCreateAdmin(formData);
      if (responseSuccess(response)) {
        queryClient.refetchQueries(COMPANY_LIST_ACCOUNT_ADMIN);
        setSearchText('');
      }
      return response;
    } catch (error) {
      return error;
    } finally {
      setIsLoadingCreateAdminAccount(false);
    }
  };

  const editAdminAccount = async (formData: IFormDataAdminAccount) => {
    try {
      setIsLoadingEditAdminAccount(true);
      const response = await apiCompanyEditAdmin(formData.id, formData);
      if (responseSuccess(response)) {
        queryClient.refetchQueries(COMPANY_LIST_ACCOUNT_ADMIN);
        setSelected(null);
      }
      return response;
    } catch (error) {
      return error;
    } finally {
      setIsLoadingEditAdminAccount(false);
    }
  };

  useEffect(() => {
    if (location.pathname.indexOf('/list') !== -1) setStatePage(STATE_PAGE.VIEW);
    if (location.pathname.indexOf('/edit') !== -1) setStatePage(STATE_PAGE.EDIT);
    if (location.pathname.indexOf('/create') !== -1) setStatePage(STATE_PAGE.CREATE);
  }, [location.pathname]);

  // useEffect(() => {
  //   onSearch(searchText || '');
  //   // eslint-disable-next-line
  // }, [allListAdminAccount, onSearch]);

  return {
    listAdminAccount,
    selected,
    isLoading,
    onSearch,
    setSelected,
    createAdminAccount,
    editAdminAccount,
    isLoadingCreateAdminAccount,
    isLoadingEditAdminAccount,
    statePage,
    setStatePage,
    searchText,
    setSearchText,
  };
};

export default useListAdminAccount;
