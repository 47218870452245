import React from 'react';

import images from '@assets/images-base';
import Loading from '@components/Loading';
import BreadCrumb from '@components/Breadcrumb/BreadCrumb';
import DatePickerCustom from '@components/CompanySite/AttendanceRecord/DatePickerCustom/DatePickerCustom';
import PaginationRecord from '@components/CompanySite/AttendanceRecord/PaginationRecord/PaginationRecord';
import useAttendanceDetail from './useAttendanceDetail';

import { SpaceBase } from 'styles';
import { SettingIcon } from '@containers/OperatorSite/OperatorBank/DetailBank/detailBankStyle';
import { DetailWrapper } from '@components/CompanySite/common/styled';
import { AttendanceTable } from '@containers/CompanySite/AttendanceDetail/AttendanceTable/AttendanceTable';
import { TitleHeaderSetting } from '@pages/OperatorSite/SettingPage/settingPageStyle';
import { SearchInputWrapper } from '@components/CompanySite/AttendanceRecord/SearchInput/searchInputStyle';
import { Container, GrantCard } from '@components/Style/Style';
import { HeaderSettingWrapper, NoteDiv } from '@containers/OperatorSite/OperatorBank/operatorBankStyle';
import { CONST_ATTENDANCE_COMPANY, CONST_COMMON, CONST_SETTING_SALARY } from 'constants/language';
import { ControlHeaderWrapper, LoadingAttendance, NoDataAttendance } from '../AttendanceRecord/attendanceRecordStyle';
import styled from 'styled-components';
import { ECompanyStaffSalaryType } from 'constants/constants';

const AttendanceDetail = () => {
  const {
    BREADS,
    isLoading,
    pageStaff,
    currentDate,
    currentDateInput,
    attendanceDetailData,
    changePaging,
    onChangeMonth,
    onSearch,
    setValueSearch,
    isRootAdmin,
    valueSearch,
    dataCompanySettingSalaryDetail,
    isLoadingCompanySettingSalaryDetail,
    cycleCompanySettingSalary,
    changeStatus,
    typeStatus,
    typeFilter,
    setAttendanceDetailData,
  } = useAttendanceDetail();
  const ButtonFilterStatus = styled.div<any>`
    width: 12.438rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: ${(props) => props?.color !== '#ffffff' && `1px solid ${props?.color}`};
    box-shadow: ${(props) =>
      props?.color !== '#ffffff' ? `2px 3px 3px 0px #00000040` : `2px 3px 3px 0px #00000040 inset`};
    margin-right: 10px;
    border-radius: 20px;
    font-weight: 700;
    font-size: 1.5rem;
    color: ${(props) => props?.color};
    background-color: ${(props) => props?.background};
    &:hover {
      cursor: pointer;
    }
  `;
  return (
    <DetailWrapper>
      <BreadCrumb breads={BREADS} />
      <Container>
        <GrantCard padding="1.25rem 0rem" percentWidth="100%" width={106.25}>
          <div>
            <HeaderSettingWrapper>
              <SettingIcon src={images.companySite.rushIcon} alt="setting" />
              <TitleHeaderSetting>{CONST_ATTENDANCE_COMPANY.ATTENDANCE_CONFIRMATION}</TitleHeaderSetting>
              {typeFilter.month > 0 && (
                <ButtonFilterStatus
                  color={typeStatus === ECompanyStaffSalaryType.MONTHLY_SALARY ? '#ffffff' : '#47CAD2'}
                  background={typeStatus === ECompanyStaffSalaryType.MONTHLY_SALARY ? '#47CAD2' : '#ffffff'}
                  style={{ marginLeft: '1rem' }}
                  onClick={() => changeStatus(ECompanyStaffSalaryType.MONTHLY_SALARY)}
                >
                  {CONST_SETTING_SALARY.MONTH_SALARY}
                </ButtonFilterStatus>
              )}
              {typeFilter.day > 0 && (
                <ButtonFilterStatus
                  color={typeStatus === ECompanyStaffSalaryType.DAILY_SALARY ? '#ffffff' : '#FDAB29'}
                  background={typeStatus === ECompanyStaffSalaryType.DAILY_SALARY ? '#FDAB29' : '#ffffff'}
                  onClick={() => changeStatus(ECompanyStaffSalaryType.DAILY_SALARY)}
                  style={{ marginLeft: '1rem' }}
                >
                  {CONST_SETTING_SALARY.DAILY_WAGE}
                </ButtonFilterStatus>
              )}
              {typeFilter.hour > 0 && (
                <ButtonFilterStatus
                  color={typeStatus === ECompanyStaffSalaryType.HOUR_SALARY ? '#ffffff' : '#53B889'}
                  background={typeStatus === ECompanyStaffSalaryType.HOUR_SALARY ? '#53B889' : '#ffffff'}
                  onClick={() => changeStatus(ECompanyStaffSalaryType.HOUR_SALARY)}
                  style={{ marginLeft: '1rem' }}
                >
                  {CONST_SETTING_SALARY.HOUR_WAGE}
                </ButtonFilterStatus>
              )}
              {isRootAdmin && <NoteDiv>{CONST_ATTENDANCE_COMPANY.ATTENDANCE_NOTE_TEXT}</NoteDiv>}
            </HeaderSettingWrapper>

            <ControlHeaderWrapper>
              <DatePickerCustom
                onChangeMonth={onChangeMonth}
                month={currentDateInput}
                normalPicker={true}
                // isLoading={isLoading}
              />
              {/* <SearchInput searchAttendance={searchAttendance} /> */}
              <SearchInputWrapper
                onKeyDown={onSearch}
                placeholder={CONST_COMMON.SEARCH_BY_NAME}
                onChange={(e) => setValueSearch(e.target.value)}
                value={valueSearch}
              />
              <PaginationRecord
                current={pageStaff.page}
                total={pageStaff.total}
                pageSize={pageStaff.per_page}
                onChange={changePaging}
              />
            </ControlHeaderWrapper>

            {isLoading && (
              <LoadingAttendance>
                <SpaceBase height={10} />
                <Loading />
              </LoadingAttendance>
            )}
            {attendanceDetailData &&
              attendanceDetailData.length === 0 &&
              !isLoading &&
              typeFilter.month === 0 &&
              typeFilter.day === 0 &&
              typeFilter.hour === 0 && <NoDataAttendance>{CONST_COMMON.NO_DATA}</NoDataAttendance>}
            {attendanceDetailData && attendanceDetailData.length > 0 && (
              <AttendanceTable
                attendanceDetailData={attendanceDetailData}
                currentDate={currentDate}
                dataCompanySettingSalaryDetail={dataCompanySettingSalaryDetail}
                isLoadingCompanySettingSalaryDetail={isLoadingCompanySettingSalaryDetail}
                cycleCompanySettingSalary={cycleCompanySettingSalary}
                setAttendanceDetailData={setAttendanceDetailData}
              />
            )}
          </div>
        </GrantCard>
      </Container>
    </DetailWrapper>
  );
};

export default AttendanceDetail;
