import { message } from 'antd';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export const createCSVFile = (data: any, fileName: string, isDownload: boolean = true) => {
  const csvData = data.map((row: any) => Object.values(row).join(',')); // Chuyển dữ liệu thành chuỗi CSV

  const csvContent = `\uFEFF${csvData.join('\n')}`; // Nội dung của tệp CSV

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  if (!isDownload) {
    return blob;
  }
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', `${fileName}.csv`);
  link.style.visibility = 'hidden';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const exportCSVFilesToZip = (data: any, fileName: string) => {
  if (!data || data?.length === 0) {
    message.error('データがありません。');
    return;
  }
  const zip = new JSZip();

  data?.forEach((item: any) => {
    if (item.data && item.fileName) {
      zip.file(`${item.fileName}.csv`, createCSVFile(item.data, item.fileName, false) as any, { dir: false });
    }
  });

  zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, `${fileName}.zip`);
  });
};

export const exportCSVFile = (data: any, fileName: string, isDownload: boolean = true) => {
  const headers = Object.keys(data[1]);
  const csvData = data.map((row: any) => headers.map((header) => row[header]));
  const csvDataFilter = csvData.map((data: any) => data.filter((item: any) => item !== undefined));
  const csvContent = `\uFEFF${csvDataFilter.map((row: any) => row.join(',')).join('\n')}`;
  // Tạo Blob từ dữ liệu CSV
  const csvBold = new Blob([csvContent], { type: 'application/csv; charset=utf-8;' });
  let link = document.createElement('a');
  link.download = `${fileName}.csv`;
  link.href = window.URL.createObjectURL(csvBold);
  link.click();
};
