import { useState, useEffect, useCallback } from 'react';

import { Dayjs } from 'dayjs';
import { IBread } from '@components/Breadcrumb/BreadCrumb';
import { CONST_LIST_INVOICE_COMPANY } from 'constants/language';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { ICompanyBasicOperatorInvoice, IFilterPage, defaultPageInvoiceDetailCompany } from 'constants/invoice';
import { getDayjsByTimeZone, isRegexMonth } from 'helper/date';
import { useHookListInvoice } from '@hooks/useCompany';
import { _isNumber } from 'helper';

const useListInvoiceSiteCompany = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [listInvoice, setListInvoice] = useState<ICompanyBasicOperatorInvoice[] | null>();
  const [currentMonth, setCurrentMonth] = useState<Dayjs>();
  const [page, setPage] = useState<IFilterPage>({ ...defaultPageInvoiceDetailCompany, per_page: 10 });

  const paramMonth = query.get('month') ?? '';
  const paramPage = _isNumber(query.get('page'), 1);

  const [paramSearch, setParamsearch] = useState<any>({
    date: '',
    per_page: 10,
    page: 1,
  });

  const { data: dataListInvoice, isLoading } = useHookListInvoice(paramSearch);

  const BREADS: IBread[] = [
    {
      name: CONST_LIST_INVOICE_COMPANY.LIST_INVOICE,
      path: '',
    },
  ];

  useEffect(() => {
    setListInvoice(dataListInvoice?.data);
    setPage((page) => ({
      ...page,
      total: dataListInvoice?.total ?? 0,
    }));
  }, [dataListInvoice]);

  useEffect(() => {
    setParamsearch((param: any) => ({
      ...param,
      page: paramPage,
    }));
    setPage((page) => ({
      ...page,
      page: paramPage,
    }));
  }, [paramPage]);

  useEffect(() => {
    if (paramMonth && isRegexMonth(paramMonth)) {
      setParamsearch((param: any) => ({
        ...param,
        date: paramMonth,
      }));
      setCurrentMonth(getDayjsByTimeZone(paramMonth));
    }
  }, [paramMonth]);

  const changePaging = useCallback(
    (pageChange: number) => {
      navigate({
        pathname: `/invoices`,
        search: `?${createSearchParams({ ...paramSearch, page: pageChange })}`,
      });
    },
    [navigate, paramSearch]
  );

  const onChangeMonth = useCallback(
    (e: Dayjs | any) => {
      setCurrentMonth(e);
      navigate({
        pathname: `/invoices`,
        search: `?${createSearchParams({ ...paramSearch, month: e ? e.format('YYYY-MM') : '', page: 1 })}`,
      });
    },
    [navigate, paramSearch]
  );

  return {
    navigate,
    BREADS,
    listInvoice,
    page,
    changePaging,
    isLoading,
    onChangeMonth,
    currentMonth,
  };
};

export default useListInvoiceSiteCompany;
