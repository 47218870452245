import { useState, useEffect, useMemo } from 'react';

import dayjs, { Dayjs } from 'dayjs';

import { getDaysOfMonth } from 'helper/getAllDayOfMonth/getAllDayOfMonth';
import { apiOperatorGetChartDateMonth, apiOperatorGetChartMonYear } from 'api/operator';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { getDayjsByTimeZone, isRegexYear } from 'helper/date';
import { _isNumber } from 'helper';

interface IDataChart {
  month: string | number;
  total_request: number;
  total_salary: string | number;
  date?: string;
}
const months: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const useChartOperator = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramYear = query.get('year') ?? '';
  const paramTypeDate = _isNumber(query.get('type_date'), 1);
  const paramType = _isNumber(query.get('type'), 1);

  const [year, _setYear] = useState(isRegexYear(paramYear) ? getDayjsByTimeZone(paramYear) : dayjs());
  const [selected, _setSelected] = useState(paramTypeDate > 2 ? 1 : paramTypeDate);
  const [selectedSwitchDate, _setSelectedSwitchDate] = useState<number>(paramType > 2 ? 1 : paramType);

  const [dataChartOperatorRaw, setDataChartOperatorRaw] = useState<IDataChart[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getDataChartMonthYear = async () => {
    try {
      setIsLoading(true);
      const response = await apiOperatorGetChartMonYear({
        year: year.format('YYYY'),
      });
      setDataChartOperatorRaw(
        months.map((element: number) => {
          const find = response?.data?.find(
            (elementFind: IDataChart) => dayjs(elementFind.month).month() + 1 === element
          );
          if (find) return find;
          return {
            month: element,
            total_request: 0,
            total_salary: 0,
          };
        })
      );
    } catch (error) {
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  };

  const getDataChartDayMonth = async () => {
    try {
      setIsLoading(true);
      const response = await apiOperatorGetChartDateMonth({
        month: year.format('YYYY-MM'),
      });
      const arrDay = getDaysOfMonth(year);
      setDataChartOperatorRaw(
        arrDay.map((element: string) => {
          const find = response?.data?.find((elementFind: IDataChart) => {
            return Number(elementFind?.date?.split('-')[2]) === Number(element);
          });
          if (find) {
            return find;
          }
          return {
            month: element,
            total_request: 0,
            total_salary: 0,
          };
        })
      );
    } catch (error) {
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  };
  useEffect(() => {
    _setYear(isRegexYear(paramYear) ? getDayjsByTimeZone(paramYear) : dayjs());
  }, [paramYear]);

  useEffect(() => {
    _setSelected(paramTypeDate > 2 ? 1 : paramTypeDate);
  }, [paramTypeDate]);

  useEffect(() => {
    _setSelectedSwitchDate(paramType > 2 ? 1 : paramType);
  }, [paramType]);

  useEffect(() => {
    if (selectedSwitchDate === 1) {
      getDataChartMonthYear();
    } else {
      getDataChartDayMonth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, selectedSwitchDate]);

  const dataChartOperatorCurrent = useMemo(() => {
    return dataChartOperatorRaw.map((element: IDataChart) => {
      if (selected === 1) return Number(element.total_salary);
      return element.total_request;
    });
  }, [dataChartOperatorRaw, selected]);

  const setYear = (e: Dayjs) => {
    _setYear(e);
    navigate({
      pathname: `/`,
      search: `?${createSearchParams({
        year: e.format('YYYY'),
        type_date: selected?.toString(),
        type: selected.toString(),
        page: '1',
      })}`,
    });
  };

  const setSelected = (value: number) => {
    _setSelected(value);
    navigate({
      pathname: `/`,
      search: `?${createSearchParams({
        year: year.format('YYYY'),
        type_date: selectedSwitchDate.toString(),
        type: value.toString(),
      })}`,
    });
  };

  const setSelectedSwitchDate = (value: number) => {
    _setSelectedSwitchDate(value);
    navigate({
      pathname: `/`,
      search: `?${createSearchParams({
        year: year.format('YYYY'),
        type_date: value.toString(),
        type: selected.toString(),
        page: '1',
      })}`,
    });
  };

  return {
    selected,
    setSelected,
    selectedSwitchDate,
    setSelectedSwitchDate,
    dataChartOperatorCurrent,
    year,
    setYear,
    isLoading,
  };
};

export default useChartOperator;
