import React, { useState, useEffect } from 'react';
import { LoadingAgencyWrapper, TopPageAgencyCard, WrapperFlexRow } from './topPageAgencyStyle';
import { GrantCompanyWrapper } from '@pages/OperatorSite/Companies/CreateCompany/createCompanyStyle';
import { Container } from '@components/Style/Style';
import IntroducedCompanySearch from '@containers/AgencySite/TopPage/IntroducedCompanySearch/IntroducedCompanySearch';
import AgencyChartComponent from '@containers/AgencySite/TopPage/AgencyChartComponent/AgencyChartComponent';
import RankUseAgency from '@containers/AgencySite/TopPage/RankUseAgency/RankUseAgency';
import Loading from '@components/Loading';
import dayjs from 'dayjs';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { _isNumber } from 'helper';
import { isRegexYear } from 'helper/date';

const TopPageAgency = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramYear = query.get('year') ?? dayjs().format('YYYY');
  const paramType = _isNumber(query.get('type'), 1);

  const [isLoadingChart, setIsLoadingChart] = useState<boolean>(true);
  const [isLoadingRank, setIsLoadingRank] = useState<boolean>(true);
  const [year, _setValue] = useState(dayjs());

  useEffect(() => {
    _setValue(isRegexYear(paramYear) ? dayjs(paramYear) : dayjs());
  }, [paramYear]);

  const setValue = (e: any) => {
    _setValue(e);
    navigate({
      pathname: `/`,
      search: `?${createSearchParams({
        year: e.format('YYYY'),
        type: paramType > 3 ? '1' : paramType.toString(),
      })}`,
    });
  };

  return (
    <GrantCompanyWrapper>
      <Container>
        <TopPageAgencyCard>
          {(isLoadingChart || isLoadingRank) && (
            <LoadingAgencyWrapper>
              <Loading />
            </LoadingAgencyWrapper>
          )}
          <IntroducedCompanySearch />
          <WrapperFlexRow>
            <AgencyChartComponent setIsLoadingChart={setIsLoadingChart} year={year} setYear={setValue} />
            <RankUseAgency setIsLoadingRank={setIsLoadingRank} year={year} />
          </WrapperFlexRow>
        </TopPageAgencyCard>
      </Container>
    </GrantCompanyWrapper>
  );
};

export default TopPageAgency;
