import styled from 'styled-components';

export const BankCodeSelectWrapper = styled.div`
  margin-left: 19.5rem;
  margin-top: 1rem;
  .ant-form-item-explain-error {
    position: absolute;
    font-size: 0.8rem;
  }
  
`;
