import React from 'react';
import {
  CompanyName,
  DateInvoice,
  DateListInvoice,
  HeaderTable,
  InvoiceItemWrapper,
  ItemHeader,
  ListInvoiceTableWrapper,
  StatusClosingDateTitle,
  UsagePlan,
  YenItem,
  ItemContent,
} from './listInvoiceTableStyle';
import { CONST_LIST_INVOICE_COMPANY, CONST_OPERATOR_INVOICE } from 'constants/language';
import { formatDateJP } from 'helper/date';
import { Collapse } from 'antd';
import { DEPOSIT_TYPE, ICompanyBasicOperatorInvoice } from 'constants/invoice';
import { isNotDateDueYet } from 'helper';
import styled from 'styled-components';
import { IconDown } from 'components/DropdownCustom/dropdownStyle';
import { getFullHostName } from 'helper';
import configs from 'config';
import images from '@assets/images-base';
import { colors } from 'constants/colorsBase';
import { DataInvoice } from './DataInvoice';

interface IListInvoiceCompanyTableProps {
  navigate: any;
  listInvoice: ICompanyBasicOperatorInvoice[];
  dateTable?: string;
  paramSearch?: any;
}

const { Panel } = Collapse;
const CustomCollapse = styled(Collapse)`
  &.ant-collapse {
    border: none;
    border-radius: 0rem;
    background-color: #ffffff;
    font-size: 1.25rem;
  }
  &.ant-collapse > .ant-collapse-item:last-child {
    border-radius: 0rem;
    border-bottom: none;
  }
  &.ant-collapse>.ant-collapse-item: last-child>.ant-collapse-header {
    border-radius: 0rem;
  }
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0rem;
  }
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
    margin-inline-start: 0rem;
  }
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {
    padding-inline-start: 0.625rem;
    padding-block-start: 2rem;
    padding-inline-end: 0.75rem;
  }
  &.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0rem;
  }
  &.ant-collapse .ant-collapse-content {
    border: none;
  }
  &.ant-collapse .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0rem;
  }
  &.hasBG {
    background: ${colors.colorColumnInvoice};
  }
`;
const ItemHeaderContent = styled.div`
  display: flex;
  align-items: center;
  width: 84.5rem;
  background: ${colors.white};
  height: 3.75rem;
  padding: 0rem 2.9rem 0rem 0.4rem ;
  border-radius: 0.625rem;
  position: relative;
  margin-left: -0.313rem;
  &.hasBG {
    background: ${colors.colorColumnInvoice};
  }
`;
const ItemContentSon = styled.div`
  display: flex;
  align-items: center;
  width: 87.5rem;
  background: ${colors.white};
  height: 3.75rem;
  padding: 0px 3.6rem;
  border-radius: 0.625rem;
  position: relative;
  &.hasBG {
    background: ${colors.colorColumnInvoice};
  }
`;
export const ListInvoiceCompanyTable = ({
  navigate,
  listInvoice,
  dateTable,
  paramSearch,
}: IListInvoiceCompanyTableProps) => {
  const getUseRoleByHostName = () => {
    switch (getFullHostName()) {
      case configs.APP_FRONTEND_COMPANY:
        return images.companySite.arrowBottomCompany;
      default:
        return images.company.down;
    }
  };
  return (
    <ListInvoiceTableWrapper>
      <HeaderTable>
        <DateListInvoice>
          {formatDateJP(dateTable, false, false)}　{CONST_LIST_INVOICE_COMPANY.LIST_INVOICE}
        </DateListInvoice>
      </HeaderTable>
      <InvoiceItemWrapper>
        <ItemHeader>
          <CompanyName>{CONST_OPERATOR_INVOICE.LIST_INVOICE_COMPANY_TABLE.COMPANY_NAME}</CompanyName>
          <DateInvoice>{CONST_OPERATOR_INVOICE.LIST_INVOICE_COMPANY_TABLE.BILLING_DATE}</DateInvoice>
          <DateInvoice>{CONST_OPERATOR_INVOICE.LIST_INVOICE_COMPANY_TABLE.TRANSFER_DEADLINE}</DateInvoice>
          <YenItem>{CONST_OPERATOR_INVOICE.LIST_INVOICE_COMPANY_TABLE.TRANSFER_AMOUNT}</YenItem>
          <UsagePlan>{CONST_OPERATOR_INVOICE.LIST_INVOICE_COMPANY_TABLE.USAGE_PLAN}</UsagePlan>
          {Number(paramSearch.usage_plan) !== DEPOSIT_TYPE && (
            <StatusClosingDateTitle>
              {CONST_OPERATOR_INVOICE.LIST_INVOICE_COMPANY_TABLE.STATUS_CLOSING_DATE_TITLE}
            </StatusClosingDateTitle>
          )}
          {Number(paramSearch.usage_plan) === DEPOSIT_TYPE &&
            !!listInvoice?.length &&
            isNotDateDueYet(listInvoice[0].closing_date) && (
              <div className="not-due">{CONST_LIST_INVOICE_COMPANY.TEXT_DUE}</div>
            )}
        </ItemHeader>
        {listInvoice.map((item: ICompanyBasicOperatorInvoice, index: number) => {
          return (
            <>
              {item.pattern_id !== null ? (
                <CustomCollapse
                  key={item.id}
                  className={index % 2 === 1 ? 'hasBG' : ''}
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <IconDown width={1.3} src={getUseRoleByHostName()} alt="" />
                    ) : (
                      <IconDown width={1.3} src={images.company.noActive} alt="" />
                    )
                  }
                >
                  <Panel
                    header={
                      <ItemHeaderContent key={item.id} className={index % 2 === 1 ? 'hasBG' : ''}>
                        <DataInvoice item={item} navigate={navigate} paramSearch={paramSearch} isParent={true} />
                      </ItemHeaderContent>
                    }
                    key="1"
                  >
                    {item.invoice_company_paired?.map((invoice: any) => {
                      return (
                        <ItemContentSon key={invoice.id}>
                          <DataInvoice item={invoice} navigate={navigate} paramSearch={paramSearch} />
                        </ItemContentSon>
                      );
                    })}
                  </Panel>
                </CustomCollapse>
              ) : (
                <ItemContent key={item.id} className={index % 2 === 1 ? 'hasBG' : ''}>
                  <DataInvoice item={item} navigate={navigate} paramSearch={paramSearch} />
                </ItemContent>
              )}
            </>
          );
        })}
      </InvoiceItemWrapper>
    </ListInvoiceTableWrapper>
  );
};
