import { CONST_ATTENDANCE_COMPANY } from 'constants/language';
import dayjs from 'dayjs';
import React from 'react';
import { SpaceBase } from 'styles';
import { Confirmed } from '../../AttendanceHeaderTable/attendanceHeaderTableStyle';
import { TimeActiveItemWrapper, TimeWrapper, Brackets, TimeContent } from './timeActiveItemStyle';
import { ECompanyStaffSalaryType, ESettingTimer, ETimeShift, ETimeShiftFormat } from 'constants/constants';
import { getDayjsByTimeZone } from 'helper/date';
import CountDownDate from '@components/CountdownTimer/CountDownDate';

const TimeActiveItem = ({
  isConfirmed,
  date,
  isTimeNoon,
  time,
  isTimeClosing,
  isDisableBtn = false,
  element,
  setStateHour,
}: {
  isConfirmed: boolean;
  date: string;
  isTimeNoon: boolean;
  isDisableBtn?: boolean;
  time?: string;
  isTimeClosing: number | null;
  element?: any;
  setStateHour: any;
}) => {
  const timeShift = isTimeNoon ? ETimeShiftFormat.NOON : ETimeShiftFormat.NIGHT;
  const isClosed = date && time ? getDayjsByTimeZone(date + ' ' + time).isBefore(getDayjsByTimeZone()) : false;

  const renderCountdown = (time: string | undefined) => {
    return (
      isTimeClosing === ESettingTimer.SETTING_TIMER &&
      time &&
      getDayjsByTimeZone(date + ' ' + time).isAfter(getDayjsByTimeZone()) && (
        <div className="attendance-count-down">
          <CountDownDate
            date={getDayjsByTimeZone(date + ' ' + time).format('YYYY-MM-DD HH:mm:ss')}
            text={'確定まで'}
            salaryType={element?.salary_type}
            setStateHour={setStateHour}
          />
        </div>
      )
    );
  };

  // const formatTime = (time: string | undefined) => {
  //   if (time) {
  //     return time.substring(0, 5).replace(/:/g, '：');
  //   }
  //   return '';
  // };

  const renderComponent = () => {
    if (
      isTimeNoon &&
      element?.date &&
      dayjs(element.date).format('YYYY-MM-DD') <= dayjs().format('YYYY-MM-DD') &&
      ((element?.closing_day_shift_salary &&
        element?.closing_day_shift_salary >= dayjs().format('YYYY-MM-DD HH:mm:ss')) ||
        (element?.closing_morning_haft && element?.closing_morning_haft >= dayjs().format('YYYY-MM-DD HH:mm:ss')))
    ) {
      if (element?.day_shift === ETimeShift.ACTIVITY) {
        if (
          isTimeClosing === ESettingTimer.SETTING_STATIC &&
          element.salary_type === ECompanyStaffSalaryType.DAILY_SALARY
        ) {
          return (
            <>
              {(element?.closing_day_shift_salary || element.closing_morning_haft) && (
                <TimeWrapper>
                  <Brackets>(</Brackets>
                  <TimeContent>
                    <div>{dayjs().format('YYYY/MM/DD')}</div>
                    <div>
                      {dayjs(element?.closing_day_shift_salary || element.closing_morning_haft).format('HH:mm') ||
                        timeShift}
                    </div>
                  </TimeContent>
                  <Brackets>)</Brackets>
                </TimeWrapper>
              )}
            </>
          );
        } else {
          if (element.salary_type === ECompanyStaffSalaryType.MONTHLY_SALARY) {
            if (element.date < dayjs().format('YYYY-MM-DD')) {
              return (
                <Confirmed>
                  <SpaceBase width={1.563} />
                  {CONST_ATTENDANCE_COMPANY.CONFIRMED_BRACKET}
                </Confirmed>
              );
            }
          }
          if (element.salary_type === ECompanyStaffSalaryType.DAILY_SALARY) {
            return (
              <div className="attendance-count-down">
                <CountDownDate
                  date={getDayjsByTimeZone(date + ' ' + time).format('YYYY-MM-DD HH:mm:ss')}
                  text={'確定まで'}
                  salaryType={element?.salary_type}
                  setStateHour={setStateHour}
                />
              </div>
            );
          }
        }
      }
    }
    if (
      !isTimeNoon &&
      element?.date &&
      dayjs(element.date).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD') &&
      ((element?.closing_night_shift_salary &&
        element?.closing_night_shift_salary >= dayjs().format('YYYY-MM-DD HH:mm:ss')) ||
        (element?.closing_afternoon_haft && element?.closing_afternoon_haft >= dayjs().format('YYYY-MM-DD HH:mm:ss')))
    ) {
      if (element?.night_shift === ETimeShift.ACTIVITY) {
        if (
          isTimeClosing === ESettingTimer.SETTING_STATIC &&
          element.salary_type === ECompanyStaffSalaryType.DAILY_SALARY
        ) {
          return (
            <>
              {(element?.closing_night_shift_salary || element?.closing_afternoon_haft) && (
                <TimeWrapper>
                  <Brackets>(</Brackets>
                  <TimeContent>
                    <div>{dayjs().format('YYYY/MM/DD')}</div>
                    <div>
                      {dayjs(element?.closing_night_shift_salary || element?.closing_afternoon_haft).format('HH:mm') ||
                        timeShift}
                    </div>
                  </TimeContent>
                  <Brackets>)</Brackets>
                </TimeWrapper>
              )}
            </>
          );
        } else {
          if (element.salary_type === ECompanyStaffSalaryType.MONTHLY_SALARY) {
            if (element.date < dayjs().format('YYYY-MM-DD')) {
              return (
                <Confirmed>
                  <SpaceBase width={1.563} />
                  {CONST_ATTENDANCE_COMPANY.CONFIRMED_BRACKET}
                </Confirmed>
              );
            }
          }
          if (element.salary_type === ECompanyStaffSalaryType.DAILY_SALARY) {
            return (
              <div className="attendance-count-down">
                <CountDownDate
                  date={getDayjsByTimeZone(date + ' ' + time).format('YYYY-MM-DD HH:mm:ss')}
                  text={'確定まで'}
                  salaryType={element?.salary_type}
                  setStateHour={setStateHour}
                />
              </div>
            );
          }
        }
      }
    }
    return (
      <Confirmed>
        <SpaceBase width={1.563} />
        {CONST_ATTENDANCE_COMPANY.CONFIRMED_BRACKET}
      </Confirmed>
    );
  };
  return (
    <TimeActiveItemWrapper>
      {isConfirmed || isClosed || isDisableBtn ? (
        <>{renderComponent()}</>
      ) : (
        <TimeWrapper>
          {isTimeClosing === ESettingTimer.SETTING_STATIC && (
            <>
              <Brackets>(</Brackets>
              <TimeContent>
                <div>{dayjs(element.date).format('YYYY/MM/DD')}</div>
                <div>{timeShift}</div>
              </TimeContent>
              <Brackets>)</Brackets>
            </>
          )}
          <div style={{ marginLeft: '-0.75rem' }}>{renderCountdown(time)}</div>
        </TimeWrapper>
      )}
    </TimeActiveItemWrapper>
  );
};

export default TimeActiveItem;
