import { useState, useEffect } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { EApprovalMethod, EKeyCode } from 'constants/constants';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { getDayjsByTimeZone, isRegexDate } from 'helper/date';
import { useHookRequestSalary } from '@hooks/useCompany';
import { _isNumber } from 'helper';

const defaultPage = {
  per_page: 5,
  page: 1,
  total: 0,
};
export interface IRequestAdvance {
  admin_approved_id: null;
  approved_date: null;
  company_id: number;
  date_timeout: string | null;
  id: number;
  request_date: string;
  salary: number;
  send_last_time_otp: string;
  staff_id: number;
  staff_name: string;
  status: number;
  request_status: number;
  time_send_otp: number;
  type: number;
  is_force_cancel: number;
  transaction_history_status: number;
  transaction_history_transfer_status: number;
  is_seven_bank: number;
  message_error: any;
}
export interface IUpdateRequestData extends IRequestAdvance {}

const useApproveSalaryAdvance = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const paramDate = query.get('date') ?? '';
  const paramPage = _isNumber(query.get('page'), 1);
  const paramKeyWord = query.get('key_word') ?? '';
  const [typeStatus, setTypeStatus] = useState(EApprovalMethod.MANUAL_APPROVAL);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listRequest, setListRequest] = useState<IRequestAdvance[] | null>(null);
  const [pageStaff, setPageStaff] = useState(defaultPage);
  const [valueSearch, setValueSearch] = useState<string>('');
  const [allManualAuto, setAllManualAuto] = useState<any>(false);

  const [paramSearch, setParamsearch] = useState<any>({
    key_word: '',
    date: isRegexDate(paramDate) ? dayjs(paramDate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
    per_page: defaultPage.per_page,
    page: 1,
    type: [EApprovalMethod.MANUAL_APPROVAL],
  });
  const [currentDate, setCurrentDate] = useState<Dayjs>(getDayjsByTimeZone(paramSearch.date));
  const [currentDateInput, setCurrentDateInput] = useState<Dayjs | undefined>(getDayjsByTimeZone(paramSearch.date));
  const { data: dataListRequest, isLoading: isLoadingData, isFetching } = useHookRequestSalary(paramSearch);

  useEffect(() => {
    setIsLoading(isLoadingData || isFetching);
  }, [isLoadingData, isFetching]);

  useEffect(() => {
    if (dataListRequest?.request_auto > 0 && dataListRequest?.request_manual > 0) {
      setAllManualAuto(true);
    } else if (dataListRequest?.request_manual === 0 && dataListRequest?.request_auto > 0) {
      setTypeStatus(EApprovalMethod.AUTO_APPROVAL);
      setAllManualAuto(false);
    } else if (dataListRequest?.request_auto === 0 && dataListRequest?.request_manual > 0) {
      setTypeStatus(EApprovalMethod.MANUAL_APPROVAL);
      setAllManualAuto(false);
    } else {
      setAllManualAuto(false);
    }
    setListRequest(dataListRequest?.data);
    setPageStaff((page) => ({
      ...page,
      total: dataListRequest?.total ?? 0,
    }));
  }, [dataListRequest]);

  useEffect(() => {
    if (paramDate && isRegexDate(paramDate)) {
      setParamsearch((param: any) => ({
        ...param,
        date: paramDate,
      }));
      setCurrentDate(getDayjsByTimeZone(paramDate));
      setCurrentDateInput(getDayjsByTimeZone(paramDate));
    }
  }, [paramDate]);

  useEffect(() => {
    setParamsearch((param: any) => ({
      ...param,
      page: paramPage,
    }));
    setPageStaff((page) => ({
      ...page,
      page: paramPage,
    }));
  }, [paramPage]);

  useEffect(() => {
    setParamsearch((param: any) => ({
      ...param,
      key_word: paramKeyWord,
    }));
    setValueSearch(paramKeyWord);
  }, [paramKeyWord]);

  useEffect(() => {
    setParamsearch((param: any) => ({
      ...param,
      type: [`${typeStatus}`],
    }));
  }, [typeStatus]);

  const onChangeMonth = (e: Dayjs | any) => {
    if (e) {
      setCurrentDate(e);
      setCurrentDateInput(e);
      setParamsearch({ ...paramSearch, date: e.format('YYYY-MM-DD') });
      setTypeStatus(EApprovalMethod.MANUAL_APPROVAL);
      navigate({
        pathname: `/approve`,
        search: `?${createSearchParams({ ...paramSearch, date: e.format('YYYY-MM-DD'), page: 1 })}`,
      });
    } else {
      setCurrentDateInput(undefined);
    }
  };

  const changePaging = (pageChange: number) => {
    setPageStaff({
      ...pageStaff,
      page: pageChange,
    });
    navigate({
      pathname: `/approve`,
      search: `?${createSearchParams({ ...paramSearch, page: pageChange })}`,
    });
  };

  const updateListRequest = (item: IUpdateRequestData) => {
    if (!listRequest) return;
    const newData = [...listRequest].map((element: IUpdateRequestData) => {
      if (element.id === item.id) {
        return {
          ...element,
          ...item,
        };
      }
      return element;
    });
    setListRequest(newData);
  };

  const onSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === EKeyCode.ENTER) {
      navigate({
        pathname: `/approve`,
        search: `?${createSearchParams({ ...paramSearch, key_word: valueSearch, page: 1 })}`,
      });
    }
  };
  const changeStatus = (statusChange: number) => {
    setTypeStatus(statusChange);
    navigate({
      pathname: `/approve`,
      search: `?${createSearchParams({ ...paramSearch, type: statusChange, page: 1 })}`,
    });
  };

  return {
    listRequest,
    currentDate,
    currentDateInput,
    onChangeMonth,
    pageStaff,
    changePaging,
    updateListRequest,
    isLoading,
    onSearch,
    setValueSearch,
    valueSearch,
    typeStatus,
    changeStatus,
    allManualAuto,
  };
};

export default useApproveSalaryAdvance;
