/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';

import { Dayjs } from 'dayjs';
import { IBread } from '@components/Breadcrumb/BreadCrumb';
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';
import { responseSuccess } from 'helper/responseSuccess/responseSuccess';
import { CONST_ATTENDANCE_COMPANY } from 'constants/language';
import { apiGetHistoryAllStaffDetail } from 'api/company';
import { getDayjsByTimeZone, isRegexMonth } from 'helper/date';
import { useHookHistoryAdvanceDataOfStaff } from '@hooks/useCompany';
import { createCSVFile } from 'helper/export/exportCsv';
import { ICycleCompanySettingSalary } from '../AttendanceRecord/interface';

export interface ISalaryAdvance {
  request_date: string;
  total_salary: number;
  system_fee: number;
  transaction_fee: number;
  transaction_fee_tax_floor: number;
  system_fee_tax: number;
  system_fee_tax_floor: number;
}

const useAttendanceHistoryDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const paramMonth = query.get('month') ?? '';

  const [month, setMonth] = useState<Dayjs>(
    isRegexMonth(paramMonth) ? getDayjsByTimeZone(paramMonth) : getDayjsByTimeZone()
  );
  const [monthInput, setMonthInput] = useState<Dayjs | undefined>(
    isRegexMonth(paramMonth) ? getDayjsByTimeZone(paramMonth) : getDayjsByTimeZone()
  );
  const [cycleCompanySettingSalary, setCycleCompanySettingSalary] = useState<ICycleCompanySettingSalary>({
    start_date: '',
    end_date: '',
  });

  const BREADS: IBread[] = [
    {
      name: CONST_ATTENDANCE_COMPANY.PAYMENT_APPLICATION,
      path: `/history?month=${month?.format('YYYY-MM')}`,
    },
    {
      name: CONST_ATTENDANCE_COMPANY.PREPAYMENT_HISTORY,
      path: '',
    },
  ];

  const { idStaff } = useParams();
  const [listSalaryAdvance, setListSalaryAdvance] = useState<ISalaryAdvance[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [staffName, setStaffName] = useState('');
  const { data: dataExport, isLoading: isLoadingDataExport } = useHookHistoryAdvanceDataOfStaff(
    month.format('YYYY-MM'),
    idStaff || 0
  );

  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);

  useEffect(() => {
    let _month = isRegexMonth(paramMonth) ? getDayjsByTimeZone(paramMonth) : getDayjsByTimeZone();
    setMonth(_month);
    setMonthInput(_month);
  }, [paramMonth]);

  const getListSalaryAdvance = async (day: Dayjs) => {
    try {
      setIsLoading(true);
      const response = await apiGetHistoryAllStaffDetail(idStaff, {
        month: day.format('YYYY-MM'),
      });
      if (responseSuccess(response)) {
        setListSalaryAdvance(response.data.staff_salary_advance);
        setStaffName(response.data.name);
        setCycleCompanySettingSalary({
          start_date: response.data ? response.data?.start_date : '',
          end_date: response.data ? response.data?.end_date : '',
        });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeMonth = (e: Dayjs) => {
    if (e) {
      setMonth(e);
      setMonthInput(e);
      navigate({
        pathname: `/history/${idStaff}`,
        search: `?${createSearchParams({ month: e.format('YYYY-MM') })}`,
      });
    } else {
      setMonthInput(e);
    }
  };

  const exportCSV = useCallback(() => {
    if (
      !dataExport ||
      dataExport?.staff_salary_advance_detail_request?.length === 0 ||
      isLoadingExport ||
      isLoadingDataExport
    )
      return;
    setIsLoadingExport(true);

    const request = dataExport?.staff_salary_advance_detail_request;
    const totalSalary = request?.reduce((acc: number, curr: any) => {
      return acc + curr?.total_salary;
    }, 0);
    const totalSystemFee = request?.reduce((acc: number, curr: any) => {
      return acc + curr?.system_fee_tax_floor;
    }, 0);
    const totalTransactionFee = request?.reduce((acc: number, curr: any) => {
      return acc + curr?.transaction_fee_tax_floor;
    }, 0);

    const dataCSV = [
      {
        first: '従業員名',
        second: dataExport?.name,
        third: 'ID',
        fourth: dataExport?.code.toString(),
      },
      {
        first: '月合計金額',
        second: '',
        third: totalSalary,
        fourth: totalSystemFee,
        fifth: totalTransactionFee,
        sixth: totalSalary + totalSystemFee + totalTransactionFee,
      },
      {
        first: '番号',
        second: '日付',
        third: '申請金額',
        fourth: 'システム利用料',
        fifth: '振込手数料',
        sixth: '合計金額',
      },
      // map dataExport
      ...dataExport?.staff_salary_advance_detail_request?.map((request: any, index: number) => {
        return {
          first: index + 1,
          second: request?.request_date?.substr(0, 10).replaceAll('-', '/'),
          third: request?.total_salary,
          fourth: request?.system_fee_tax_floor,
          fifth: request?.transaction_fee_tax_floor,
          sixth: request?.total_salary + request?.system_fee_tax_floor + request?.transaction_fee_tax_floor,
        };
      }),
    ];

    createCSVFile(dataCSV, dataExport?.code + '_' + dataExport?.name + '_' + month.format('YYYY年MM月'));

    setTimeout(() => {
      setIsLoadingExport(false);
    }, 1000);
  }, [dataExport, isLoadingExport, isLoadingDataExport, month]);

  useEffect(() => {
    getListSalaryAdvance(month);
  }, [month?.format('YYYY-MM')]);

  return {
    listSalaryAdvance,
    month: monthInput,
    isLoading,
    onChangeMonth,
    staffName,
    BREADS,
    exportCSV,
    isLoadingExport,
    dataExportLength: dataExport?.staff_salary_advance_detail_request?.length,
    cycleCompanySettingSalary: cycleCompanySettingSalary,
  };
};

export default useAttendanceHistoryDetail;
