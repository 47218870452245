import { Button } from 'antd';
import { colors } from 'constants/colorsBase';
import styled from 'styled-components';

export const ContainerHistoryWrapper = styled.div`
  margin-left: 2rem;
  margin-top: -10px;
`;

export const ButtonExport = styled(Button) <any>`
  cursor: ${(props) => (props?.isLoading ? 'wait' : 'pointer')};
  height: ${(props) => props.height || '3.125rem'};
  width: ${(props) => props.width || '18.125rem'};
  background: ${(props) => props?.background || 'linear-gradient(270deg, #fd9672 0%, #ffb239 100%)'};
  box-shadow: ${(props) => (props?.boxshadow ? `${props?.boxshadow}` : '0rem 0.313rem 0.313rem rgba(0, 0, 0, 0.25)')};
  border: none;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 7.875rem;
  margin-bottom: 0.75rem;
  img {
    width: 2.719rem;
    height: 2.719rem;
    position: absolute;
    left: 0.25rem;
  }
  &:hover {
    transform: translateY(-0.07em);
  }
  &:disabled {
    color: rgb(51, 51, 51) !important;
  }
  div {
    font-style: normal;
    font-weight: ${(props) => props?.fontWeight || '400'};
    font-size: 1.75rem;
    line-height: 2rem;
    color: ${colors.white} !important;
    margin-left: 0.5rem;
  }
`;
