import React, { useEffect, useState } from 'react';

import images from '@assets/images-base';
import Loading from '@components/Loading';

import {
  IconInfo,
  Label,
  LabelRequired,
  RowFormItem,
  TitleLabel,
  BasicFormItem,
  BasicInput,
  DivUnit,
  NoticeRequired,
  ToolTipShow,
  CheckboxRadio,
  DetailWrapperSalary,
  LabelSetting,
  NoteRequired,
} from './salaryAdvanceDetailStyle';
import {
  BtnCreateWrapper,
  BtnWrapper,
  DetailForm,
  DetailFormItem,
  ItemIcon,
  PrefixIcon,
  TitlePageWrapper,
} from '../BankSetting/BankSettingDetail/bankSettingDetailStyle';
import { Container } from '@pages/OperatorSite/Companies/CreateCompany/createCompanyStyle';
import { SpaceBase } from 'styles';
import { RuleObject } from 'antd/es/form';
import { SettingCard } from '@pages/CompanySite/SettingPage/settingPageStyle';
import { Col, FormInstance } from 'antd';
import { ISalaryAdvanceData } from '@pages/CompanySite/SalaryAdvance/interface';
import BreadCrumb, { IBread } from '@components/Breadcrumb/BreadCrumb';
import { REGEX_SETTING_SALARY } from 'helper/regex';
import {
  DetailRadio,
  DetailRadioGroup,
  DivFormItem,
  ModalContent,
} from '../StaffManagement/DetailStaff/detailStaffStyle';
import {
  EApprovalMethod,
  ESettingTimer,
  MAX_LENGTH,
  VALIDATE_VALUE,
  ECompanyStaffSalaryType,
} from 'constants/constants';
import {
  CONST_COMPANY_BANK_SETTING,
  CONST_COMPANY_STAFF_MANAGEMENT,
  CONST_SETTING_SALARY,
  LANGUAGE_COMPANY,
} from 'constants/language';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Form } from 'antd';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { ModalContainer } from '@pages/CompanySite/CompanyB2B/companyB2BStyle';
import ModalCommon from '@components/Modal/ModalCommon';
interface ISalaryAdvanceDetailProps {
  BREADS: IBread[];
  form: FormInstance<any>;
  salaryAdvanceData?: ISalaryAdvanceData;
  handleSubmitForm?: any;
  isLoadingBtn: boolean;
  firstTime?: boolean;
  handleStringToNumber: (
    e: React.FocusEvent<HTMLInputElement, Element>,
    length?: number | undefined,
    nullable?: boolean
  ) => void;
  handleTrimSpaceInput: (e: React.FocusEvent<HTMLInputElement, Element>, length?: number | undefined) => void;
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  navigate: any;
  isSalaryDayEndMonth: boolean;
  onChangeSalaryInputEndMonth: (e: CheckboxChangeEvent) => void;
  salaryType: any;
  changeTypeSalary: any;
}

export const ButtonSettingType = styled.div<any>`
  font-size: 1.5rem;
  width: 12.438rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(props: any) => props?.borderColor === '#C4C4C4' && `1px solid ${props?.borderColor}`};
  box-shadow: ${(props: any) =>
    props?.borderColor === '#C4C4C4' ? `2px 3px 3px 0px #00000040` : `2px 3px 3px 0px #00000040 inset`};
  border-radius: 20px;
  font-weight: 700;
  color: ${(props: any) => props?.color};
  background-color: ${(props: any) => props?.background};
  cursor: pointer;
`;

export const SalaryAdvanceDetail = ({
  BREADS,
  form,
  salaryAdvanceData,
  handleSubmitForm,
  isLoadingBtn,
  firstTime,
  handleStringToNumber,
  handleTrimSpaceInput,
  handleOnChange,
  navigate,
  isSalaryDayEndMonth,
  onChangeSalaryInputEndMonth,
  salaryType,
  changeTypeSalary,
}: ISalaryAdvanceDetailProps) => {
  const isTimeClosing = Form.useWatch('is_time_closing', form);
  const closingMinute = Form.useWatch('closing_minute', form);
  const closingHour = Form.useWatch('closing_hour', form);
  const [isShowConfirm, setIsShowConfirm] = useState<boolean>(false);

  useEffect(() => {
    let keySetting = Cookies.get('isSettingSalary');
    if (keySetting) {
      setIsShowConfirm(true);
    }
  }, []);

  if (isTimeClosing === ESettingTimer.SETTING_STATIC) {
    form.setFields([
      {
        name: 'closing_hour',
        errors: [],
      },
      {
        name: 'closing_minute',
        errors: [],
      },
    ]);
  }

  const renderChooseTypeSalary = (typeSettingSalary: any, type: any) => {
    const renderColor = () => {
      if (type === ECompanyStaffSalaryType.HOUR_SALARY) {
        return `#53B889`;
      } else if (type === ECompanyStaffSalaryType.DAILY_SALARY) {
        return `#FDAB29`;
      } else {
        return `#47CAD2`;
      }
    };

    return (
      <>
        <ButtonSettingType
          color={typeSettingSalary === EApprovalMethod.AUTO_APPROVAL ? '#ffffff' : '#C4C4C4'}
          background={typeSettingSalary === EApprovalMethod.AUTO_APPROVAL ? `${renderColor()}` : '#ffffff'}
          borderColor={typeSettingSalary === EApprovalMethod.AUTO_APPROVAL ? `${renderColor()}` : '#C4C4C4'}
          onClick={() => changeTypeSalary(EApprovalMethod.AUTO_APPROVAL, type)}
          style={{ marginLeft: '1.5rem' }}
        >
          {CONST_SETTING_SALARY.AUTOMATIC_APPROVAL}
        </ButtonSettingType>
        <IconInfo className="iconInfo" src={images.companySite.iconSettingSalaryType} alt="" />
        <ButtonSettingType
          color={typeSettingSalary === EApprovalMethod.MANUAL_APPROVAL ? '#ffffff' : '#C4C4C4'}
          background={typeSettingSalary === EApprovalMethod.MANUAL_APPROVAL ? `${renderColor()}` : '#ffffff'}
          borderColor={typeSettingSalary === EApprovalMethod.MANUAL_APPROVAL ? `${renderColor()}` : '#C4C4C4'}
          onClick={() => changeTypeSalary(EApprovalMethod.MANUAL_APPROVAL, type)}
        >
          {CONST_SETTING_SALARY.MANUAL_APPROVAL}
        </ButtonSettingType>
      </>
    );
  };

  return (
    <>
      <DetailWrapperSalary firstTime={firstTime}>
        {!firstTime && <BreadCrumb margin="0.25rem 0 0.625rem 0.125rem" breads={BREADS} />}
        <Container height={firstTime ? '100%' : ''}>
          <SettingCard
            firstTime={firstTime}
            marginTop={firstTime ? '6.125rem' : '0'}
            padding={firstTime ? '1.5rem 5rem 0 8.75rem' : '1.5rem 0 0 0'}
            height={firstTime ? '100%' : ''}
            width={firstTime ? '100%' : ''}
          >
            <div>
              <TitlePageWrapper>
                <PrefixIcon src={images.companySite.settingCompany} alt="" />
                <div>{CONST_SETTING_SALARY.ADVANCE_PAYMENT_APP_SETTING}</div>
                {!firstTime && <NoteRequired>{CONST_SETTING_SALARY.NOTICE_REQUIRED}</NoteRequired>}
                {firstTime && <span>{CONST_SETTING_SALARY.INSTRUCTION_TEXT}</span>}
                {firstTime && <NoticeRequired>{CONST_SETTING_SALARY.NOTICE_REQUIRED}</NoticeRequired>}
              </TitlePageWrapper>

              {isLoadingBtn && <Loading />}

              {!isLoadingBtn && (
                <DetailForm
                  form={form}
                  initialValues={salaryAdvanceData}
                  onFinish={handleSubmitForm}
                  // requiredMark={false}
                  scrollToFirstError={true}
                  validateTrigger="onSubmit"
                >
                  <SpaceBase height={1} />
                  <Col span={24}>
                    <TitleLabel>
                      <LabelSetting style={{ color: '#53B889' }}>{CONST_SETTING_SALARY.HOUR_WAGE}</LabelSetting>
                      <IconInfo className="iconInfo" src={images.companySite.iconInfo} alt="" />
                      <ToolTipShow
                        left="4.75rem"
                        top="-11.375rem"
                        width="65.25rem"
                        height="19.563rem"
                        leftText="4.75rem"
                        topText="6.25rem"
                        className="tooltip"
                      >
                        <img src={images.companySite.tooltipDayAmountLimit} alt="" />
                        <div className="tooltip-text">
                          <div>{CONST_SETTING_SALARY.TOOLTIP_TEXT.SALARY_HOUR_TEXT}</div>
                          <div>{CONST_SETTING_SALARY.TOOLTIP_TEXT.SALARY_TEXT_2}</div>
                          <div>{CONST_SETTING_SALARY.TOOLTIP_TEXT.SALARY_TEXT_3}</div>
                        </div>
                      </ToolTipShow>
                      {renderChooseTypeSalary(salaryType.hour, ECompanyStaffSalaryType.HOUR_SALARY)}
                    </TitleLabel>
                    <RowFormItem>
                      <div style={{ borderLeft: '9px solid #53B889', marginRight: '20px' }}></div>
                      <DivFormItem>
                        <BasicFormItem
                          labelCol={{ span: 24 }}
                          colon={false}
                          label="申請上限額(昼)"
                          name="prepaid_salary_morning_working_time"
                          rules={[
                            {
                              required: true,
                              message: CONST_SETTING_SALARY.VALIDATE.DAY_TIME_APPLICATION_LIMIT_REQUIRED,
                            },
                            () => ({
                              validator(_: RuleObject, value: string) {
                                if (Number(value) === 0 && value !== '') {
                                  return Promise.reject(new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_MORNING));
                                }
                                if (value && Number(value) < VALIDATE_VALUE.SALARY_MIN) {
                                  return Promise.reject(new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_MORNING));
                                }
                                if (value && Number(value) % VALIDATE_VALUE.SALARY_MIN !== 0) {
                                  return Promise.reject(new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_MORNING));
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <BasicInput
                            placeholder={CONST_SETTING_SALARY.AMOUNT_MONEY}
                            name="prepaid_salary_morning_working_time"
                            maxLength={MAX_LENGTH.INPUT_SALARY}
                            onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) =>
                              handleStringToNumber(e, MAX_LENGTH.INPUT_SALARY)
                            }
                            onChange={handleOnChange}
                          />
                        </BasicFormItem>
                        <DivUnit>{CONST_COMPANY_STAFF_MANAGEMENT.YEN_DIV_HOUR}</DivUnit>
                      </DivFormItem>
                      <SpaceBase width={3} />
                      <DivFormItem>
                        <BasicFormItem
                          required={true}
                          labelCol={{ span: 24 }}
                          colon={false}
                          label="申請上限額(夜)"
                          name="prepaid_salary_afternoon_working_time"
                          rules={[
                            {
                              required: true,
                              message: CONST_SETTING_SALARY.VALIDATE.NIGHT_TIME_APPLICATION_LIMIT_REQUIRED,
                            },
                            () => ({
                              validator(_: RuleObject, value: string) {
                                if (Number(value) === 0 && value !== '') {
                                  return Promise.reject(new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_AFTERNOON));
                                }
                                if (value && Number(value) < VALIDATE_VALUE.SALARY_MIN) {
                                  return Promise.reject(new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_AFTERNOON));
                                }
                                if (value && Number(value) % VALIDATE_VALUE.SALARY_MIN !== 0) {
                                  return Promise.reject(new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_AFTERNOON));
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <BasicInput
                            placeholder={CONST_SETTING_SALARY.AMOUNT_MONEY}
                            name="prepaid_salary_afternoon_working_time"
                            maxLength={MAX_LENGTH.INPUT_SALARY}
                            onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) =>
                              handleStringToNumber(e, MAX_LENGTH.INPUT_SALARY)
                            }
                            onChange={handleOnChange}
                          />
                        </BasicFormItem>
                        <DivUnit>{CONST_COMPANY_STAFF_MANAGEMENT.YEN_DIV_HOUR}</DivUnit>
                      </DivFormItem>
                    </RowFormItem>
                  </Col>
                  <SpaceBase height={1} />
                  <Col span={24}>
                    <TitleLabel>
                      <LabelSetting style={{ color: '#FDAB29' }}>{CONST_SETTING_SALARY.DAILY_WAGE}</LabelSetting>

                      <IconInfo className="iconInfo" src={images.companySite.iconInfo} alt="" />
                      <ToolTipShow
                        left="4.75rem"
                        top="-11.375rem"
                        width="65.25rem"
                        height="19.563rem"
                        leftText="4.75rem"
                        topText="6.25rem"
                        className="tooltip"
                      >
                        <img src={images.companySite.tooltipDayAmountLimit} alt="" />
                        <div className="tooltip-text">
                          <div>{CONST_SETTING_SALARY.TOOLTIP_TEXT.SALARY_DAY_TEXT}</div>
                          <div>{CONST_SETTING_SALARY.TOOLTIP_TEXT.SALARY_TEXT_2}</div>
                          <div>{CONST_SETTING_SALARY.TOOLTIP_TEXT.SALARY_TEXT_3}</div>
                        </div>
                      </ToolTipShow>
                      {renderChooseTypeSalary(salaryType.day, ECompanyStaffSalaryType.DAILY_SALARY)}
                    </TitleLabel>
                    <RowFormItem>
                      <div style={{ borderLeft: '9px solid #FDAB29', marginRight: '20px' }}></div>
                      <div style={{ marginRight: '50px' }}>
                        <DivFormItem>
                          <BasicFormItem
                            labelCol={{ span: 24 }}
                            colon={false}
                            label={CONST_COMPANY_STAFF_MANAGEMENT.DAY_TIME_APPLICATION_LIMIT}
                            name="prepaid_salary_morning"
                            rules={[
                              {
                                required: true,
                                message: CONST_SETTING_SALARY.VALIDATE.DAY_TIME_APPLICATION_LIMIT_REQUIRED,
                              },
                              () => ({
                                validator(_: RuleObject, value: string) {
                                  if (Number(value) === 0 && value !== '') {
                                    return Promise.reject(new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_MORNING));
                                  }
                                  if (value && Number(value) < VALIDATE_VALUE.SALARY_MIN) {
                                    return Promise.reject(new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_MORNING));
                                  }
                                  if (value && Number(value) % VALIDATE_VALUE.SALARY_MIN !== 0) {
                                    return Promise.reject(new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_MORNING));
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <BasicInput
                              placeholder={CONST_SETTING_SALARY.AMOUNT_MONEY}
                              name="prepaid_salary_morning"
                              maxLength={MAX_LENGTH.INPUT_SALARY}
                              onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) =>
                                handleStringToNumber(e, MAX_LENGTH.INPUT_SALARY)
                              }
                              onChange={handleOnChange}
                            />
                          </BasicFormItem>
                          <DivUnit>{CONST_COMPANY_STAFF_MANAGEMENT.YEN_DIV_ATTENDANCE}</DivUnit>
                        </DivFormItem>
                        <SpaceBase width={3} height={2} />
                        <DivFormItem>
                          <BasicFormItem
                            labelCol={{ span: 24 }}
                            colon={false}
                            label="半日給(昼)"
                            name="prepaid_salary_morning_half"
                            rules={[
                              {
                                required: true,
                                message: CONST_SETTING_SALARY.VALIDATE.DAY_HALF_TIME_APPLICATION_LIMIT_REQUIRED,
                              },
                              () => ({
                                validator(_: RuleObject, value: string) {
                                  if (Number(value) === 0 && value !== '') {
                                    return Promise.reject(
                                      new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_MORNING_HALF)
                                    );
                                  }
                                  if (value && Number(value) < VALIDATE_VALUE.SALARY_MIN) {
                                    return Promise.reject(
                                      new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_MORNING_HALF)
                                    );
                                  }
                                  if (value && Number(value) % VALIDATE_VALUE.SALARY_MIN !== 0) {
                                    return Promise.reject(
                                      new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_MORNING_HALF)
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <BasicInput
                              placeholder={CONST_SETTING_SALARY.AMOUNT_MONEY}
                              name="prepaid_salary_morning_half"
                              maxLength={MAX_LENGTH.INPUT_SALARY}
                              onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) =>
                                handleStringToNumber(e, MAX_LENGTH.INPUT_SALARY)
                              }
                              onChange={handleOnChange}
                            />
                          </BasicFormItem>
                          <DivUnit>{CONST_COMPANY_STAFF_MANAGEMENT.YEN_DIV_ATTENDANCE}</DivUnit>
                        </DivFormItem>
                      </div>
                      <div>
                        <DivFormItem>
                          <BasicFormItem
                            labelCol={{ span: 24 }}
                            colon={false}
                            label={CONST_COMPANY_STAFF_MANAGEMENT.NIGHT_TIME_APPLICATION_LIMIT}
                            name="prepaid_salary_afternoon"
                            rules={[
                              {
                                required: true,
                                message: CONST_SETTING_SALARY.VALIDATE.NIGHT_TIME_APPLICATION_LIMIT_REQUIRED,
                              },
                              () => ({
                                validator(_: RuleObject, value: string) {
                                  if (Number(value) === 0 && value !== '') {
                                    return Promise.reject(new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_AFTERNOON));
                                  }
                                  if (value && Number(value) < VALIDATE_VALUE.SALARY_MIN) {
                                    return Promise.reject(new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_AFTERNOON));
                                  }
                                  if (value && Number(value) % VALIDATE_VALUE.SALARY_MIN !== 0) {
                                    return Promise.reject(new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_AFTERNOON));
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <BasicInput
                              placeholder={CONST_SETTING_SALARY.AMOUNT_MONEY}
                              name="prepaid_salary_afternoon"
                              maxLength={MAX_LENGTH.INPUT_SALARY}
                              onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) =>
                                handleStringToNumber(e, MAX_LENGTH.INPUT_SALARY)
                              }
                              onChange={handleOnChange}
                            />
                          </BasicFormItem>
                          <DivUnit>{CONST_COMPANY_STAFF_MANAGEMENT.YEN_DIV_ATTENDANCE}</DivUnit>
                        </DivFormItem>
                        <SpaceBase width={3} height={2} />
                        <DivFormItem>
                          <BasicFormItem
                            labelCol={{ span: 24 }}
                            colon={false}
                            label="半日給(夜)"
                            name="prepaid_salary_afternoon_half"
                            rules={[
                              {
                                required: true,
                                message: CONST_SETTING_SALARY.VALIDATE.NIGHT_HALF_TIME_APPLICATION_LIMIT_REQUIRED,
                              },
                              () => ({
                                validator(_: RuleObject, value: string) {
                                  if (Number(value) === 0 && value !== '') {
                                    return Promise.reject(
                                      new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_AFTERNOON_HALF)
                                    );
                                  }
                                  if (value && Number(value) < VALIDATE_VALUE.SALARY_MIN) {
                                    return Promise.reject(
                                      new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_AFTERNOON_HALF)
                                    );
                                  }
                                  if (value && Number(value) % VALIDATE_VALUE.SALARY_MIN !== 0) {
                                    return Promise.reject(
                                      new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_AFTERNOON_HALF)
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <BasicInput
                              placeholder={CONST_SETTING_SALARY.AMOUNT_MONEY}
                              name="prepaid_salary_afternoon_half"
                              maxLength={MAX_LENGTH.INPUT_SALARY}
                              onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) =>
                                handleStringToNumber(e, MAX_LENGTH.INPUT_SALARY)
                              }
                              onChange={handleOnChange}
                            />
                          </BasicFormItem>
                          <DivUnit>{CONST_COMPANY_STAFF_MANAGEMENT.YEN_DIV_ATTENDANCE}</DivUnit>
                        </DivFormItem>
                      </div>
                    </RowFormItem>
                  </Col>
                  <SpaceBase height={1.5} />
                  <Col span={24}>
                    <TitleLabel>
                      <LabelSetting style={{ color: '#47CAD2' }}>{CONST_SETTING_SALARY.MONTH_SALARY}</LabelSetting>

                      <IconInfo className="iconInfo" src={images.companySite.iconInfo} alt="" />
                      <ToolTipShow
                        left="4.75rem"
                        top="-11.375rem"
                        width="65.25rem"
                        height="19.563rem"
                        leftText="4.75rem"
                        topText="6.25rem"
                        className="tooltip"
                      >
                        <img src={images.companySite.tooltipMonthAmountLimit} alt="" />
                        <div className="tooltip-text">
                          <div>{CONST_SETTING_SALARY.TOOLTIP_TEXT.SALARY_MONTH_TEXT}</div>
                          <div>{CONST_SETTING_SALARY.TOOLTIP_TEXT.SALARY_TEXT_2}</div>
                          <div>{CONST_SETTING_SALARY.TOOLTIP_TEXT.SALARY_TEXT_3}</div>
                        </div>
                      </ToolTipShow>
                      {renderChooseTypeSalary(salaryType.month, ECompanyStaffSalaryType.MONTHLY_SALARY)}
                    </TitleLabel>
                    <RowFormItem>
                      <div style={{ borderLeft: '9px solid #47CAD2', marginRight: '20px' }}></div>
                      <DivFormItem>
                        <BasicFormItem
                          labelCol={{ span: 24 }}
                          colon={false}
                          label={CONST_COMPANY_STAFF_MANAGEMENT.DAYILY_TIME_APPLICATION_LIMIT}
                          name="prepaid_salary_month"
                          rules={[
                            {
                              required: true,
                              message: CONST_SETTING_SALARY.VALIDATE.DAYILY_TIME_APPLICATION_LIMIT_REQUIRED,
                            },
                            () => ({
                              validator(_: RuleObject, value: string) {
                                if (Number(value) === 0 && value !== '') {
                                  return Promise.reject(new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_MONTH));
                                }
                                if (value && Number(value) < VALIDATE_VALUE.SALARY_MIN) {
                                  return Promise.reject(new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_MONTH));
                                }
                                if (value && Number(value) % VALIDATE_VALUE.SALARY_MIN !== 0) {
                                  return Promise.reject(new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_MONTH));
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <BasicInput
                            name="prepaid_salary_month"
                            placeholder={CONST_SETTING_SALARY.AMOUNT_MONEY}
                            maxLength={MAX_LENGTH.INPUT_SALARY}
                            onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) =>
                              handleStringToNumber(e, MAX_LENGTH.INPUT_SALARY)
                            }
                            onChange={handleOnChange}
                          />
                        </BasicFormItem>
                        <DivUnit>{CONST_COMPANY_STAFF_MANAGEMENT.YEN_DIV_DAY}</DivUnit>
                      </DivFormItem>
                      <SpaceBase width={3} />
                      <DivFormItem>
                        <BasicFormItem
                          labelCol={{ span: 24 }}
                          colon={false}
                          label={CONST_COMPANY_STAFF_MANAGEMENT.MONTHLY_TIME_APPLICATION_LIMIT}
                          name="prepaid_salary_day"
                          rules={[
                            {
                              required: true,
                              message: CONST_SETTING_SALARY.VALIDATE.MONTHLY_TIME_APPLICATION_LIMIT_REQUIRED,
                            },
                            () => ({
                              validator(_: RuleObject, value: string) {
                                if (Number(value) === 0 && value !== '') {
                                  return Promise.reject(new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_DAY));
                                }
                                if (value && Number(value) < VALIDATE_VALUE.SALARY_MIN) {
                                  return Promise.reject(new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_DAY));
                                }
                                if (value && Number(value) % VALIDATE_VALUE.SALARY_MIN !== 0) {
                                  return Promise.reject(new Error(CONST_SETTING_SALARY.VALIDATE.MIN_100_DAY));
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <BasicInput
                            name="prepaid_salary_day"
                            maxLength={MAX_LENGTH.INPUT_SALARY}
                            placeholder={CONST_SETTING_SALARY.AMOUNT_MONEY}
                            onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) =>
                              handleStringToNumber(e, MAX_LENGTH.INPUT_SALARY)
                            }
                            onChange={handleOnChange}
                          />
                        </BasicFormItem>
                        <DivUnit>{CONST_COMPANY_STAFF_MANAGEMENT.YEN_DIV_MONTH}</DivUnit>
                      </DivFormItem>
                    </RowFormItem>
                  </Col>
                  <SpaceBase height={1.5} />
                  <Col span={24}>
                    <TitleLabel>
                      <LabelRequired>{CONST_SETTING_SALARY.SALARY_CLOSING_DATE}</LabelRequired>
                      <IconInfo className="iconInfo" src={images.companySite.iconInfo} alt="" />
                      <ToolTipShow
                        left="6.875rem"
                        top="-4.375rem"
                        width="25.875rem"
                        height="5.563rem"
                        className="tooltip"
                      >
                        <img src={images.companySite.tooltipSalaryDay} alt="" />
                        <div className="tooltip-text">
                          <div>{CONST_SETTING_SALARY.TOOLTIP_TEXT.SALARY_CLOSING_DATE}</div>
                        </div>
                      </ToolTipShow>
                    </TitleLabel>
                    <DivFormItem>
                      <BasicFormItem
                        labelCol={{ span: 24 }}
                        colon={false}
                        className="not-required"
                        label={
                          <>
                            {CONST_SETTING_SALARY.LAST_DAY}
                            <CheckboxRadio checked={isSalaryDayEndMonth} onChange={onChangeSalaryInputEndMonth} />
                          </>
                        }
                        name="salary_day"
                        rules={[
                          {
                            required: !isSalaryDayEndMonth,
                            message: CONST_SETTING_SALARY.VALIDATE.SALARY_DAY_REQUIRED,
                          },
                          {
                            pattern: REGEX_SETTING_SALARY,
                            message: CONST_SETTING_SALARY.VALIDATE.SALARY_DAY_INVALID,
                          },
                        ]}
                      >
                        <BasicInput
                          name="salary_day"
                          disabled={isSalaryDayEndMonth}
                          maxLength={MAX_LENGTH.INPUT_NUMBER}
                          placeholder={isSalaryDayEndMonth ? '' : CONST_SETTING_SALARY.DATE}
                          onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) =>
                            handleStringToNumber(e, MAX_LENGTH.INPUT_NUMBER, true)
                          }
                          onChange={handleOnChange}
                        />
                      </BasicFormItem>
                      <DivUnit>{CONST_SETTING_SALARY.DAY}</DivUnit>
                    </DivFormItem>
                  </Col>
                  <SpaceBase height={1.5} />
                  <Col span={24}>
                    <TitleLabel>
                      <>
                        <Label>{CONST_SETTING_SALARY.SETTING_CLOSING_DATE}</Label>
                        <IconInfo className="iconInfo" src={images.companySite.iconInfo} alt="" />
                        <ToolTipShow
                          left="6.875rem"
                          top="-4.375rem"
                          width="60.313rem"
                          height="6.438rem"
                          className="tooltip"
                        >
                          <img src={images.companySite.tooltipSettingTimer} alt="" />
                          <div className="tooltip-text">
                            <div>{CONST_SETTING_SALARY.SETTING_CLOSING_DATE_TOOLTIP1}</div>
                            <div>{CONST_SETTING_SALARY.SETTING_CLOSING_DATE_TOOLTIP2}</div>
                          </div>
                        </ToolTipShow>
                      </>
                    </TitleLabel>

                    <div className="title-setting-timer">{CONST_SETTING_SALARY.SETTING_CLOSING_TITLE}</div>

                    <DetailFormItem labelCol={{ span: 24 }} colon={false} name="is_time_closing">
                      <DetailRadioGroup margin={'0 0 0 3.125rem'}>
                        <DetailRadio value={ESettingTimer.SETTING_STATIC}>
                          {CONST_SETTING_SALARY.SETTING_CLOSING_STATIC}
                        </DetailRadio>
                        <DetailRadio value={ESettingTimer.SETTING_TIMER}>
                          {CONST_SETTING_SALARY.SETTING_CLOSING_TIMER}
                        </DetailRadio>
                      </DetailRadioGroup>
                    </DetailFormItem>
                  </Col>
                  <Col span={24}>
                    <DivFormItem className="setting-timer-form-item">
                      <BasicFormItem
                        labelCol={{ span: 24 }}
                        colon={false}
                        className="not-required"
                        name="closing_hour"
                        rules={[
                          {
                            validator: (_, value) => {
                              try {
                                if (
                                  isTimeClosing === ESettingTimer.SETTING_TIMER &&
                                  (Number(value) > 24 ||
                                    (value && Number(value) === 0 && Number(closingMinute) <= 0) ||
                                    (value && Number(value) === 24 && Number(closingMinute) > 0))
                                ) {
                                  return Promise.reject(CONST_SETTING_SALARY.VALIDATE.SALARY_MORE_LESS_24_HOUR);
                                }
                                return Promise.resolve();
                              } catch (error) {}
                            },
                          },
                        ]}
                      >
                        <BasicInput
                          name="closing_hour"
                          disabled={!isTimeClosing}
                          maxLength={MAX_LENGTH.INPUT_NUMBER}
                          placeholder={CONST_SETTING_SALARY.SETTING_CLOSING_HOUR}
                          onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) =>
                            handleStringToNumber(e, MAX_LENGTH.INPUT_NUMBER, true)
                          }
                          onChange={handleOnChange}
                        />
                      </BasicFormItem>
                      <DivUnit className="setting-timer-unit">{CONST_SETTING_SALARY.SETTING_CLOSING_HOUR}</DivUnit>
                      <BasicFormItem
                        labelCol={{ span: 24 }}
                        colon={false}
                        className="not-required"
                        name="closing_minute"
                        rules={[
                          {
                            validator: (_, value) => {
                              try {
                                if (
                                  isTimeClosing === ESettingTimer.SETTING_TIMER &&
                                  !closingHour &&
                                  !value &&
                                  Number(value) <= 0
                                ) {
                                  return Promise.reject(CONST_SETTING_SALARY.VALIDATE.SALARY_TIMER_REQUIRED);
                                }
                                return Promise.resolve();
                              } catch (error) {}
                            },
                          },
                          {
                            validator: (_, value) => {
                              try {
                                if (
                                  !closingHour &&
                                  isTimeClosing === ESettingTimer.SETTING_TIMER &&
                                  value &&
                                  Number(value) <= 0
                                ) {
                                  return Promise.reject(CONST_SETTING_SALARY.VALIDATE.SALARY_MORE_THAN_1_MINUTE);
                                }
                                return Promise.resolve();
                              } catch (error) {}
                            },
                          },
                          {
                            validator: (_, value) => {
                              try {
                                if (isTimeClosing === ESettingTimer.SETTING_TIMER && value && Number(value) > 60) {
                                  return Promise.reject(CONST_SETTING_SALARY.VALIDATE.SALARY_MORE_LESS_60_MINUTE);
                                }
                                return Promise.resolve();
                              } catch (error) {}
                            },
                          },
                        ]}
                      >
                        <BasicInput
                          name="closing_minute"
                          disabled={!isTimeClosing}
                          maxLength={MAX_LENGTH.INPUT_NUMBER}
                          placeholder={CONST_SETTING_SALARY.SETTING_CLOSING_MINUTE}
                          onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) =>
                            handleStringToNumber(e, MAX_LENGTH.INPUT_NUMBER, true)
                          }
                          onChange={handleOnChange}
                        />
                      </BasicFormItem>
                      <DivUnit className="setting-timer-unit">{CONST_SETTING_SALARY.SETTING_CLOSING_MINUTE}</DivUnit>
                    </DivFormItem>
                  </Col>
                </DetailForm>
              )}
            </div>
            {!isLoadingBtn && (
              <BtnWrapper>
                <BtnCreateWrapper
                  onClick={form.submit}
                  icon={
                    <ItemIcon
                      src={images.companySite.saveAccount}
                      alt={CONST_COMPANY_BANK_SETTING.ALT.SAVE_BANK_SETTING}
                    />
                  }
                  loading={isLoadingBtn}
                >
                  {CONST_SETTING_SALARY.SAVE_SETTINGS}
                </BtnCreateWrapper>
              </BtnWrapper>
            )}
          </SettingCard>
        </Container>
      </DetailWrapperSalary>
      <ModalCommon
        isOpen={isShowConfirm}
        setIsOpen={() => setIsShowConfirm(false)}
        onClickOk={() => setIsShowConfirm(false)}
        txtOK={LANGUAGE_COMPANY.createStaff.btnOKConfirm}
        _className="confirm-company-pair confirm-attendance confirm-upload"
        isShowBtnCancel={false}
        width={'60rem'}
      >
        <ModalContainer>
          <ModalContent>
            <div style={{ fontSize: '1.5rem' }} className="attendance-date-txt">
              {LANGUAGE_COMPANY.createStaff.textSettingSalaryOne}
            </div>
            <div style={{ fontSize: '1.5rem', marginTop: '2.5rem' }} className="attendance-date-txt">
              {LANGUAGE_COMPANY.createStaff.textSettingSalaryTwo}
            </div>
          </ModalContent>
        </ModalContainer>
      </ModalCommon>
    </>
  );
};
