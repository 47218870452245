import React from 'react';

import images from '@assets/images-base';
import Loading from '@components/Loading';
import BreadCrumb from '@components/Breadcrumb/BreadCrumb';
import PaginationRecord from '@components/CompanySite/AttendanceRecord/PaginationRecord/PaginationRecord';
import CompanyInvoiceViewTable from '@components/InvoiceTable/InvoiceViewTable/CompanyInvoiceViewTable';
import CompanyInvoiceExportTable from '@components/InvoiceTable/InvoiceExportTable/CompanyInvoiceExportTable';
import useInvoiceDetailSiteCompany from './useInvoiceDetailSiteCompany';

import { SettingIcon } from '@containers/OperatorSite/OperatorBank/DetailBank/detailBankStyle';
import { CONST_COMMON, CONST_LIST_INVOICE_COMPANY } from 'constants/language';
import { DetailWrapper } from '@components/CompanySite/common/styled';
import { TitleHeaderSetting } from '../SettingPage/settingPageStyle';
import { Container, GrantCard } from '@components/Style/Style';
import { HeaderSettingWrapper } from '@containers/OperatorSite/OperatorBank/operatorBankStyle';
import { SpaceBase } from 'styles';
import { ControlHeaderWrapper } from '../AttendanceRecord/attendanceRecordStyle';
import { ButtonView } from '@containers/CompanySite/InvoiceItem/invoiceItemStyle';
import { DateInvoice, InvoiceDetailWrapper, LoadingInvoice } from './invoiceDetailSiteCompanyStyle';
import { MAX_ITEM_PAGE_ONE_COMPANY, MAX_ITEM_PAGE_OTHER_COMPANY } from 'constants/invoice';
import { getTextInvoiceCreateAt, getTextInvoiceCreateAtDown, isNotDateDueYet } from 'helper';
import { ListCompanyInvoice, WrapperText } from '@pages/OperatorSite/InvoiceDetailCompany/InvoiceDetailCompany';
import { IconDown } from 'components/DropdownCustom/dropdownStyle';
import { Select } from 'antd';
import { useParams } from 'react-router-dom';

const { Option } = Select;

export const InvoiceDetailSiteCompany = () => {
  const {
    BREADS,
    invoiceDetail,
    invoiceAllDetail,
    isLoading,
    page,
    changePaging,
    isLoadingAllDetail,
    exportInvoicePDF,
    isLoadingExport,
    invoiceInfo,
    listInvoiceCompany,
    changeCompanyInvoice,
  } = useInvoiceDetailSiteCompany();
  const { id } = useParams();

  const exportInvoice = () => {
    if (invoiceInfo && !isNotDateDueYet(invoiceInfo?.closing_date)) {
      exportInvoicePDF();
    }
  };

  return (
    <DetailWrapper>
      <BreadCrumb breads={BREADS} />
      <Container>
        <GrantCard padding="1.25rem 0rem" percentWidth="100%" width={106.25}>
          <div>
            <HeaderSettingWrapper>
              <SettingIcon src={images.companySite.settingTermOfUseCompany} />
              <TitleHeaderSetting>
                {invoiceInfo?.company_name}　{CONST_LIST_INVOICE_COMPANY.INVOICE_CONTENT}
              </TitleHeaderSetting>
            </HeaderSettingWrapper>
            {listInvoiceCompany.length > 0 && (
              <ListCompanyInvoice
                id="ListCompanyInvoiceSiteCompany"
                suffixIcon={<IconDown width={1.3} src={images.company.whiteDown} alt="" />}
                onSelect={(e) => changeCompanyInvoice(e)}
                defaultValue={listInvoiceCompany[0].value}
                dropdownStyle={{ minWidth: '42.563rem', left: '12rem' }}
              >
                {listInvoiceCompany.map((element: any) => (
                  <Option key={element.value} value={element.value} disabled={element.color === '#BEBEBE'}>
                    <WrapperText>
                      {element.color === '#BEBEBE' ? (
                        <IconDown width={2.5} src={images.company.warningVoice} alt="" />
                      ) : (
                        <div className="circle" />
                      )}
                      <span className="label-down">{element.label}</span>
                    </WrapperText>
                  </Option>
                ))}
              </ListCompanyInvoice>
            )}
            <InvoiceDetailWrapper>
              {invoiceInfo && (
                <ControlHeaderWrapper padding="0.75rem 14.375rem 0.75rem 10.625rem">
                  <PaginationRecord
                    right="50rem"
                    current={page.page}
                    total={page.total + (MAX_ITEM_PAGE_OTHER_COMPANY - MAX_ITEM_PAGE_ONE_COMPANY)}
                    pageSize={MAX_ITEM_PAGE_OTHER_COMPANY}
                    onChange={changePaging}
                  />
                  <ButtonView
                    className="export"
                    width="18.75rem"
                    // icon={<span className="square"></span>}
                    loading={isLoadingExport}
                    disabled={isNotDateDueYet(invoiceInfo?.closing_date)}
                    onClick={exportInvoice}
                  >
                    {CONST_LIST_INVOICE_COMPANY.EXPORT_INVOICE}
                  </ButtonView>
                </ControlHeaderWrapper>
              )}
              {!invoiceInfo && !isLoading && <div className="not-found">{CONST_COMMON.NOT_FOUND}</div>}

              {isLoading && (
                <LoadingInvoice>
                  <SpaceBase height={5} />
                  <Loading />
                </LoadingInvoice>
              )}
              <SpaceBase height={2.5} />
              <DateInvoice>{getTextInvoiceCreateAt(isLoadingAllDetail, invoiceInfo)}</DateInvoice>
              {invoiceInfo && (
                <CompanyInvoiceViewTable
                  invoiceDetail={invoiceDetail || []}
                  invoiceInfo={invoiceInfo}
                  currentPage={page.page}
                  pageSize={page.page === 1 ? MAX_ITEM_PAGE_ONE_COMPANY : MAX_ITEM_PAGE_OTHER_COMPANY}
                  isOperatorCompany
                  txtRequestDate={getTextInvoiceCreateAtDown(isLoading, invoiceInfo)}
                  idInvoice={id}
                />
              )}
              {invoiceInfo && (
                <CompanyInvoiceExportTable
                  invoiceDetail={invoiceAllDetail || []}
                  invoiceInfo={invoiceInfo}
                  isOperatorCompany
                />
              )}
            </InvoiceDetailWrapper>
          </div>
        </GrantCard>
      </Container>
    </DetailWrapper>
  );
};
