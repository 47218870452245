import {
  getBackGroundInvoice,
  getBgColorBtnLogin,
  getBgColorLogin,
  getColorColumnInvoice,
  getColorLogin,
  getShadowBtnLogin,
} from './../helper/colorSite';

export const colors: any = {
  mainText: '#333333',
  mainColor: '#52B788',
  mainColorHover: '#52b788cc',
  greenCyan: '#95D5B2',
  second1: '#223354',
  white: '#fff',
  none: '#4D4D4D',
  ashGrey: '#BABABA',
  doveGrey: '#6C6C6C',
  greyAlice: '#8F8F8F',
  pewter: '#CACACA',
  deepSaphire: '#4A5570',
  starDust: '#9F9F9F',
  davyGrey: '#555555',
  dune: '#333333',
  border: '#333333',
  iron: '#D8D8D8',
  aquaSqueeze: '#E9F4EF',
  smokeyGrey: '#707070',
  tomato: 'tomato',
  deepChestnut: '#B75252',
  romance: '#5A5A5A',
  nobel: '#B3B3B3',
  rangoon: '#484848',
  mainColorAgency: '#52B788',
  mainColorAgencyDisable: '#52b78899',
  mainColorCompany: '#FDAB29',
  mainColorCompanyDisable: '#fdab2980',
  hoverMainColorCompany: '#ffa42d',
  gainsBoro: '#DDDDDD',
  reddishOrange: '#EF4716',
  reddish: '#C74646',
  atomicTangerine: '#FD9670',
  lightGrey: '#FCFCFC',
  grey: '#c1c1c1',
  lightGray: '#BEBEBE',
  darkGray: '#A3A3A3',
  companyBgLogin: getBgColorLogin(true),
  bgLogin: getBgColorLogin(),
  colorLogin: getColorLogin(),
  btnLogin: getBgColorBtnLogin(),
  btnLoginShadow: getShadowBtnLogin(),
  btnDefaultCompanySite: 'linear-gradient(270deg, #FD9672 0%, #FFB239 100%)',
  btnRadialGradientRightCompanySite: 'linear-gradient(90deg, #fdab29 0%, #fd9672 100%)',
  btnRadialGradientLeftCompanySite: 'radial-gradient(100% 175.66% at 66.51% 0%, #FFB239 0%, #FD9672 100%)',
  red: '#FF4236',
  brightRed: '#FF2727',
  greenWhite: '#E9E9E9',
  mediumTurquoise: '#47CAD2',
  shadowAttendance: '3px 3px 6px rgba(0, 0, 0, 0.25)',
  shadowInsetFor: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
  shadowActive: 'inset 0px 1px 4px rgba(0, 0, 0, 0.14)',
  dayShift: '#fdab29',
  nightShift: '#47CAD2',
  leaveShift: '#868686',
  btnDisabledBG: 'linear-gradient(270deg, #868686 0%, #868686 100%)',
  whiteSmoke: '#f4f4f4',
  crimson: '#f65171',
  lineColor: 'rgb(0, 0, 0, 0.3)',
  osloGrey: '#8A8A8A',
  borderBtnFile: '#D9D9D9',
  shadowBtnFile: 'rgba(0, 0, 0, 0.25)',
  btnRejectAccont: 'linear-gradient(90deg, #C74646 0%, #C74646 100%)',
  // company setting pair
  pairB2BBorder: '#fdab29',
  pairB2BBorderCoral: '#fd9670',
  pairB2BColorYellow: '#fdab29',
  pairB2BColorWhite: '#ffffff',
  pairB2BColorCoral: '#fd9670',
  pairB2BColorSilver: '#333333',
  pairB2BBackgroundYellow: '#fdab29',
  pairB2BBackgroundWhite: '#ffffff',
  pairB2BBtnB2BSubmit: 'linear-gradient(90deg, #fdab29 0%, #fd9672 95.67%)',
  pairB2BBtnApproved: 'linear-gradient(90deg, #ffb239 0%, #fd9672 100%)',
  pairB2BBoxShadow: 'rgba(0, 0, 0, 0.25)',
  pairB2BStatusColorRequest: '#ffcb78',
  pairB2BStatusColorConfirm: '#FD7754',
  pairB2BStatusColorReject: '#868686',
  pairB2BStatusColorB2Bd: '#FDAB29',
  pairB2BStatusColorCancel: '#F65171',
  historyItemShadow: 'inset 2px 2px 2px rgba(0, 0, 0, 0.25)',
  // ==create staff===
  createStaffBackground: '#f65171',
  createStaffBtnBackgroundCancel: '#f65171',
  createStaffColorWhite: '#ffffff',
  createStaffBackgroundWhite: '#ffffff',
  createStaffColorTxtDownload: '#fd9670',
  createStaffColorDescriptionreDownload: '#6c6c6c',
  createStaffColorNumnberProgress: '#fdab29',
  createStaffBtnUpload: 'linear-gradient(90deg, #fdab29 0%, #fd9672 100%)',
  createStaffBoxShadow: 'rgba(0, 0, 0, 0.25)',
  createStaffColorCreateStaffMany: '#FDAB29',
  createStaffBtnCreateStaffMany: 'linear-gradient(270deg, #FFFFFF 0%, #FFFFFF 100%)',
  listViewStaffBoxShadow: 'rgba(0, 0, 0, 0.25)',
  listViewStafBtnSubmit: 'linear-gradient(270deg, #fd9672 0%, #ffb239 100%)',
  listViewStaffBtnCancelColor: '#fd9670',
  listViewStafBtnCancel: '#ffffff',
  listViewStafBtnCancelBorder: '#fd9670',
  listViewStafTableHeaderColor: '#fdab29',
  suspended: '#8D8C8C',
  backgroundSuspended: 'rgba(162, 166, 164, 0.25)',
  shadowSuspended: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
  // invoice
  backgroudInvoice: getBackGroundInvoice(),
  colorColumnInvoice: getColorColumnInvoice(),
  cancelAdvance: '#919191',
  brilliantAzure: '#1677ff',
  failedAdvance: '#ff7272',
};
