import React, { forwardRef, useImperativeHandle, useRef, useEffect } from 'react';

import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import { colors } from 'constants/colorsBase';
import { TableName } from '../AttendanceDetail/AttendanceTable/attendanceTableStyle';
import { IWeekType } from '@pages/CompanySite/AttendanceRecord/interface';
import { indexToDay } from 'helper/attendanceRecord';
import { useNavigate } from 'react-router-dom';
import { DAY_OF_WEEK } from 'constants/company';
import { formatMoney } from 'helper/formatMoney';
import { CONST_ATTENDANCE_COMPANY } from 'constants/language';
import { IStaffHistory, IStaffHistoryItem } from '@pages/CompanySite/AttendanceHistory/useAttendanceHistory';
import {
  AmountApplied,
  FeeContent,
  FeeWrapper,
  Brackets,
  ItemHistory,
  ItemHistoryTotal,
  TotalApplication,
  TransactionFee,
  WrapperItemHistory,
  WrapperTotalItemHistory,
  WeekendWrapper,
  ToolTipShow,
} from './attendanceHistoryContainerStyle';
import {
  CarouselContainer,
  LineMemberShift,
  LineShift,
  ListShiftItemWrapper,
  MemberWrapper,
} from '@components/CompanySite/AttendanceRecord/ListShiftItem/listShiftItemStyle';
import { isNumber } from 'lodash';
import { formatDateJP, getDayjsByTimeZone } from 'helper/date';
import images from '@assets/images-base';
import { renderColorBackground } from '../AttendanceDetail/AttendanceTable/AttendanceTable';
import { Tooltip } from 'antd';

dayjs.extend(weekOfYear);

const AttendanceHistoryContainer: React.ForwardRefRenderFunction<any, any> = (
  { listDate, setPage, listMember, maxPage, month, page, cycleCompanySettingSalary },
  ref
) => {
  const navigate = useNavigate();
  const listCarousel = Array.from({ length: maxPage }, (_, i) => i + 1);

  const getTotalRequestSalary = (arrAdvance: IStaffHistoryItem[]) => {
    return arrAdvance.reduce((sum, salary) => sum + Number(salary.total_salary), 0) || 0;
  };
  const getTotalSystemFee = (arrAdvance: IStaffHistoryItem[]) => {
    return arrAdvance.reduce((sum, salary) => sum + Number(salary.system_fee_tax_floor), 0) || 0;
  };
  const getTotalTransactionFee = (arrAdvance: IStaffHistoryItem[]) => {
    return arrAdvance.reduce((sum, salary) => sum + Number(salary.transaction_fee_tax_floor), 0) || 0;
  };
  const refRoute = useRef<any>();

  useImperativeHandle(ref, () => {
    return {
      nextPage: () => {
        refRoute.current.next();
      },
      prePage: () => {
        refRoute.current.prev();
      },
      toFirst: () => {
        refRoute.current.goTo(0);
      },
      toPage: (page: number) => {
        refRoute.current.goTo(page);
      },
    };
  });

  useEffect(() => {
    refRoute.current.goTo(page - 1);
  }, [page]);

  useEffect(() => {
    if (getDayjsByTimeZone().format('YYYY-MM-DD') >= cycleCompanySettingSalary.start_date) {
      const indexDateNow = listDate.findIndex(
        (date: any) => date !== null && dayjs(date).isSame(dayjs().format('YYYY-MM-DD'), 'day')
      );
      if (indexDateNow !== -1) {
        const indexWeekNow = Math.floor(indexDateNow / 7) + 1;
        setPage(indexWeekNow);
      } else {
        setPage(1);
      }
    }
  }, [setPage, cycleCompanySettingSalary, listDate]);

  const getTimeAttendanceInWeek: any = (week: number) => {
    const newArr = [...listDate]?.slice(DAY_OF_WEEK * week, DAY_OF_WEEK * (week + 1)) || [];
    return newArr;
  };

  const getItemData = (date: string | null, listShift: IStaffHistoryItem[]) => {
    if (!date) {
      return null;
    }
    const find = listShift.find((element: IStaffHistoryItem) => element?.request_date === date);
    if (find) {
      return find;
    }
    return null;
  };

  const getStyleItem: any = (itemTime: number | null | boolean, itemData: IStaffHistoryItem | null) => {
    const styleCustom = {
      background: colors.dayShift,
      color: colors.white,
      shadow: colors.historyItemShadow,
    };
    if (!itemData) {
      styleCustom.background = colors.greenWhite;
      styleCustom.shadow = 'none';
    }
    if (itemTime === null) {
      styleCustom.shadow = 'none';
      styleCustom.background = colors.white;
    }
    return styleCustom;
  };

  const isStaffDisconnect = (element: IStaffHistory) => {
    if (element && (element?.deleted_at || element?.staff_disconnect_time)) {
      return true;
    }
    return false;
  };

  const handleMouseEnter = (e: any) => {
    const element = e.currentTarget;
    let tooltip: any;
    let iconPos: any;
    let itemHistory: any;
    if (element?.classList?.contains('warning')) {
      tooltip = element.nextSibling;
      iconPos = element.getBoundingClientRect();
      itemHistory = element.parentElement;
    } else if (element?.classList?.contains('itemHistory')) {
      tooltip = element.querySelector('.tooltip');
      iconPos = element.querySelector('.warning')?.getBoundingClientRect();
      itemHistory = element;
    } else {
      return;
    }
    if (!tooltip || !iconPos || !itemHistory) {
      return;
    }
    const newTooltip = tooltip.cloneNode(true);
    document.body.appendChild(newTooltip);
    newTooltip.style.display = 'flex';
    newTooltip?.classList?.add('laterTransferDates-tooltip');
    newTooltip.style.position = 'absolute';
    newTooltip.style.top = `CALC(${iconPos.top}px - 7.5rem)`;
    newTooltip.style.zIndex = 9999;
    // add attr data-tooltip
    newTooltip.setAttribute('data-tooltip', itemHistory?.classList?.value);

    if (iconPos.left / window.innerWidth >= 0.6) {
      newTooltip?.classList?.add('laterTransferDates-tooltip-right');
      newTooltip.style.left = `CALC(${iconPos.left}px - 37.8rem)`;
    } else {
      newTooltip.style.left = `CALC(${iconPos.left}px + 0.8rem)`;
    }
  };

  const handleMouseLeave = (e: any) => {
    let element = e.currentTarget;
    if (element?.classList?.contains('warning')) {
      element = element.parentElement;
    }
    if (element) {
      setTimeout(() => {
        const tooltip = document.querySelector('.laterTransferDates-tooltip');
        if (tooltip && tooltip.getAttribute('data-tooltip') === element?.classList?.value) {
          document.body.removeChild(tooltip);
        }
      }, 100);
    }
  };

  return (
    <ListShiftItemWrapper>
      <MemberWrapper>
        {listMember?.map((element: IStaffHistory, index: number) => (
          <React.Fragment key={String(element.id + '-' + index)}>
            <WrapperItemHistory id={String(element.id)}>
              <TableName
                onClick={() => navigate(`${element.id}?month=${month.format('YYYY-MM')}`)}
                className={isStaffDisconnect(element) ? 'staff-disconnect' : ''}
                color={renderColorBackground(element.salary_type)}
              >
                <Tooltip title={element.name} placement="topLeft">
                  {element.name}
                </Tooltip>
                <div>
                  <span className="staff-code">{element.code}</span>
                </div>
              </TableName>
              <AmountApplied>
                {CONST_ATTENDANCE_COMPANY.AMOUNT_CAN_BE_APPLIED}
                {formatMoney(element.salary_remain)}
              </AmountApplied>
            </WrapperItemHistory>
            <LineMemberShift />
          </React.Fragment>
        ))}
      </MemberWrapper>

      {/* <CarouselContainer afterChange={onChange} ref={refRoute}> */}
      <CarouselContainer ref={refRoute}>
        {listCarousel?.map((element, indexLCarouse: number) => (
          <div key={String(element)}>
            {listMember?.map((member: IStaffHistory, index: number) => (
              <React.Fragment key={String(member.id + '-' + index)}>
                <WeekendWrapper>
                  {getTimeAttendanceInWeek(indexLCarouse)?.map((itemTime: string | null, indexTime: IWeekType) => {
                    const value = getItemData(itemTime, member.staff_salary_advance);
                    const transferDates = value?.transfer_dates?.split(',')?.map((item) => {
                      const [date, id] = item.split('_');
                      return { id, date };
                    });
                    const requestTimes = value?.request_times?.split(',')?.map((item) => {
                      const [time, id] = item.split('_');
                      return { id, time };
                    });
                    let laterTransferDatesTexts: string[] = [];
                    if (transferDates && transferDates?.length > 0 && requestTimes && requestTimes?.length > 0) {
                      laterTransferDatesTexts = transferDates.map((item) => {
                        if (item.date !== value?.request_date) {
                          const time = requestTimes.find((time) => time.id === item.id);
                          if (time) {
                            return `${CONST_ATTENDANCE_COMPANY.SCHEDULED_TRANSFER_DATE}${formatDateJP(
                              item.date,
                              true,
                              false
                            )}（${time.time} 申請分）`;
                          }
                        }
                        return '';
                      });
                    }
                    // remove empty string
                    laterTransferDatesTexts = laterTransferDatesTexts.filter((item) => item !== '');

                    return (
                      <React.Fragment key={`${itemTime}${indexToDay(indexTime)}`}>
                        <ItemHistory
                          className="itemHistory"
                          styleProps={getStyleItem(itemTime, value)}
                          onMouseEnter={(e) => handleMouseEnter(e)}
                          onMouseLeave={(e) => handleMouseLeave(e)}
                        >
                          {formatMoney(value?.total_salary)}
                          {!!(isNumber(value?.transaction_fee) || isNumber(value?.system_fee)) && (
                            <FeeWrapper>
                              <Brackets>(</Brackets>
                              <FeeContent>
                                {isNumber(value?.system_fee) && (
                                  <TransactionFee>
                                    {CONST_ATTENDANCE_COMPANY.SYSTEM_FEE} {formatMoney(value?.system_fee_tax_floor)}
                                  </TransactionFee>
                                )}
                                {isNumber(value?.transaction_fee) && (
                                  <TransactionFee>
                                    {CONST_ATTENDANCE_COMPANY.TRANSFER_FEE}{' '}
                                    {formatMoney(value?.transaction_fee_tax_floor)}
                                  </TransactionFee>
                                )}
                              </FeeContent>
                              <Brackets>)</Brackets>
                            </FeeWrapper>
                          )}
                          {Number(laterTransferDatesTexts.length) > 0 && (
                            <>
                              <img
                                src={images.companySite.warning}
                                alt="warning"
                                className="warning"
                                onMouseEnter={(e) => handleMouseEnter(e)}
                                onMouseLeave={(e) => handleMouseLeave(e)}
                              />
                              <ToolTipShow className="tooltip">
                                <div className="tooltip-text">
                                  <div>{CONST_ATTENDANCE_COMPANY.TRANSFERED_BUT_BANK_NOT_WORKING}</div>
                                  <div>{CONST_ATTENDANCE_COMPANY.TRANSFERED_BUT_BANK_NOT_WORKING_2}</div>
                                  {laterTransferDatesTexts.map((item: string, index: number) => (
                                    <div key={index}>{item}</div>
                                  ))}
                                </div>
                              </ToolTipShow>
                            </>
                          )}
                        </ItemHistory>
                      </React.Fragment>
                    );
                  })}
                </WeekendWrapper>
                <LineShift />
              </React.Fragment>
            ))}
          </div>
        ))}
      </CarouselContainer>

      <MemberWrapper>
        <TotalApplication>{CONST_ATTENDANCE_COMPANY.TOTAL_SALARY}</TotalApplication>
        {listMember?.map((element: IStaffHistory, index:number) => (
          <React.Fragment key={String(element.id + '-' + index)}>
            <WrapperTotalItemHistory>
              <ItemHistoryTotal styleProps={getStyleItem(true, getTotalRequestSalary(element.staff_salary_advance))}>
                {getTotalRequestSalary(element.staff_salary_advance)
                  ? formatMoney(getTotalRequestSalary(element.staff_salary_advance))
                  : ''}

                {!!element.staff_salary_advance?.length && (
                  <FeeWrapper>
                    <Brackets>(</Brackets>
                    <FeeContent>
                      <TransactionFee>
                        {CONST_ATTENDANCE_COMPANY.SYSTEM_FEE}{' '}
                        {formatMoney(getTotalSystemFee(element.staff_salary_advance))}
                      </TransactionFee>
                      <TransactionFee>
                        {CONST_ATTENDANCE_COMPANY.TRANSFER_FEE}{' '}
                        {formatMoney(getTotalTransactionFee(element.staff_salary_advance))}
                      </TransactionFee>
                    </FeeContent>
                    <Brackets>)</Brackets>
                  </FeeWrapper>
                )}
              </ItemHistoryTotal>
            </WrapperTotalItemHistory>
            <LineMemberShift />
          </React.Fragment>
        ))}
      </MemberWrapper>
    </ListShiftItemWrapper>
  );
};

export default forwardRef(AttendanceHistoryContainer);
