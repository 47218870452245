import { useEffect, useState } from 'react';
import { responseSuccess } from 'helper/responseSuccess/responseSuccess';
import textHelpers from 'helper/text';
import { apiCompanyCreateStaff, apiCompanyDetailStaff, apiCompanyUpdateStaff } from 'api/company';
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';
import { BANK_ACCOUNT_LABEL, ECompanyStaffSalaryType, ECompanyStaffStatusType } from 'constants/constants';
import { formatPhone } from 'helper/formatPhone';
import { useHookListStaff } from '@hooks/useCompany';
import { COMPANY_LIST_STAFF } from 'constants/keyQuery';
import { useQueryClient } from 'react-query';

export interface ICompanyStaffDetailInformation {
  id?: number;
  code?: string;
  name: string;
  name_kana: string;
  email: string;
  phone: string;
  status: number;
  salary_type?: number;
  company_id?: number;
  amount_limit_1?: number;
  amount_limit_2?: number;
  is_setting_limit_1?: number;
  is_setting_limit_2?: number;
  day_amount_limit_1?: number;
  day_amount_limit_2?: number;
  month_amount_limit_1?: number;
  month_amount_limit_2?: number;
  login_first_time?: string;
  account_name?: string;
  account_number?: string;
  bank_branches_code?: string;
  bank_code?: string;
  bank_type?: number;
  url_login?: string;
  staff_created?: string;
  prepaid_salary_morning_half?: number;
  prepaid_salary_afternoon_half?: number;
  prepaid_salary_morning_working_time?: number;
  prepaid_salary_afternoon_working_time?: number;
}

export interface ICompanyStaffBasicInformation {
  id: number;
  status: number;
  code: string;
  name: string;
}

export const STATE_PAGE = {
  VIEW: 1,
  EDIT: 2,
  CREATE: 3,
  CREATE_MANY: 4,
};

const useStaffManagement = (params: any, state: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  // const [allListStaff, setAllListStaff] = useState<ICompanyStaffBasicInformation[]>([]);
  const [listStaff, setListStaff] = useState<ICompanyStaffBasicInformation[]>([]);
  const [selected, setSelected] = useState<ICompanyStaffBasicInformation | null>(null);
  const [detailStaff, setDetailStaff] = useState<ICompanyStaffDetailInformation | null>(null);
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingCreateStaff, setIsLoadingCreateStaff] = useState<boolean>(false);
  const [isLoadingEditStaff, setIsLoadingEditStaff] = useState<boolean>(false);
  const [isLoadingDetailStaff, setIsLoadingDetailStaff] = useState<boolean>(false);
  const { data: allListStaff, isLoading } = useHookListStaff();

  const [paramSearch, setParamsearch] = useState<any>({
    key_word: '',
    type: ECompanyStaffStatusType.NONE,
  });
  const [statePage, setStatePage] = useState<number>(STATE_PAGE.VIEW);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [activeStatus, setActiveStatus] = useState<number>(paramSearch.type);

  const { id } = useParams();
  const query = new URLSearchParams(location.search);
  const paramType = query.get('type') ?? ECompanyStaffStatusType.NONE;
  const paramKeyWord = query.get('key_word') ?? '';

  useEffect(() => {
    setParamsearch((params: any) => ({
      ...params,
      type: paramType,
    }));
    setActiveStatus(Number(paramType));
  }, [paramType]);

  useEffect(() => {
    setParamsearch((params: any) => ({
      ...params,
      key_word: paramKeyWord,
    }));
    setSearchText(paramKeyWord);
  }, [paramKeyWord]);

  useEffect(() => {
    if (!id) {
      setSelected(null);
      setDetailStaff(null);
    }
  }, [id, allListStaff]);

  // useEffect(() => {
  //   if (!id) {
  //     setListStaff(allListStaff);
  //   }
  // }, [allListStaff, id, paramKeyWord]);

  const getDetailStaff = async (id: number) => {
    try {
      setIsLoadingDetailStaff(true);
      const response = await apiCompanyDetailStaff(id);
      if (responseSuccess(response)) {
        const amountLimit1 = response.data?.is_setting_limit_1 ? response.data?.amount_limit_1 : '';
        const amountLimit2 = response.data?.is_setting_limit_2 ? response.data?.amount_limit_2 : '';
        setDetailStaff({
          id: response.data?.id,
          code: response.data?.code,
          name: response.data?.name,
          name_kana: response.data?.name_kana,
          email: response.data?.email,
          phone: response.data?.phone ? formatPhone(response.data?.phone) : '',
          status: response.data?.status,
          salary_type: response.data?.salary_type,
          url_login: response.data?.url_login,
          amount_limit_1: amountLimit1,
          amount_limit_2: amountLimit2,
          is_setting_limit_1: response.data?.is_setting_limit_1,
          is_setting_limit_2: response.data?.is_setting_limit_2,
          day_amount_limit_1:
            response.data?.salary_type === ECompanyStaffSalaryType.DAILY_SALARY ? amountLimit1 : undefined,
          day_amount_limit_2:
            response.data?.salary_type === ECompanyStaffSalaryType.DAILY_SALARY ? amountLimit2 : undefined,
          month_amount_limit_1:
            response.data?.salary_type === ECompanyStaffSalaryType.MONTHLY_SALARY ? amountLimit2 : undefined,
          month_amount_limit_2:
            response.data?.salary_type === ECompanyStaffSalaryType.MONTHLY_SALARY ? amountLimit1 : undefined,
          prepaid_salary_morning_half: response.data?.prepaid_salary_morning_half
            ? response.data.prepaid_salary_morning_half
            : undefined,
          prepaid_salary_afternoon_half: response.data?.prepaid_salary_afternoon_half
            ? response.data.prepaid_salary_afternoon_half
            : undefined,
          prepaid_salary_morning_working_time: response.data?.prepaid_salary_morning_working_time
            ? response.data.prepaid_salary_morning_working_time
            : undefined,
          prepaid_salary_afternoon_working_time: response.data?.prepaid_salary_afternoon_working_time
            ? response.data.prepaid_salary_afternoon_working_time
            : undefined,
          login_first_time: response.data?.login_first_time?.substr(0, 10)?.replaceAll('-', '/'),
          account_name: response.data?.staff_bank?.account_name,
          account_number: response.data?.staff_bank?.account_number,
          bank_branches_code: response.data?.staff_bank?.bank_branches_code
            ? `${response.data?.staff_bank?.bank_branches_name} (${response.data?.staff_bank?.bank_branches_code})`
            : '',
          bank_code: response.data?.staff_bank?.bank_code
            ? `${response.data?.staff_bank?.bank_name} (${response.data?.staff_bank?.bank_code})`
            : '',
          bank_type: response.data?.staff_bank?.bank_type && BANK_ACCOUNT_LABEL[response.data?.staff_bank?.bank_type],
        });
      }
    } catch {
    } finally {
      setIsLoadingDetailStaff(false);
    }
  };

  useEffect(() => {
    if (allListStaff?.length) {
      let _text = paramSearch?.key_word ?? '';
      let _listStaff = [];
      let __listStaff: ICompanyStaffBasicInformation[] = [];

      const listSearch: string[] = allListStaff.map((element: ICompanyStaffBasicInformation) =>
        element.name.toLowerCase().replace(/\s/g, '')
      );
      _text = _text.toLowerCase().replace(/\s/g, '');
      _listStaff = allListStaff.filter(
        (_: any, index: number) =>
          listSearch[index].includes(_text) ||
          textHelpers.searchTextInLongText({
            text: _text,
            longText: listSearch[index],
          }) ||
          !_text
      );

      if (Number(paramSearch?.type) !== ECompanyStaffStatusType.NONE) {
        if (Number(paramSearch?.type) === ECompanyStaffStatusType.DELETED) {
          const arrStatus = [
            ECompanyStaffStatusType.DELETED,
            ECompanyStaffStatusType.STAFF_NOT_ALLOW_REQUEST_SALARY,
            ECompanyStaffStatusType.STAFF_DISCONNECT,
          ];
          __listStaff = _listStaff.filter((item: any, index: number) => arrStatus.includes(Number(item?.status)));
        } else {
          __listStaff = _listStaff.filter(
            (item: any, index: number) => Number(item?.status) === Number(paramSearch?.type)
          );
        }
      } else {
        __listStaff = [..._listStaff];
      }
      setListStaff(__listStaff);
    }
  }, [allListStaff, paramSearch?.key_word, paramSearch?.type]);

  // const filterStatus = useCallback(() => {
  //   if (!allListStaff?.length) return;
  //   updateListStaff('', activeStatus);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [activeStatus, allListStaff]);

  const onSearch = (searchText: string) => {
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams({ ...paramSearch, key_word: searchText })}`,
    });
  };

  const createStaff = async (formData: ICompanyStaffDetailInformation) => {
    try {
      setIsLoadingCreateStaff(true);
      const response = await apiCompanyCreateStaff(formData);
      if (responseSuccess(response)) {
        if (response.data?.is_alert) {
          setIsOpen(true);
        }
        queryClient.refetchQueries(COMPANY_LIST_STAFF);
        setSearchText('');
      }
      return response;
    } catch (error) {
      return error;
    } finally {
      setIsLoadingCreateStaff(false);
    }
  };

  const editStaff = async (formData: ICompanyStaffDetailInformation) => {
    try {
      setIsLoadingEditStaff(true);
      const response = await apiCompanyUpdateStaff(formData);
      if (responseSuccess(response)) {
        if (response.data?.is_alert) {
          setIsOpen(true);
        }
        queryClient.refetchQueries(COMPANY_LIST_STAFF);
        setSelected(null);
      }
      return response;
    } catch (error) {
      return error;
    } finally {
      setIsLoadingEditStaff(false);
    }
  };

  useEffect(() => {
    setSelected(state);
  }, [state]);

  useEffect(() => {
    if (selected) {
      getDetailStaff(selected?.id);
    }
  }, [selected]);

  useEffect(() => {
    if (location.pathname.indexOf('/list') !== -1) setStatePage(STATE_PAGE.VIEW);
    if (location.pathname.indexOf('/edit') !== -1) setStatePage(STATE_PAGE.EDIT);
    if (location.pathname.indexOf('/create') !== -1) setStatePage(STATE_PAGE.CREATE);
  }, [location.pathname]);

  // useEffect(() => {
  //   filterStatus();
  // }, [activeStatus, filterStatus]);

  // useEffect(() => {
  //   onSearch(searchText || '');
  //   // eslint-disable-next-line
  // }, [allListStaff, onSearch]);

  return {
    listStaff,
    selected,
    isLoading,
    onSearch,
    setSelected,
    createStaff,
    editStaff,
    isLoadingCreateStaff,
    isLoadingEditStaff,
    isLoadingDetailStaff,
    statePage,
    setStatePage,
    searchText,
    setSearchText,
    activeStatus,
    setActiveStatus,
    detailStaff,
    setDetailStaff,
    isOpen,
    setIsOpen,
  };
};

export default useStaffManagement;
