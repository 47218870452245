import React from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { SpaceBase } from 'styles';
import { CONST_ATTENDANCE_COMPANY } from 'constants/language';
import {
  AttendanceHeaderTableWrapper,
  Confirmed,
  ContentTextHeader,
  DateContent,
  DayTable,
  NameTable,
  StatusWorkTable,
  TimeChange,
} from './attendanceHeaderTableStyle';
import { ECompanyStaffSalaryType, ESettingTimer, ETimeShiftFormat } from 'constants/constants';

const AttendanceHeaderTable = ({
  currentDate,
  isLoadingSettingSalary,
  isTimeClosing,
  attendanceDetailData,
}: {
  currentDate: Dayjs;
  isLoadingSettingSalary: boolean;
  isTimeClosing: number;
  attendanceDetailData?: any;
}) => {
  const getDate = currentDate.format('YYYY/MM/DD');
  const checkDayShift = () => {
    if (dayjs().isAfter(currentDate.format(ETimeShiftFormat.DATE_NOON))) return true;
    return false;
  };
  const checkNightShift = () => {
    if (dayjs().isAfter(currentDate.format(ETimeShiftFormat.DATE_NIGHT))) return true;
    return false;
  };

  function TimeComponent() {
    return (
      <DayTable className="table-header-day">
        {!isLoadingSettingSalary && isTimeClosing === ESettingTimer.SETTING_STATIC && (
          <>
            {!checkNightShift() && (
              <div style={{ marginLeft: '2.5rem' }} className="table-header-day-content">
                <SpaceBase height={3} />
                <DateContent>{getDate}</DateContent>
                <DateContent>{ETimeShiftFormat.NIGHT}</DateContent>
              </div>
            )}
          </>
        )}
        {isTimeClosing === ESettingTimer.SETTING_STATIC && checkNightShift() && (
          <Confirmed style={{ marginLeft: `${attendanceDetailData?.[0]?.salary_type === 2 ? '4.5rem' : '3.5rem'}` }}>
            {CONST_ATTENDANCE_COMPANY.CONFIRMED}
          </Confirmed>
        )}
      </DayTable>
    );
  }

  return (
    <AttendanceHeaderTableWrapper>
      <NameTable>
        <ContentTextHeader>{CONST_ATTENDANCE_COMPANY.FAMILY_NAME}</ContentTextHeader>
      </NameTable>
      <StatusWorkTable>
        <ContentTextHeader>{CONST_ATTENDANCE_COMPANY.ATTENDANCE}</ContentTextHeader>
      </StatusWorkTable>
      {attendanceDetailData?.[0]?.salary_type !== ECompanyStaffSalaryType.HOUR_SALARY ? (
        <>
          <div style={{ width: '2.5rem' }}></div>
          <DayTable className="table-header-day">
            <SpaceBase height={3} />
            {!isLoadingSettingSalary && isTimeClosing === ESettingTimer.SETTING_STATIC && (
              <>
                {!checkDayShift() && (
                  <div className="table-header-day-content">
                    <DateContent>{getDate}</DateContent>
                    <DateContent>{ETimeShiftFormat.NOON}</DateContent>
                  </div>
                )}
              </>
            )}
            {isTimeClosing === ESettingTimer.SETTING_STATIC && checkDayShift() && (
              <Confirmed style={{ marginLeft: '1rem' }}>{CONST_ATTENDANCE_COMPANY.CONFIRMED}</Confirmed>
            )}
          </DayTable>
          <div style={{ width: '12.5rem' }}></div>
          <TimeComponent />
        </>
      ) : (
        <div style={{ width: '49rem' }}></div>
      )}

      <TimeChange>
        <ContentTextHeader>
          {!isLoadingSettingSalary && isTimeClosing === ESettingTimer.SETTING_STATIC && (
            <>{!checkNightShift() && <DateContent>{CONST_ATTENDANCE_COMPANY.CHANGEABLE_DEADLINE}</DateContent>}</>
          )}
          {isTimeClosing === ESettingTimer.SETTING_STATIC && checkNightShift() && (
            <Confirmed>{CONST_ATTENDANCE_COMPANY.CHANGEABLE_DEADLINE}</Confirmed>
          )}
        </ContentTextHeader>
      </TimeChange>
    </AttendanceHeaderTableWrapper>
  );
};

export default AttendanceHeaderTable;
