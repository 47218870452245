export const OPERATOR_LIST_OPERATOR = 'OPERATOR_LIST_OPERATOR';
export const OPERATOR_LIST_COMPANY = 'OPERATOR_LIST_COMPANY';
export const OPERATOR_LIST_AGENCY = 'OPERATOR_LIST_AGENCY';
export const OPERATOR_LIST_COMPANY_INVOICE = 'OPERATOR_LIST_COMPANY_INVOICE';
export const OPERATOR_LIST_AGENCY_INVOICE = 'OPERATOR_LIST_AGENCY_INVOICE';
export const OPERATOR_LIST_HISTORY_ACTION = 'OPERATOR_LIST_HISTORY_ACTION';

export const COMPANY_LIST_STAFF = 'COMPANY_LIST_STAFF';
export const COMPANY_LIST_ACCOUNT_ADMIN = 'COMPANY_LIST_ACCOUNT_ADMIN';
export const COMPANY_HISTORY_ALL_STAFF = 'COMPANY_HISTORY_ALL_STAFF';
export const COMPANY_LIST_STAFF_ATTENDANCE = 'COMPANY_LIST_STAFF_ATTENDANCE';
export const COMPANY_LIST_INVOICE = 'COMPANY_LIST_INVOICE';
export const COMPANY_APPROVED_SALARY = 'COMPANY_APPROVED_SALARY';
export const COMPANY_LIST_HISTORY_ACTION = 'COMPANY_LIST_HISTORY_ACTION';
export const COMPANY_SETTING_SALARY_DETAIL = 'COMPANY_SETTING_SALARY_DETAIL';
export const COMPANY_HISTORY_ADVANCE_ALL_DATA_STAFF = 'COMPANY_HISTORY_ADVANCE_ALL_DATA_STAFF';
export const COMPANY_HISTORY_ADVANCE_DATA_OF_STAFF = 'COMPANY_HISTORY_ADVANCE_DATA_OF_STAFF';
export const COMPANY_ADMIN_GET_ATTENDANCE = 'COMPANY_ADMIN_GET_ATTENDANCE';
export const COMPANY_INFO_INVOICE_ID = 'COMPANY_INFO_INVOICE_ID';
export const COMPANY_INVOICE_DETAIL_ID = 'COMPANY_INVOICE_DETAIL_ID';
export const COMPANY_SETTING_SALARY_LAST = 'COMPANY_SETTING_SALARY_LAST';

export const AGENCY_LIST_COMPANY = 'AGENCY_LIST_COMPANY';
export const AGENCY_LIST_INVOICE = 'AGENCY_LIST_INVOICE';
export const AGENCY_INFO_INVOICE_ID = 'AGENCY_INFO_INVOICE_ID';
export const AGENCY_INVOICE_DETAIL_ID = 'AGENCY_INVOICE_DETAIL_ID';
