import images from '@assets/images-base';
import { Rate } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import { disableBtnTimekeeping } from '../MemberTable/MemberTable';
import { ECompanyStaffSalaryType, ESettingTimer, ETimeShift, ETimeShiftKeys } from 'constants/constants';
import dayjs from 'dayjs';

const Div = styled.div`
  border-radius: 0.313rem;
  box-shadow: 0px 0px 4px 0px #00000033 inset, 0px 4px 4px 0px #00000040;
  width: 2.25rem;
  height: 3.125rem;
`;
const CustomRate = styled(Rate)<any>`
  .ant-rate-star-full .ant-rate-star-second {
    border-radius: 0.313rem;
    background-color: ${(props) => props?.color || '#FDAB29'};
  }
  &.ant-rate-disabled.ant-rate .ant-rate-star {
    background-color: #f3f3f3;
    cursor: not-allowed;
  }
`;
const TextHour = styled.span`
  font-family: Itim !important;
  font-size: 0.875rem;
  font-weight: 400;
`;
const TextHourText = styled.span`
  font-family: Itim !important;
  font-weight: 400;
  font-size: 0.875rem;
`;
const AttendanceForHour = ({
  cycleCompanySettingSalary,
  element,
  currentDate,
  isRootAdmin,
  changeTime,
  oneMember,
  staffId,
  dataCompanySettingSalaryDetail,
  stateHour,
  isTimeClosing,
  isOwner,
}: any) => {
  const [hourMorningWorking, setHourMorningWorking] = useState(0);
  const [hourAfternoonWorking, setHourAfternoonWorking] = useState(0);
  const [hourHoverMorning, setHourHoverMorning] = useState(0);
  const [keyHoverMorning, setKeyHoverMorning] = useState(false);
  const [hourHoverAfternoon, setHourHoverAfternoon] = useState(0);
  const [keyHoverAfternoon, setKeyHoverAfternoon] = useState(false);
  useEffect(() => {
    setHourMorningWorking(
      element?.morning_working_time_shift_time ? element?.morning_working_time_shift_time / 60 : ETimeShift.NOT_ACTIVITY
    );
    setHourAfternoonWorking(
      element?.afternoon_working_time_shift_time
        ? element?.afternoon_working_time_shift_time / 60
        : ETimeShift.NOT_ACTIVITY
    );
  }, [element]);
  useEffect(() => {
    if (stateHour > 0) {
      setKeyHoverMorning(false);
      setKeyHoverAfternoon(false);
    }
  }, [stateHour]);

  const ItemActiveHour = ({
    hourWorking,
    setHourWorking,
    iconPrev,
    iconNext,
    iconTime,
    status,
    hourHover,
    setHourHover,
    keyHover,
    setKeyHover,
    color,
  }: any) => {
    let customChooseHour: any = {};
    let customAboveHour: any = {};
    let customBelowHour: any = {};
    let customChooseHourHover: any = {};
    let customBelowHourHover: any = {};
    const disabledHour = () => {
      if (currentDate.format('YYYY-MM-DD') < cycleCompanySettingSalary?.start_date || !isOwner) {
        return true;
      } else {
        if (dataCompanySettingSalaryDetail?.is_time_closing === ESettingTimer.SETTING_TIMER) {
          if (status === 'sun' && currentDate.format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD')) {
            if (!isRootAdmin) {
              return true;
            }
          }
          if (status === 'moon' && currentDate.format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD')) {
            if (!isRootAdmin) {
              return true;
            }
          }
          if (
            status === 'moon' &&
            element?.closing_afternoon_working_time < dayjs().add(2, 'second').format('YYYY-MM-DD HH:mm:ss')
          ) {
            if (isRootAdmin && hourWorking !== 0) {
              return true;
            }
            if (!isRootAdmin) {
              return true;
            }
            return false;
          }
          if (
            status === 'sun' &&
            element?.closing_morning_working_time < dayjs().add(2, 'second').format('YYYY-MM-DD HH:mm:ss')
          ) {
            if (isRootAdmin && hourWorking !== 0) {
              return true;
            }
            if (!isRootAdmin) {
              return true;
            }
            return false;
          }
        } else if (dataCompanySettingSalaryDetail?.is_time_closing === ESettingTimer.SETTING_STATIC) {
          if (
            status === 'sun' &&
            element?.closing_morning_working_time &&
            element.closing_morning_working_time < dayjs().format('YYYY-MM-DD HH:mm:ss')
          ) {
            if (isRootAdmin && hourWorking !== 0) {
              return true;
            }
            if (!isRootAdmin) {
              return true;
            }
            return false;
          }
          if (
            status === 'moon' &&
            element?.closing_afternoon_working_time &&
            element.closing_afternoon_working_time < dayjs().format('YYYY-MM-DD HH:mm:ss')
          ) {
            if (isRootAdmin && hourWorking !== 0) {
              return true;
            }
            if (!isRootAdmin) {
              return true;
            }
            return false;
          }
          if (status === 'sun' && currentDate.format('YYYY-MM-DD 11:59') < dayjs().format('YYYY-MM-DD HH:mm')) {
            if (!isRootAdmin) {
              return true;
            }
          }
          if (status === 'moon' && currentDate.format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD')) {
            if (!isRootAdmin) {
              return true;
            }
          }
          // if (status === 'sun' && !element?.closing_afternoon_working_time && currentDate.format('YYYY-MM-DD 11:59:59') < dayjs().format('YYYY-MM-DD HH:mm:ss')) {
          //   if (isRootAdmin && hourWorking !== 0) {
          //     return true;
          //   }
          //   if (!isRootAdmin) {
          //     return true;
          //   }
          //   return false;
          // }
          // if (status === 'moon' && !element?.closing_morning_working_time && currentDate.format('YYYY-MM-DD 23:59:59') < dayjs().format('YYYY-MM-DD HH:mm:ss')) {
          //   if (isRootAdmin && hourWorking !== 0) {
          //     return true;
          //   }
          //   if (!isRootAdmin) {
          //     return true;
          //   }
          //   return false;
          // }
        }
        return false;
      }
    };

    for (let i = 1; i < 13; i++) {
      customChooseHour[i] = (
        <Div
          style={{
            position: 'relative',
            boxShadow: `${(hourWorking !== 0 || disabledHour()) && '0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset'}`,
          }}
        >
          <div
            style={{
              position: 'absolute',
              color: '#fff',
              display: 'flex',
              marginTop: `${i < 10 ? '0.4rem' : '0.8rem'}`,
              marginLeft: `${i < 10 ? '0.4rem' : '0.15rem'}`,
            }}
          >
            {i < 10 ? (
              <TextHour style={{ fontSize: '2rem' }}>{i}</TextHour>
            ) : (
              <TextHour style={{ fontSize: '1.4rem' }}>{i}</TextHour>
            )}
            <TextHourText style={{ marginTop: `${i < 10 ? '1rem' : '0.5rem'}` }} className="">
              h
            </TextHourText>
          </div>
        </Div>
      );
      customChooseHourHover[i] = (
        <Div
          style={{
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              color: '#fff',
              display: 'flex',
              marginTop: `${i < 10 ? '0.4rem' : '0.8rem'}`,
              marginLeft: `${i < 10 ? '0.4rem' : '0.15rem'}`,
            }}
          >
            {i < 10 ? (
              <TextHour style={{ fontSize: '2rem' }}>{i}</TextHour>
            ) : (
              <TextHour style={{ fontSize: '1.4rem' }}>{i}</TextHour>
            )}
            <TextHourText style={{ marginTop: `${i < 10 ? '1rem' : '0.5rem'}` }} className="">
              h
            </TextHourText>
          </div>
        </Div>
      );
      customAboveHour[i] = (
        <Div
          style={{
            position: 'relative',
            boxShadow: `${disabledHour() && '0px 0px 4px 0px rgba(0, 0, 0, 0.2) inset'}`,
          }}
        ></Div>
      );
      customBelowHour[i] = (
        <Div
          style={{
            position: 'relative',
            boxShadow: `${(hourWorking !== 0 || disabledHour()) && '0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset'}`,
          }}
        ></Div>
      );
      customBelowHourHover[i] = (
        <Div
          style={{
            position: 'relative',
          }}
        ></Div>
      );
    }

    const sendData = (hour: number, status: string) => {
      let changeData: any = {
        staff_id: staffId,
        date: currentDate.format('YYYY-MM-DD'),
        day_shift: element?.day_shift || ETimeShift.NOT_ACTIVITY,
        night_shift: element?.night_shift || ETimeShift.NOT_ACTIVITY,
        cycle_start_date: cycleCompanySettingSalary.start_date,
        cycle_end_date: cycleCompanySettingSalary.end_date,
        morning_haft_shift: element?.day_shift || ETimeShift.NOT_ACTIVITY,
        afternoon_haft_shift: element?.night_shift || ETimeShift.NOT_ACTIVITY,
        salary_type: ECompanyStaffSalaryType.HOUR_SALARY,
      };
      if (status === 'sun') {
        changeData.morning_working_time_shift_time = hour * 60;
        changeData.afternoon_working_time_shift_time =
          element?.afternoon_working_time_shift_time || ETimeShift.NOT_ACTIVITY;
        if (oneMember) {
          changeTime(changeData, ETimeShiftKeys.DAY_SHIFT, currentDate.format('YYYY-MM-DD'), isTimeClosing);
        } else {
          changeTime(changeData, ETimeShiftKeys.DAY_SHIFT, isTimeClosing);
        }
      } else {
        changeData.afternoon_working_time_shift_time = hour * 60;
        changeData.morning_working_time_shift_time =
          element?.morning_working_time_shift_time || ETimeShift.NOT_ACTIVITY;

        if (oneMember) {
          changeTime(changeData, ETimeShiftKeys.NIGHT_SHIFT, currentDate.format('YYYY-MM-DD'), isTimeClosing);
        } else {
          changeTime(changeData, ETimeShiftKeys.NIGHT_SHIFT, isTimeClosing);
        }
      }
    };
    return (
      <>
        <div
          style={{ display: 'flex', marginRight: `${status === 'moon' ? '0.8rem' : '0.7rem'}`, alignItems: 'center' }}
        >
          <div
            style={{
              position: 'relative',
              marginTop: '0.875rem',
              marginRight: '0.313rem',
              cursor: `${hourWorking === 0 || disabledHour() ? 'not-allowed' : 'pointer'}`,
            }}
            onClick={() => {
              if (!disabledHour()) {
                if (hourWorking > 0) {
                  sendData(hourWorking - 1, status);
                }
              }
            }}
          >
            <img src={iconPrev} alt="" style={{ width: '2rem' }} />
            <div style={{ position: 'absolute', color: '#fff', top: '-0.43rem', right: '0.67rem' }}>-</div>
          </div>
          <img src={iconTime} style={{ marginLeft: `${status === 'moon' ? '-0.513rem' : ''}`, width: '2rem' }} alt="" />
          <div
            style={{
              position: 'relative',
              marginTop: '0.875rem',
              marginLeft: `${status === 'moon' ? '0.9rem' : '0.4rem'}`,
              cursor: `${hourWorking === 12 || disabledHour() ? 'not-allowed' : 'pointer'}`,
            }}
            onClick={() => {
              if (!disabledHour()) {
                if (hourWorking < 12) {
                  sendData(hourWorking + 1, status);
                }
              }
            }}
          >
            <img src={iconNext} alt="" style={{ width: '2rem' }} />
            <div style={{ position: 'absolute', color: '#fff', top: '-0.313rem', left: '0.15rem' }}>+</div>
          </div>
        </div>
        <CustomRate
          character={({ index }: { index: number }) => {
            if (keyHover) {
              if (hourHover > index + 1) {
                return customBelowHourHover[index + 1];
              } else if (hourHover === index + 1) {
                return customChooseHourHover[index + 1];
              } else {
                return customAboveHour[index + 1];
              }
            } else {
              if (hourWorking > index + 1) {
                return customBelowHour[index + 1];
              } else if (hourWorking === index + 1) {
                return customChooseHour[index + 1];
              } else {
                return customAboveHour[index + 1];
              }
            }
          }}
          onChange={(e: any) => {
            sendData(e, status);
          }}
          count={12}
          value={hourWorking}
          color={color}
          onHoverChange={(e: any) => {
            if (e) {
              setHourHover(e);
              setKeyHover(true);
            } else {
              setHourHover(0);
              setHourWorking(hourWorking);
              setKeyHover(false);
            }
          }}
          disabled={disabledHour()}
        />
      </>
    );
  };

  return (
    <div style={{ display: 'block', width: '49rem' }}>
      <div style={{ marginTop: '0.313rem', display: 'flex', height: '3rem', marginBottom: '0.5rem', width: '100%' }}>
        <ItemActiveHour
          hourWorking={hourMorningWorking}
          setHourWorking={setHourMorningWorking}
          iconPrev={images.companySite.prevHour}
          iconNext={images.companySite.nextHour}
          iconTime={images.companySite.sun}
          status="sun"
          hourHover={hourHoverMorning}
          setHourHover={setHourHoverMorning}
          keyHover={keyHoverMorning}
          setKeyHover={setKeyHoverMorning}
        />
      </div>
      <div style={{ display: 'flex', height: '3rem', width: '100%' }}>
        <ItemActiveHour
          hourWorking={hourAfternoonWorking}
          setHourWorking={setHourAfternoonWorking}
          iconPrev={images.companySite.prevHour}
          iconNext={images.companySite.nextMoon}
          iconTime={images.companySite.moon}
          status="moon"
          hourHover={hourHoverAfternoon}
          setHourHover={setHourHoverAfternoon}
          keyHover={keyHoverAfternoon}
          setKeyHover={setKeyHoverAfternoon}
          color="#47C9D1"
        />
      </div>
      <div style={{ color: '#fff', marginTop: `${oneMember && '-1.5rem'}` }}>{stateHour}</div>
    </div>
  );
};
export default AttendanceForHour;
