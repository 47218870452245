import { Confirmed } from '@containers/CompanySite/AttendanceDetail/AttendanceHeaderTable/attendanceHeaderTableStyle';
import { CONST_ATTENDANCE_COMPANY } from 'constants/language';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

const CountDownDate = ({
  date,
  text,
  isShowDay,
  salaryType,
  setStateHour,
}: {
  date: string;
  text?: string;
  isShowDay?: boolean;
  salaryType?: number;
  setStateHour?: any;
}) => {
  const calculateTimeLeft = (_date: any) => {
    const difference = dayjs(_date).diff(dayjs(), 'second');

    if (difference > 0) {
      const seconds = difference % 60;
      const minutes = Math.floor((difference / 60) % 60);
      const hours = Math.floor((difference / 3600) % 24);
      const days = Math.floor(difference / 86400);

      return {
        days,
        hours: hours >= 10 ? hours : `0${hours}`,
        minutes: minutes >= 10 ? minutes : `0${minutes}`,
        seconds: seconds >= 10 ? seconds : `0${seconds}`,
      };
    }
  };
  const [timeLeft, setTimeLeft]: any = useState(calculateTimeLeft(date));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft(date));
    }, 1000);

    return () => clearInterval(interval);
  }, [date]);

  useEffect(() => {
    if (!timeLeft) {
      setStateHour((prev: number) => prev + 1);
    }
  }, [timeLeft, setStateHour, salaryType]);

  return (
    <>
      {!timeLeft && salaryType ? (
        <Confirmed
          style={{ marginLeft: `${salaryType !== 3 && '-1.3rem'}`, alignItems: `${salaryType !== 3 && 'center'}` }}
        >
          ({CONST_ATTENDANCE_COMPANY.CONFIRMED} )
        </Confirmed>
      ) : (
        <div style={{ display: `${salaryType === 3 && 'flex'}` }}>
          <div style={{ marginRight: `${salaryType === 3 && '1rem'}` }}>{text && timeLeft ? text : ''}</div>
          {timeLeft?.days !== undefined &&
            (isShowDay
              ? `${timeLeft?.totalHours}:${timeLeft?.minutes}:${timeLeft?.seconds}`
              : timeLeft?.days > 0
              ? `${timeLeft?.days}日${timeLeft?.hours}:${timeLeft?.minutes}:${timeLeft?.seconds}`
              : `${timeLeft?.hours}:${timeLeft?.minutes}:${timeLeft?.seconds}`)}
        </div>
      )}
    </>
  );
};

export default CountDownDate;
