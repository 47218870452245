import { useEffect, useState, useMemo } from 'react';

import dayjs, { Dayjs } from 'dayjs';

import { ETotalTypeChart } from 'constants/constants';
import { responseSuccess } from 'helper/responseSuccess/responseSuccess';
import { apiCompanyChartTopPage } from 'api/company';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { _isNumber } from 'helper';
import { getDayjsByTimeZone, isRegexDate, isRegexYear } from 'helper/date';

interface IDataChart {
  month: string | number;
  total_request: number;
  total_salary: string | number;
}

const months: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const useChartComponent = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramYear = query.get('year') ?? '';
  const paramType = _isNumber(query.get('type'), 1);
  const paramDateStart = isRegexDate(query.get('date_start') ?? '') ? query.get('date_start') : '';
  const paramDateEnd = isRegexDate(query.get('date_end') ?? '') ? query.get('date_end') : '';

  const [selected, _setSelected] = useState<ETotalTypeChart.SALARY | ETotalTypeChart.REQUEST>(ETotalTypeChart.SALARY);
  const [year, _setYear] = useState<any>();
  const [dataChartRaw, setDataChartRaw] = useState<IDataChart[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    _setYear(isRegexYear(paramYear) ? getDayjsByTimeZone(paramYear) : dayjs());
  }, [paramYear]);

  useEffect(() => {
    _setSelected(paramType > 2 ? 1 : paramType);
  }, [paramType]);

  const getDataChart = async () => {
    try {
      setIsLoading(true);
      if (year) {
        const response = await apiCompanyChartTopPage({
          year: year.format('YYYY'),
        });
        if (responseSuccess(response)) {
          setDataChartRaw(
            months.map((element: number) => {
              const find = response?.data?.find(
                (elementFind: IDataChart) => dayjs(elementFind.month).month() + 1 === element
              );
              if (find) return find;
              return {
                month: element,
                total_request: 0,
                total_salary: 0,
              };
            })
          );
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDataChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  const dataChartCurrent = useMemo(() => {
    return dataChartRaw.map((element: IDataChart) => {
      if (selected === ETotalTypeChart.SALARY) return Number(element.total_salary);
      return element.total_request;
    });
  }, [dataChartRaw, selected]);

  const setYear = (e: Dayjs) => {
    _setYear(e);
    let params: any = {
      year: year.format('YYYY'),
      type: selected.toString(),
    };

    if (paramDateStart && paramDateEnd && paramDateStart <= paramDateEnd) {
      params = { ...params, date_start: '', date_end: paramDateEnd };
    }
    navigate({
      pathname: `/`,
      search: `?${createSearchParams(params)}`,
    });
  };

  const setSelected = (value: number) => {
    _setSelected(value);
    let params: any = {
      year: year.format('YYYY'),
      type: value.toString(),
    };

    if (paramDateStart && paramDateEnd && paramDateStart <= paramDateEnd) {
      params = { ...params, date_start: paramDateStart, date_end: paramDateEnd };
    }
    navigate({
      pathname: `/`,
      search: `?${createSearchParams(params)}`,
    });
  };

  return { setSelected, dataChartCurrent, isLoading, selected, setYear, year };
};

export default useChartComponent;
