import { useState, useEffect, useCallback } from 'react';

import { IBread } from '@components/Breadcrumb/BreadCrumb';
import { CONST_EXPORT_INVOICE, CONST_LIST_INVOICE_COMPANY } from 'constants/language';
import { createInvoicePDF } from 'helper/export';
import { formatDateCommon } from 'helper/date';
import { useNavigate, useParams } from 'react-router-dom';
import {
  IFilterPage,
  IInvoiceDetailSiteCompany,
  MAX_ITEM_PAGE_ONE_COMPANY,
  MAX_ITEM_PAGE_OTHER_COMPANY,
  defaultPageInvoiceDetailCompany,
} from 'constants/invoice';
import { useCompanyInvoiceDetail, useGetCompanyInfoInvoice } from '@hooks/useCompany';
import { apiListCompanyInvoiceDetail } from 'api/operator';
import { colors } from 'constants/colorsBase';

const useInvoiceDetailCompany = () => {
  const { id }: any = useParams();
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
  const [invoiceDetail, setInvoiceDetail] = useState<IInvoiceDetailSiteCompany[]>();
  const [invoiceAllDetail, setInvoiceAllDetail] = useState<IInvoiceDetailSiteCompany[]>();
  const [page, setPage] = useState<IFilterPage>(defaultPageInvoiceDetailCompany);
  const [perPage, setPerPage] = useState<number>(page.per_page);
  const [listInvoiceCompany, setListInvoiceCompany] = useState<any>([]);

  const { data: invoiceInfo, isLoading } = useGetCompanyInfoInvoice(id);
  const getListCompany = async (invoiceInfo: any) => {
    let params: any = {
      start_date: invoiceInfo.start_date,
      end_date: invoiceInfo.end_date,
    };
    params.company_id = invoiceInfo.company_id;
    const response = await apiListCompanyInvoiceDetail(params);
    let listCompany = [];
    listCompany.push({
      value: `${params.company_id}_${invoiceInfo.usage_plan}_${invoiceInfo.start_date}_${invoiceInfo.end_date}_all`,
      label: '総括',
      color: colors.mainColor,
    });
    listCompany.push({
      value: `${params.company_id}_${invoiceInfo.usage_plan}_${invoiceInfo.start_date}_${invoiceInfo.end_date}`,
      label: response?.data?.[0]?.company_name ? response?.data?.[0].company_name : invoiceInfo.company_name,
      color: colors.mainColor,
    });
    response.data?.forEach((company: any) => {
      listCompany.push({
        value: `${company.company_paired_id}_${company.company_plan}_${invoiceInfo.start_date}_${invoiceInfo.end_date}`,
        label: company.company_paired_name,
        color: company.company_paired_plan === company.company_plan ? colors.mainColor : colors.lightGray,
      });
    });
    setListInvoiceCompany(listCompany);
  };

  useEffect(() => {
    let max = MAX_ITEM_PAGE_ONE_COMPANY;
    if (
      invoiceInfo?.operator_address1 &&
      invoiceInfo?.operator_address1.length > 23 &&
      invoiceInfo?.operator_address2 &&
      invoiceInfo?.operator_address2.length > 23
    )
      max--; // 23 characters in one row
    setPerPage(max);
  }, [invoiceInfo]);

  useEffect(() => {
    if (invoiceInfo && invoiceInfo.pattern_id === 0) {
      getListCompany(invoiceInfo);
    }
  }, [invoiceInfo]);

  const { data: dataInvoiceDetail, isLoading: isLoadingInvoiceDetail } = useCompanyInvoiceDetail(id, {
    isLoading: isLoading,
    per_page: perPage,
    offset: page.offset,
  });

  const { data: dataInvoiceAllDetail, isLoading: isLoadingInvoiceAllDetail } = useCompanyInvoiceDetail(id, {
    isLoading: isLoading,
    per_page: page.total,
  });

  useEffect(() => {
    setInvoiceDetail(dataInvoiceDetail?.data);
    setPage((_perPage) => ({
      ..._perPage,
      total: dataInvoiceDetail?.total ? dataInvoiceDetail.total : 0,
    }));
  }, [dataInvoiceDetail]);

  useEffect(() => {
    setInvoiceAllDetail(dataInvoiceAllDetail?.data);
  }, [dataInvoiceAllDetail]);

  const BREADS: IBread[] = [
    {
      name: CONST_LIST_INVOICE_COMPANY.LIST_INVOICE,
      path: '/company-invoices',
    },
    {
      name: CONST_LIST_INVOICE_COMPANY.INVOICE_DETAIL,
      path: '',
    },
  ];

  const exportInvoicePDF = useCallback(() => {
    if (isLoadingExport || !invoiceInfo) {
      return;
    }
    let infoInvoices = {
      isGeneral: false,
      patternId: invoiceInfo.pattern_id,
    };
    if (id && id.includes('all')) {
      infoInvoices.isGeneral = true;
    }
    setIsLoadingExport(true);
    setTimeout(async () => {
      await createInvoicePDF(
        invoiceInfo.id,
        CONST_EXPORT_INVOICE.REQUEST_DATE + formatDateCommon(invoiceInfo?.request_date),
        CONST_EXPORT_INVOICE.EXPORT_FILE_NAME,
        true,
        false,
        infoInvoices
      );
      setIsLoadingExport(false);
    }, 100);
  }, [invoiceInfo, isLoadingExport, id]);

  const changePaging = useCallback(
    (pageChange: number) => {
      if (pageChange > 1) {
        setPage({
          ...page,
          page: pageChange,
          per_page: MAX_ITEM_PAGE_OTHER_COMPANY,
          offset: MAX_ITEM_PAGE_ONE_COMPANY + MAX_ITEM_PAGE_OTHER_COMPANY * (pageChange - 2),
        });
      } else {
        setPage({
          ...page,
          page: pageChange,
          per_page: MAX_ITEM_PAGE_ONE_COMPANY,
          offset: 0,
        });
      }
    },
    [page]
  );
  const navigate = useNavigate();
  const changeCompanyInvoice = (e: any) => {
    setPage(defaultPageInvoiceDetailCompany);
    navigate(`/company-invoices/detail/${e}`);
  };

  return {
    BREADS,
    invoiceDetail,
    page,
    changePaging,
    isLoading,
    exportInvoicePDF,
    invoiceAllDetail,
    isLoadingExport,
    invoiceInfo,
    isLoadingInvoiceAllDetail,
    isLoadingInvoiceDetail,
    listInvoiceCompany,
    changeCompanyInvoice,
  };
};

export default useInvoiceDetailCompany;
