import { useEffect, useState, useCallback } from 'react';
import type { DatePickerProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import textHelpers from 'helper/text';
import { responseSuccess } from 'helper/responseSuccess/responseSuccess';
import { apiAdminGetCompanyStatisticalDate, apiAdminGetDetailCompany } from 'api/agency';
import { ICompanyStatisticalDate, IDetailCompany, IListCompany } from '../interface';
import { useHookListCompany } from '@hooks/useAgency';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getDayjsByTimeZone, isRegexMonth } from 'helper/date';

const useListCompany = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const query = new URLSearchParams(search);
  const paramSerach = query.get('name') ?? '';
  const paramMonth: Dayjs = isRegexMonth(query.get('month') ?? '')
    ? getDayjsByTimeZone(query.get('month') ?? '')
    : dayjs();
  const { id } = useParams();

  const [isLoadingDetailCompany, setIsLoadingDetailCompany] = useState<boolean>(false);
  const [isLoadingCompanyStatisticalDate, setIsLoadingCompanyStatisticalDate] = useState<boolean>(false);

  const [detailCompany, setDetailCompany] = useState<IDetailCompany | null>(null);
  const [companyStatisticalDate, setCompanyStatisticalDate] = useState<ICompanyStatisticalDate | null>(null);
  const [selected, setSelected] = useState<IListCompany | null>(null);
  const [currentListCompany, setCurrentListCompany] = useState<IListCompany[]>([]);
  const [dateSearch, setDateSearch] = useState<Dayjs>(paramMonth);
  const [dateSearchInput, setDateSearchInput] = useState<Dayjs | undefined>(paramMonth);
  const [nameSearch, setNameSearch] = useState<string>(paramSerach);

  const { data: listCompany, isLoading } = useHookListCompany();

  useEffect(() => {
    if (listCompany?.length) setCurrentListCompany([...listCompany]);
    else setCurrentListCompany([]);
  }, [listCompany]);

  const onListCompanyCurent = useCallback(
    (searchText: string) => {
      if (listCompany?.length) {
        const listSearch = [...listCompany].map((element: IDetailCompany) => element.name + element.code);
        const dataSearch: IDetailCompany[] = listCompany.filter(
          (_: any, index: number) =>
            listSearch[index]
              .replaceAll(/\s/g, '')
              .toLowerCase()
              .includes(searchText.toLowerCase().replaceAll(/\s/g, '')) ||
            textHelpers.searchTextInLongText({
              text: searchText,
              longText: listSearch[index],
            }) ||
            searchText === ''
        );
        setCurrentListCompany(dataSearch);
      } else {
        setCurrentListCompany([]);
      }
    },
    [listCompany]
  );

  useEffect(() => {
    if (!id) {
      setSelected(null);
    }
  }, [id, listCompany, pathname]);

  useEffect(() => {
    setDateSearch(paramMonth);
    setDateSearchInput(paramMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramMonth.format('YYYY-MM')]);

  useEffect(() => {
    setNameSearch(paramSerach);
    onListCompanyCurent(paramSerach);
  }, [onListCompanyCurent, paramSerach]);

  const onSearch = (searchText: string) => {
    navigate({
      pathname,
      search: `?name=${searchText}&date=${paramMonth.format('YYYY-MM')}`,
    });
    onListCompanyCurent(searchText);
  };

  const getDetailCompany = async (idCompany: number) => {
    const response = await apiAdminGetDetailCompany(idCompany);
    if (responseSuccess(response)) {
      setDetailCompany(response.data);
    }
    setIsLoadingDetailCompany(false);
  };

  const getCompanyStatisticalDate = async (idCompany: number, date?: string) => {
    setIsLoadingCompanyStatisticalDate(true);
    try {
      const response = await apiAdminGetCompanyStatisticalDate(idCompany, date);
      if (responseSuccess(response)) {
        setCompanyStatisticalDate(response.data);
      }
    } catch {
      //
    } finally {
      setIsLoadingCompanyStatisticalDate(false);
    }
  };

  useEffect(() => {
    if (selected) {
      setIsLoadingDetailCompany(true);
      setDetailCompany(null);
      getDetailCompany(selected.id);
    }
  }, [selected]);

  useEffect(() => {
    if (selected) {
      getCompanyStatisticalDate(selected.id, dateSearch?.format('YYYY-MM-DD'));
    }
  }, [selected, dateSearch]);

  const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
    if (date) {
      setDateSearch(date);
      setDateSearchInput(date);
      navigate({
        pathname,
        search: `?name=${nameSearch}&month=${date?.format('YYYY-MM')}`,
      });
    } else {
      setDateSearchInput(undefined);
    }
  };

  return {
    selected,
    isLoading,
    listCompany,
    currentListCompany,
    onSearch,
    setSelected,
    detailCompany,
    isLoadingDetailCompany,
    onChangeDate,
    dateSearch,
    dateSearchInput,
    companyStatisticalDate,
    isLoadingCompanyStatisticalDate,
    nameSearch,
    setNameSearch,
  };
};

export default useListCompany;
