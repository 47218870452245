import { sendGet, sendPost, sendPut } from 'helper/api/axios';

interface ICompanyChangePassword {
  password: string;
  password_confirm: string;
}

interface ILogin {
  email: string;
  password: string;
  user_role: number;
}
interface ICompanyVerifyPassword {
  email: string;
  user_role: number;
}
export const apiGetListOperator = () => sendGet('/s1/operator/list');
export const apiOperatorVerifyChangePassword = (params: ICompanyVerifyPassword) =>
  sendPost('/api/verify-email-change-password', params);
export const apiCreateOperator = (params: any) => sendPost('/s1/operator/create', params);
export const apiUpdateOperator = (idOperator: number, params: any) =>
  sendPut(`/s1/operator/update/${idOperator}`, params);
export const apiLogin = (params: ILogin) => sendPost('/api/login', params);
export const apiChangePassword = (code: string | null, params: ICompanyChangePassword) =>
  sendPost(`/api/change-password/${code}`, params);
export const apiGetUserRegulations = (type: number) => sendGet('/api/user/regulations-detail', { type });
export const apiAgreeRegulations = (data: FormData) => sendPost('/api/user/agrees-regulations', data);
export const apiGetLastAgreedRegulations = (type: number, companyId?: number, agencyId?: number) =>
  sendGet('/api/user/last-agreed-regulation', { type, company_id: companyId, agency_id: agencyId });
export const apiGetUserRegulationsStatus = () => sendGet('/api/user/regulations-status');

export const apiUploadFile = (data: FormData) => sendPost('/api/file/upload', data);
export const apiSaveFile = (urls: string[]) => sendPost('/api/file/save', { urls });

export const apiReadAllNotifications = () => sendPost('/api/notify/read-all');

export const apiGetUserInfo = () => sendGet('/api/user/info');
export const apiGetBankList = (params: any) => sendGet('/api/bank/list', params);
export const apiGetBankBranchList = (params: any) => sendGet('/api/bank-branch/list', params);

export const apiGetOperatorInfo = () => sendGet('/api/operator/basic-info');

export const apiGetCompanyInfoInvoice = (id: any) => sendGet(`/api/invoice-company/detail/${id}`);
export const apiGetAgencyInfoInvoice = (id: number) => sendGet(`/api/invoice-agency/detail/${id}`);

export const apiCheckForgotPassword = (params: any) => sendPost(`/api/check-link-forgot-password`, params);

export const apiCheckMaintenance = (params: any) => sendGet(`/api/heal-check/maintenance`, params);
