const configs = {
  APP_ENV: process.env.REACT_APP_ENV,
  APP_FRONTEND_OPERATOR: process.env.REACT_APP_FRONTEND_OPERATOR,
  APP_FRONTEND_AGENCY: process.env.REACT_APP_FRONTEND_AGENCY,
  APP_FRONTEND_COMPANY: process.env.REACT_APP_FRONTEND_COMPANY,
  APP_FRONTEND_EMPLOYEE: process.env.REACT_APP_FRONTEND_EMPLOYEE,
  REACT_APP_FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
  REACT_APP_URL_UPLOAD: process.env.REACT_APP_URL_UPLOAD,
  APP_CLOUDFOUNT: process.env.REACT_APP_APP_CLOUDFOUNT,
  TIME_ZONE: process.env.REACT_APP_TIME_ZONE,
};

export default configs;
