import { DAY_OF_WEEK } from 'constants/company';
import dayjs, { Dayjs } from 'dayjs';
import { getDayjsByTimeZone } from 'helper/date';

export const getAllDayOfMonth = (month: Dayjs) => {
  const arrDate = [];
  const startDay = dayjs(month).startOf('month').day();
  const endDay = dayjs(month).daysInMonth();
  const maxArr = Math.ceil((endDay + startDay) / DAY_OF_WEEK) * DAY_OF_WEEK;

  for (let i = -startDay; i < maxArr - startDay; i++) {
    if (i >= 0 && i < endDay) {
      arrDate.push(i + 1);
    } else {
      arrDate.push(null);
    }
  }
  return arrDate;
};

export const getAllDayOfMonthCycle = (startDate: string | null, endDate: string | null) => {
  const arrDate: any = [];
  let _startDate = startDate;
  let _endDate = endDate;
  if (!_startDate) _startDate = getDayjsByTimeZone().startOf('month').format('YYYY-MM-DD');
  if (!_endDate) _endDate = getDayjsByTimeZone().endOf('month').format('YYYY-MM-DD');

  let startDay = dayjs(_startDate);
  let startDayFirst = dayjs(_startDate);
  let endDay = dayjs(_endDate);
  let endDayLast = dayjs(_endDate);

  while (startDayFirst.day() > 0) {
    arrDate.push(null);
    if (startDayFirst.day() === 0) {
      break;
    }
    startDayFirst = startDayFirst.subtract(1, 'day');
  }
  while (startDay.format('YYYY-MM-DD') <= endDay.format('YYYY-MM-DD')) {
    arrDate.push(startDay.format('YYYY-MM-DD'));
    startDay = startDay.add(1, 'day');
  }
  while (endDayLast.day() < 6) {
    arrDate.push(null);
    if (endDayLast.day() === 6) {
      break;
    }
    endDayLast = endDayLast.add(1, 'day');
  }

  return arrDate;
};

export const getDayListOfMonth = (month: Dayjs) => {
  const endDay = dayjs(month).daysInMonth();
  return Array.from(Array(endDay).keys());
};

export const getDaysOfMonth = (month: Dayjs) => {
  const endDay = dayjs(month).daysInMonth();
  const arrDate = [];

  for (let i = 0; i < endDay; i++) {
    if (i >= 0 && i < endDay) {
      arrDate.push(String(i + 1));
    }
  }
  return arrDate;
};
