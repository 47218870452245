import { useQuery } from 'react-query';

import {
  OPERATOR_LIST_AGENCY,
  OPERATOR_LIST_AGENCY_INVOICE,
  OPERATOR_LIST_COMPANY,
  OPERATOR_LIST_COMPANY_INVOICE,
  OPERATOR_LIST_HISTORY_ACTION,
  OPERATOR_LIST_OPERATOR,
} from 'constants/keyQuery';

import { apiGetListOperator } from 'api';
import {
  apiAgencyListInvoice,
  apiCompanyListInvoice,
  apiOperatorAgencyList,
  apiOperatorFootstepsHistory,
  apiOperatorGetListCompany,
} from 'api/operator';

export const useHookListOperator = () => {
  const { data, isLoading } = useQuery<any>([OPERATOR_LIST_OPERATOR], async () => {
    const response = await apiGetListOperator();
    return response;
  });

  return { ...data, isLoading };
};

export const useHookOperatorGetListCompany = () => {
  const { data, isLoading } = useQuery<any>([OPERATOR_LIST_COMPANY], async () => {
    const response = await apiOperatorGetListCompany();
    return response;
  });

  return { ...data, isLoading };
};

export const useHookOperatorAgencyList = () => {
  const { data, isLoading } = useQuery<any>([OPERATOR_LIST_AGENCY], async () => {
    const response = await apiOperatorAgencyList();
    return response;
  });

  return { ...data, isLoading };
};

export const useHookCompanyListInvoice = (params: any) => {
  const { data, isLoading } = useQuery<any>([OPERATOR_LIST_COMPANY_INVOICE, params], async () => {
    const response = await apiCompanyListInvoice(params);
    return response;
  });

  return { ...data, isLoading };
};

export const useHookAgencyListInvoice = (params: any) => {
  const { data, isLoading } = useQuery<any>([OPERATOR_LIST_AGENCY_INVOICE, params], async () => {
    const response = await apiAgencyListInvoice(params);
    return response;
  });

  return { ...data, isLoading };
};

export const useHookOperatorFootstepsHistory = (params: any) => {
  const { data, isLoading } = useQuery<any>([OPERATOR_LIST_HISTORY_ACTION, params], async () => {
    const response = await apiOperatorFootstepsHistory(params);
    return response;
  });

  return { ...data, isLoading };
};
