import { useState, useEffect } from 'react';
import originalMoment from 'moment';

import { extendMoment } from 'moment-range';
import { apiCompanyCalendarTopPage } from 'api/company';
import { responseSuccess } from 'helper/responseSuccess/responseSuccess';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { _isNumber } from 'helper';
import dayjs from 'dayjs';
import { isRegexDate } from 'helper/date';
const moment: any = extendMoment(originalMoment as any);

interface ITotalRequest {
  total_salary: number;
  total_request: number;
}
const useRangePicker = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramYear = query.get('year') ?? dayjs().format('YYYY');
  const paramType = _isNumber(query.get('type'), 1);
  const paramDateStart = query.get('date_start') ?? '';
  const paramDateEnd = query.get('date_end') ?? '';

  const [value, _setValue] = useState<any>();

  const [totalRequest, setTotalRequest] = useState<ITotalRequest | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (isRegexDate(paramDateStart) && isRegexDate(paramDateEnd) && paramDateStart <= paramDateEnd) {
      _setValue(moment.range(moment(paramDateStart).clone(), moment(paramDateEnd).clone()));
    } else {
      _setValue(moment.range(moment().startOf('month').clone(), moment().clone()));
    }
  }, [paramDateStart, paramDateEnd]);

  const getDataFromDate = async () => {
    try {
      if (value.start) {
        setIsLoading(true);
        const response = await apiCompanyCalendarTopPage({
          date_start: moment(value.start).format('YYYY-MM-DD'),
          date_end: moment(value.end).format('YYYY-MM-DD'),
        });
        if (responseSuccess(response)) {
          setTotalRequest(response.data);
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDataFromDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(value)]);

  const setValue = (e: any) => {
    _setValue(e);
    navigate({
      pathname: `/`,
      search: `?${createSearchParams({
        year: paramYear.toString(),
        type: paramType.toString(),
        date_start: moment(e.start).format('YYYY-MM-DD'),
        date_end: moment(e.end).format('YYYY-MM-DD'),
      })}`,
    });
  };

  return { value, setValue, totalRequest, isLoading };
};

export default useRangePicker;
