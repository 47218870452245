import React from 'react';
import { SpaceBase } from 'styles';
import {
  DateTxt,
  FooterTableWrapper,
  HeaderExport,
  HeaderTableWrapper,
  LeftHeader,
  LineTable,
  NameRight,
  NotiBill,
  PageNumber,
  RightHeader,
  RowTable,
  SpaceTable,
  SpaceTop,
  TableExportWrapper,
  TableStyled,
  TaxInclude,
  TextAmount,
  TextLeft,
  TextLeftSmaller,
  TextRight,
  TextRightMail,
  TitleTable,
  WrapperBill,
  WrapperMoneyPay,
} from './invoiceViewTableStyle';
import { CONST_EXPORT_INVOICE } from 'constants/language';
import { formatDateJP } from 'helper/date';
import { formatMoneyNumber } from 'helper/formatMoney';
import { BANK_ACCOUNT_LABEL, ECompanyBankAccountType } from 'constants/constants';
import { ICompanyBasicOperatorInvoice, IInvoiceDetailSiteCompany, DEPOSIT_TYPE } from 'constants/invoice';
import { postalCodeFormat } from 'helper/formatPostalCode';
import { Tooltip } from 'antd';

interface ICompanyInvoiceViewTableProps {
  invoiceDetail: IInvoiceDetailSiteCompany[];
  invoiceInfo: ICompanyBasicOperatorInvoice;
  currentPage: number;
  pageSize: number;
  isOperatorCompany?: boolean;
  txtRequestDate?: string | null;
  idInvoice?: string;
}

const CompanyInvoiceViewTable = ({
  invoiceDetail,
  invoiceInfo,
  currentPage,
  pageSize,
  isOperatorCompany,
  txtRequestDate,
  idInvoice,
}: ICompanyInvoiceViewTableProps) => {
  const isFirstPage = currentPage === 1;
  const maxPageSize = pageSize - invoiceDetail.length;
  const isShowColumnUnitPrice = invoiceInfo.usage_plan === DEPOSIT_TYPE;
  const RowSpace = () => {
    const menuItems = [];
    for (let i = 0; i < maxPageSize; i++) {
      menuItems.push(
        <RowTable key={String(invoiceDetail.length + i)} index={invoiceDetail.length + i}>
          <tr>
            <td colSpan={isShowColumnUnitPrice ? 1 : 2} className={isShowColumnUnitPrice ? '' : 'longer'}>
              <div>&nbsp;</div>
            </td>
            <td colSpan={1}>
              <div>&nbsp;</div>
            </td>
            {isShowColumnUnitPrice && (
              <td colSpan={1}>
                <div>&nbsp;</div>
              </td>
            )}
            <td colSpan={1} className={isShowColumnUnitPrice ? '' : 'longer'}>
              <div>&nbsp;</div>
            </td>
          </tr>
        </RowTable>
      );
    }
    return <>{menuItems}</>;
  };

  return (
    <>
      <TableExportWrapper>
        <SpaceTop />
        <HeaderExport>
          <LeftHeader>
            <TitleTable>
              {idInvoice?.includes('all') && invoiceInfo?.pattern_id === 0
                ? CONST_EXPORT_INVOICE.TITLE_INVOICE_GENERAL
                : CONST_EXPORT_INVOICE.TITLE_INVOICE}
            </TitleTable>
          </LeftHeader>
          {isFirstPage && (
            <RightHeader>
              <DateTxt>
                {txtRequestDate}
              </DateTxt>
            </RightHeader>
          )}
        </HeaderExport>
        {isFirstPage && (
          <HeaderExport>
            <LeftHeader>
              <TextLeft>&nbsp;</TextLeft>
              <TextLeft>
                {invoiceInfo?.company_name}
                {CONST_EXPORT_INVOICE.YOUR}&nbsp;
              </TextLeft>
              <SpaceBase height={1} />
              <LineTable />

              <TextLeftSmaller>{invoiceInfo?.company_address1}&nbsp;</TextLeftSmaller>
              <TextLeftSmaller>{invoiceInfo?.company_address2}&nbsp;</TextLeftSmaller>
              <SpaceBase height={1} />
              <LineTable />

              <NotiBill>
                {invoiceInfo && (
                  <span>
                    {CONST_EXPORT_INVOICE.TIME_USING}：{formatDateJP(invoiceInfo.start_date, true, false)}〜
                    {formatDateJP(invoiceInfo.end_date, true, false)} <br />
                  </span>
                )}
                {CONST_EXPORT_INVOICE.GUIDE_NOTE_CREATE_BILL}
              </NotiBill>
              <SpaceBase height={0.813} />

              <WrapperBill>
                <WrapperMoneyPay>
                  <TextLeftSmaller>{CONST_EXPORT_INVOICE.PAY_AMOUNT}</TextLeftSmaller>
                  <SpaceBase width={2.5} />
                  <Tooltip placement="bottomLeft" title={formatMoneyNumber(invoiceInfo?.total_payment || 0)}>
                    <TextAmount>¥ {formatMoneyNumber(invoiceInfo?.total_payment || 0)} -</TextAmount>
                  </Tooltip>
                </WrapperMoneyPay>
                <TaxInclude>{CONST_EXPORT_INVOICE.TAX_INCLUDED}</TaxInclude>
              </WrapperBill>
              <SpaceBase height={0.813} />
              <LineTable />
            </LeftHeader>

            <RightHeader>
              <SpaceBase height={2} />
              {invoiceInfo?.operator_register_code && (
                <TextRight>
                  {CONST_EXPORT_INVOICE.REGISTER_NUMBER}
                  {invoiceInfo?.operator_register_code}
                </TextRight>
              )}
              {invoiceInfo?.operator_company_name && (
                <NameRight className="resize-name">{invoiceInfo?.operator_company_name}&nbsp;</NameRight>
              )}
              {invoiceInfo?.operator_representative_name && (
                <TextRight>
                  {isOperatorCompany && CONST_EXPORT_INVOICE.REPRESENTATIVE}
                  {invoiceInfo?.operator_representative_name || ''}
                  &nbsp;
                </TextRight>
              )}
              {invoiceInfo?.operator_postal_code && (
                <TextRight>{postalCodeFormat(invoiceInfo?.operator_postal_code)}&nbsp;</TextRight>
              )}
              {invoiceInfo?.operator_address1 && <TextRight>{invoiceInfo?.operator_address1}&nbsp;</TextRight>}
              {invoiceInfo?.operator_address2 && <TextRight>{invoiceInfo?.operator_address2}&nbsp;</TextRight>}
              {invoiceInfo?.operator_representative_phone && (
                <TextRight>Tel：{invoiceInfo?.operator_representative_phone}&nbsp;</TextRight>
              )}
              {invoiceInfo?.operator_representative_fax && (
                <TextRight>FAX：{invoiceInfo?.operator_representative_fax}&nbsp;</TextRight>
              )}
              {invoiceInfo?.operator_representative_email && (
                <TextRightMail>Email：{invoiceInfo?.operator_representative_email}&nbsp;</TextRightMail>
              )}
            </RightHeader>
          </HeaderExport>
        )}
        <SpaceBase height={isFirstPage ? 3.125 : 1.5} />
        <TableStyled data-width={10}>
          <HeaderTableWrapper>
            <tr>
              <td colSpan={isShowColumnUnitPrice ? 1 : 2} className={isShowColumnUnitPrice ? '' : 'longer'}>
                {CONST_EXPORT_INVOICE.ITEM}
              </td>
              <td colSpan={1}>{CONST_EXPORT_INVOICE.QUANTITY}</td>
              {isShowColumnUnitPrice && <td colSpan={1}>{CONST_EXPORT_INVOICE.UNIT_PRICE}</td>}
              <td colSpan={1} className={isShowColumnUnitPrice ? '' : 'longer'}>
                {CONST_EXPORT_INVOICE.PRICE}
              </td>
            </tr>
          </HeaderTableWrapper>
          {!!invoiceDetail?.length &&
            invoiceDetail?.map((element: IInvoiceDetailSiteCompany, index: number) => (
              <>
                <RowTable key={String(index)} index={index}>
                  <tr>
                    <td colSpan={isShowColumnUnitPrice ? 1 : 2} className={isShowColumnUnitPrice ? '' : 'longer'}>
                      <div>{element.staff_name}</div>
                    </td>
                    <td colSpan={1}>
                      <div>{element.number_request}</div>
                    </td>
                    {isShowColumnUnitPrice && (
                      <td colSpan={1}>
                        <div>
                          {formatMoneyNumber(Number(element.fee)) === ''
                            ? ''
                            : formatMoneyNumber(Number(element.fee) || 0)}
                        </div>
                      </td>
                    )}
                    <td colSpan={1} className={isShowColumnUnitPrice ? '' : 'longer'}>
                      <div>{formatMoneyNumber(Number(element.total_payment || 0))}</div>
                    </td>
                  </tr>
                </RowTable>
              </>
            ))}
          <RowSpace />
          <SpaceTable />
          {isFirstPage && (
            <FooterTableWrapper>
              <tr>
                <td colSpan={1} rowSpan={3}>
                  <div className="title-footer">
                    <span>{CONST_EXPORT_INVOICE.ABOUT_PAYMENT}</span>
                    <span> &ensp;{CONST_EXPORT_INVOICE.PLEASE_BEAR_PAYMENT_FEE}</span>
                    <span>
                      &ensp;{formatDateJP(invoiceInfo?.deadline_transfer, true, false)}
                      {CONST_EXPORT_INVOICE.ABOUT_DEADLINE_TRANSFER}
                    </span>
                    <span>
                      &ensp;{CONST_EXPORT_INVOICE.PAYEE}
                      {invoiceInfo?.operator_bank_name} {invoiceInfo?.operator_bank_branch_name} (
                      {BANK_ACCOUNT_LABEL[invoiceInfo?.operator_bank_type || ECompanyBankAccountType.USUALLY]}){' '}
                      {invoiceInfo?.operator_account_number}
                    </span>
                    <span>
                      &ensp;{CONST_EXPORT_INVOICE.ACCOUNT_NAME}
                      {invoiceInfo?.operator_account_name}
                    </span>
                  </div>
                </td>
                <td colSpan={2} className="hasBG">
                  <div className="title">{CONST_EXPORT_INVOICE.SUBTOTAL}</div>
                </td>
                <td colSpan={1} className="hasBG">
                  <div className="amount">{formatMoneyNumber(invoiceInfo?.total_amount || 0)}</div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div className="title">{CONST_EXPORT_INVOICE.CONSUMPTION_TAX}</div>
                </td>
                <td colSpan={1}>
                  <div className="amount">
                    {formatMoneyNumber(
                      Math.floor((Number(invoiceInfo?.total_amount || 0) * Number(invoiceInfo?.vat_fee || 0)) / 100)
                    )}
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="hasBG">
                  <div className="title">{CONST_EXPORT_INVOICE.TOTAL_AMOUNT}</div>
                </td>
                <td colSpan={1} className="hasBG">
                  <div className="amount">{formatMoneyNumber(invoiceInfo?.total_payment || 0)}</div>
                </td>
              </tr>
            </FooterTableWrapper>
          )}
        </TableStyled>
        <PageNumber>{currentPage}</PageNumber>
      </TableExportWrapper>
    </>
  );
};

export default CompanyInvoiceViewTable;
