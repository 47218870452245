import { useState, useEffect } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { responseSuccess } from 'helper/responseSuccess/responseSuccess';
import { apiOperatorGetRankCompany } from 'api/operator';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { _isNumber } from 'helper';

export interface ICompanyRank {
  id: string;
  name: string;
  total_salary: number;
  total_request: number;
}

const SELECT_MONTH = 2;

const useCompanyRankTopPage = (year: Dayjs, selectedSwitchDate: number) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramPage = _isNumber(query.get('page'), 1);
  const paramYear = query.get('year') ?? dayjs().format('YYYY');
  const paramTypeDate = _isNumber(query.get('type_date'), 1);
  const paramType = _isNumber(query.get('type'), 1);

  const [listCompany, setListCompany] = useState<ICompanyRank[]>([]);
  const [pageSetting, setPageSetting] = useState({
    page: 1,
    perPage: 5,
  });

  const getListRankCompany = async () => {
    try {
      const response = await apiOperatorGetRankCompany({
        year: selectedSwitchDate === SELECT_MONTH ? null : year.format('YYYY'),
        month: selectedSwitchDate === SELECT_MONTH ? year.format('YYYY-MM') : null,
      });
      if (responseSuccess(response)) {
        setListCompany(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getListRankCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, selectedSwitchDate]);

  useEffect(() => {
    setPageSetting((page) => ({
      ...page,
      page: paramPage,
    }));
  }, [paramPage]);

  const changePaging = (pageChange: number) => {
    navigate({
      pathname: `/`,
      search: `?${createSearchParams({
        year: paramYear,
        type_date: paramTypeDate?.toString(),
        type: paramType.toString(),
        page: pageChange.toString(),
      })}`,
    });
  };

  return { listCompany, pageSetting, changePaging };
};

export default useCompanyRankTopPage;
