import { useHookCompanyFootstepsHistory } from '@hooks/useCompany';
import { IFootstepsHistory } from '@pages/OperatorSite/FootstepsHistory/useFootstepsHistory';
import { EKeyCode } from 'constants/constants';
import dayjs, { Dayjs } from 'dayjs';
import { _isNumber } from 'helper';
import { getDayjsByTimeZone, isRegexMonth } from 'helper/date';
import React, { useEffect, useState } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

const BREADS = [
  {
    name: '操作ログ',
    path: '',
  },
];

const defaultPage = {
  per_page: 10,
  page: 1,
  total: 0,
};

const useFootstepsHistoryCompany = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const paramDate = query.get('date') ?? '';
  const paramKeyWord = query.get('key_word') ?? '';
  const paramPage = _isNumber(query.get('page'), 1);

  const [pageStaff, setPageStaff] = useState(defaultPage);

  const [listHistory, setListHistory] = useState<IFootstepsHistory[][] | null>(null);
  const [activeKey, setActiveKey] = useState<string[] | string>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const [paramSearch, setParamsearch] = useState<any>({
    date: isRegexMonth(paramDate) ? paramDate : dayjs().format('YYYY-MM'),
    per_page: defaultPage.per_page,
    page: 1,
    key_word: '',
  });

  const [month, setMonth] = useState<Dayjs>(getDayjsByTimeZone(paramSearch.date));
  const [monthInput, setMonthInput] = useState<Dayjs | undefined>(getDayjsByTimeZone(paramSearch.date));
  const { data: dataListHistory, isLoading } = useHookCompanyFootstepsHistory(paramSearch);

  useEffect(() => {
    setListHistory(dataListHistory?.data);
    setPageStaff((page) => ({
      ...page,
      total: dataListHistory?.total ?? 0,
    }));
  }, [dataListHistory]);

  useEffect(() => {
    setParamsearch((param: any) => ({
      ...param,
      date: isRegexMonth(paramDate) ? paramDate : dayjs().format('YYYY-MM'),
    }));
    setMonth(isRegexMonth(paramDate) ? getDayjsByTimeZone(paramDate) : getDayjsByTimeZone());
    setMonthInput(isRegexMonth(paramDate) ? getDayjsByTimeZone(paramDate) : getDayjsByTimeZone());
  }, [paramDate]);

  useEffect(() => {
    setParamsearch((param: any) => ({
      ...param,
      key_word: paramKeyWord,
    }));
    setSearchValue(paramKeyWord);
  }, [paramKeyWord]);

  useEffect(() => {
    setParamsearch((param: any) => ({
      ...param,
      page: paramPage,
    }));
    setPageStaff((page) => ({
      ...page,
      page: paramPage,
    }));
  }, [paramPage]);

  useEffect(() => {
    if (listHistory?.length) {
      setActiveKey(listHistory.map((element: IFootstepsHistory[]) => element[0].date));
    }
  }, [listHistory, searchValue]);

  const onChangeMonth = (e: Dayjs | any) => {
    if (e) {
      setMonth(e);
      setMonthInput(e);
      navigate({
        pathname: `/setting-page/footsteps`,
        search: `?${createSearchParams({ ...paramSearch, date: e.format('YYYY-MM'), page: 1 })}`,
      });
    } else {
      setMonthInput(undefined);
    }
  };

  const changePaging = (pageChange: number) => {
    setPageStaff({
      ...pageStaff,
      page: pageChange,
    });
    navigate({
      pathname: `/setting-page/footsteps`,
      search: `?${createSearchParams({ ...paramSearch, page: pageChange })}`,
    });
  };

  const onSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === EKeyCode.ENTER) {
      const target = e.target as HTMLTextAreaElement;
      navigate({
        pathname: `/setting-page/footsteps`,
        search: `?${createSearchParams({ ...paramSearch, key_word: target.value, page: 1 })}`,
      });
    }
  };

  return {
    BREADS,
    pageStaff,
    changePaging,
    month,
    monthInput,
    onChangeMonth,
    listHistory,
    onSearch,
    isLoading,
    activeKey,
    setActiveKey,
    setSearchValue,
    searchValue,
  };
};

export default useFootstepsHistoryCompany;
