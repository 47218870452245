import React from 'react';

import Loading from '@components/Loading';
import BreadCrumb from '@components/Breadcrumb/BreadCrumb';
import useInvoiceDetailSiteAgency from './useInvoiceDetailSiteAgency';
import PaginationRecord from '@components/CompanySite/AttendanceRecord/PaginationRecord/PaginationRecord';

import { SettingIcon } from '@containers/OperatorSite/OperatorBank/DetailBank/detailBankStyle';
import { CONST_COMMON, CONST_LIST_INVOICE_COMPANY } from 'constants/language';
import { Container } from '@components/Style/Style';
import { SpaceBase } from 'styles';
import { IconFile } from '@components/Icon';
import images from '@assets/images-base';
import { FootstepsHistoryCard } from '@pages/OperatorSite/FootstepsHistory/footstepsHistoryStyle';
import { GrantCompanyWrapper } from '@pages/OperatorSite/Companies/CreateCompany/createCompanyStyle';
import { TitleHeaderSetting } from '@pages/CompanySite/SettingPage/settingPageStyle';
import { ControlHeaderWrapper } from '@pages/CompanySite/AttendanceRecord/attendanceRecordStyle';
import {
  ButtonConfirm,
  ButtonExport,
  DateInvoice,
  HeaderWrapper,
  InvoiceDetailWrapper,
} from './invoiceDetailSiteAgencyStyle';
import AgencyInvoiceViewTable from '@components/InvoiceTable/InvoiceViewTable/AgencyInvoiceViewTable';
import AgencyInvoiceExportTable from '@components/InvoiceTable/InvoiceExportTable/AgencyInvoiceExportTable';
import { EStatusInvoiceAgency } from 'constants/constants';
import { STATUS_INVOICE_AGENCY_TEXT } from '@containers/OperatorSite/ListInvoiceTable/ListInvoiceAgencyTable';
import { LoadingInvoice } from '@pages/CompanySite/InvoiceDetailSiteCompany/invoiceDetailSiteCompanyStyle';
import { MAX_ITEM_PAGE_ONE_AGENCY, MAX_ITEM_PAGE_OTHER_AGENCY } from 'constants/invoice';
import { getTextInvoiceCreateAt, getTextInvoiceCreateAtDown, isNotDateDueYetDetail } from 'helper';

export const InvoiceDetailSiteAgency = () => {
  const {
    BREADS,
    invoiceDetail,
    invoiceAllDetail,
    isLoading,
    page,
    changePaging,
    exportInvoicePDF,
    invoiceInfo,
    isLoadingExport,
    isLoadingConfirm,
    confirmInvoice,
  } = useInvoiceDetailSiteAgency();

  const exportInvoice = () => {
    if (
      invoiceInfo &&
      !isNotDateDueYetDetail(isLoading, invoiceInfo) &&
      invoiceInfo?.status > EStatusInvoiceAgency.UNCOMFIRMED
    ) {
      exportInvoicePDF();
    }
  };

  return (
    <GrantCompanyWrapper>
      <BreadCrumb breads={BREADS} />
      <Container>
        <FootstepsHistoryCard between={true}>
          <div>
            <HeaderWrapper>
              <div>
                <div className="title-page">
                  <SettingIcon src={images.agencySite.agencyInvoice} />
                  <TitleHeaderSetting>{CONST_LIST_INVOICE_COMPANY.USAGE_INVOICE}</TitleHeaderSetting>
                </div>
                {invoiceInfo && <DateInvoice>{getTextInvoiceCreateAt(isLoading, invoiceInfo)}</DateInvoice>}
              </div>
              {invoiceInfo && (
                <ControlHeaderWrapper padding="1rem 0 1rem 43rem">
                  <PaginationRecord
                    right="45rem"
                    current={page.page}
                    total={page.total + (MAX_ITEM_PAGE_OTHER_AGENCY - MAX_ITEM_PAGE_ONE_AGENCY)}
                    pageSize={MAX_ITEM_PAGE_OTHER_AGENCY}
                    onChange={changePaging}
                  />
                  <ButtonConfirm
                    width="10.375rem"
                    disabled={
                      isNotDateDueYetDetail(isLoading, invoiceInfo) ||
                      invoiceInfo?.status !== EStatusInvoiceAgency.UNCOMFIRMED
                    }
                    loading={isLoadingConfirm}
                    onClick={confirmInvoice}
                    icon={
                      invoiceInfo?.status === EStatusInvoiceAgency.UNCOMFIRMED &&
                      !isNotDateDueYetDetail(isLoading, invoiceInfo) && <IconFile className="iconFile" />
                    }
                  >
                    {invoiceInfo?.status === EStatusInvoiceAgency.UNCOMFIRMED
                      ? CONST_LIST_INVOICE_COMPANY.CONFIRM_INVOICE_BTN_AGENCY
                      : STATUS_INVOICE_AGENCY_TEXT[Number(invoiceInfo?.status) || 0]}
                  </ButtonConfirm>
                  <ButtonExport
                    loading={isLoadingExport}
                    disabled={
                      isNotDateDueYetDetail(isLoading, invoiceInfo) ||
                      invoiceInfo?.status <= EStatusInvoiceAgency.UNCOMFIRMED
                    }
                    onClick={exportInvoice}
                  >
                    {CONST_LIST_INVOICE_COMPANY.EXPORT_INVOICE_FEE}
                  </ButtonExport>
                </ControlHeaderWrapper>
              )}
            </HeaderWrapper>
            <InvoiceDetailWrapper>
              {!invoiceInfo && !isLoading && (
                <div className='not-found'>
                  {CONST_COMMON.NOT_FOUND}
                </div>
              )}

              {isLoading && (
                <LoadingInvoice>
                  <SpaceBase height={5} />
                  <Loading />
                </LoadingInvoice>
              )}
              {invoiceInfo && (
                <AgencyInvoiceViewTable
                  invoiceDetail={invoiceDetail || []}
                  invoiceInfo={invoiceInfo}
                  currentPage={page.page}
                  pageSize={page.page === 1 ? MAX_ITEM_PAGE_ONE_AGENCY : MAX_ITEM_PAGE_OTHER_AGENCY}
                  txtRequestDate={getTextInvoiceCreateAtDown(isLoading, invoiceInfo)}
                />
              )}
              {invoiceInfo && (
                <AgencyInvoiceExportTable invoiceDetail={invoiceAllDetail || []} invoiceInfo={invoiceInfo} />
              )}
            </InvoiceDetailWrapper>
          </div>
        </FootstepsHistoryCard>
      </Container>
    </GrantCompanyWrapper>
  );
};
