/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { IBread } from '@components/Breadcrumb/BreadCrumb';
import { ICycleCompanySettingSalary, IStaff } from '@pages/CompanySite/AttendanceRecord/interface';
import { CONST_ATTENDANCE_COMPANY } from 'constants/language';

import { Dayjs } from 'dayjs';
import { ECompanyStaffSalaryType, EKeyCode, ETimeShift } from 'constants/constants';
import { useAppSelector } from '@hooks/useSelector/useAppSelector';
import { USER_TYPE_OWNER } from 'constants/User';
import { useHookCompanySalaryDetail, useHookStaffAttendance } from '@hooks/useCompany';
import { getDayjsByTimeZone, isRegexDate } from 'helper/date';
import { useLocation, useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
// import { useQueryClient } from 'react-query';
// import { COMPANY_LIST_STAFF_ATTENDANCE } from 'constants/keyQuery';
import { _isNumber } from 'helper';

const defaultPage = {
  per_page: 5,
  page: 1,
  total: 0,
};
const BREADS: IBread[] = [
  {
    name: CONST_ATTENDANCE_COMPANY.ATTENDANCE_CONFIRMATION,
    path: '',
  },
];

const useAttendanceDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const paramDate = query.get('day') ?? '';
  const paramKeyWord = query.get('key_word') ?? '';
  const paramPage = _isNumber(query.get('page'), 1);
  const paramType = _isNumber(query.get('type'), 2);
  // const queryClient = useQueryClient();
  const [typeStatus, setTypeStatus] = useState<any>(paramType);
  const authInfo = useAppSelector((state) => state.auth.authInfo);
  const isRootAdmin = authInfo?.user?.type === USER_TYPE_OWNER;
  const [attendanceDetailData, setAttendanceDetailData] = useState<IStaff[] | null>(null);
  const [pageStaff, setPageStaff] = useState(defaultPage);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [valueSearch, setValueSearch] = useState<string>('');
  const [cycleCompanySettingSalary, setCycleCompanySettingSalary] = useState<ICycleCompanySettingSalary>({
    start_date: '5023-12-30',
    end_date: '5023-12-30',
  });
  const [hourSalary, setNumberHourSalary] = useState<any>([]);
  const [daySalary, setNumberDaySalary] = useState<any>([]);
  const [monthSalary, setNumberMonthSalary] = useState<any>([]);
  const [typeFilter, setTypeFilter] = useState({ month: 0, day: 0, hour: 0 });
  const [paramSearch, setParamsearch] = useState<any>({
    day: isRegexDate(paramDate) ? paramDate : getDayjsByTimeZone().format('YYYY-MM-DD'),
    per_page: defaultPage.per_page,
    page: 1,
    key_word: '',
    type: typeStatus > 0 ? typeStatus : 2,
  });
  const [currentDate, setCurrentDate] = useState<Dayjs>(getDayjsByTimeZone(paramSearch.day));
  const [currentDateInput, setCurrentDateInput] = useState<Dayjs | undefined>(getDayjsByTimeZone(paramSearch.day));
  const { data: dataListMember, isLoading: isLoadingListMember } = useHookStaffAttendance(paramSearch);

  const { data: dataCompanySettingSalaryDetail, isLoading: isLoadingCompanySettingSalaryDetail } =
    useHookCompanySalaryDetail();

  useEffect(() => {
    dataListMember?.data?.forEach((obj: any) => {
      obj.time_attendance?.forEach((attendance: any) => {
        if (attendance.morning_haft_shift === ETimeShift.ACTIVITY) {
          attendance.day_shift = ETimeShift.ACTIVITY;
        }
        if (attendance.afternoon_haft_shift === ETimeShift.ACTIVITY) {
          attendance.night_shift = ETimeShift.ACTIVITY;
        }
      });
    });
    setNumberDaySalary(dataListMember?.attendance_day);
    setNumberHourSalary(dataListMember?.attendance_hour);
    setNumberMonthSalary(dataListMember?.attendance_month);

    setAttendanceDetailData(dataListMember?.data);
    setCycleCompanySettingSalary({
      start_date: dataListMember?.start_date_current ? dataListMember.start_date_current : '5023-12-30',
      end_date: dataListMember?.end_date_current ? dataListMember.end_date_current : '5023-12-30',
    });
    setPageStaff((page) => ({
      ...page,
      total: dataListMember?.total ?? 0,
    }));
  }, [dataListMember]);

  useEffect(() => {
    if (dataListMember && 'attendance_month' in dataListMember) {
      setTypeFilter((prev) => ({
        ...prev,
        month: dataListMember.attendance_month ? 1 : 0,
      }));
    }
  }, [dataListMember?.attendance_month]);

  useEffect(() => {
    if (dataListMember && 'attendance_day' in dataListMember) {
      setTypeFilter((prev) => ({
        ...prev,
        day: dataListMember.attendance_day ? 1 : 0,
      }));
    }
  }, [dataListMember?.attendance_day]);

  useEffect(() => {
    if (dataListMember && 'attendance_hour' in dataListMember) {
      setTypeFilter((prev) => ({
        ...prev,
        hour: dataListMember?.attendance_hour ? 1 : 0,
      }));
    }
  }, [dataListMember?.attendance_hour]);
  useEffect(() => {
    let arrayHasTypeFilter = [];
    if (typeFilter.month > 0) {
      arrayHasTypeFilter.push(ECompanyStaffSalaryType.MONTHLY_SALARY);
    }
    if (typeFilter.day > 0) {
      arrayHasTypeFilter.push(ECompanyStaffSalaryType.DAILY_SALARY);
    }
    if (typeFilter.hour > 0) {
      arrayHasTypeFilter.push(ECompanyStaffSalaryType.HOUR_SALARY);
    }
    if (arrayHasTypeFilter?.length === 0 && typeStatus !== 0) {
      setTypeStatus(0);
    }
    if (arrayHasTypeFilter?.length && (!arrayHasTypeFilter?.includes(paramType) || typeStatus === 0)) {
      setTypeStatus(arrayHasTypeFilter[0]);
    }
  }, [typeFilter, paramType]);

  useEffect(() => {
    setIsLoading(isLoadingListMember);
  }, [isLoadingListMember]);

  useEffect(() => {
    setParamsearch((param: any) => ({
      ...param,
      key_word: paramKeyWord,
    }));
    setValueSearch(paramKeyWord);
  }, [paramKeyWord]);

  useEffect(() => {
    setParamsearch((param: any) => ({
      ...param,
      page: paramPage,
    }));
    setPageStaff((page) => ({
      ...page,
      page: paramPage,
    }));
  }, [paramPage]);

  useEffect(() => {
    setParamsearch((param: any) => ({
      ...param,
      type: typeStatus === 0 ? ECompanyStaffSalaryType.MONTHLY_SALARY : typeStatus,
    }));
  }, [typeStatus]);

  useEffect(() => {
    setParamsearch((param: any) => ({
      ...param,
      day: isRegexDate(paramDate) ? paramDate : getDayjsByTimeZone().format('YYYY-MM-DD'),
    }));
    setCurrentDate(isRegexDate(paramDate) ? getDayjsByTimeZone(paramDate) : getDayjsByTimeZone());
    setCurrentDate(isRegexDate(paramDate) ? getDayjsByTimeZone(paramDate) : getDayjsByTimeZone());
  }, [paramDate]);

  const onChangeMonth = (e: Dayjs | any) => {
    if (e) {
      setCurrentDate(e);
      setCurrentDateInput(e);
      navigate({
        pathname: `/attendance/detail`,
        search: `?${createSearchParams({ ...paramSearch, day: e.format('YYYY-MM-DD'), page: 1 })}`,
      });
    } else {
      setCurrentDateInput(undefined);
    }
  };

  const onSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === EKeyCode.ENTER) {
      navigate({
        pathname: `/attendance/detail`,
        search: `?${createSearchParams({ ...paramSearch, key_word: valueSearch, page: 1 })}`,
      });
    }
  };

  const changePaging = (pageChange: number) => {
    setPageStaff({
      ...pageStaff,
      page: pageChange,
    });
    navigate({
      pathname: `/attendance/detail`,
      search: `?${createSearchParams({ ...paramSearch, page: pageChange })}`,
    });
  };

  const changeStatus = (statusChange: number) => {
    setTypeStatus(statusChange);
    navigate({
      pathname: `/attendance/detail`,
      search: `?${createSearchParams({ ...paramSearch, type: statusChange, page: 1 })}`,
    });
  };

  return {
    BREADS,
    isLoading,
    pageStaff,
    currentDate,
    currentDateInput,
    attendanceDetailData,
    changePaging,
    onChangeMonth,

    onSearch,
    setValueSearch,
    isRootAdmin,
    valueSearch,
    dataCompanySettingSalaryDetail,
    isLoadingCompanySettingSalaryDetail,
    cycleCompanySettingSalary,
    changeStatus,
    typeStatus,
    monthSalary,
    hourSalary,
    daySalary,
    setAttendanceDetailData,
    typeFilter,
  };
};

export default useAttendanceDetail;
