import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { theme } from 'antd';

import Loading from '@components/Loading';
import configs from 'config';
import images from '@assets/images-base';
import { BoxMantenance, MaintenanceStyle, Wrapper } from './errorPageStyle';
import { HeaderBarWrapper, IconCompany } from '@components/layout/HeaderBar/headerBarStyle';
import { apiCheckMaintenance } from 'api';
import { getFullHostName } from 'helper';
import { responseSuccess } from 'helper/responseSuccess/responseSuccess';
import { useNavigate } from 'react-router';

const PageMaintenance = () => {
  const navigate = useNavigate();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [isLoading, setIsLoading] = useState(true);
  const [dataDate, setDataDate]: any = useState({
    start: '',
    end: '',
  });

  const getMaintenance = async () => {
    try {
      setIsLoading(false);
      const response = await apiCheckMaintenance({});
      if (responseSuccess(response) && !response?.data?.data.length) {
        navigate('/');
      }
    } catch (error: any) {
      if (error?.response?.data?.data) {
        let start = error?.response?.data?.data?.start_date;
        let end = error?.response?.data?.data?.end_date;
        setDataDate({
          start: start ? dayjs(start) : '',
          end: end ? dayjs(end) : '',
        });
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMaintenance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const textTime = () => {
    if (dataDate.start !== '' && dataDate.end !== '') {
      if (dataDate.start.format('YYYY-MM-DD') === dataDate.end.format('YYYY-MM-DD')) {
        return (
          dataDate.start.format('YYYY年MM月DD日') +
          ' ' +
          dataDate.start.format('HH:mm') +
          '~' +
          dataDate.end.format('HH:mm')
        );
      } else {
        return (
          dataDate.start.format('YYYY年MM月DD日') +
          ' ' +
          dataDate.start.format('HH:mm') +
          ' ~ ' +
          dataDate.end.format('YYYY年MM月DD日') +
          ' ' +
          dataDate.end.format('HH:mm')
        );
      }
    }
  };

  return (
    <MaintenanceStyle>
      <HeaderBarWrapper style={{ height: '3.75rem', background: colorBgContainer }}>
        <IconCompany
          src={
            getFullHostName() === configs.APP_FRONTEND_COMPANY
              ? images.sideBar.moibuchoCompany
              : images.sideBar.moibuchoOperator
          }
        />
      </HeaderBarWrapper>
      <Wrapper>
        <BoxMantenance>
          {isLoading && <Loading />}
          {!isLoading && (
            <div>
              <div className="title">ただいまメインテナンス中です</div>
              <div className="txt-time">【メインテナンス日時】</div>
              <div className="time">{textTime()}</div>
              <div className="description">ご利用の皆様にはご迷惑をおかけして、申し訳ございません。</div>
              <div className="description">メインテナンス終了までしばらくお待ちください。</div>
            </div>
          )}
        </BoxMantenance>
      </Wrapper>
    </MaintenanceStyle>
  );
};

export default PageMaintenance;
