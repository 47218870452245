/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { IBread } from '@components/Breadcrumb/BreadCrumb';
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ICycleCompanySettingSalary, ITimeAttendance } from '@pages/CompanySite/AttendanceRecord/interface';
import { getDayListOfMonth } from 'helper/getAllDayOfMonth/getAllDayOfMonth';
import { CONST_ATTENDANCE_COMPANY } from 'constants/language';
import { ETimeShift } from 'constants/constants';
import { useAppSelector } from '@hooks/useSelector/useAppSelector';
import { USER_TYPE_OWNER } from 'constants/User';
import { _atob } from 'helper/stringEmpty';
import { COMPANY_LIST_STAFF_ATTENDANCE } from 'constants/keyQuery';
import { useQueryClient } from 'react-query';
import { getDayjsByTimeZone, isRegexMonth } from 'helper/date';
import { useAdminGeListAttendance, useHookCompanySalaryDetail } from '@hooks/useCompany';

const BREADS: IBread[] = [
  {
    name: CONST_ATTENDANCE_COMPANY.ATTENDANCE_CONFIRMATION,
    path: '/attendance/detail',
  },
  {
    name: CONST_ATTENDANCE_COMPANY.ATTENDANCE_CONFIRM,
    path: '',
  },
];

const useAttendanceMember = () => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { idStaff } = useParams();
  const authInfo = useAppSelector((state) => state.auth.authInfo);
  const isRootAdmin = authInfo?.user?.type === USER_TYPE_OWNER;
  const [staffName, setStaffName] = useState<string>('');
  const [detailData, setDetailData] = useState<ITimeAttendance[]>([]);
  const [isStaffDisconnected, setIsStaffDisconnected] = useState<boolean>();

  const query = new URLSearchParams(location.search);
  const paramMonth = query.get('month') ?? '';
  const [month, setMonth] = useState<Dayjs>(isRegexMonth(paramMonth) ? getDayjsByTimeZone(paramMonth) : dayjs());
  const [monthInput, setMonthInput] = useState<Dayjs | undefined>(
    isRegexMonth(paramMonth) ? getDayjsByTimeZone(paramMonth) : dayjs()
  );

  const { data: dataCompanySettingSalaryDetail, isLoading: isLoadingCompanySettingSalaryDetail } =
    useHookCompanySalaryDetail();
  const [cycleCompanySettingSalary, setCycleCompanySettingSalary] = useState<ICycleCompanySettingSalary>({
    start_date: '5023-12-30',
    end_date: '5023-12-30',
  });

  const { data: dataCompanyAdminGetAttendance, isLoading } = useAdminGeListAttendance({
    month: month.format('YYYY-MM'),
    per_page: 32,
    page: 1,
    staff_id: [Number(_atob(idStaff))],
  });
  useEffect(() => {
    setMonth(isRegexMonth(paramMonth) ? getDayjsByTimeZone(paramMonth) : dayjs());
    setMonthInput(isRegexMonth(paramMonth) ? getDayjsByTimeZone(paramMonth) : dayjs());
  }, [paramMonth]);

  useEffect(() => {
    if (dataCompanyAdminGetAttendance?.data) {
      setStaffName(dataCompanyAdminGetAttendance?.data[0].name);
      if (
        dataCompanyAdminGetAttendance?.data[0].staff_disconnect_time &&
        dayjs(dataCompanyAdminGetAttendance?.data[0].staff_disconnect_time).format('YYYY-MM') <
          month.format('YYYY-MM') &&
        !dataCompanyAdminGetAttendance?.data[0]?.time_attendance?.length
      ) {
        setIsStaffDisconnected(true);
      } else {
        setIsStaffDisconnected(false);
      }
      const arr = getDayListOfMonth(month);
      const newArr: ITimeAttendance[] | [] = arr.map((element: number) => {
        const dateMap: string = dayjs(month.format(`YYYY-MM`) + `${element + 1}`).format('YYYY-MM-DD');
        const find = dataCompanyAdminGetAttendance?.data[0]?.time_attendance?.find(
          (elementTime: ITimeAttendance) => dateMap === elementTime?.date
        );
        if (find) {
          return {
            ...find,
            salary_type: find?.salary_type ? find.salary_type : dataCompanyAdminGetAttendance?.data[0]?.salary_type,
            day_shift: find.morning_haft_shift === ETimeShift.ACTIVITY ? ETimeShift.ACTIVITY : find.day_shift,
            night_shift: find.afternoon_haft_shift === ETimeShift.ACTIVITY ? ETimeShift.ACTIVITY : find.night_shift,
          };
        }
        return {
          date: dateMap,
          day_shift: ETimeShift.NOT_ACTIVITY,
          morning_haft_shift: ETimeShift.NOT_ACTIVITY,
          afternoon_haft_shift: ETimeShift.NOT_ACTIVITY,
          night_shift: ETimeShift.NOT_ACTIVITY,
          staff_id: Number(_atob(idStaff)),
          salary_type: dataCompanyAdminGetAttendance?.data[0]?.salary_type,
          afternoon_working_time_shift_time: ETimeShift.NOT_ACTIVITY,
          morning_working_time_shift_time: ETimeShift.NOT_ACTIVITY,
        };
      });

      setDetailData(newArr);
    }
    setCycleCompanySettingSalary({
      start_date: dataCompanyAdminGetAttendance?.start_date_current
        ? dataCompanyAdminGetAttendance.start_date_current
        : '5023-12-30',
      end_date: dataCompanyAdminGetAttendance?.end_date_current
        ? dataCompanyAdminGetAttendance.end_date_current
        : '5023-12-30',
    });

    queryClient.refetchQueries(COMPANY_LIST_STAFF_ATTENDANCE);
  }, [dataCompanyAdminGetAttendance]);

  const onChangeMonth = (e: Dayjs | any) => {
    if (e) {
      setMonth(e);
      setMonthInput(e);
      navigate({
        pathname: `/attendance/detail/${idStaff}`,
        search: `?${createSearchParams({ month: e.format('YYYY-MM') })}`,
      });
    } else {
      setMonthInput(undefined);
    }
  };

  return {
    BREADS,
    onChangeMonth,
    isLoading,
    month,
    monthInput,
    staffName,
    detailData,
    isRootAdmin,
    isStaffDisconnected,
    cycleCompanySettingSalary,
    dataCompanySettingSalaryDetail,
    isLoadingCompanySettingSalaryDetail,
    setDetailData,
  };
};

export default useAttendanceMember;
