import React, { useState } from 'react';

import { colors } from 'constants/colorsBase';
import {
  ECompanyStaffSalaryType,
  ESettingTimer,
  ETimeShift,
  ETimeShiftFormat,
  ETimeShiftKeys,
} from 'constants/constants';
import {
  StatusItem,
  renderStaticSettingHourNight,
  renderStaticSettingHourNoon,
} from '../AttendanceTable/AttendanceTable';
import { ActiveItem } from '../AttendanceTable/attendanceTableStyle';
import {
  ICompanySettingSalaryDetail,
  ICycleCompanySettingSalary,
  ITimeAttendance,
} from '@pages/CompanySite/AttendanceRecord/interface';
import { CONST_ATTENDANCE_COMPANY, CONST_COMMON } from 'constants/language';
import {
  ActiveItemWrapper,
  DashboardWrapper,
  DayElement,
  DayWrapperMember,
  DeadlineForChange,
  ItemMemberWrapper,
  MemberTableWrapper,
  ScrollWrapperMemberTable,
} from './memberTableStyle';

import dayjs from 'dayjs';
import TimeActiveItem from './TimeActiveItem/TimeActiveItem';
import useMemberTable from './useMemberTable';
import MemberHeaderTable from '../MemberHeaderTable/MemberHeaderTable';
import { checkOverDate, shiftTxt } from 'helper/attendanceRecord';
import { useAppSelector } from '@hooks/useSelector/useAppSelector';
import { USER_TYPE_OWNER } from 'constants/User';
import ModalCommon from '@components/Modal/ModalCommon';
import { ModalContainer } from '@pages/CompanySite/CompanyB2B/companyB2BStyle';
import { ModalContent } from '@components/common/Modal/style';
import { getDayjsByTimeZone } from 'helper/date';
import AttendanceForHour from '../AttendanceTable/AttendancenForHour';
import CountDownDate from '@components/CountdownTimer/CountDownDate';
import { Confirmed } from '../AttendanceHeaderTable/attendanceHeaderTableStyle';

interface IActiveItemComponent {
  title: string;
  shift:
    | ETimeShiftKeys.DAY_SHIFT
    | ETimeShiftKeys.NIGHT_SHIFT
    | ETimeShiftKeys.MORNING_HALF
    | ETimeShiftKeys.AFTERNOON_HALF;
  color: string;
  element: ITimeAttendance;
  isForceInActive: boolean;
  isTimeClosing: number;
}

export const disableBtnTimekeeping = (
  date: string,
  closingLeave: string | null | undefined,
  startCycle: string,
  isRootAdmin: Boolean,
  isOwner?: Boolean
) => {
  if (!isOwner) return true;
  if (dayjs(date).format('YYYY-MM-DD') < startCycle) return true;
  if (closingLeave && getDayjsByTimeZone().format('YYYY-MM-DD HH:mm:ss') >= closingLeave && !isRootAdmin) return true;

  return false;
};
let temp: any = null;

const MemberTable = ({
  detailData,
  cycleCompanySettingSalary,
  dataCompanySettingSalaryDetail,
  isLoadingCompanySettingSalaryDetail,
  setDetailData,
}: {
  detailData: ITimeAttendance[];
  cycleCompanySettingSalary: ICycleCompanySettingSalary;

  dataCompanySettingSalaryDetail: ICompanySettingSalaryDetail;
  isLoadingCompanySettingSalaryDetail: boolean;
  setDetailData: any;
}) => {
  const authInfo = useAppSelector((state) => state.auth.authInfo);
  const companyIdLeague = useAppSelector((state) => state.auth.companyIdLeague);
  const isOwner = !(companyIdLeague && companyIdLeague !== authInfo?.company?.id);
  const isRootAdmin = authInfo?.user?.type === USER_TYPE_OWNER;
  const {
    handleAnalysisColorDay,
    changeTime,
    isLoading,
    handleCancelConfirm,
    handleOkConfirm,
    isShowConfirm,
    setIsShowConfirm,
    confirmChanging,
    confirmDate,
    checkIsConfirmed,
    textConfirm,
  } = useMemberTable(isRootAdmin, setDetailData, detailData);
  const [stateHour, setStateHour] = useState<number>(0);
  temp = setStateHour;
  const ItemSetting = ({
    title,
    element,
    shift,
    color,
    isForceInActive = false,
    isTimeClosing,
  }: IActiveItemComponent) => {
    const isActive = element ? element[`${shift}`] : ETimeShift.NOT_ACTIVITY;
    const isActiveHalf = shift === ETimeShiftKeys.DAY_SHIFT ? element.morning_haft_shift : element.afternoon_haft_shift;
    const changeData: ITimeAttendance = {
      staff_id: element.staff_id,
      date: element.date,
      day_shift: element?.day_shift || ETimeShift.NOT_ACTIVITY,
      night_shift: element?.night_shift || ETimeShift.NOT_ACTIVITY,
      leave: element?.leave || ETimeShift.NOT_ACTIVITY,
      [`${shift}`]: isActive ? ETimeShift.NOT_ACTIVITY : ETimeShift.ACTIVITY,
      cycle_start_date: cycleCompanySettingSalary.start_date,
      cycle_end_date: cycleCompanySettingSalary.end_date,
      morning_haft_shift: element?.morning_haft_shift || ETimeShift.NOT_ACTIVITY,
      afternoon_haft_shift: element?.afternoon_haft_shift || ETimeShift.NOT_ACTIVITY,
      morning_working_time_shift_time: element?.morning_working_time_shift_time || ETimeShift.NOT_ACTIVITY,
      afternoon_working_time_shift_time: element?.afternoon_working_time_shift_time || ETimeShift.NOT_ACTIVITY,
      salary_type: element?.salary_type,
    };
    let dateActiveDay = '';
    let dateActiveDayHalf = '';
    let dateActiveNight = '';
    let dateActiveNightHalf = '';
    let timeActive = '';
    let forceInActive = isForceInActive;
    const now = getDayjsByTimeZone().add(2, 'second');
    if (shift === ETimeShiftKeys.DAY_SHIFT) {
      if (shift === ETimeShiftKeys.DAY_SHIFT && element?.closing_day_shift_salary) {
        if (
          element?.salary_type === ECompanyStaffSalaryType.MONTHLY_SALARY &&
          element.date < dayjs().format('YYYY-MM-DD')
        ) {
          forceInActive = true;
        } else {
          const closingTime = getDayjsByTimeZone(element?.closing_day_shift_salary);
          forceInActive = now.isAfter(closingTime) || isForceInActive;
          dateActiveDay = closingTime.format('YYYY-MM-DD');
          timeActive = closingTime.format('HH:mm:ss');
        }
      }
      if (element?.morning_haft_shift === ETimeShift.ACTIVITY && element?.closing_morning_haft) {
        const closingTime = getDayjsByTimeZone(element?.closing_morning_haft);
        forceInActive = now.isAfter(closingTime) || isForceInActive;
        dateActiveDayHalf = closingTime.format('YYYY-MM-DD');
        timeActive = closingTime.format('HH:mm:ss');
      }
    } else {
      if (shift === ETimeShiftKeys.NIGHT_SHIFT && element?.closing_night_shift_salary) {
        if (
          element?.salary_type === ECompanyStaffSalaryType.MONTHLY_SALARY &&
          element.date < dayjs().format('YYYY-MM-DD')
        ) {
          forceInActive = true;
        } else {
          const closingTime = getDayjsByTimeZone(element?.closing_night_shift_salary);
          forceInActive = now.isAfter(closingTime) || isForceInActive;
          dateActiveNight = closingTime.format('YYYY-MM-DD');
          timeActive = closingTime.format('HH:mm:ss');
        }
      }

      if (element?.afternoon_haft_shift === ETimeShift.ACTIVITY && element?.closing_afternoon_haft) {
        const closingTime = getDayjsByTimeZone(element?.closing_afternoon_haft);
        forceInActive = now.isAfter(closingTime) || isForceInActive;
        dateActiveNightHalf = closingTime.format('YYYY-MM-DD');
        timeActive = closingTime.format('HH:mm:ss');
      }
    }

    const renderTimeActive = () => {
      if (shift === ETimeShiftKeys.DAY_SHIFT) {
        if (element.morning_haft_shift === ETimeShift.NOT_ACTIVITY) {
          return (
            <TimeActiveItem
              isConfirmed={checkIsConfirmed(element.date, shift, isTimeClosing)}
              date={dateActiveDay}
              isTimeNoon={shift === ETimeShiftKeys.DAY_SHIFT}
              time={timeActive}
              isTimeClosing={isTimeClosing}
              isDisableBtn={isForceInActive && !isRootAdmin}
              element={element}
              setStateHour={temp}
            />
          );
        } else {
          return (
            <TimeActiveItem
              isConfirmed={checkIsConfirmed(element.date, ETimeShiftKeys.MORNING_HALF, isTimeClosing)}
              date={dateActiveDayHalf}
              isTimeNoon={shift === ETimeShiftKeys.DAY_SHIFT}
              time={timeActive}
              isTimeClosing={isTimeClosing}
              isDisableBtn={isForceInActive && !isRootAdmin}
              element={element}
              setStateHour={temp}
            />
          );
        }
      }
      if (shift === ETimeShiftKeys.NIGHT_SHIFT) {
        if (element.afternoon_haft_shift === ETimeShift.NOT_ACTIVITY) {
          return (
            <TimeActiveItem
              isConfirmed={checkIsConfirmed(element.date, shift, isTimeClosing)}
              date={dateActiveNight}
              isTimeNoon={false}
              time={timeActive}
              isTimeClosing={isTimeClosing}
              isDisableBtn={isForceInActive && !isRootAdmin}
              element={element}
              setStateHour={temp}
            />
          );
        }
        if (element.afternoon_haft_shift === ETimeShift.ACTIVITY) {
          return (
            <TimeActiveItem
              isConfirmed={checkIsConfirmed(element.date, ETimeShiftKeys.AFTERNOON_HALF, isTimeClosing)}
              date={dateActiveNightHalf}
              isTimeNoon={false}
              time={timeActive}
              isTimeClosing={isTimeClosing}
              isDisableBtn={isForceInActive && !isRootAdmin}
              element={element}
              setStateHour={temp}
            />
          );
        }
      }
    };
    return (
      <>
        {element.salary_type === ECompanyStaffSalaryType.DAILY_SALARY ? (
          <ActiveItemWrapper>
            {element.day_shift === ETimeShift.NOT_ACTIVITY && element.night_shift === ETimeShift.NOT_ACTIVITY && (
              <div style={{ width: '3rem' }}></div>
            )}
            {element.day_shift === ETimeShift.NOT_ACTIVITY &&
              element.night_shift === ETimeShift.ACTIVITY &&
              shift === ETimeShiftKeys.DAY_SHIFT && <div style={{ width: '3rem' }}></div>}
            {element.day_shift === ETimeShift.ACTIVITY &&
              element.night_shift === ETimeShift.NOT_ACTIVITY &&
              shift === ETimeShiftKeys.NIGHT_SHIFT && <div style={{ width: '3rem' }}></div>}
            <ActiveItem
              isLoading={isLoading}
              activeData={handleAnalysisColorDay(changeData, isActive, color, shift, element.date, forceInActive)}
              isOwner={isOwner}
              onClick={() => {
                if (!isOwner || forceInActive) return;
                if (changeData[shift] === ETimeShift.NOT_ACTIVITY) {
                  if (shift === ETimeShiftKeys.DAY_SHIFT) {
                    changeData.morning_haft_shift = ETimeShift.NOT_ACTIVITY;
                  } else if (shift === ETimeShiftKeys.NIGHT_SHIFT) {
                    changeData.afternoon_haft_shift = ETimeShift.NOT_ACTIVITY;
                  }
                }
                changeTime(changeData, shift, element.date, isTimeClosing);
              }}
            >
              {title}
            </ActiveItem>
            {element.day_shift === ETimeShift.ACTIVITY && shift === ETimeShiftKeys.DAY_SHIFT && (
              <>
                <div style={{ width: '2rem' }}></div>
                <ActiveItem
                  isLoading={isLoading}
                  activeData={handleAnalysisColorDay(
                    changeData,
                    isActiveHalf,
                    colors.leaveShift,
                    ETimeShiftKeys.MORNING_HALF,
                    element.date,
                    forceInActive
                  )}
                  isOwner={isOwner}
                  onClick={() => {
                    if (!isOwner || forceInActive) return;
                    if (element.morning_haft_shift === ETimeShift.ACTIVITY) {
                      changeData.morning_haft_shift = ETimeShift.NOT_ACTIVITY;
                    } else {
                      changeData.morning_haft_shift = ETimeShift.ACTIVITY;
                    }
                    changeTime(changeData, ETimeShiftKeys.MORNING_HALF, element.date, isTimeClosing);
                  }}
                >
                  {CONST_ATTENDANCE_COMPANY.HAFT}
                </ActiveItem>
              </>
            )}
            {element.night_shift === ETimeShift.ACTIVITY && shift === ETimeShiftKeys.NIGHT_SHIFT && (
              <>
                <div style={{ width: '2rem' }}></div>
                <ActiveItem
                  isLoading={isLoading}
                  activeData={handleAnalysisColorDay(
                    changeData,
                    isActiveHalf,
                    colors.leaveShift,
                    ETimeShiftKeys.AFTERNOON_HALF,
                    element.date,
                    forceInActive
                  )}
                  isOwner={isOwner}
                  onClick={() => {
                    if (!isOwner || forceInActive) return;
                    if (element.afternoon_haft_shift === ETimeShift.ACTIVITY) {
                      changeData.afternoon_haft_shift = ETimeShift.NOT_ACTIVITY;
                    } else {
                      changeData.afternoon_haft_shift = ETimeShift.ACTIVITY;
                    }
                    changeTime(changeData, ETimeShiftKeys.AFTERNOON_HALF, element.date, isTimeClosing);
                  }}
                >
                  {CONST_ATTENDANCE_COMPANY.HAFT}
                </ActiveItem>
              </>
            )}
            {((element.night_shift === ETimeShift.NOT_ACTIVITY && shift === ETimeShiftKeys.NIGHT_SHIFT) ||
              (element.day_shift === ETimeShift.NOT_ACTIVITY && shift === ETimeShiftKeys.DAY_SHIFT)) && (
              <div style={{ width: '4rem' }}></div>
            )}
            {renderTimeActive()}
          </ActiveItemWrapper>
        ) : (
          <ActiveItemWrapper>
            <ActiveItem
              isLoading={isLoading}
              activeData={handleAnalysisColorDay(changeData, isActive, color, shift, element.date, forceInActive)}
              isOwner={isOwner}
              onClick={() => {
                if (!isOwner || forceInActive) return;
                changeTime(changeData, shift, element.date, isTimeClosing);
              }}
            >
              {title}
            </ActiveItem>
            <TimeActiveItem
              isConfirmed={checkIsConfirmed(element.date, shift, isTimeClosing)}
              date={shift === ETimeShiftKeys.DAY_SHIFT ? dateActiveDay : dateActiveNight}
              isTimeNoon={shift === ETimeShiftKeys.DAY_SHIFT}
              time={timeActive}
              isTimeClosing={isTimeClosing}
              isDisableBtn={isForceInActive && !isRootAdmin}
              element={element}
              setStateHour={temp}
            />
          </ActiveItemWrapper>
        )}
      </>
    );
  };
  const getTimeCountDown = (dateTime: string | null | undefined, element: any, key: any) => {
    if (key === ETimeShiftKeys.DAY_SHIFT) {
      if (
        (dayjs(element?.date).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD') && !dateTime) ||
        (dateTime && dateTime < dayjs().format('YYYY-MM-DD HH:mm:ss'))
      ) {
        return <Confirmed>({CONST_ATTENDANCE_COMPANY.CONFIRMED} )</Confirmed>;
      }
      if (dateTime && dateTime >= dayjs().format('YYYY-MM-DD HH:mm:ss')) {
        return (
          <div style={{ color: '#f65171' }}>
            {dateTime && (
              <CountDownDate date={dateTime} text={'確定まで'} salaryType={element?.salary_type} setStateHour={temp} />
            )}
          </div>
        );
      }
    } else {
      if (
        (dayjs(element?.date).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD') && !dateTime) ||
        (dateTime && dateTime < dayjs().format('YYYY-MM-DD HH:mm:ss'))
      ) {
        return <Confirmed>({CONST_ATTENDANCE_COMPANY.CONFIRMED} )</Confirmed>;
      }

      if (dateTime && dateTime >= dayjs().format('YYYY-MM-DD HH:mm:ss')) {
        return (
          <div style={{ color: '#f65171' }}>
            {dateTime && (
              <CountDownDate date={dateTime} text={'確定まで'} salaryType={element?.salary_type} setStateHour={temp} />
            )}
          </div>
        );
      }
    }
  };
  const statusAttendanceDayAndNight = (element: any) => {
    if (
      element?.closing_afternoon_working_time &&
      element?.closing_morning_working_time &&
      element?.closing_morning_working_time < dayjs().format('YYYY-MM-DD HH:mm:ss') &&
      element?.closing_afternoon_working_time < dayjs().format('YYYY-MM-DD HH:mm:ss')
    ) {
      return false;
    }

    if (
      !element?.closing_morning_working_time &&
      element?.closing_afternoon_working_time &&
      element?.closing_afternoon_working_time < dayjs().format('YYYY-MM-DD HH:mm:ss')
    ) {
      return false;
    }
    return true;
  };
  return (
    <MemberTableWrapper>
      <MemberHeaderTable />
      <ScrollWrapperMemberTable>
        {detailData.map((element: ITimeAttendance) => {
          return (
            <ItemMemberWrapper key={element.date}>
              <DayWrapperMember>
                <DayElement>{dayjs(element.date).format('MM/DD')}</DayElement>
                <StatusItem element={element} type={element?.salary_type} />
              </DayWrapperMember>
              <DashboardWrapper>
                {element.salary_type === ECompanyStaffSalaryType.DAILY_SALARY && (
                  <>
                    <ItemSetting
                      title={CONST_ATTENDANCE_COMPANY.NOON}
                      element={element}
                      color={colors.dayShift}
                      shift={ETimeShiftKeys.DAY_SHIFT}
                      isForceInActive={disableBtnTimekeeping(
                        element.date,
                        element.closing_leave,
                        cycleCompanySettingSalary.start_date,
                        isRootAdmin,
                        isOwner
                      )}
                      isTimeClosing={dataCompanySettingSalaryDetail?.is_time_closing}
                    />
                    <div style={{ width: '4rem' }}></div>
                    <ItemSetting
                      title={CONST_ATTENDANCE_COMPANY.NIGHT}
                      element={element}
                      color={colors.nightShift}
                      shift={ETimeShiftKeys.NIGHT_SHIFT}
                      isForceInActive={disableBtnTimekeeping(
                        element.date,
                        element.closing_leave,
                        cycleCompanySettingSalary.start_date,
                        isRootAdmin,
                        isOwner
                      )}
                      isTimeClosing={dataCompanySettingSalaryDetail?.is_time_closing}
                    />
                  </>
                )}
                {element.salary_type === ECompanyStaffSalaryType.MONTHLY_SALARY && (
                  <>
                    <ItemSetting
                      title={CONST_ATTENDANCE_COMPANY.NOON}
                      element={element}
                      color={colors.dayShift}
                      shift={ETimeShiftKeys.DAY_SHIFT}
                      isForceInActive={disableBtnTimekeeping(
                        element.date,
                        element.closing_leave,
                        cycleCompanySettingSalary.start_date,
                        isRootAdmin,
                        isOwner
                      )}
                      isTimeClosing={dataCompanySettingSalaryDetail?.is_time_closing}
                    />
                    <div style={{ width: '7rem' }}></div>
                    <ItemSetting
                      title={CONST_ATTENDANCE_COMPANY.NIGHT}
                      element={element}
                      color={colors.nightShift}
                      shift={ETimeShiftKeys.NIGHT_SHIFT}
                      isForceInActive={disableBtnTimekeeping(
                        element.date,
                        element.closing_leave,
                        cycleCompanySettingSalary.start_date,
                        isRootAdmin,
                        isOwner
                      )}
                      isTimeClosing={dataCompanySettingSalaryDetail?.is_time_closing}
                    />
                  </>
                )}
                {element.salary_type === ECompanyStaffSalaryType.HOUR_SALARY && (
                  <>
                    <AttendanceForHour
                      isRootAdmin={isRootAdmin}
                      currentDate={dayjs(element.date)}
                      element={element}
                      cycleCompanySettingSalary={cycleCompanySettingSalary}
                      changeTime={changeTime}
                      oneMember={true}
                      staffId={element.staff_id}
                      dataCompanySettingSalaryDetail={dataCompanySettingSalaryDetail}
                      stateHour={stateHour}
                      isTimeClosing={dataCompanySettingSalaryDetail?.is_time_closing}
                      isOwner={isOwner}
                    />
                    <div
                      style={{
                        marginLeft: `${
                          dataCompanySettingSalaryDetail?.is_time_closing === ESettingTimer.SETTING_TIMER
                            ? '2rem'
                            : '-5rem'
                        }`,
                      }}
                    >
                      <div
                        className={`box-timer`}
                        style={{
                          display: 'flex',
                          marginBottom: '1.25rem',
                          marginTop: `${'0.875rem'}`,
                          width: '16rem',
                        }}
                      >
                        {dataCompanySettingSalaryDetail?.is_time_closing === ESettingTimer.SETTING_TIMER &&
                          getTimeCountDown(element?.closing_morning_working_time, element, ETimeShiftKeys.DAY_SHIFT)}
                        {dataCompanySettingSalaryDetail?.is_time_closing === ESettingTimer.SETTING_STATIC &&
                          renderStaticSettingHourNoon(
                            dayjs(element?.date),
                            ETimeShiftFormat.NOON,
                            element?.closing_morning_working_time
                          )}
                      </div>
                      <div
                        className={`box-timer`}
                        style={{
                          display: 'flex',
                          marginBottom: '1.25rem',
                          marginTop: `${
                            element?.morning_working_time_shift_time !== 0 &&
                            element?.afternoon_working_time_shift_time !== 0 &&
                            dataCompanySettingSalaryDetail?.is_time_closing === ESettingTimer.SETTING_TIMER
                              ? '1.5rem'
                              : element?.closing_afternoon_working_time &&
                                element?.closing_afternoon_working_time > dayjs().format('YYYY-MM-DD HH:mm:ss') &&
                                element?.morning_working_time_shift_time === 0 &&
                                dataCompanySettingSalaryDetail?.is_time_closing === ESettingTimer.SETTING_TIMER
                              ? '3.5rem'
                              : '0.875rem'
                          }`,
                          width: '16rem',
                        }}
                      >
                        {dataCompanySettingSalaryDetail?.is_time_closing === ESettingTimer.SETTING_TIMER &&
                          getTimeCountDown(
                            element?.closing_afternoon_working_time,
                            element,
                            ETimeShiftKeys.NIGHT_SHIFT
                          )}
                        {dataCompanySettingSalaryDetail?.is_time_closing === ESettingTimer.SETTING_STATIC &&
                          renderStaticSettingHourNight(
                            dayjs(element?.date),
                            ETimeShiftFormat.NIGHT,
                            element?.closing_afternoon_working_time
                          )}
                      </div>
                    </div>
                  </>
                )}

                {!dataCompanySettingSalaryDetail?.is_time_closing &&
                  !checkOverDate(element.date, ETimeShiftFormat.DATE_NIGHT, false) &&
                  statusAttendanceDayAndNight(element) && (
                    <DeadlineForChange
                      style={{
                        marginLeft: `${element.salary_type === ECompanyStaffSalaryType.DAILY_SALARY ? '2rem' : '0rem'}`,
                      }}
                    >
                      {CONST_ATTENDANCE_COMPANY.CHANGEABLE_DEADLINE}
                    </DeadlineForChange>
                  )}
              </DashboardWrapper>
              <div style={{ color: '#fff' }}>{stateHour}</div>
            </ItemMemberWrapper>
          );
        })}
      </ScrollWrapperMemberTable>
      <ModalCommon
        isOpen={isShowConfirm}
        setIsOpen={() => setIsShowConfirm(false)}
        onClickOk={handleOkConfirm}
        onClickCancel={handleCancelConfirm}
        onCancel={handleCancelConfirm}
        txtOK={CONST_COMMON.CHANGE}
        txtCancel={CONST_COMMON.CANCEL}
        _className="confirm-company-pair confirm-attendance"
        btnCancelColor={colors.atomicTangerine}
      >
        <ModalContainer>
          <ModalContent>
            <div className="attendance-date-txt">
              {dayjs(confirmDate).format('MM月DD日')}　　{shiftTxt(confirmChanging)}
            </div>
            <div className="attendance-confirm-txt">{textConfirm}</div>
          </ModalContent>
        </ModalContainer>
      </ModalCommon>
    </MemberTableWrapper>
  );
};

export default MemberTable;
