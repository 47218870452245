import React from 'react';

import { colors } from 'constants/colorsBase';
import { ETimeShift } from 'constants/constants';
import { IShiftItem } from '@pages/CompanySite/AttendanceRecord/interface';
import { CONST_ATTENDANCE_COMPANY } from 'constants/language';
import { AfternoonShift, MorningShift, ShiftItemWrapper } from './shiftItemStyle';

const ShiftItem = ({ shiftElement }: { shiftElement: IShiftItem | null }) => {
  const renderMorning =
    shiftElement?.day_shift === ETimeShift.ACTIVITY ||
    shiftElement?.morning_haft_shift === ETimeShift.ACTIVITY ||
    shiftElement?.morning_working_time_shift_time !== 0;
  const renderAfternoon =
    shiftElement?.night_shift === ETimeShift.ACTIVITY ||
    shiftElement?.afternoon_haft_shift === ETimeShift.ACTIVITY ||
    shiftElement?.afternoon_working_time_shift_time !== ETimeShift.NOT_ACTIVITY;

  const getColorMorning = () => {
    if (shiftElement === null) return colors.white;
    if (renderMorning) return colors.mainColorCompany;
    return colors.greenWhite;
  };
  const getColorAfternoon = () => {
    if (shiftElement === null) return colors.white;
    if (renderAfternoon) return colors.mediumTurquoise;
    return colors.greenWhite;
  };

  return (
    <ShiftItemWrapper>
      <MorningShift color={getColorMorning()} isWork={!!shiftElement && renderMorning}>
        {renderMorning && CONST_ATTENDANCE_COMPANY.NOON}
      </MorningShift>
      <AfternoonShift color={getColorAfternoon()} isWork={!!shiftElement && renderAfternoon}>
        {renderAfternoon && CONST_ATTENDANCE_COMPANY.NIGHT}
      </AfternoonShift>
    </ShiftItemWrapper>
  );
};

export default ShiftItem;
