import { ETimeShift } from 'constants/constants';

export interface ITimeAttendance {
  date: string;
  day_shift: ETimeShift.ACTIVITY | ETimeShift.NOT_ACTIVITY;
  id?: number;
  leave: number;
  night_shift: number;
  staff_id: number;
  is_time_day_shift?: number;
  is_time_night_shift?: number;
  is_time_afternoon_haft?: number;
  is_time_morning_haft?: number;
  is_time_morning_working_time?: number;
  is_time_afternoon_working_time?: number;
  is_leave?: number;
  closing_day_shift_salary?: string | null;
  closing_night_shift_salary?: string | null;
  closing_leave?: string | null;
  cycle_start_date: string;
  cycle_end_date: string;
  morning_working_time_shift_time: number;
  afternoon_working_time_shift_time: number;
  morning_haft_shift: number;
  afternoon_haft_shift: number;
  closing_afternoon_haft?: string | null;
  closing_morning_haft?: string | null;
  closing_morning_working_time?: string | null;
  closing_afternoon_working_time?: string | null;
  salary_type?: number;
}

export interface IShiftItem {
  date: string;
  day_shift: ETimeShift.ACTIVITY | ETimeShift.NOT_ACTIVITY;
  id?: number;
  leave: number;
  night_shift: number;
  staff_id: number;
  is_time_day_shift?: number;
  is_time_night_shift?: number;
  is_leave?: number;
  closing_day_shift_salary?: string | null;
  closing_night_shift_salary?: string | null;
  closing_leave?: string | null;
  morning_working_time_shift_time?: number;
  morning_haft_shift?: number;
  afternoon_working_time_shift_time?: number;
  afternoon_haft_shift?: number;
}

export const defaultTimeAttendance = {
  date: '',
  day_shift: ETimeShift.NOT_ACTIVITY,
  leave: ETimeShift.NOT_ACTIVITY,
  night_shift: ETimeShift.NOT_ACTIVITY,
  staff_id: ETimeShift.NOT_ACTIVITY,
  morning_haft_shift: ETimeShift.NOT_ACTIVITY,
  afternoon_haft_shift: ETimeShift.NOT_ACTIVITY,
  morning_working_time_shift_time: ETimeShift.NOT_ACTIVITY,
  afternoon_working_time_shift_time: ETimeShift.NOT_ACTIVITY,
};

export interface IStaff {
  name: string;
  staff_id: number;
  staff_disconnect_time: string | null;
  code: string;
  deleted_at: string | null;
  time_attendance: ITimeAttendance[];
  salary_type: number;
}

export interface ICompanySettingSalaryDetail {
  closing_hour: number | null;
  closing_minute: number | null;
  company_id: number;
  created_at: string;
  id: number;
  is_time_closing: number;
  prepaid_salary_afternoon: number | null;
  prepaid_salary_day: number | null;
  prepaid_salary_month: number | null;
  prepaid_salary_morning: number | null;
  salary_day: number | null;
  salary_day_end_month: number;
  salary_type: number;
  updated_at: string;
}

export interface ICycleCompanySettingSalary {
  start_date: string;
  end_date: string;
}

export type IWeekType = 0 | 1 | 2 | 3 | 4 | 5 | 6;
