const CONST_COMMON = {
  EMAIL: 'メールアドレス',
  INPUT_EMAIL: 'メールアドレス入力',
  FULL_NAME: '氏名',
  FULL_NAME_FURIGANA: 'フリガナ',
  ACCOUNT_INFORMATION: '口座情報',
  FINANCIAL_INSTITUTION_NAME: '金融機関名',
  BANK_CODE: '銀行コード',
  NAME_KANA: '氏名(カナ)',
  BANK_NAME_CODE: '銀行名(コード)',
  BRANCH_NAME_CODE: '支店名(コード)',
  BRANCH_NAME: '支店名',
  BRANCHES_CODE: '支店コード',
  OWNER_NAME_KANA: '名義人名(カナ)',
  ACCOUNT_TYPE: '口座種類',
  ACCOUNT_NUMBER: '口座番号',
  SEARCH_BY_NAME: 'お名前検索',
  EDIT: '編　集',
  CREATE: '登　録',
  CORRECTION: '修　正',
  INVITE_AGAIN: '再　招　待',
  DELETE_INVITE: '削　除',
  SAVE: '保　存',
  ISSUE: '発　行',
  SETTING: '設　定',
  CANCEL: 'キャンセル',
  BACK: '戻る',
  CHANGE: '変更',
  CANCEL_HINA: '取り消し',
  INVITE: '招待',
  NO_DATA: 'データがありません',
  BASIC_INFORMATION: '基本情報',
  REPRESENTATIVE_NAME: '代表者名',
  FIRST_NAME: '姓',
  LAST_NAME: 'お名前(カナ)',
  PHONE_NUMBER: '電話番号',
  YEN: '円',
  REIMBURSEMENT: '立替',
  KEEP: '保　存',
  SYSTEM_ERROR: 'システムエラー',
  NOT_ACCESS: 'アクセス権限がありません',
  REMOVE_EDIT: '入力内容が全て破棄されます。よろしいですか？',
  AGREE: '同意',
  AGREED_BTN: '同意済み',
  OK: 'OK',
  DELETE: '削除',
  PERCENT: '％',
  CONFIRM_CANCEL: '登録内容を破棄し、入力画面を閉じます。',
  PLACEHOLDER: {
    FULL_NAME: '山田　太朗',
    FULL_NAME_FURIGANA: 'ヤマダ タロウ',
    PHONE_NUMBER: '090-111-2222',
    PHONE_UPLOAD_FILE: '000-000-0000',
    EMAIL: 'maebarai@example.co.jp ',
  },
  YEAR: '年',
  MONTH: '月',
  DAY: '日',
  FINAL_UPDATE: '最終更新',
  PROFILE_POPUP: {
    AGENCY: '代理店',
    DAIRI_TARO: 'ダイリ タロウ',
    AGENCY_INFORMATION: '代理店情報',
    LOGOUT: 'ログアウト',
  },
  EDIT_SUCCESS: '更新完了しました',
  BTN_SAVE: '確　認',
  DRAG_OR_DROP: 'ドラック＆ドロップまたは',
  VERIFY: '確　認',
  VERIFY_NO_SPACE: '確認',
  SELECT_FILES: 'ファイルを選択',
  AGREE_BTN: '同　意',
  ALT: {
    LOGO: 'Logo',
    UPLOAD: 'Upload',
    AGREE: 'Agree',
    PREVIEW: 'Preview',
    DOWNLOAD: 'Download',
    BACK_TO_HOME: 'Back to home',
  },
  VALIDATE_EMAIL: 'メールアドレスが無効な値です',
  SYSTEM_NOT_EMAIL: 'メールアドレスが無効です。',
  NAME: 'お名前',
  PLACEHOLDER_PHONE: '090-111-2222',
  maxLengthName: '100桁まで入力して下さい。',
  maxLengthNameKana: '100桁まで入力して下さい',
  maxLengthEmail: '255桁まで入力して下さい。',
  maxLengthPhone: '11桁まで入力して下さい',
  maxLengthAmount: '11桁まで入力して下さい。',
  LOOKS: '様',
  NOTICE: 'お知らせ',
  NOTICE_DETAIL: 'お知らせ(詳細)',
  NEW: 'NEW',
  errorSystemAgain: 'エラーが発生しました。再度お試しください',
  INCORRECT_PHONE: '電話番号が間違っています。',
  messageErrorDuplicate: '重複しています。',
  OPERATION: '運　営',
  KATAKANA_HAF: 'カタカナ（半角）のみ有効です',
  KATAKANA_HAF_OPERATOR: '名義人名（カナ）はご利用できない文字が含まれています',
  KATAKANA_HAF_AGENCY: '名義人名（カナ）はご利用できない文字が含まれています',
  SETTING_SUCCESS: '設定が完了しました。',
  REQUIRE_ADDRESS_1: '住所1は必要です。',
  REQUIRE: '*',
  REQUIRE_TITLE: '＊印の項目は必須項目です',
  COPY_SUCCESS: 'コピーしました',
  COPY: 'コピー',
  NOT_FOUND: 'データがありません',
  OK_JP: '保存',
  CONFIRM: '確定',
};

const CONST_EDIT_OPERATOR = {
  ERROR_FIRST_NAME: '姓(セイ)は必須です',
  ERROR_LAST_NAME: 'お名前(カナ)は必須です',
  OPERATOR_ID: '運営ID',
  PASSWORD: 'パスワード',
  FIRST_NAME: '姓(セイ)',
  LAST_NAME: 'お名前(カナ)',
  NAME: 'お名前',
  EMAIL_ADDRESS: 'メールアドレス',
  CANCEL: 'キャンセル',
  KEEP: '保　存',
  EDIT_SUCCESS: '更新完了しました',
};

const CONST_CREATE_COMPANY = {
  COMPANY_NAME: '企業名',
  REPRESENTATIVE_NAME: '代表者名',
  FIRST_NAME: '姓',
  LAST_NAME: 'お名前(カナ)',
  PHONE_NUMBER: '電話番号',
  EMAIL_ADDRESS: 'メールアドレス',
  USAGE_PLAN: 'ご利用プラン',
  AGENCY_CODE: '代理店名(コード)',
  BROKERAGE_FREE: '仲介手数料 (1件あたり)',
  BROKERAGE__FEE_TOP: '仲介手数料',
  BROKERAGE__PRE_CASE: '(1件あたり)',
  AGENCY_NAME: '代理店名',
  AGENCY_CORPORATION: '代理株式会社',
  YEN: '円',
  PLAN: 'ご利用プラン',
  VERIFICATION: '確　認',
  ISSUE: '発　行',
  CORRECTION: '修　正',
  TERMS_SERVICE: '利用規約',
  PRIVACY_POLICY: 'プライバシーポリシー',
  FINANCIAL_STATEMENTS: '決算書（二期分）',
  FEE_FREE_DATE: '利用料フリー期間',
  FEE: 'システム利用料',
  SUMMARY: '総括',
  HOURLY_WAGE: '時給',
  ADDITION_OPTIONS: '追加オプション',
  addItem: '項目追加',
  // Create page
  CREATE_TITLE: '企業ID/管理者アカウント 発行',
  LIST_COMPANY_PAGE: '企業一覧',
  CORPORATE: '企業/管理者発行',
  COMPANY_ID: '企業ID',
  POSTAL_CODE: '郵便番号',
  ADDRESS_FIRST: '住所 1',
  ADDRESS_SECOND: '住所 2',
  OPERATOR_ID: '管理者ID',
  REGISTER_STATUS: '登録状況',
  // ERROR
  EMPTY_COMPANY_NAME: '企業名は必須です',
  EMPTY_FIRST_NAME: 'お名前は必要です',
  EMPTY_LAST_NAME: 'お名前(カナ)は必須です',
  EMPTY_PHONE: '電話番号は必須です',
  EMPTY_EMAIL: 'メールアドレスは必須です',
  EMPTY_AGENCY_CODE: '代理店コード入力は整数のみ有効です',
  EMPTY_FEE: '仲介手数料（1件あたり）は必須です',
  EMPTY_FILE: '必要書類は必須です',
  EMPTY_CHARGE: 'この項目は必要です。',
  NOT_NUMBER_AGENCY: '代理店コード入力は整数のみ有効です',
  NOT_NUMBER_FEE: '手数料（1件あたり）は整数のみ有効です',
  NOT_NUMBER_PHONE: '電話番号は整数のみ有効です',
  NOT_SELECTED_FILE: '必要書類名は必須です',
  VALIDATE_MAIL: 'メールアドレスが無効な値です',
  NOT_NUMBER_POSTAL: 'この項目は数字のみ有効です',
  DOCUMENT_DUPLICATE: '必要書類提出項目は重複しています。',
  END_DATE_GREATER_THAN_STAT_DATE: 'フリー期間の終了日は開始日以降で設定をお願いします',
  // PLACEHOLDER
  COMPANY_NAME_PLACE: '株式会社〇〇警備',
  POSTAL_FIRST_PLACE: '000',
  POSTAL_END_PLACE: '0000',
  ADDRESS_FIRST_PLACE: '〇〇県〇〇市〇〇区〇〇町0丁目0−0',
  ADDRESS_END_PLACE: '〇〇ビル 0F',
  FIRST_NAME_PLACE: '山田　太郎',
  PHONE_PLACE: '090-111-2222',
  MAIL_PLACE: 'aaa.bbb@example.com',
  AGENCY_CODE_PLACE: '代理店名またはコード',
  USAGE_SITUATION: '利用状況',
  UNDER_REVIEW: '審査中',
  CREATE_COMPANY: '企業/管理者アカウント発行',
  REQUIRED_POSTAL_CODE: '郵便番号は必要です。',
  REQUIRED_ADDRESS: '住所1は必要です。',
};

const CONST_COMPANY_ADMIN_ACCOUNT = {
  TITLE_PAGE_VIEW: '管理者情報',
  TITLE_PAGE_CREATE: '管理者アカウント発行',
  TITLE_PAGE_EDIT: '管理者情報',
  BASIC_INFORMATION: '基本情報',
  CREATE_ADMIN_ACCOUNT: '管理者アカウント発行',
  EMAIL_PLACEHOLDER: 'maebarai@example.co.jp',
  CONFIRM_CANCEL: '登録内容を破棄し、入力画面を閉じます。',
  UPLOAD_DOCUMENT_DESCRIPTION: '必要書類を下記項目からアップロードしてください',
  TERMS_OF_USE_ELECTRONIC_CONTRACT: '利用規約電子契約書',
  PRIVACY_POLICY_CONTRACT: 'プライバシーポリシー',
  TERMS_OF_USE_CONTRACT: '利用規約/契約書',
  PRIVACY_POLICY_CONTRACT_UPDATE_TEXT: 'プライバシーポリシーが更新されましたので改めてご確認下さい',
  TERMS_OF_USE_CONTRACT_UPDATE_TEXT: '利用規約が更新されましたので改めてご確認下さい',
  CONFIRM_DELETE: 'アカウントを削除しますか？',
  DELETED: 'アカウントを削除しました',
  VALIDATE: {
    FULL_NAME_REQUIRED: '氏名は必須です',
    FULL_NAME_FURIGANA_REQUIRED: 'フリガナは必須です',
    EMAIL_REQUIRED: 'メールアドレスは必須です',
    EMAIL_REGEX: 'メールアドレスが無効な値です。',
    EMAIL_UNIQUE: 'メールアドレスが既に存在しています。',
  },
  ALT: {
    CREATE_ACCOUNT: 'Create account',
    SAVE_ACCOUNT: 'Save account',
    BACK: 'Back',
    Logo: 'Logo',
  },
};

const CONST_BREADS = {
  ACCOUNT_LIST_COMPANY_OR_ADMIN: '企業/管理者アカウント一覧',
  COMPANY_SITE: {
    LIST_STAFF: '従業員一覧',
    LIST_ADMIN_ACCOUNT: '管理者一覧',
    COMPANY_PROFILE: '企業情報',
    CREATE_STAFF_MANY: '従業員アカウント一括発行',
    SALARY_SETTING: '前払い申請設定',
    BANK_SETTING: '口座設定',
    TERM_OF_USE: '利用規約等',
  },
  LIST_OF_INTRODUCED_COMPANIES: '導入企業一覧',
  SETTING_TOPAGE: '連携管理',
  LIST_COMPANY: '企業一覧',
};
const CONST_EDIT_AGENCY = {
  AGENCY_CODE: '代理店コード',
  AGENCY_FEE_SETTING: '仲介手数料設定',
  DEPOSIT: 'デポジット',
  ACCOUNT_INFORMATION: '口座情報',
  BANK_CODE: '銀行コード',
  BRANCHES_CODE: '支店コード',
  ACCOUNT_TYPE: '口座種類',
  KANA_NAME: '名義人名(カナ)',
  ACCOUNT_NUMBER: '口座番号',
};
const CONST_SETTING_PAGE = {
  SETTING_TITLE: 'その他 / 設定',
  CHANGE_NAME: '名称変更',
  OPERATOR_ACCOUNT: '運営口座設定',
  WORD_LOG: '操作ログ',
  PRIVACY_POLICE: 'プライバシーポリシー',
  OPERATION_COMPANIES: '運営/企業間',
  TERMS_OF_SERVICE: '利用規約',
  COMPANY_EMPLOYEES: '企業/従業員間',
  OPERATOR_BETWEEN_AGENCY: '運営/代理店間',
  EMPLOYEES_SERVICE: '従業員サービス',
  EDIT_SUCCESS: '更新完了しました',
  KEEP: '保　存',
  EDIT: '編　集',
  CONTENT_REQUIRED: '内容は必須です',
  PLAN_PEE: 'プラン料金/',
  MANAGEMENT_ACCOUNT_SETTING: '運営口座設定',
  ERROR_FILE_TYPE: 'ファイル形式がPDFのみ有効です。',
  COMPANY_SITE: {
    NOTICE: 'お知らせ',
    CHAT: 'お問い合わせ',
    REGULATIONS: '利用規約等',
    HISTORY: '操作ログ',
    QANDA: 'よくある質問',
    HELP: 'ヘルプ',
    PARIED_COMPANY: '連携管理',
    SALARY: '前払い申請設定',
    BANK: '銀 行',
  },
};

const CONST_COMPANY_BANK_SETTING = {
  TITLE_PAGE: '口座設定',
  INSTRUCTION_TEXT: '従業員へ前払給与を振込む口座情報を設定してください',
  LABEL: {
    BANK_CODE: '金融機関名(銀行コード)',
    BRANCHES_CODE: '支店名(支店コード)',
    BANK_TYPE: '口座種類',
    ACCOUNT_NAME: '名義人名(カナ)',
    ACCOUNT_NUMBER: '口座番号',
    SEVEN_USER_ID: '企業コード',
  },
  PLACEHOLDER: {
    BANK_CODE: '金融機関名またはコードを入力',
    BRANCHES_CODE: '支店名またはコードを入力',
    BANK_TYPE: '口座種類',
    ACCOUNT_NAME: 'カ）〇〇〇〇',
    ACCOUNT_NUMBER: '0000000',
    SEVEN_USER_ID: '0123456789',
  },
  BTN_SAVE: '確　認',
  BANK_ACCOUNT_TYPE: {
    USUALLY: '普通',
    CURRENT: '当座',
  },
  VALIDATE: {
    BANK_CODE_REQUIRED: '金融機関名(銀行コード)は必須です。',
    BRANCHES_CODE_REQUIRED: '支店名(支店コード)は必須です。',
    BANK_TYPE_REQUIRED: '口座種類は必須です',
    ACCOUNT_NAME_REQUIRED: '名義人名(カナ)は必須です',
    ACCOUNT_NUMBER_REQUIRED: '口座番号は必須です',
    ACCOUNT_NUMBER_REGEX: '口座番号は整数のみ有効です',
    SEVEN_USER_REQUIRE: '企業コードは必須です。',
  },
  ALT: {
    SAVE_BANK_SETTING: 'Save bank setting',
    BACK_TO_HOME: 'Back to home',
  },
};

const CONST_SETTING_SALARY = {
  APPROVAL_METHOD: '承認方法',
  DAILY_WAGE: '日　給',
  HOUR_WAGE: '時　給',
  AMOUNT_PAID_ADVANCE: '昼前払い可能額',
  AMOUNT_MONEY: '金額',
  PREPAID_AFTERNOON: '夜前払い可能額',
  MAXIMUM_PAYMENT_MOUNT: '支払い上限額',
  MONTH_SALARY: '月　給',
  DAILY_APPLICATION_LIMIT: '1日申請上限額',
  SALARY_CLOSING_DATE: '給与締日',
  LAST_DAY: '末日',
  DAY: '日',
  DATE: '日付',
  SAVE_SETTINGS: '設定を保存',
  ADVANCE_PAYMENT_APP_SETTING: '前払い申請設定',
  INSTRUCTION_TEXT: '※  システム内の設定画面よりいつでも変更することができます',
  AUTOMATIC_APPROVAL: '自動承認',
  MANUAL_APPROVAL: '手動承認',
  TOOLTIP_TEXT: {
    SALARY_TEXT_1: '従業員から前払い申請がきた際に振込までの条件設定を選択して下さい',
    SALARY_TEXT_2: '「自動承認」：振込までを自動で完結',
    SALARY_TEXT_3: '「手動承認」：前払申請額を直接確認し許可を出すことで振込される',
    DAILY_WAGE: '日給の方の1出勤あたりにおける上限振込額を昼夜それぞれで設定します',
    MONTH_SALARY: '月給の方の前払い申請上限額を月単位と日単位それぞれで設定します',
    SALARY_CLOSING_DATE: '給与を締める日付を設定して下さい',
    SALARY_HOUR_TEXT: '時給：1時間あたりにおける上限振込額を昼夜それぞれで設定します',
    SALARY_DAY_TEXT: '日給/半日：1出勤あたりにおける上限振込額を昼夜それぞれで設定します',
    SALARY_MONTH_TEXT: '月給：前払い申請上限額を月単位と日単位それぞれで設定します',
  },
  VALIDATE: {
    DAY_TIME_APPLICATION_LIMIT_REQUIRED: '申請上限額(昼)は必須です。',
    NIGHT_TIME_APPLICATION_LIMIT_REQUIRED: '申請上限額(夜)は必須です。',
    DAY_HALF_TIME_APPLICATION_LIMIT_REQUIRED: '半日給(昼)は必須です。',
    NIGHT_HALF_TIME_APPLICATION_LIMIT_REQUIRED: '半日給(夜)は必須です。',
    DAYILY_TIME_APPLICATION_LIMIT_REQUIRED: '申請上限額(月)は必須です。',
    MONTHLY_TIME_APPLICATION_LIMIT_REQUIRED: '申請上限額(日)は必須です。',
    MIN_1000: '入力は1000円単位になります。',
    MIN_100: '入力は100円単位になります。',
    MIN_1000_MORNING: '申請上限額(昼)は1000円\n単位で入力してください',
    MIN_1000_AFTERNOON: '申請上限額(夜)は1000円\n単位で入力してください',
    MIN_100_MORNING: '申請上限額(昼)は100円\n単位で入力してください',
    MIN_100_MORNING_HALF: '半日給(昼)は100円\n単位で入力してください',
    MIN_100_AFTERNOON_HALF: '半日給(夜)は100円\n単位で入力してください',
    MIN_100_AFTERNOON: '申請上限額(夜)は100円\n単位で入力してください',
    MIN_100_MONTH: '申請上限額(月)は100円\n単位で入力してください',
    MIN_100_DAY: '申請上限額(日)は100円\n単位で入力してください',
    ENTER_IN_UNITS_OF_1000: '申請上限金額は1,000円\n単位以上で入力して下さい',
    ENTER_IN_UNITS_OF_100: '申請上限金額は100円\n単位以上で入力して下さい',
    SALARY_DAY_REQUIRED: '給与締日は必須です',
    SALARY_TIMER_REQUIRED: 'タイマーの時間が必要です。',
    SALARY_DAY_INVALID: '1 か月は最大 31 日しかありません',
    SALARY_MORE_THAN_1_MINUTE: '１分以上の入力で設定をお願いします。',
    SALARY_MORE_LESS_60_MINUTE: '60分以下の入力で設定をお願いします。',
    SALARY_MORE_LESS_24_HOUR: '24時以下の入力で設定をお願いします。',
  },
  NOTICE_REQUIRED: '＊印の項目は必須項目です',
  ALERT_SETTING_SALARY1: 'ご入力された金額が、今月既に申請済みの金額より低いため',
  ALERT_SETTING_SALARY2: '上限額の適用は次回の給与締日以降になります。',
  ALERT_SETTING_SALARY3: 'よろしいですか？',
  CONFIRM_SETTING_SALARY1: '更新された金額以上に前払い済みです',
  SETTING_CLOSING_DATE: '出勤確認',
  SETTING_CLOSING_DATE_TOOLTIP1: '固定(0時/12時)：毎日昼のチェックは12時、夜のチェックは0時に確定されます。',
  SETTING_CLOSING_DATE_TOOLTIP2: 'タイマー：出勤確認にチェックを入れてから確定されるまでの時間を入力して下さい。',
  SETTING_CLOSING_STATIC: '固定(0時/12時)',
  SETTING_CLOSING_TIMER: 'タイマー',
  SETTING_CLOSING_HOUR: '時間',
  SETTING_CLOSING_MINUTE: '分',
  SETTING_CLOSING_TITLE: '確定方法',
  SETTING_SALARY_TIMER_DESCRIPTION1: '出勤確定方法を変更すると',
  SETTING_SALARY_TIMER_DESCRIPTION2: '未確定のものはリセットされます',
  SETTING_SALARY_TIMER_DESCRIPTION3: 'よろしいですか？',
  PREVENT_PAYMENT: '過払いを防ぐため上限金額の反映は',
  WILL_BE_EMPTY: 'からになります。',
};

const CONST_LIST_COMPANY = {
  SECURITY: '警備',
  HOLDER_NAME_OR_ID: '氏名もしくは企業IDを入力',
  HOLDER_SEARCH_COMPANY: '企業名もしくは企業IDを入力',
  COMPANY_NAME: '企業名',
  COMPANY_CODE: '企業ID',
  RE_REQUESTING: '再提出依頼中',
  // status
  STATUS_USING: '利用中',
  STATUS_SUSPEND: '利用停止',
  STATUS_NOTVNVERIFY: '審査中',

  REPRESENTATIVE_NAME: '代表者名',
  OPERATOR_ID: '管理者ID',
  PASSWORD: 'パスワード',
  PHONE_NUMBER: '電話番号',
  EMAIL_ADDRESS: 'メールアドレス',
  USAGE_PLAN: 'ご利用プラン',
  REGISTRATION_STATUS: '登録状況',
  AGENCY_CODE: '代理店コード',
  AGENCY_FEE: '仲介手数料 (1件あたり)',
  AGENCY_NAME: '代理店名',
  DOCUMENTS: '必要書類提出項目',
  NO_AGENCY_CODE: '代理店コードが存在していません',
  YEN: '円',
  APPROVAL: '承認',
  DENIAL: '否認',

  // Delete
  CONFIRM_DELETE: '選択した企業を本当に削除しますか？',
  DELETED: '削除しました。',

  // Detail
  TITLE_TEXTAREA_CONFIRM: '不備内容入力欄',
  PLACEHOLDER_COMPANY_CONFIRM: '相手に送る文面入力欄',
  REJECT_DOCUMENT: '再提出',
  BTN_REJECT_ACCOUNT: '利用不可',
  BTN_ROLLBACK_ACCOUNT: '再提出依頼',
  BTN_APPROVED_DUCUMENT: '審査完了',
  CONFIRM_REFUSE: '必要書類提出を本当に否認しますか',
  REJECT_ACCOUNT: '利用不可',
};

const CONST_OPERATOR_BANK = {
  USAGE_PLAN_PRICING: 'ご利用プラン料金設定',
  DEPOSIT_PLAN: 'デポジットプラン',
  ADVANCE_PLAN: '立替プラン',
  REIMBURSEMENT_PLAN: '立替プラン',
  ACCOUNT_SETTING: '口座設定',
  BANK_CODE: '金融機関名(銀行コード)',
  BRANCH_NAME: '支店名(支店コード)',
  ACCOUNT_TYPE: '口座種類',
  NAME_KANA: '名義人名(カナ)',
  ACCOUNT_NUMBER: '口座番号',
  EDIT: '編　集',
  CURRENT: '当座',
  USUALLY: '普通',
  PLACEHOLDER_ACCOUNT_NUMBER: '0000000',
  PLACEHOLDER_ACCOUNT_NAME: 'カ）〇〇〇〇',
  TITLE_POPUP: 'ご利用プラン料金/口座情報を',
  CONFIRM: '本当に変更してもいいですか？',
  KEEP: '保　存',
  TITLE_SETTING_BANK: 'プラン料金/口座設定',

  // setting
  ENTER_NAME_OR_CODE: '金融機関名またはコードを入力',
  ENTER_BRANCH_NAME_OR_CODE: '支店名またはコードを入力',
  EMPTY_BANK_CODE: '金融機関名(銀行コード)は必須です',
  EMPTY_BANK_BRANCHES_CODE: '支店名(支店コード)は必須です',
  EMPTY_ACCOUNT_NAME: '名義人名(カナ)は必須です',
  EMPTY_ACCOUNT_NUMBER: '口座番号は必須です',
  ACCOUNT_NAME_NOT_KATAKANA: 'お名前(カナ)はカタカナのみ有効です',
  ACCOUNT_NUMBER_NOT_NUMBER: '口座番号は整数のみ有効です',
  OVER_100: '入力した価値が100を超えています。再度入力してください',
};

const CONST_COMPANY_STAFF_MANAGEMENT = {
  CREATE_ONE_STAFF: '従業員アカウント発行/招待',
  CREATE_MANY_STAFF: '従業員アカウント一括発行',
  BUTTON_FILLTER_ACTIVE_TEXT: '利用中',
  BUTTON_FILLTER_BLOCKED_TEXT: '停止中',
  BUTTON_CHOOSE_FILE: 'ファイルを選択',
  STAFF_INFORMATION: '従業員情報',
  NOT_ACCESS_OR_WARNING_FOR_COOPERATION: '初回登録または連携待ち',
  BASIC_INFORMATION: '基本情報',
  ID_STAFF: '従業員ID',
  SALARY_FORM: '給与形態',
  DAILY_SALARY: '日給',
  MONTHLY_SALARY: '月給',
  INDIVIDUAL_SETTING: '個別設定',
  DAY_TIME_APPLICATION_LIMIT: '申請上限額(昼)',
  NIGHT_TIME_APPLICATION_LIMIT: '申請上限額(夜)',
  HALF_DAY_TIME_APPLICATION_LIMIT: '半日給(昼)',
  HALF_NIGHT_TIME_APPLICATION_LIMIT: '半日給(夜)',
  DAYILY_TIME_APPLICATION_LIMIT: '申請上限額(月)',
  MONTHLY_TIME_APPLICATION_LIMIT: '申請上限額(日)',
  SETTING_INDIVIDUAL: '個別設定 (申請上限)',
  SETTING_HALF_DAY_INDIVIDUAL: '個別設定 (半日給)',
  YEN_DIV_ATTENDANCE: '円/出勤',
  YEN_DIV_DAY: '円/月',
  YEN_DIV_MONTH: '円/日',
  YEN_DIV_HOUR: '円/時間',
  USEAGE_START_DATE: 'ご利用開始日',
  USEAGE_STATUS: 'ご利用状況',
  ACCOUNT_INFORMATION: '口座情報',
  CONSENT_FORM: '同意書',
  CONSENT_FORM_CONFIRMATION: '同意書確認',
  PREPAYMENT_HISTORY: '前払い履歴',
  ATTDENDANCE_RECORD: '勤怠実績',
  STAFF_ACCOUNT_ISSUANCE: '従業員アカウント発行',
  STAFF_URL_LOGIN: '導入準備URL',
  STAFF_QR_CODE_LOGIN: '導入準備QRコード',
  STAFF_STATUS: {
    NOT_ACCESS: '初回登録待ち',
    USING: '利用中',
    REJECT: '連携拒否',
    STAFF_DISCONNECT: '脱退',
    DELETED: '利用停止中',
    STAFF_NOT_ALLOW_REQUEST_SALARY: '申請不可',
    WAITING_CONFIRM: '承認待ち',
  },
  ALT: {
    STAFF: 'Staff',
    WARNING: 'Warning',
    CREATE_ONE_STAFF: 'Create one staff',
    CREATE_MANY_STAFF: 'Create many staff',
    LINK_ACOUNT_STAFF: 'Link account staff',
  },
  VALIDATE: {
    SALARY_TYPE_REQUIRED: '給与形態を選択して下さい',
    FULL_NAME_REQUIRED: '氏名は必須です',
    FULL_NAME_KATA_REQUIRED: 'フリガナは必須です',
    FULL_NAME_KATA_REGEX: 'フリガナはカタカナのみ有効です',
    PHONE_NUMBER_REQUIRED: '電話番号は必須です',
    PHONE_NUMBER_REGEX: '電話番号は数字のみ有効です',
    EMAIL_REQUIRED: 'メールアドレスは必須です',
    SALARY_REQUIRED_REQUIRED: 'メールアドレスは必須です',
    EMAIL_REGEX: 'メールアドレスが無効な値です。',
    EMAIL_UNIQUE: 'メールアドレスが既に存在しています。',
    PHONE_UNIQUE: '電話番号が存在しています。',
    DAY_AMOUNT_LIMIT_REQUIRED: '申請上限額(昼)は必須です',
    DAY_AMOUNT_LIMIT_REGEX: '申請上限額(昼)は整数のみ有効です',
    NIGHT_AMOUNT_LIMIT_REQUIRED: '申請上限額(夜)は必須です',
    NIGHT_AMOUNT_LIMIT_REGEX: '申請上限額(夜)は整数のみ有効です',
    ONE_MONTH_AMOUNT_LIMIT_REQUIRED: '申請上限額(日)は必須です',
    ONE_MONTH_AMOUNT_LIMIT_REGEX: '申請上限額(日)は整数のみ有効です',
    ONE_DAY_AMOUNT_LIMIT_REQUIRED: '申請上限額(月)は必須です',
    ONE_DAY_AMOUNT_LIMIT_REGEX: '申請上限額(月)は整数のみ有効です',
  },
  INPUT_INVITION: '従業員招待入力',
  EMAIL_NOT_EXISTS: 'メールアドレスがシステムに存在していません',
  EMAIL_INVITED: '従業員が招待できません',
  CODE: 'コード : ',
  INVITED: 'さんを招待しました',
  NOTICE_REQUIRED: '＊印の項目は必須項目です',
  TO: 'への',
  REJECT_INVITE: '招待は拒否されました',
  CONFIRM_DELETE: '選択した従業員を本当に削除しますか？',
  DELETED: '削除しました。',
  CONFIRM_CHANGE_STATUS: '「ご利用状況」を「申請不可」に変更すると未確定分は削除されます。',
  IS_OK: 'よろしいですか？',
  CONFIRM_TO_WORK_UNTIL: '様はまだ未確定の出勤確認が登録されています。',
};

const LANGUAGE_AGENCY_COMPANY_LIST = {
  SHOWING_CONFIRMED_INFORMATION_IN: 'に確定した情報を表示しています',
  CLICK_TO_SELECT_DATE: 'クリックして日付を選択',
  NUMBER_OF_ADVANCE_PAYMENTS: '前払い件数',
  USAGE_AMOUNT: 'ご利用金額',
  BROKERAGE_FEE: '仲介手数料',
  ENTERPRISE_ID: '企業ID',
  REPRESENTATIVE_NAME: '代表者名',
  USAGE_SITUATION: '利用状況',
  USAGE_PLAN: 'ご利用プラン',
  PREPAID_USAGE_INFORMATION: '前払い利用情報',
  BROKERAGE_FEE_TOTAL: '仲介手数料(1件あたり)',
};
const CONST_AGENCY_SITE_DETAIL = {
  BASIC_INFORMATION: '基本情報',
  AGENCY_NAME: '企業名',
  CODE: '代理店コード',
  REPRESENTATIVE_NAME: '代表者名',
  PHONE_NUMBER: '電話番号',
  EMAIL: 'メールアドレス',
  AGENCY_FEE: '仲介手数料設定',
  DEPOSIT: 'デポジット',
  REIMBURSEMENT: '立替',
  ACCOUNT_INFORMATION: '口座情報',
  INSTITUTION_NAME: '金融機関名(銀行コード)',
  BRANCH_NAME: '支店名(支店コード)',
  ACCOUNT_TYPE: '口座種類',
  NOMINEE_NAME: '名義人名(カナ)',
  ACCOUNT_NUMBER: '口座番号',
  AGENCY_ACCOUNT_LIST: '代理店一覧',
  AGENCY_ACCOUNT: '導入企業一覧',
  DOCUMENT: '必要書類提出項目',
  POSTAL_CODE: '郵便番号',
  INVOICE_REGISTRATION: 'インボイス登録番号',
  ADDRESS_FIRST: '住所 1',
  ADDRESS_SECOND: '住所 2',
};

const CONST_COMPANY_PROFILE = {
  COMPANY_INFORMATION: '企業情報',
  OWNER_INFORMATION: '代表者情報',
  ACCOUNT_INFORMATION: '口座情報',
  COMPANY_NAME: '企業名',
  ALT: {
    COMPANY: 'Company',
  },
  COMPANY_ID: '企業ID',
  USAGE_PLAN: 'ご利用プラン',
  POST_CODE: '郵便番号',
  ADDRESS_FIRST: '住所１',
  ADDRESS_SECOND: '住所２',
  AGENCY_CODE: '代理店コード',
  AGENCY_NAME: '代理店名',
  REPRESENTATIVE_INFORMATION: '代表者情報',
  REPRESENTATIVE_NAME: '代表者名',
  ADMINISTRATOR_ID: '管理者ID',
  PHONE: '電話番号',
  EMAIL: 'メールアドレス',
  BANK_CODE: '金融機関名(銀行コード)',
  BRANCHES_CODE: '支店名(支店コード)',
  ACCOUNT_TYPE: '口座種類',
  NOMINEE_NAME: '名義人名(カナ)',
  ACCOUNT_NUMBER: '口座番号',
  //
  PLACEHOLDER_PHONE: '090-111-2222',
  PLACEHOLDER_POSTAL_FIRST: '000',
  PLACEHOLDER_POSTAL_SECOND: '0000',
  WRONG_ZIP_COD: '郵便番号が間違っています。',
  INCORRECT_PHONE: '電話番号が間違っています。',
  REQUIRED_POSTAL_CODE: '郵便番号は必要です。',
  POSTAL_CODE: '郵便番号',
};

const CONST_AGENCY_SITE = {
  AGENCY_INDOMATION: '代理店情報',
  BASIC_INFORMATION: '基本情報',
  COMPANY_NAME: '企業名',
  AGENCY_CODE: '代理店コード',
  REPRESENTATIVE: '代表者名',
  NAME: 'お名前',
  PHONE: '電話番号',
  EMAIL: 'メールアドレス',
  BROKERAGE_FEE: '仲介手数料設定',
  DEPOSIT: 'デポジット',
  REIMBURSEMENT: '立替',
  ACCOUNT_INFORMATION: '口座情報',
  BANK_CODE_TITLE: '金融機関名(銀行コード)',
  BANK_CODE_PLACE: '金融機関名またはコードを入力',
  BANK_BRANCH_TITLE: '支店名(支店コード)',
  BANK_BRANCH_PLACE: '支店名またはコードを入力',
  ACCOUNT_TYPE: '口座種類',
  ACCOUNT_NAME_TITLE: '名義人名(カナ)',
  ACCOUNT_NAME_PLACE: 'カ）〇〇〇〇',
  ACCOUNT_TITLE_NUMBER: '口座番号',
  ACCOUNT_TITLE_PLACE: '0000000',
  PLACEHOLDER_PHONE: '090-111-2222',
  // Empty Error
  EMPTY_NAME: 'お名前は必要です',
  EMPTY_PHONE: '電話番号は必須です',
  EMPTY_TEL: '電話番号は必要です',
  EMPTY_BANK: '金融機関名(銀行コード)は必須です',
  EMPTY_BRANCH_BANK: '支店名(支店コード)は必須です',
  EMPTY_ACCOUNT_NAME: '名義人名(カナ)は必須です',
  KATAKANA_ACCOUNT_NAME: 'お名前(カナ)はカタカナのみ有効です',
  EMPTY_ACCOUNT_NUMBER: '口座番号は必須です',
  ENTER_COMPANY_NAME_OR_COMPANY_CODE: '企業名もしくは企業コードを入力',
  ADVANCE_PAYMENT_MANAGER: '前払い　管理人',
  // cover
  INVOICE_REGISTRATION: 'インボイス登録番号',
  INVOICE_REGISTRATION_HOLDER: 'T0-0000-0000-0000',
  INVOICE_REGISTRATION_WRONG_NUMBER: 'インボイス登録番号が間違っています。',
  ADDRESS_FIRST_HOLDER: '〇〇県〇〇市〇〇区〇〇町0丁目0−0',
  ADDRESS: '住所',
  LABEL_ADDRESS_FIRST: '住所 1',
  LABEL_ADDRESS_SECOND: '住所 2',
  TEL: 'Tel',
  FAX_LABEL: 'Fax',
  FAX_HOLDER: '090-111-2222',
};

const CONST_ATTENDANCE_COMPANY = {
  NOON: '昼',
  NIGHT: '夜',
  REST: '休',
  HAFT: '半',
  HAFT_DAY: '半日',
  ATTENDANCE_RECORD: '勤怠実績',
  FAMILY_NAME: '氏名',
  ATTENDANCE: '勤怠',
  CHANGEABLE_DEADLINE: '( 変更可能期限 )',
  GO_TO_WORK: '昼 出勤',
  GO_TO_WORK_NIGHT: '夜 出勤',
  HOLIDAY: '休み',
  ATTENDANCE_CONFIRMATION: '出勤確認',
  ATTENDANCE_NOTE_TEXT: '※本アカウントは代表者アカウントのため確定済みでも出勤を追加できます',
  CONFIRMED: '確定済み',
  DATE: '日付',
  CONFIRMED_BRACKET: '( 確定済み )',
  ATTENDANCE_CONFIRM: '出勤確認(個人)',
  APPLICATION_AMOUNT: '申請額',
  PAYMENT_APPLICATION: '前払い履歴',
  APPROVE: '承認',
  REJECTION: '拒否',
  REJECTED: '拒否済',
  APPROVED: '承認済',
  UNTIL_CONFIRM: '確定まで',
  AMOUNT_CAN_BE_APPLIED: '申請可能額：',
  TOTAL_SALARY: '申請合計',
  PREPAYMENT_HISTORY: '前払い履歴(個人)',
  PREPAYMENT_APPROVE: '前払い申請',
  TRANSFER_FEE: '振込手数料',
  SYSTEM_FEE: '利用料',
  SYSTEM_FEE_TAX: 'システム利用料',
  TOTAL_AMOUNT: '合計金額',
  TOTAL_TITLE: '月合計金額',
  WITHDRAWN: '脱退済み',
  CANCEL_ALREADY: 'キャンセル済',
  ATTENDANCE_MODAL_CONFIRM: '出勤に変更しますか？',
  ATTENDANCE_CANCEL_MODAL_CONFIRM: '出勤をキャンセルしますか？',
  ATTENDANCE_REQUEST_DATE_INVALID: '出勤確認されました。出勤確認が変更できません。',
  ATTENDANCE_STAFF_DISCONNECTED: '従業員が脱退しました。出勤確認ができません。',
  ATTENDANCE_STAFF_NOT_ALLOW_REQUEST_SALARY: '従業員が申請不可のため、出勤確認ができません。',
  ATTENDANCE_STAFF_BLOCK: '従業員アカウントがありません、または削除されました。',
  SUCCESS_TRANSFER: '振込完了',
  FAILED_TRANSFER: '振込失敗',
  NEW_TRANSFER: '振込待機中',
  PROCESSING_TRANSFER: '銀行申請済',
  TRANSFERED_BUT_BANK_NOT_WORKING: '前払い申請がされましたが従業員様ご利用銀行が営業時間外',
  TRANSFERED_BUT_BANK_NOT_WORKING_2: 'またはメンテナンス中だったため翌営業日に振り込まれます。',
  SCHEDULED_TRANSFER_DATE: '振込予定日 ：',
  REGISTER_TIME: '申請分',
  EXPORT_CSV_ALL: 'CSV一括書き出し',
  EXPORT_CSV: 'CSV書き出し',
  PERIOD: '期間',
  CANCEL: 'キャンセル',
};

const CONST_COMPANY_UPLOAD_DOCUMENT = {
  DOCUMENT_STEPS: {
    ACCEPT: 'お申し込み受付',
    UPLOAD: 'アップロード',
    WAIT_REVIEW: '審査中',
    COMPELE_REVIEW: '審査完了',
  },
  GUIDE_TEXT: {
    AGREE_AND_UPLOAD_ALL_DOCUMENT: '各規約の同意と必要書類のアップロードをしてください',
    CONTINUE_AFTER_AGREE_AND_UPLOAD: '利用規約の同意と必要書類をアップロード完了したら「審査依頼」ボタンを押して下さい',
    VERIFYING: 'ただいま審査中です',
    UPLOAD_REQUIRED_DOCUMENTS: '必要書類を下記項目からアップロードしてください',
  },
  REVIEW_REQUEST: '審査依頼',
  SEND_DOCUMENT_AGAIN: '書類再提出',
  REVIEWING: '審査中',
  START_SETTING: '初回設定へ',
  GO_FIRST_SETTING: '初回登録へ',
  PLEASE_AGREE_DOCUMENT: '内容を確認して同意して下さい',
  PLEASE_UPLOAD_DOCUMENT: 'ファイルをアップロードして下さい',
  PLEASE_WAIT_FOR_VERIFYING: '審査が完了するまで、しばらくお待ちください。',
  REVIEW_COMPLETED: '審査が完了しました。',
  PLEASE_CONTINUE_OTHER_SETTING: '「初回設定へ」ボタンより各種設定をおこなって下さい。',
  THE_RESULT_WILL_BE_SENT_TO_YOUR_EMAIL: '結果はご登録のメールアドレスにも送信致します。',
  WAIT_FORM_ONE_TO_TWO_DAY_FOR_VERIFYING:
    '※  審査には、通常1~2日お時間をいただきます。（場合によっては3日以上お時間をいただくことがあります)',
  MAYBE_CANCEL_YOUR_APPLICATION: '※  審査の結果、お申し込みの意に沿えない場合があります。あらかじめご了承ください。',
  WE_REALLY_SORRY: '誠に申し訳ありませんが、弊社審査基準に基づき、',
  WE_CANT_VERIFY_DOCUMENTS: '総合的判断の結果今回はお客様のご意向に沿えないこととなりました。',
  THANK_YOU: '何卒ご了承賜りますようお願い申し上げます。',
  ERROR_FILE_TYPE: 'アップロードファイル形式がpdf/excel/word/jpg/jpeg/pngのみ有効です。',
};

const CONST_AGENCY_VERIFY_DOCUMENT = {
  PLEASE_VERIFY_DOCUMENT: '各利用規約書類への同意をお願いします',
};

const LANGUAGE_COMPANY_SETING = {
  pair_b2b: {
    title: '連携管理',
    titleInputB2B: '連携先メールアドレス入力',
    placeholderInputB2B: 'メールアドレス入力',
    btnSumitB2B: '申請',
    btn_submit_request: '申　請',
    txt_button_ok: '管理画面へ',

    status_request: '申請済',
    status_reject: '申請拒否',
    status_cancel: '申請キャンセル',
    status_confirm: '承認待ち',
    status_pair: '連携中',
    status_disconnect: '連携解除',
    status_delete: '連携解除',

    btn_disconnect: '連携解除', // cancel when paired
    btn_cancel: '申請キャンセル', // cancel when company confirm pair
    btn_reject: '拒　否',
    btn_approved: '承　認',
    btn_delete: '削除',

    txt_confirm_add_pair: '上記企業へ連携申請を行いますか？',
    txt_comfirm_approved: '連携申請を承認しますか？',
    txt_comfirm_reject: '連携申請を拒否しますか？',
    txt_comfirm_cancel: '連携申請をキャンセルしますか？',
    txt_comfirm_disconnect: '上記企業との連携を解除しますか？',
    txt_comfirm_delete: '上記企業との連携を解除しますか？',

    txt_noti_disconnect: '上記企業との連携を解除しました',
    txt_noti_cancel: '連携申請をキャンセルしました',
    txt_noti_add_pair: '上記企業へ連携申請を行いました',
    txt_noti_approved: 'と連携しました',
    txt_noti_reject: '連携申請を拒否しました',
    txt_noti_delete: '上記企業との連携を解除しました',

    txt_company_pair_null: '連携企業がまだありません',
  },
  noti_not_insufficient: 'お客様の口座残高不足により本システムを従業員様が利用できません。',
  please_check_your_bank: '銀行口座の残高をご確認下さい。',
};

const CREATE_NOTIFICATION_OPERATOR = {
  NEW_ARRIVALS: '新着/お知らせ一覧',
  NEW_ANNOUNCEMENT: '新規お知らせ投稿',
  TITLE: 'タイトル',
  ANNOUNCEMENT_TITLE: 'お知らせのタイトル',
  SUBJECT: '対象',
  ALL: '全て',
  AGENCY: '代理店',
  DEPOSIT_PLAN: 'デポジットプラン',
  REIMBURSEMENT_PLAN: '立替プラン',
  TEXT: '本文',
  MULTI_TEXT: '本文',
  CONFIRMATION: '確　認',
  CANCEL: 'キャンセル',
  VALIDATE_TITLE: 'タイトルは必須です。',
  VALIDATE_CONTENT: '本文は必須です。',
  TARGET_REQUIRED: '対象は必要です。',
  CONFIRM_BACK: '入力内容が全て破棄されます。よろしいですか？',
  POST: '投　稿',
  CORRECTION: '修　正',
  NEW_TITLE_LIST: '新着情報 / お知らせ 一覧',
  NEW_POST: '新規投稿',
  NEW_ANNOUNCEMENT_DETAIL: '新規お知らせ詳細',
  CONFIRM_DELETE: '新着情報 / おしらせ を削除しますか？',
  COMPANY_NOTICE: '企業',
  STAFF_NOTICE: '従業員',
  DELETE_NOTICE_SUCCESS: '新着情報 / おしらせ を削除しました。',
};

const LANGUAGE_COMPANY = {
  createStaff: {
    tileUploadFile: '従業員アカウント一括登録（CSV）',
    tileUploadFileProgress: '従業員アカウントの一括発行（CSV）',
    noteForUploadFile: '※一括登録シートは１ファイルずつアップロードして下さい',
    descriptionUploadFile: 'アップロードするファイルをドラック＆ドロップします',
    descriptionUploadFileProgress: 'アップロードファイルを読み込んでいます...',
    uploadFileError: 'ファイルがアップロードできません。',
    uploadFile10MB: 'ファイルの容量が10MBを超えています。',
    uploadFileCSV: 'ファイル形式がCSVのみ有効です。',
    uploadFileNoData: 'インポートファイルにデータを入力してください。',
    uploadFileFormatError: 'アップロードしたファイルのフォーマットが間違っています。',
    btnCancelUploadFile: 'アップロードをキャンセル',
    descriptionDownload: '一括登録シート（CSV）はこちらから',
    txtDownload: 'ダウンロード',
    listStaffTitle: '従業員アカウント一括発行',
    listStaffTopNote: '読み込みが完了したアカウント情報を確認し、発行してください',
    tableName: '氏名',
    tableNameKana: 'フリガナ',
    tablePhone: '電話番号',
    tableEmail: 'メールアドレス',
    tableType: '給与形態',
    tableAmount: '個別設定(申請上限)',
    dayTime: '昼',
    nightTime: '夜',
    dayilyTime: '月',
    monthlyTime: '日',
    btnSubmitViewStaff: '発　行',
    btnCancelStaff: 'キャンセル',
    btnDeleteStaff: '削  除',
    btnRestoreStaff: '復帰',
    errorTxtTop: '一部読み込みに失敗しました。\nエラー項目を入力して下さい。',
    errorTxtFeeThanMore0: '申請上限金額にマイナス値が入力できません',
    successStaffTitle: '従業員アカウント一括発行',
    successStaffTxtSuccess: '従業員アカウントの一括登録が完了しました',
    btnRedirectListStaff: '従業員一覧',
    btnOKConfirm: '閉じる',
    textConfirmOne: '一部読み込みに失敗しました。',
    textConfirmTwo: '再度、エラー箇所を修正して発行して下さい。',
    textSettingSalaryOne: '前払い申請設定が完了しないとモアイ部長を利用できません。',
    textSettingSalaryTwo: '全ての必須項目を入力してから設定を保存して下さい。',
  },
};

const CONST_FOOTSTEPS_HISTORY = {
  OPERATION_LOG: '操作ログ',
};
const CONST_CHAT_MANAGEMENT = {
  INPUT_CHAT_PLACEHOLDER: 'ここにメッセージ内容を入力',
  SEND: '送　信',
  CHANGE_NEW: '更　新',
  GET_MESSAGE_ERROR: 'お問い合わせ履歴が読み込めません。',
  ADD_MESSAGE_ERROR: 'メッセージが送信できません。',
  ERROR_FILE_TYPE: 'ファイル形式がExcel / Word / PowerPoint / CSV / Image / PDF / Videoのみ有効です。',
};
const CONST_TOP_PAGE_COMPANY = {
  DAILY_WORK: '日々の業務',
  PREPAYMENT_APPLICATION: '前払い申請',
  ATTENDANCE_CONFIRMATION: '出勤確認',
  SALARY_TYPE_NOT_SELECT: '給与形態未選択',
  PREPAYMENT_HISTORY: '前払い履歴',
  ATTENDANCE_RECORD: '勤怠実績',
  EMPLOYEE_LIST: '従業員一覧',
  INVOICE: '請求書',
  NUMBER_ADVANCE_PAYMENT: '前払い件数',
  USAGE_AMOUNT: 'ご利用金額',
  USER_AMOUNT: 'ご利用数',
  SUBJECT: '件',
  PREPAID_USAGE: '前払い利用数',
};

const CONST_LIST_INVOICE_COMPANY = {
  LIST_INVOICE: '請求書一覧',
  CONFIRM_INVOICE: '請求書確認',
  AMOUT_FEE: 'ご請求金額',
  INVOICE_CONTENT: '請求書内容',
  INVOICE_DETAIL: '請求書詳細',
  EXPORT_INVOICE: '請求書ダウンロード',
  EXPORT_INVOICE_FEE: '手数料請求書ダウンロード',
  INVOICE_CREATED_AT: '請求書発行日 : ',
  INVOICE: '請求書',
  INVOICE_DATE: '請求書作成日',
  TRANSFER_DEADLINE: '振込期限',
  PROGRESS: '進捗状況',
  AMOUNT_WITH_TAX: '税込金額',
  TEXT_DUE: '未確定',
  CONFIRM_INVOICE_BTN: '請求書確定',
  CONFIRM_INVOICE_BTN_AGENCY: '請求書同意',
  USAGE_INVOICE: '利用請求書',
  STATUS_INVOICE_AGENCY: {
    UNCOMFIRMED: '代理店未確認',
    WAITING_OPERATOR_CONFIRM: '運営確認待ち',
    WAITING_OPERATOR_OTP: '確定済み',
    WAITING_OPERATOR_APPROVED: '確定済み',
    TRANSFER_IN_PROGRESS: '銀行申請済',
    TRANSFER_COMPLETED: '振込済み',
    TRANSFER_COMPLETED_ERROR: '振込失敗',
  },
  WAITING_FOR_CONSENT: '同意待ち',
};

const CONST_OPERATOR_INVOICE = {
  LIST_AGENCY_INVOICE: '代理店請求書一覧',
  LIST_COMPANY_INVOICE: '企業請求書一覧',
  AGENCY_INVOICE_DETAIL: '代理店請求書詳細',
  PLACEHOLDER_SEARCH_AGENCY: '代理店名または代表者名を入力',
  PLACEHOLDER_SEARCH_COMPANY: '企業名を入力',
  PLACEHOLDER_CHOOSE_MONTH: 'クリックして月を選択',
  TOTAL_TRANSFER_AMOUNT: '振込金額合計',
  LIST_INVOICE_AGENCY_TABLE: {
    COMPANY_NAME: '代理店名/代表者名',
    STATUS: '進捗状況',
    BILLING_DATE: '請求日',
    TRANSFER_DEADLINE: '振込期限',
    TRANSFER_AMOUNT: '振込金額',
    TRANSFER_CHECK: '振込チェック',
    msgCantConfirmInvoice: 'こちらの請求書確定ができません。',
    titleModalOTP: '〇〇代理店',
    titleDescriptionOTP: '振込を完了させるためには認証コードを入力してください',
    btnOKOTP: '振込',
    btnCencal: 'キャンセル',
    msgOperatorNotPhone: '電話番号がまだ設定していません',
    msgVerifyOTPFalse: '認証コードが間違っています',
    msgVerifyOTPEmpty: '認証コードは必要です。',
    msgVerifyOTPNotFull: '認証コードが間違っています。',
    msgVerifyOTPSccuess: '認証コードが入力完了しました。',
    msgAgencyNotSettingBank: '指定している代理店の口座情報が設定されていません',
    msgAgencyTransferInprogress: '振込処理を行なっている為、動作ができません。',
  },
  LIST_INVOICE_COMPANY_TABLE: {
    COMPANY_NAME: '会社名',
    BILLING_DATE: '請求日',
    TRANSFER_DEADLINE: '振込期限',
    TRANSFER_AMOUNT: '税込金額',
    USAGE_PLAN: '利用プラン',
    STATUS_CLOSING_DATE_TITLE: 'ステータス',
    STATUS_CLOSING_DATE: '未確定',
    STATUS_CLOSING_DATE_APPROVED: '確定済み',
    TXT_CONFIRM_TRANSACTION_SUCCESS: '振込済みに変更しますか？',
    TXT_CONFIRM_OK: '振込完了',
    TXT_CONFIRM_CANCEL: 'キャンセル',
  },
  DEPOSIT: 'デポジット',
  REIMBURSEMENT: '立替',
};

const CONST_EXPORT_INVOICE = {
  TITLE_INVOICE_GENERAL: '請　求　書（総括）',
  TITLE_INVOICE: '請　求　書',
  GUIDE_NOTE_CREATE_BILL: '下記のとおりご請求申し上げます。',
  GUIDE_NOTE_TRANSFER_BILL: '下記のとおりお振込みいたします。',
  PAY_AMOUNT: 'ご請求金額',
  TAX_INCLUDED: '税込',
  REQUEST_DATE: '請求日 : ',
  REGISTER_NUMBER: '登録番号 : ',
  OPERATOR: '運営',
  OPERATOR_COMPANY_NAME: '運営会社名',
  CUSTOMER_NAME: 'お客様名',
  WITH_NAME: '　氏名',
  COMPANY: '会社名',
  YOUR: ' 御中',
  PHONE_NUMBER: 'Tel：',
  FAX_NUMBER: 'FAX：',
  EMAIL: 'Email：',
  ITEM: '品　　　目',
  QUANTITY: '数量',
  UNIT_PRICE: '単　価',
  PRICE: '価　格',
  ABOUT_PAYMENT: '【お支払いについて】',
  ABOUT_DEADLINE_TRANSFER: 'までに弊社取引銀行宛にお振込をお願い致します。',
  PAYEE: '御振込先：',
  ACCOUNT_NAME: '口座名義：',
  BANK_ACCOUNT_TYPE: {
    USUALLY: '普通',
    CURRENT: '当座',
  },
  SUBTOTAL: '小計(税抜き)',
  CONSUMPTION_TAX: '消費税 10%',
  TOTAL_AMOUNT: '合計金額',
  USAGE_REQUEST: 'ご利用請求',
  TEXT_DUE: '未確定',
  SYSTEM_USAGE_FEE: ' システム利用料',
  SALARY_ADVANCE: ' 給与前払い金',
  SYSTEM_FEE: '　システム利用料（仲介料）',
  FAILED_PREVIOUS_TRANSFER: '前回繰越分',
  EXPORT_FILE_NAME: '請求書',
  EXPORT_ALL_FILE_NAME: '請求書一覧',
  REPRESENTATIVE: '代表取締役社長　',
  TIME_USING: 'ご利用期間',
  SCHEDULED_DATE_INVOIE: '請求書発行予定日',
  EXPECTED_BILL_DATE: '請求予定日',
  PLEASE_BEAR_PAYMENT_FEE: 'お支払いの手数料は恐れ入りますがご負担お願いします。',
};

const CONST_TOP_PAGE_OPERATOR = {
  COMPANY_NAME: '企業名',
  NUMBER_OF_USE: '利用件数',
  YEAR: '年',
  MONTH: '月',
  INQUIRY: 'お問い合わせ',
  CORPORATE_INVOICE: '企業請求書',
  AGENCY_INVOICE: '代理店請求書',
  LIST_COMPANY: '企業一覧',
  NUMBER_ADVANCE_PAYMENT: '前払い件数',
  NUMBER_APPLICATION_TODAY: '本日申請数',
  SUBJECT: '件',
  CUMULATIVE_NUMBER: '累計申請数(今月)',
  SUSPENDED_NUMBER_TITLE: '利用停止企業数',
  SUSPENDED_NUMBER: '利用停止数',
  COMPANY: '社',
};
const CONST_TOP_PAGE_AGENCY = {
  NUMBER_USES: 'ご利用件数',
  USAGE_AMOUNT: 'ご利用金額',
  BROKERAGE_FEE: '仲介手数料',
  COMPANY_NAME: '企業名',
  ADDRESS: '住所',
  SEARCH_INTRODUCED_COMPANY: '導入企業検索',
  SEARCH: '検　索',
  SEARCH_NO_DATA: '検索結果該当する企業はありませんでした。',
  PREPAID_USAGE: '前払い利用数',
  PREPAID_USAGE_AMOUNT: '払い利用金額',
};

export {
  CONST_COMMON,
  CONST_EDIT_OPERATOR,
  CONST_COMPANY_ADMIN_ACCOUNT,
  CONST_CREATE_COMPANY,
  CONST_BREADS,
  CONST_EDIT_AGENCY,
  CONST_SETTING_PAGE,
  CONST_COMPANY_BANK_SETTING,
  CONST_SETTING_SALARY,
  CONST_LIST_COMPANY,
  CONST_OPERATOR_BANK,
  CONST_COMPANY_STAFF_MANAGEMENT,
  LANGUAGE_AGENCY_COMPANY_LIST,
  CONST_AGENCY_SITE_DETAIL,
  CONST_COMPANY_PROFILE,
  CONST_AGENCY_SITE,
  CONST_ATTENDANCE_COMPANY,
  CONST_COMPANY_UPLOAD_DOCUMENT,
  CONST_AGENCY_VERIFY_DOCUMENT,
  LANGUAGE_COMPANY_SETING,
  CREATE_NOTIFICATION_OPERATOR,
  LANGUAGE_COMPANY,
  CONST_FOOTSTEPS_HISTORY,
  CONST_CHAT_MANAGEMENT,
  CONST_TOP_PAGE_COMPANY,
  CONST_LIST_INVOICE_COMPANY,
  CONST_OPERATOR_INVOICE,
  CONST_EXPORT_INVOICE,
  CONST_TOP_PAGE_OPERATOR,
  CONST_TOP_PAGE_AGENCY,
};
