export const isStringEmpty = (txt: string | number) => {
  if (!txt && txt !== 0) return true;
  if (!String(txt).replace(/\s/g, '')?.length) return true;
  return false;
};

export const _atob = (txt: string | number | null | undefined) => {
  if (!txt) return '';
  try {
    return atob(txt.toString());
  } catch (error) {
    return '';
  }
};
