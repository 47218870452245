import { useState, useEffect, useCallback } from 'react';

import { Dayjs } from 'dayjs';
import { IBread } from '@components/Breadcrumb/BreadCrumb';
import { CONST_LIST_INVOICE_COMPANY } from 'constants/language';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { responseSuccess } from 'helper/responseSuccess/responseSuccess';
import { apiCompanyListAllInvoiceDetail } from 'api/operator';
import { EKeyCode } from 'constants/constants';
import { exportPDFsToZipForCompany } from 'helper/export';
import { getDayjsByTimeZone, isRegexMonth } from 'helper/date';
import {
  DEPOSIT_TYPE,
  ICompanyBasicOperatorInvoice,
  ICompanyOperatorListAllDetailInvoice,
  IFilterPage,
  REIMBURSEMENT_TYPE,
  defaultPageInvoiceDetailCompany,
} from 'constants/invoice';
import { useHookCompanyListInvoice } from '@hooks/useOperator';
import { _isNumber } from 'helper';
import { useQueryClient } from 'react-query';
import { COMPANY_INFO_INVOICE_ID, COMPANY_INVOICE_DETAIL_ID } from 'constants/keyQuery';

const useListInvoiceCompany = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const query = new URLSearchParams(search);
  const paramName = query.get('key_word') ?? '';
  const paramPlan = query.get('usage_plan') ?? '';
  const paramDate = query.get('date') ?? '';
  const paramPage = _isNumber(query.get('page'), 1);

  const [paramSearch, setParamsearch] = useState<any>({
    key_word: paramName,
    usage_plan: DEPOSIT_TYPE,
    date: paramDate,
    page: 1,
  });

  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
  const [listInvoice, setListInvoice] = useState<ICompanyBasicOperatorInvoice[]>();
  const [currentMonth, setCurrentMonth] = useState<Dayjs>(getDayjsByTimeZone(paramSearch.date));
  const [currentMonthInput, setCurrentMonthInput] = useState<Dayjs | undefined>(getDayjsByTimeZone(paramSearch.date));
  const [keyWord, setKeyWord] = useState<string>(paramSearch.key_word);
  const [page, setPage] = useState<IFilterPage>({ ...defaultPageInvoiceDetailCompany, per_page: 10 });
  const [listInvoiceAllDetail, setListInvoiceAllDetail] = useState<ICompanyOperatorListAllDetailInvoice[]>();
  const [typeCompany, setTypeCompany] = useState<number>(paramSearch.usage_plan);

  const { data: dataListInvoice, isLoading } = useHookCompanyListInvoice({
    ...paramSearch,
    date: paramSearch?.date ?? getDayjsByTimeZone().format('YYYY-MM'),
  });
  const queryClient = useQueryClient();
  queryClient.removeQueries(COMPANY_INFO_INVOICE_ID);
  queryClient.removeQueries(COMPANY_INVOICE_DETAIL_ID);

  useEffect(() => {
    if (dataListInvoice) {
      setListInvoice(dataListInvoice?.data);
      setPage((prevPage: any) => ({
        ...prevPage,
        total: dataListInvoice?.total,
        page: dataListInvoice?.page,
      }));

      setParamsearch((prevPage: any) => ({
        ...prevPage,
        page: dataListInvoice?.page,
      }));
    }
  }, [dataListInvoice]);

  useEffect(() => {
    let _paramPlan = Number(paramPlan);
    if (_paramPlan === DEPOSIT_TYPE || _paramPlan === REIMBURSEMENT_TYPE) {
      setParamsearch((prevPage: any) => ({
        ...prevPage,
        usage_plan: paramPlan,
      }));
      setTypeCompany(Number(paramPlan));
    }
  }, [paramPlan]);

  useEffect(() => {
    setParamsearch((prevPage: any) => ({
      ...prevPage,
      key_word: paramName,
    }));
    setKeyWord(paramName);
  }, [paramName]);

  useEffect(() => {
    setParamsearch((prevPage: any) => ({
      ...prevPage,
      page: paramPage,
    }));
    setPage((prevPage: any) => ({
      ...prevPage,
      page: paramPage,
    }));
  }, [paramPage]);

  useEffect(() => {
    let _date = getDayjsByTimeZone();
    if (paramDate && isRegexMonth(paramDate)) {
      _date = getDayjsByTimeZone(paramDate);
    }
    setCurrentMonth(_date);
    setCurrentMonthInput(_date);
    setParamsearch((prevPage: any) => ({
      ...prevPage,
      date: _date.format('YYYY-MM'),
    }));
  }, [paramDate, paramSearch.usage_plan]);

  const BREADS: IBread[] = [
    {
      name: CONST_LIST_INVOICE_COMPANY.LIST_INVOICE,
      path: '',
    },
  ];

  const exportAllInvoicePDF = useCallback(async () => {
    if (isLoadingExport) {
      return;
    }
    setIsLoadingExport(true);
    try {
      const response = await apiCompanyListAllInvoiceDetail({
        date: currentMonth?.format('YYYY-MM'),
        key_word: keyWord,
        usage_plan: typeCompany,
      });
      if (responseSuccess(response)) {
        setListInvoiceAllDetail(response.data);
        setTimeout(() => {
          exportPDFsToZipForCompany(response.data);
          setIsLoadingExport(false);
        }, 100);
      }
    } catch (error) {
      //
    }
  }, [isLoadingExport, typeCompany, currentMonth, keyWord]);

  const onChangeTypeCompany = (value: number) => {
    setTypeCompany(value);
    navigate({
      pathname,
      search: `?${createSearchParams({ ...paramSearch, usage_plan: value.toString(), page: 1, key_word: '' })}`,
    });
    setCurrentMonth(getDayjsByTimeZone());
    setParamsearch({
      ...paramSearch,
      date: getDayjsByTimeZone().format('YYYY-MM'),
      usage_plan: value,
      page: 1,
      key_word: '',
    });
  };

  const onChangeMonth = (e: Dayjs | any) => {
    if (e) {
      setCurrentMonth(e);
      setCurrentMonthInput(e);
      navigate({
        pathname,
        search: `?${createSearchParams({ ...paramSearch, date: e?.format('YYYY-MM'), page: 1 })}`,
      });
      setParamsearch({ ...paramSearch, date: e?.format('YYYY-MM'), page: 1 });
    } else {
      setCurrentMonthInput(undefined);
    }
  };

  const onChangeKeyWord = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyWord(e.target.value);
  };

  const changePaging = (pageChange: number) => {
    setPage({
      ...page,
      page: pageChange,
    });
    setParamsearch((prevPage: any) => ({
      ...prevPage,
      page: pageChange,
    }));
    navigate({
      pathname,
      search: `?${createSearchParams({ ...paramSearch, page: pageChange })}`,
    });
  };

  const refeshNumberPage = useCallback(() => {
    navigate({
      pathname,
      search: `?${createSearchParams({ ...paramSearch, key_word: keyWord ?? '', page: 1 })}`,
    });
    setParamsearch({ ...paramSearch, key_word: keyWord ?? '', page: 1 });
    // eslint-disable-next-line
  }, [keyWord]);

  const onKeyDownKeyWord = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === EKeyCode.ENTER) {
        e.preventDefault();
        refeshNumberPage();
      }
    },
    [refeshNumberPage]
  );

  return {
    navigate,
    BREADS,
    listInvoice,
    currentMonth,
    currentMonthInput,
    onChangeMonth,
    page,
    changePaging,
    isLoading,
    typeCompany,
    onChangeTypeCompany,
    listInvoiceAllDetail,
    keyWord,
    onChangeKeyWord,
    onKeyDownKeyWord,
    refeshNumberPage,
    exportAllInvoicePDF,
    isLoadingExport,
    paramSearch,
  };
};

export default useListInvoiceCompany;
