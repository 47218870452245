import { useState, useEffect, useCallback } from 'react';

import { Dayjs } from 'dayjs';
import { IBread } from '@components/Breadcrumb/BreadCrumb';
import { CONST_LIST_INVOICE_COMPANY } from 'constants/language';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { IAgencyBasicOperatorInvoice, IFilterPage, defaultPageInvoiceDetailAgency } from 'constants/invoice';
import { getDayjsByTimeZone, isRegexMonth } from 'helper/date';
import { useHookListInvoice } from '@hooks/useAgency';
import { _isNumber } from 'helper';

const useListInvoiceSiteAgency = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const paramMonth = query.get('month') ?? '';
  const paramPage = _isNumber(query.get('page'), 1);

  const [listInvoice, setListInvoice] = useState<IAgencyBasicOperatorInvoice[]>();
  const [currentMonth, setCurrentMonth] = useState<Dayjs | undefined>();
  const [page, setPage] = useState<IFilterPage>({ ...defaultPageInvoiceDetailAgency, per_page: 10 });

  const [paramSearch, setParamsearch] = useState<any>({
    month: paramMonth,
    page: page.page,
    per_page: 10,
  });

  const { data: dataListInvoice, isLoading } = useHookListInvoice({ ...paramSearch, date: paramSearch.month });

  const BREADS: IBread[] = [
    {
      name: CONST_LIST_INVOICE_COMPANY.LIST_INVOICE,
      path: '',
    },
  ];

  useEffect(() => {
    setListInvoice(dataListInvoice?.data);
    setPage((page) => ({
      ...page,
      total: dataListInvoice?.total ?? 0,
    }));
  }, [dataListInvoice]);

  useEffect(() => {
    setParamsearch((param: any) => ({
      ...param,
      page: paramPage,
    }));
    setPage((page) => ({
      ...page,
      page: paramPage,
    }));
  }, [paramPage]);

  useEffect(() => {
    if (paramMonth && isRegexMonth(paramMonth)) {
      setParamsearch((param: any) => ({
        ...param,
        month: paramMonth,
      }));
      setCurrentMonth(getDayjsByTimeZone(paramMonth));
    } else {
      setParamsearch((param: any) => ({
        ...param,
        month: '',
      }));
      setCurrentMonth(undefined);
    }
  }, [paramMonth]);

  const changePaging = useCallback(
    (pageChange: number) => {
      navigate({
        pathname: `/invoices`,
        search: `?${createSearchParams({ ...paramSearch, page: pageChange })}`,
      });
    },
    [navigate, paramSearch]
  );

  const onChangeMonth = useCallback(
    (e: Dayjs | any) => {
      setCurrentMonth(e);
      navigate({
        pathname: `/invoices`,
        search: `?${createSearchParams({ ...paramSearch, month: e ? e.format('YYYY-MM') : '', page: 1 })}`,
      });
    },
    [navigate, paramSearch]
  );

  return {
    navigate,
    BREADS,
    listInvoice,
    currentMonth,
    onChangeMonth,
    page,
    changePaging,
    isLoading,
  };
};

export default useListInvoiceSiteAgency;
