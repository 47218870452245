import dayjs, { Dayjs } from 'dayjs';
import { DAY_OF_WEEK, HASH_WEEK } from 'constants/company';
import { ITimeAttendance, IWeekType } from '@pages/CompanySite/AttendanceRecord/interface';
import { ESettingTimer, ETimeShiftFormat, ETimeShiftKeys } from 'constants/constants';
import { CONST_ATTENDANCE_COMPANY } from 'constants/language';
import { getDayjsByTimeZone } from 'helper/date';
import { getAllDayOfMonthCycle } from 'helper/getAllDayOfMonth/getAllDayOfMonth';

export const getMaxPage = (month: Dayjs) => {
  const startDay = dayjs(month).startOf('month').day();
  const endDay = dayjs(month).daysInMonth();
  const maxArr = Math.ceil((endDay + startDay) / DAY_OF_WEEK) * DAY_OF_WEEK;
  return Math.ceil(maxArr / DAY_OF_WEEK);
};

export const getMaxPageCycle = (startDate: string | null, endDate: string | null) => {
  const arrDate = getAllDayOfMonthCycle(startDate, endDate);
  return Math.ceil(arrDate.length / DAY_OF_WEEK);
};

export const indexToDay = (index: IWeekType) => {
  return HASH_WEEK[index] || '';
};

export const checkAdminHasTimekeeping = (date: string | Dayjs, timeShift: string, isRootAdmin: boolean) => {
  let dataChoose = date;
  if (typeof dataChoose === 'string') {
    dataChoose = getDayjsByTimeZone(dataChoose);
  }
  if (!isRootAdmin && getDayjsByTimeZone().isAfter(dataChoose.format(timeShift))) return false;
  return true;
};

export const checkDateInCycle = (date: string | Dayjs, cycle_start_date: string) => {
  let dataChoose = date;
  if (typeof dataChoose === 'string') {
    dataChoose = getDayjsByTimeZone(dataChoose);
  }
  if (dataChoose.format('YYYY-MM-DD') < cycle_start_date) return false;
  return true;
};

export const checkOverDate = (date: string | Dayjs, timeShift: string, isRootAdmin: boolean) => {
  let dataChoose = date;
  if (typeof dataChoose === 'string') {
    dataChoose = dayjs(date);
  }
  // if isRootAdmin and current date in now month
  const firstDayOfMonth = dayjs().startOf('month');
  if (isRootAdmin && dataChoose.isAfter(firstDayOfMonth.subtract(1, 'second'))) return false;
  // if not isRootAdmin and current date is over date
  if (dayjs().isAfter(dayjs(date).format(timeShift))) return true;
  return false;
};

export const shiftTxt = (shift: string) => {
  switch (shift) {
    case ETimeShiftKeys.DAY_SHIFT:
      return CONST_ATTENDANCE_COMPANY.NOON;
    case ETimeShiftKeys.NIGHT_SHIFT:
      return CONST_ATTENDANCE_COMPANY.NIGHT;
    case ETimeShiftKeys.MORNING_HALF:
      return CONST_ATTENDANCE_COMPANY.NOON;
    case ETimeShiftKeys.AFTERNOON_HALF:
      return CONST_ATTENDANCE_COMPANY.NIGHT;
    default:
      return '';
  }
};

export const checkCanChangeByRootAdmin = (
  changeData: ITimeAttendance,
  changing: string,
  currentDate: string | Dayjs,
  isRootAdmin: boolean,
  isSetting?: number | null
) => {
  if (!checkDateInCycle(currentDate, changeData.cycle_start_date)) {
    return false;
  }
  if (
    isSetting === ESettingTimer.SETTING_TIMER &&
    changing === ETimeShiftKeys.DAY_SHIFT &&
    !checkAdminHasTimekeeping(currentDate, ETimeShiftFormat.DATE_NIGHT, isRootAdmin)
  ) {
    return false;
  }
  if (
    changing === ETimeShiftKeys.DAY_SHIFT &&
    !checkAdminHasTimekeeping(currentDate, ETimeShiftFormat.DATE_NOON, isRootAdmin)
  ) {
    return false;
  }
  if (
    changing === ETimeShiftKeys.NIGHT_SHIFT &&
    !checkAdminHasTimekeeping(currentDate, ETimeShiftFormat.DATE_NIGHT, isRootAdmin)
  ) {
    return false;
  }
  if (
    changing === ETimeShiftKeys.LEAVE &&
    !checkAdminHasTimekeeping(currentDate, ETimeShiftFormat.DATE_NIGHT, isRootAdmin)
  ) {
    return false;
  }
  return true;
};

export const getLastTimeCanChange = (canChange: boolean, isRootAdmin: boolean, date: string) => {
  if (isRootAdmin) {
    if (canChange) {
      return getDayjsByTimeZone(date).endOf('month');
    } else {
      return false;
    }
  } else {
    if (canChange) {
      return getDayjsByTimeZone(date);
    } else {
      return false;
    }
  }
};
