import React from 'react';
import { CompanyName, DateInvoice, StatusClosingDate, UsagePlan, YenItem } from './listInvoiceTableStyle';
import { CONST_LIST_INVOICE_COMPANY, CONST_OPERATOR_INVOICE } from 'constants/language';
import { ButtonView } from '@containers/AgencySite/InvoiceItem/invoiceItemStyle';
import { formatMoney } from 'helper/formatMoney';
import { formatDateCommon } from 'helper/date';
import { Tooltip } from 'antd';
import { DEPOSIT_TYPE } from 'constants/invoice';
import { isNotDateDueYet } from 'helper';

const USAGE_PLAN_AGENCY_TEXT = ['', CONST_OPERATOR_INVOICE.DEPOSIT, CONST_OPERATOR_INVOICE.REIMBURSEMENT];
export const DataInvoice = ({ item, navigate, paramSearch, isParent = false }: any) => {
  return (
    <>
      <Tooltip placement="leftTop" title={item.company_name}>
        <CompanyName>{item.company_name}</CompanyName>
      </Tooltip>
      <DateInvoice>{formatDateCommon(item.release_date)}</DateInvoice>
      <DateInvoice>{formatDateCommon(item.deadline_transfer)}</DateInvoice>
      <YenItem>{formatMoney(item.total_payment || 0)}</YenItem>
      <UsagePlan>{USAGE_PLAN_AGENCY_TEXT[Number(item.usage_plan) || 0]}</UsagePlan>
      {Number(paramSearch.usage_plan) !== DEPOSIT_TYPE && (
        <StatusClosingDate>
          {isNotDateDueYet(item.closing_date)
            ? CONST_OPERATOR_INVOICE.LIST_INVOICE_COMPANY_TABLE.STATUS_CLOSING_DATE
            : CONST_OPERATOR_INVOICE.LIST_INVOICE_COMPANY_TABLE.STATUS_CLOSING_DATE_APPROVED}
        </StatusClosingDate>
      )}
      <ButtonView
        fontSize="1.25rem"
        fontWeight="500"
        height="2.5rem"
        width="7.5rem"
        borderRadius="0.25rem"
        onClick={() => {
          if (isParent) navigate(`/company-invoices/detail/${item.key_id}_all`);
          else navigate(`/company-invoices/detail/${item.key_id}`);
        }}
      >
        {CONST_LIST_INVOICE_COMPANY.CONFIRM_INVOICE}
      </ButtonView>
    </>
  );
};
