import { useCallback, useState } from 'react';

import { IBread } from '@components/Breadcrumb/BreadCrumb';
import { STATE_PAGE } from '@pages/CompanySite/StaffManagement/useStaffManagement';
import { useNavigate } from 'react-router-dom';
import { CONST_BREADS } from 'constants/language';
import { IAdminAccount } from '@pages/CompanySite/ListAdminAccount/useListAdminAccount';
import { apiCompanyDeleteAdmin } from 'api/company';
import { responseSuccess } from 'helper/responseSuccess/responseSuccess';
import { COMPANY_LIST_ACCOUNT_ADMIN } from 'constants/keyQuery';
import { useQueryClient } from 'react-query';

const useViewAdminAccount = (
  adminAccount: IAdminAccount | null,
  setSelected: React.Dispatch<React.SetStateAction<IAdminAccount | null>>,
  setStatePage: React.Dispatch<React.SetStateAction<number>>
) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [adminAccountName, setAdminAccountName] = useState<string>('');
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false);
  const [isOpenModalSuccess, setIsOpenModalSuccess] = useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);

  const BREADS: IBread[] = [
    {
      name: CONST_BREADS.COMPANY_SITE.LIST_ADMIN_ACCOUNT,
      path: '/admin-account-list',
    },
  ];

  const showPopupConfirmBack = useCallback(
    (isBackHome: boolean = false) => {
      if (isBackHome) {
        navigate('/');
      } else {
        setSelected(null);
        navigate('/admin-account-list');
      }
    },
    [navigate, setSelected]
  );

  const navigateToUpdate = (item: IAdminAccount) => {
    navigate(`/admin-account/edit/${btoa(item?.id.toString())}`, {
      state: item,
    });
    setStatePage(STATE_PAGE.EDIT);
  };

  const confirmDelete = useCallback(() => {
    if (adminAccount?.full_name) {
      setAdminAccountName(adminAccount?.full_name);
      setIsOpenModalConfirm(true);
    }
  }, [adminAccount]);

  const deleteAdmin = useCallback(async () => {
    if (adminAccount?.id) {
      setIsLoadingDelete(true);
      const response = await apiCompanyDeleteAdmin(adminAccount?.id);
      if (responseSuccess(response)) {
        setIsOpenModalConfirm(false);
        setIsOpenModalSuccess(true);
      } else {
        setIsOpenModalConfirm(false);
      }
      setIsLoadingDelete(false);
    }
  }, [adminAccount]);

  const successDelete = useCallback(() => {
    setIsOpenModalSuccess(false);
    setSelected(null);
    navigate('/admin-account-list');
    queryClient.refetchQueries(COMPANY_LIST_ACCOUNT_ADMIN);
  }, [navigate, queryClient, setSelected]);

  return {
    BREADS,
    showPopupConfirmBack,
    navigateToUpdate,
    isOpenModalConfirm,
    setIsOpenModalConfirm,
    isOpenModalSuccess,
    setIsOpenModalSuccess,
    adminAccountName,
    confirmDelete,
    isLoadingDelete,
    deleteAdmin,
    successDelete,
  };
};

export default useViewAdminAccount;
