import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ICompanyStaffBasicInformation, STATE_PAGE } from '@pages/CompanySite/StaffManagement/useStaffManagement';
import { ECompanyStaffStatusType } from 'constants/constants';
import images from '@assets/images-base';

const useSideBarStaff = (
  setStatePage: React.Dispatch<React.SetStateAction<number>>,
  setSelected: (company: ICompanyStaffBasicInformation | null) => void,
  activeStatus?: number,
  setActiveStatus?: React.Dispatch<React.SetStateAction<number>>
) => {
  const { pathname, search } = useLocation();
  const query = new URLSearchParams(search);
  const paramSerach = query.get('key_word') ?? '';
  const paramType = query.get('type') ?? '';

  const navigate = useNavigate();
  const toCreateStaff = () => {
    navigate('/staff-list/create');
    setSelected(null);
    setStatePage(STATE_PAGE.CREATE);
  };

  const onSelectItem = (staff: ICompanyStaffBasicInformation) => {
    setSelected(staff);
    navigate({
      pathname: `/staff-list/${btoa(staff?.id.toString())}`,
      search: `?key_word=${paramSerach}&type=${paramType}`,
    });
    setStatePage(STATE_PAGE.VIEW);
  };

  const filterStatus = useCallback(
    (status: number) => {
      if (!setActiveStatus) return;
      let _status: number = status;
      if (status && status !== ECompanyStaffStatusType.USING && status !== ECompanyStaffStatusType.DELETED) {
        _status = ECompanyStaffStatusType.NONE;
      }
      if (activeStatus === _status) {
        setActiveStatus(ECompanyStaffStatusType.NONE);
        navigate({
          pathname: pathname,
          search: `?key_word=${paramSerach}&type=`,
        });
        return;
      }
      setActiveStatus(_status);
      navigate({
        pathname: pathname,
        search: `?key_word=&${paramSerach}type=${_status}`,
      });
    },
    [activeStatus, navigate, paramSerach, setActiveStatus, pathname]
  );

  const getIconItemStaff = (element: ICompanyStaffBasicInformation, selected: ICompanyStaffBasicInformation | null) => {
    if (
      element?.status === ECompanyStaffStatusType.NOT_ACCESS ||
      element?.status === ECompanyStaffStatusType.REJECT ||
      element?.status === ECompanyStaffStatusType.STAFF_WAITING_APPROVE
    ) {
      return (
        <img
          src={selected?.id === element.id ? images.companySite.warningRed : images.companySite.warningWhite}
          alt=""
        />
      );
    }
    return undefined;
  };

  const toManyStaff = () => {
    navigate('/staff-list/upload-staff');
    setSelected(null);
    setStatePage(STATE_PAGE.CREATE_MANY);
  };

  return {
    toCreateStaff,
    onToManyStaff: toManyStaff,
    onSelectItem,
    filterStatus,
    getIconItemStaff,
  };
};

export default useSideBarStaff;
