import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import {
  EApprovalMethod,
  ECompanyStaffSalaryType,
  ESettingTimer,
  EStatusCheckCompanyDocument,
  EStatusCheckPrivacyPolicy,
} from 'constants/constants';
import { responseSuccess } from 'helper/responseSuccess/responseSuccess';
import { apiCompanySalaryDetail, apiCompanySalarySetting, apiConfirmCompanySalarySetting } from 'api/company';
import { useAppSelector } from '@hooks/useSelector/useAppSelector';
import { useDispatch } from 'react-redux';
import { storeSetAuth } from '@store/auth-reducer';
import { ISalaryAdvanceData } from './interface';
import { CONST_BREADS, CONST_COMMON, CONST_SETTING_SALARY } from 'constants/language';
import { Form, message } from 'antd';
import { onBlurNumber } from 'helper/text';
import { FormInstance } from 'antd/lib/form/Form';
import { trimSpaceInput } from 'helper';
import { IBread } from '@components/Breadcrumb/BreadCrumb';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { showConfirm } from 'helper/modal-confirm';
import {
  COMPANY_ADMIN_GET_ATTENDANCE,
  COMPANY_LIST_STAFF_ATTENDANCE,
  COMPANY_SETTING_SALARY_DETAIL,
} from 'constants/keyQuery';
import { useCompanySettingSalaryLast } from '@hooks/useCompany';
import { getDayjsByTimeZone } from 'helper/date';
import Cookies from 'js-cookie';

const SalaryAdvanceDetailDefault: ISalaryAdvanceData = {
  prepaid_salary_morning: undefined,
  prepaid_salary_afternoon: undefined,
  prepaid_salary_month: undefined,
  prepaid_salary_day: undefined,
  salary_day: undefined,
  is_time_closing: ESettingTimer.SETTING_STATIC,
  closing_hour: null,
  closing_minute: null,
};

const useSalaryAdvance = ({ firstTime = false }: { firstTime: boolean }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authInfo = useAppSelector((state) => state.auth.authInfo);
  const [form]: FormInstance<any>[] = Form.useForm();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [salaryAdvanceData, setSalaryAdvanceData] = useState<ISalaryAdvanceData>(SalaryAdvanceDetailDefault);
  const [isSalaryDayEndMonth, setIsSalaryDayEndMonth] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [salaryType, setSalaryType] = useState<any>({
    day: EApprovalMethod.AUTO_APPROVAL,
    hour: EApprovalMethod.AUTO_APPROVAL,
    month: EApprovalMethod.AUTO_APPROVAL,
  });
  const BREADS: IBread[] = [
    {
      name: CONST_BREADS.COMPANY_SITE.SALARY_SETTING,
      path: '',
    },
  ];

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      form.setFields([
        {
          name: e.target.name,
          errors: undefined,
        },
      ]);
    },
    [form]
  );

  const handleStringToNumber = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>, length?: number, nullable?: boolean) => {
      form.setFieldsValue({
        [e.target.name]: onBlurNumber(e.target.value, length) || '',
      });
    },
    [form]
  );

  const handleTrimSpaceInput = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>, length?: number) => {
      form.setFieldsValue({
        [e.target.name]: trimSpaceInput(e.target.value, length),
      });
    },
    [form]
  );

  const onChangeSalaryInputEndMonth = useCallback((e: CheckboxChangeEvent) => {
    setIsSalaryDayEndMonth(e.target.checked);
  }, []);

  const updateSalaryAdvanceSetting = async (data: ISalaryAdvanceData, is_Confirm: Number) => {
    let payload: any = data;
    payload.is_confirm_alert = is_Confirm;
    try {
      setIsLoading(true);
      const response = await apiCompanySalarySetting(payload);
      if (responseSuccess(response)) {
        const dataTemp: any = { ...authInfo };
        const updateAuthInfo = {
          ...dataTemp,
          company: {
            ...dataTemp?.company,
            company_setting: response.data,
          },
        };
        dispatch(storeSetAuth(updateAuthInfo));
        if (firstTime) {
          if (updateAuthInfo?.company?.is_setting_bank) {
            navigate('/');
          } else {
            navigate('/setting/edit/bank');
          }
        } else {
          if (response.data?.is_alert) {
            setIsOpen(true);
          } else {
            message.success(CONST_COMMON.SETTING_SUCCESS);
          }
          setSalaryAdvanceData({
            ...response.data,
            closing_minute: response.data.closing_minute
              ? response.data.closing_minute
              : response.data.closing_hour
              ? 0
              : ESettingTimer.SETTING_TIMER_MINUTE_DEFAULT,
          });
          setIsSalaryDayEndMonth(!!response.data?.salary_day_end_month);
        }
        Cookies.remove('isSettingSalary');
        queryClient.refetchQueries(COMPANY_ADMIN_GET_ATTENDANCE);
        queryClient.refetchQueries(COMPANY_SETTING_SALARY_DETAIL);
        queryClient.refetchQueries(COMPANY_LIST_STAFF_ATTENDANCE);
      } else {
        message.error(CONST_COMMON.SYSTEM_ERROR);
      }
    } catch {
      message.error(CONST_COMMON.SYSTEM_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const getBankSettingDetail = async () => {
    try {
      setIsLoading(true);
      const response = await apiCompanySalaryDetail();
      if (responseSuccess(response)) {
        setSalaryType({
          day: response.data?.salary_type_day ? response.data?.salary_type_day : EApprovalMethod.AUTO_APPROVAL,
          hour: response.data?.salary_type_hour ? response.data?.salary_type_hour : EApprovalMethod.AUTO_APPROVAL,
          month: response.data?.salary_type_month ? response.data?.salary_type_month : EApprovalMethod.AUTO_APPROVAL,
        });
        setSalaryAdvanceData({
          ...response.data,
          closing_minute: response.data.closing_minute
            ? response.data.closing_minute
            : response.data.closing_hour
            ? 0
            : ESettingTimer.SETTING_TIMER_MINUTE_DEFAULT,
        });
        setIsSalaryDayEndMonth(!!response.data?.salary_day_end_month);
      }
    } catch {
      message.error(CONST_COMMON.SYSTEM_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!firstTime) {
      getBankSettingDetail();
    }
  }, [firstTime]);

  const onFinishForm = (value: any) => {
    if (
      !firstTime &&
      salaryAdvanceData &&
      Number(salaryAdvanceData.is_time_closing) !== Number(value.is_time_closing)
    ) {
      showConfirm({
        className: 'popup-confirm-company-setting-salary',
        okText: CONST_COMMON.OK_JP,
        content: (
          <div>
            <p>{CONST_SETTING_SALARY.SETTING_SALARY_TIMER_DESCRIPTION1}</p>
            <p>{CONST_SETTING_SALARY.SETTING_SALARY_TIMER_DESCRIPTION2}</p>
            <p className="description3">{CONST_SETTING_SALARY.SETTING_SALARY_TIMER_DESCRIPTION3}</p>
          </div>
        ),
        onOk: () => onSubmitForm(value),
      });
    } else {
      onSubmitForm(value);
    }
  };

  const { data: dataSettingLast, isLoading: isLoadingSettingLast } = useCompanySettingSalaryLast(
    Number(authInfo?.company?.id)
  );
  const changeTypeSalary = (typeSalary: any, type: any) => {
    setSalaryType((prevSalaryType: any) => {
      const newSalaryType = { ...prevSalaryType };
      if (type === ECompanyStaffSalaryType.HOUR_SALARY) {
        newSalaryType.hour = typeSalary;
      } else if (type === ECompanyStaffSalaryType.DAILY_SALARY) {
        newSalaryType.day = typeSalary;
      } else {
        newSalaryType.month = typeSalary;
      }
      return newSalaryType;
    });
  };

  const onSubmitForm = useCallback(
    async (values: any) => {
      const dataForm: ISalaryAdvanceData = {
        prepaid_salary_morning: values?.prepaid_salary_morning,
        prepaid_salary_afternoon: values?.prepaid_salary_afternoon,
        prepaid_salary_morning_half: values?.prepaid_salary_morning_half,
        prepaid_salary_afternoon_half: values?.prepaid_salary_afternoon_half,
        prepaid_salary_morning_working_time: values?.prepaid_salary_morning_working_time,
        prepaid_salary_afternoon_working_time: values?.prepaid_salary_afternoon_working_time,
        prepaid_salary_month: values?.prepaid_salary_month,
        prepaid_salary_day: values?.prepaid_salary_day,
        salary_type_day: salaryType.day,
        salary_type_month: salaryType.month,
        salary_type_hour: salaryType.hour,
        salary_day: values?.salary_day,
        salary_day_end_month: isSalaryDayEndMonth ? 1 : 0,
        is_time_closing: values?.is_time_closing,
        closing_hour: values?.closing_hour,
        closing_minute: values?.closing_minute,
      };
      try {
        const response = await apiConfirmCompanySalarySetting(dataForm);
        let startDate: any = dataSettingLast?.end_date
          ? getDayjsByTimeZone(dataSettingLast?.end_date).add(1, 'day').format('YYYY年MM月DD日')
          : '';

        if (response.data?.is_alert === 1) {
          showConfirm({
            className: 'popup-confirm-company-setting-salary',
            okText: CONST_COMMON.CONFIRM,
            content: (
              <div>
                <p className="textTitle">{CONST_SETTING_SALARY.CONFIRM_SETTING_SALARY1}</p>
                <div className="textConfirm">{`${CONST_SETTING_SALARY.PREVENT_PAYMENT}\n${startDate}${CONST_SETTING_SALARY.WILL_BE_EMPTY}`}</div>
                <p className="">{CONST_SETTING_SALARY.SETTING_SALARY_TIMER_DESCRIPTION3}</p>
              </div>
            ),
            onOk: () => updateSalaryAdvanceSetting(dataForm, response?.data?.is_alert),
          });
        } else {
          updateSalaryAdvanceSetting(dataForm, response?.data?.is_alert);
        }
      } catch {}
    },
    // eslint-disable-next-line
    [isSalaryDayEndMonth, salaryType]
  );

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line
  }, [salaryAdvanceData]);

  useEffect(() => {
    if (
      firstTime &&
      (authInfo?.regulations_status !== EStatusCheckPrivacyPolicy.AGREE ||
        authInfo?.company?.documents?.filter(
          (document: any) => document?.status !== EStatusCheckCompanyDocument.VERIFIED
        )?.length > 0)
    ) {
      navigate('/company/upload-document');
    }
  }, [authInfo, firstTime, navigate]);

  return {
    form,
    salaryAdvanceData,
    isLoading: isLoading || isLoadingSettingLast,
    navigate,
    handleStringToNumber,
    handleTrimSpaceInput,
    onFinishForm,
    handleOnChange,
    BREADS,
    isSalaryDayEndMonth,
    onChangeSalaryInputEndMonth,
    isOpen,
    setIsOpen,
    salaryType,
    changeTypeSalary,
  };
};

export default useSalaryAdvance;
