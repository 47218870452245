import {
  ICompanySettingSalaryDetail,
  ICycleCompanySettingSalary,
  IStaff,
} from '@pages/CompanySite/AttendanceRecord/interface';
import { colors } from 'constants/colorsBase';
import {
  ECompanyStaffSalaryType,
  ESettingTimer,
  ETimeShift,
  ETimeShiftFormat,
  ETimeShiftKeys,
} from 'constants/constants';
import { CONST_ATTENDANCE_COMPANY, CONST_COMMON } from 'constants/language';
import dayjs, { Dayjs } from 'dayjs';
import React, { useState } from 'react';
import AttendanceHeaderTable from '../AttendanceHeaderTable/AttendanceHeaderTable';
import {
  AttendanceHeaderTableWrapper,
  DayTable,
  NameTable,
  Confirmed,
  DateContent,
} from '../AttendanceHeaderTable/attendanceHeaderTableStyle';
import { ActiveItem, AttendanceTableWrapper, StatusActive, TableName } from './attendanceTableStyle';
import useAttendanceTable from './useAttendanceTable';
import { useAppSelector } from '@hooks/useSelector/useAppSelector';
import ModalCommon from '@components/Modal/ModalCommon';
import { ModalContainer } from '@pages/CompanySite/CompanyB2B/companyB2BStyle';
import { ModalContent } from '@components/common/Modal/style';
import { shiftTxt } from 'helper/attendanceRecord';
import { getDayjsByTimeZone } from 'helper/date';
import { USER_TYPE_IP_SUPPORT, USER_TYPE_OWNER } from 'constants/User';
import CountDownDate from '../../../../components/CountdownTimer/CountDownDate';
import { disableBtnTimekeeping } from '../MemberTable/MemberTable';
import AttendanceForHour from './AttendancenForHour';
import styled from 'styled-components';
import { Tooltip } from 'antd';

interface IActiveItemComponent {
  title: string;
  shift: 'day_shift' | 'night_shift' | 'leave' | 'morning_haft_shift' | 'afternoon_haft_shift';
  element: IStaff;
  color: string;
  isForceInActive: boolean;
}
interface IAttendanceTable {
  attendanceDetailData: IStaff[];
  currentDate: Dayjs;
  dataCompanySettingSalaryDetail: ICompanySettingSalaryDetail;
  isLoadingCompanySettingSalaryDetail: boolean;
  cycleCompanySettingSalary: ICycleCompanySettingSalary;
  setAttendanceDetailData: any;
}
const StatusWorkTableHour = styled.div`
  width: 15.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StatusWorkHaftDay = styled.div`
  width: 17.5rem;
`;
export const checkDayShift = (currentDate: any) => {
  if (dayjs().isAfter(currentDate.format(ETimeShiftFormat.DATE_NOON))) return true;
  return false;
};
export const checkNightShift = (currentDate: any) => {
  if (dayjs().isAfter(currentDate.format(ETimeShiftFormat.DATE_NIGHT))) return true;
  return false;
};
export const renderStaticSettingHourNoon = (currentDate: any, time: any, dateTime: any) => {
  if (dateTime && dateTime < dayjs().format('YYYY-MM-DD HH:mm:ss')) {
    return <Confirmed style={{ marginLeft: '5rem' }}>{CONST_ATTENDANCE_COMPANY.CONFIRMED}</Confirmed>;
  }
  if (
    currentDate &&
    dayjs(currentDate).format('YYYY-MM-DD 11:59') <= dayjs().format('YYYY-MM-DD HH:mm') &&
    dateTime &&
    dateTime >= dayjs().format('YYYY-MM-DD HH:mm:ss')
  ) {
    return (
      <div style={{ display: 'flex' }}>
        <DateContent style={{ marginRight: '1rem' }}>{dayjs().format('YYYY/MM/DD')}</DateContent>
        <DateContent>
          {dayjs(dateTime).format('HH:mm') === '11:59' ? ETimeShiftFormat.NOON : ETimeShiftFormat.NIGHT}
        </DateContent>
      </div>
    );
  }
  return (
    <>
      {!checkDayShift(currentDate) && (
        <div style={{ display: 'flex' }}>
          <DateContent style={{ marginRight: '1rem' }}>{currentDate.format('YYYY/MM/DD')}</DateContent>
          <DateContent>{time}</DateContent>
        </div>
      )}
      {checkDayShift(currentDate) && (
        <Confirmed style={{ marginLeft: '5rem' }}>{CONST_ATTENDANCE_COMPANY.CONFIRMED}</Confirmed>
      )}
    </>
  );
};
export const renderStaticSettingHourNight = (currentDate: any, time: any, dateTime: any) => {
  if (dateTime && dateTime < dayjs().format('YYYY-MM-DD HH:mm:ss')) {
    return <Confirmed style={{ marginLeft: '5rem' }}>{CONST_ATTENDANCE_COMPANY.CONFIRMED}</Confirmed>;
  }
  if (
    currentDate &&
    dayjs(currentDate).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD') &&
    dateTime &&
    dateTime >= dayjs().format('YYYY-MM-DD HH:mm:ss')
  ) {
    return (
      <div style={{ display: 'flex' }}>
        <DateContent style={{ marginRight: '1rem' }}>{dayjs().format('YYYY/MM/DD')}</DateContent>
        <DateContent>
          {dayjs(dateTime).format('HH:mm') === '11:59' ? ETimeShiftFormat.NOON : ETimeShiftFormat.NIGHT}
        </DateContent>
      </div>
    );
  }
  return (
    <>
      {!checkNightShift(currentDate) && (
        <div style={{ display: 'flex' }}>
          <DateContent style={{ marginRight: '1rem' }}>{currentDate.format('YYYY/MM/DD')}</DateContent>
          <DateContent>{time}</DateContent>
        </div>
      )}
      {checkNightShift(currentDate) && (
        <Confirmed style={{ marginLeft: '5rem' }}>{CONST_ATTENDANCE_COMPANY.CONFIRMED}</Confirmed>
      )}
    </>
  );
};
export const renderColorBackground = (salary_type: number) => {
  if (salary_type === ECompanyStaffSalaryType.HOUR_SALARY) {
    return '#53B889';
  } else if (salary_type === ECompanyStaffSalaryType.DAILY_SALARY) {
    return '#FDAB29';
  } else {
    return '#47CAD2';
  }
};

export const StatusItem = ({
  element,
  type,
  marginTop,
}: {
  element: {
    day_shift: number;
    night_shift: number;
    leave: number;
    morning_working_time_shift_time: number;
    afternoon_working_time_shift_time: number;
    morning_haft_shift: number;
    afternoon_haft_shift: number;
  };
  type?: number;
  marginTop?: string;
}) => {
  return (
    <>
      {(type === ECompanyStaffSalaryType.MONTHLY_SALARY || type === ECompanyStaffSalaryType.HOUR_SALARY) && (
        <StatusWorkTableHour>
          {(element?.day_shift === ETimeShift.ACTIVITY || element?.morning_working_time_shift_time > 0) && (
            <StatusActive color={colors.dayShift}>{CONST_ATTENDANCE_COMPANY.GO_TO_WORK}</StatusActive>
          )}
          {(element?.night_shift === ETimeShift.ACTIVITY || element?.afternoon_working_time_shift_time > 0) && (
            <StatusActive color={colors.nightShift}>{CONST_ATTENDANCE_COMPANY.GO_TO_WORK_NIGHT}</StatusActive>
          )}
        </StatusWorkTableHour>
      )}

      {type === ECompanyStaffSalaryType.DAILY_SALARY && (
        <StatusWorkHaftDay style={{ marginTop: `${marginTop}` }}>
          <div style={{ display: 'flex' }}>
            {element?.day_shift === ETimeShift.ACTIVITY && (
              <StatusActive color={colors.dayShift} style={{ marginRight: '0.5rem' }}>
                {CONST_ATTENDANCE_COMPANY.GO_TO_WORK}
              </StatusActive>
            )}
            {element?.morning_haft_shift === ETimeShift.ACTIVITY && (
              <StatusActive color={colors.leaveShift}>{CONST_ATTENDANCE_COMPANY.HAFT_DAY}</StatusActive>
            )}
          </div>
          <div style={{ display: 'flex' }}>
            {element?.night_shift === ETimeShift.ACTIVITY && (
              <StatusActive color={colors.nightShift} style={{ marginRight: '0.5rem' }}>
                {CONST_ATTENDANCE_COMPANY.GO_TO_WORK_NIGHT}
              </StatusActive>
            )}
            {element?.afternoon_haft_shift === ETimeShift.ACTIVITY && (
              <StatusActive color={colors.leaveShift}>{CONST_ATTENDANCE_COMPANY.HAFT_DAY}</StatusActive>
            )}
          </div>
        </StatusWorkHaftDay>
      )}
    </>
  );
};

let temp: any = null;

export const AttendanceTable = ({
  attendanceDetailData,
  currentDate,
  dataCompanySettingSalaryDetail,
  isLoadingCompanySettingSalaryDetail,
  cycleCompanySettingSalary,
  setAttendanceDetailData,
}: IAttendanceTable) => {
  const authInfo = useAppSelector((state) => state.auth.authInfo);
  const companyIdLeague = useAppSelector((state) => state.auth.companyIdLeague);
  const isOwner = !(companyIdLeague && companyIdLeague !== authInfo?.company?.id);
  const isRootAdmin = authInfo?.user?.type === USER_TYPE_OWNER;

  const {
    handleAnalysisColorDay,
    changeTime,
    isLoading,
    toDetailMember,
    handleCancelConfirm,
    handleOkConfirm,
    isShowConfirm,
    setIsShowConfirm,
    confirmChanging,
    textConfirm,
  } = useAttendanceTable({
    currentDate,
    isRootAdmin,
    setAttendanceDetailData,
    attendanceDetailData,
  });
  const [stateHour, setStateHour] = useState<number>(0);
  temp = setStateHour;

  const ActiveItemComponent = ({ title, shift, element, color, isForceInActive = false }: IActiveItemComponent) => {
    const isActive = element?.time_attendance[0] ? element?.time_attendance[0][`${shift}`] : ETimeShift.NOT_ACTIVITY;
    let changeData: any = {};
    if (element.salary_type === ECompanyStaffSalaryType.DAILY_SALARY) {
      changeData = {
        staff_id: element.staff_id,
        date: currentDate.format('YYYY-MM-DD'),
        day_shift: element?.time_attendance[0]?.day_shift || ETimeShift.NOT_ACTIVITY,
        night_shift: element?.time_attendance[0]?.night_shift || ETimeShift.NOT_ACTIVITY,
        morning_haft_shift: element?.time_attendance[0]?.morning_haft_shift || ETimeShift.NOT_ACTIVITY,
        afternoon_haft_shift: element?.time_attendance[0]?.afternoon_haft_shift || ETimeShift.NOT_ACTIVITY,
        leave: element?.time_attendance[0]?.leave || ETimeShift.NOT_ACTIVITY,
        [`${shift}`]: isActive ? ETimeShift.NOT_ACTIVITY : ETimeShift.ACTIVITY,
        cycle_start_date: cycleCompanySettingSalary.start_date,
        cycle_end_date: cycleCompanySettingSalary.end_date,
        morning_working_time_shift_time: ETimeShift.NOT_ACTIVITY,
        afternoon_working_time_shift_time: ETimeShift.NOT_ACTIVITY,
        salary_type: element.salary_type,
      };
    } else if (element.salary_type === ECompanyStaffSalaryType.MONTHLY_SALARY) {
      changeData = {
        staff_id: element.staff_id,
        date: currentDate.format('YYYY-MM-DD'),
        day_shift: element?.time_attendance[0]?.day_shift || ETimeShift.NOT_ACTIVITY,
        night_shift: element?.time_attendance[0]?.night_shift || ETimeShift.NOT_ACTIVITY,
        morning_haft_shift: ETimeShift.NOT_ACTIVITY,
        afternoon_haft_shift: ETimeShift.NOT_ACTIVITY,
        leave: element?.time_attendance[0]?.leave || ETimeShift.NOT_ACTIVITY,
        [`${shift}`]: isActive ? ETimeShift.NOT_ACTIVITY : ETimeShift.ACTIVITY,
        cycle_start_date: cycleCompanySettingSalary.start_date,
        cycle_end_date: cycleCompanySettingSalary.end_date,
        morning_working_time_shift_time: ETimeShift.NOT_ACTIVITY,
        afternoon_working_time_shift_time: ETimeShift.NOT_ACTIVITY,
        salary_type: element.salary_type,
      };
    }

    let forceInActive = isForceInActive;
    const now = getDayjsByTimeZone().add(2, 'second');
    if (shift === ETimeShiftKeys.DAY_SHIFT) {
      if (
        element?.time_attendance[0]?.closing_morning_haft &&
        now.isAfter(getDayjsByTimeZone(element?.time_attendance[0]?.closing_morning_haft))
      ) {
        forceInActive = true;
      } else if (element?.time_attendance[0]?.closing_day_shift_salary) {
        if (
          element?.salary_type === ECompanyStaffSalaryType.MONTHLY_SALARY &&
          dayjs(currentDate).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD')
        ) {
          forceInActive = true;
        } else {
          const closingTime = getDayjsByTimeZone(element?.time_attendance[0]?.closing_day_shift_salary);
          forceInActive = now.isAfter(closingTime) || isForceInActive;
        }
      }
    }
    if (shift === ETimeShiftKeys.MORNING_HALF) {
      if (
        element?.time_attendance[0]?.closing_day_shift_salary &&
        now.isAfter(getDayjsByTimeZone(element?.time_attendance[0]?.closing_day_shift_salary))
      ) {
        forceInActive = true;
      } else if (element?.time_attendance[0]?.closing_morning_haft) {
        const closingTime = getDayjsByTimeZone(element?.time_attendance[0]?.closing_morning_haft);
        forceInActive = now.isAfter(closingTime) || isForceInActive;
      }
    }
    if (shift === ETimeShiftKeys.NIGHT_SHIFT) {
      if (
        element?.time_attendance[0]?.closing_afternoon_haft &&
        now.isAfter(getDayjsByTimeZone(element?.time_attendance[0]?.closing_afternoon_haft))
      ) {
        forceInActive = true;
      } else if (element?.time_attendance[0]?.closing_night_shift_salary) {
        if (
          element?.salary_type === ECompanyStaffSalaryType.MONTHLY_SALARY &&
          dayjs(currentDate).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD')
        ) {
          forceInActive = true;
        } else {
          const closingTime = getDayjsByTimeZone(element?.time_attendance[0]?.closing_night_shift_salary);
          forceInActive = now.isAfter(closingTime) || isForceInActive;
        }
      }
    }
    if (shift === ETimeShiftKeys.AFTERNOON_HALF) {
      if (
        element?.time_attendance[0]?.closing_night_shift_salary &&
        now.isAfter(getDayjsByTimeZone(element?.time_attendance[0]?.closing_night_shift_salary))
      ) {
        forceInActive = true;
      } else if (element?.time_attendance[0]?.closing_afternoon_haft) {
        const closingTime = getDayjsByTimeZone(element?.time_attendance[0]?.closing_afternoon_haft);
        forceInActive = now.isAfter(closingTime) || isForceInActive;
      }
    }

    return (
      <>
        <DayTable className="table-body-day">
          <ActiveItem
            isLoading={isLoading}
            activeData={handleAnalysisColorDay(changeData, isActive, color, shift, forceInActive)}
            isOwner={isOwner}
            onClick={() => {
              if (forceInActive) return;
              if (isOwner || authInfo?.user.type === USER_TYPE_IP_SUPPORT || !forceInActive) {
                if (shift === ETimeShiftKeys.MORNING_HALF) {
                  changeData.day_shift = ETimeShift.NOT_ACTIVITY;
                }
                if (shift === ETimeShiftKeys.AFTERNOON_HALF) {
                  changeData.night_shift = ETimeShift.NOT_ACTIVITY;
                }
                if (changeData[shift] === ETimeShift.NOT_ACTIVITY && element.salary_type === 1) {
                  if (shift === 'day_shift') {
                    changeData.morning_haft_shift = ETimeShift.NOT_ACTIVITY;
                  } else if (shift === 'night_shift') {
                    changeData.afternoon_haft_shift = ETimeShift.NOT_ACTIVITY;
                  }
                }
                changeTime(changeData, shift, dataCompanySettingSalaryDetail?.is_time_closing);
              }
            }}
          >
            {title}
          </ActiveItem>
        </DayTable>
        <div style={{ color: '#fff' }}>{stateHour}</div>
      </>
    );
  };

  const isStaffDisconnect = (element: IStaff) => {
    if (element && (element?.deleted_at || element?.staff_disconnect_time)) {
      return true;
    }
    return false;
  };

  const getTimeCountDown = (
    isSettingTimer: number | undefined,
    dateTime: string | null | undefined,
    element?: any,
    key?: any
  ) => {
    if (element?.salary_type === ECompanyStaffSalaryType.MONTHLY_SALARY) {
      if (dataCompanySettingSalaryDetail?.is_time_closing === ESettingTimer.SETTING_TIMER) {
        if (key === ETimeShiftKeys.DAY_SHIFT) {
          if (
            element.time_attendance?.[0]?.day_shift &&
            element.time_attendance?.[0]?.day_shift !== 0 &&
            dayjs(element.time_attendance?.[0]?.date).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD')
          ) {
            return (
              <Confirmed style={{ marginLeft: '0rem' }} className="attendance-detail">
                ({CONST_ATTENDANCE_COMPANY.CONFIRMED} )
              </Confirmed>
            );
          }
          if (
            element.time_attendance?.[0]?.day_shift &&
            element.time_attendance?.[0]?.day_shift !== 0 &&
            dateTime &&
            dateTime < dayjs().format('YYYY-MM-DD HH:mm:ss')
          ) {
            return (
              <Confirmed style={{ marginLeft: '0rem' }} className="attendance-detail">
                ({CONST_ATTENDANCE_COMPANY.CONFIRMED} )
              </Confirmed>
            );
          }

          if (
            (element.time_attendance?.[0]?.day_shift === 0 &&
              element.time_attendance?.[0]?.date < dayjs().format('YYYY-MM-DD')) ||
            (element.time_attendance?.length === 0 &&
              dayjs(currentDate).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD'))
          ) {
            return (
              <Confirmed style={{ marginLeft: '0rem' }} className="attendance-detail">
                ({CONST_ATTENDANCE_COMPANY.CONFIRMED} )
              </Confirmed>
            );
          }
          if (dateTime && dateTime >= dayjs().format('YYYY-MM-DD HH:mm:ss')) {
            return (
              <>
                {dateTime && (
                  <CountDownDate
                    date={dateTime}
                    text={'確定まで'}
                    salaryType={element?.salary_type}
                    setStateHour={temp}
                  />
                )}
              </>
            );
          }
        }

        if (key === ETimeShiftKeys.NIGHT_SHIFT) {
          if (
            element.time_attendance?.[0]?.night_shift &&
            element.time_attendance?.[0]?.night_shift !== 0 &&
            dayjs(element.time_attendance?.[0]?.date).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD')
          ) {
            return (
              <Confirmed style={{ marginLeft: '0rem' }} className="attendance-detail">
                ({CONST_ATTENDANCE_COMPANY.CONFIRMED} )
              </Confirmed>
            );
          }
          if (
            element.time_attendance?.[0]?.night_shift &&
            element.time_attendance?.[0]?.night_shift !== 0 &&
            dateTime &&
            dateTime < dayjs().format('YYYY-MM-DD HH:mm:ss')
          ) {
            return (
              <Confirmed style={{ marginLeft: '0rem' }} className="attendance-detail">
                ({CONST_ATTENDANCE_COMPANY.CONFIRMED} )
              </Confirmed>
            );
          }
          if (
            (element.time_attendance?.[0]?.night_shift === 0 &&
              element.time_attendance?.[0]?.date < dayjs().format('YYYY-MM-DD')) ||
            (element.time_attendance?.length === 0 &&
              dayjs(currentDate).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD'))
          ) {
            return (
              <Confirmed style={{ marginLeft: '0rem' }} className="attendance-detail">
                ({CONST_ATTENDANCE_COMPANY.CONFIRMED} )
              </Confirmed>
            );
          }
          if (dateTime && dateTime >= dayjs().format('YYYY-MM-DD HH:mm:ss')) {
            return (
              <>
                {dateTime && (
                  <CountDownDate
                    date={dateTime}
                    text={'確定まで'}
                    salaryType={element?.salary_type}
                    setStateHour={temp}
                  />
                )}
              </>
            );
          }
        }
        return '';
      }
      return '';
    } else if (element?.salary_type === ECompanyStaffSalaryType.HOUR_SALARY) {
      if (dataCompanySettingSalaryDetail?.is_time_closing === ESettingTimer.SETTING_TIMER) {
        if (key === ETimeShiftKeys.DAY_SHIFT) {
          if (
            (dayjs(currentDate).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD') && !dateTime) ||
            (dateTime && dateTime < dayjs().format('YYYY-MM-DD HH:mm:ss'))
          ) {
            return <Confirmed>({CONST_ATTENDANCE_COMPANY.CONFIRMED} )</Confirmed>;
          }
          if (dateTime && dateTime >= dayjs().format('YYYY-MM-DD HH:mm:ss')) {
            return (
              <>
                {dateTime && (
                  <CountDownDate
                    date={dateTime}
                    text={'確定まで'}
                    salaryType={element?.salary_type}
                    setStateHour={temp}
                  />
                )}
              </>
            );
          }
        }

        if (key === ETimeShiftKeys.NIGHT_SHIFT) {
          if (
            (dayjs(currentDate).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD') && !dateTime) ||
            (dateTime && dateTime < dayjs().format('YYYY-MM-DD HH:mm:ss'))
          ) {
            return <Confirmed>({CONST_ATTENDANCE_COMPANY.CONFIRMED} )</Confirmed>;
          }
          if (dateTime && dateTime >= dayjs().format('YYYY-MM-DD HH:mm:ss')) {
            return (
              <>
                {dateTime && (
                  <CountDownDate
                    date={dateTime}
                    text={'確定まで'}
                    salaryType={element?.salary_type}
                    setStateHour={temp}
                  />
                )}
              </>
            );
          }
        }
        return '';
      }
    } else {
      if (
        dataCompanySettingSalaryDetail?.is_time_closing === ESettingTimer.SETTING_STATIC &&
        element?.salary_type === 1 &&
        element?.time_attendance?.[0]?.date &&
        dayjs(element?.time_attendance?.[0].date).format('YYYY-MM-DD') <= dayjs().format('YYYY-MM-DD') &&
        dateTime &&
        dateTime >= dayjs().format('YYYY-MM-DD HH:mm:ss')
      ) {
        if (
          element.time_attendance?.[0]?.day_shift === 1 &&
          key === ETimeShiftKeys.DAY_SHIFT &&
          checkDayShift(currentDate)
        ) {
          return (
            <div className="table-header-day-content">
              <DateContent>{dayjs().format('YYYY/MM/DD')}</DateContent>
              <DateContent>
                {dayjs(
                  element.time_attendance?.[0]?.closing_day_shift_salary ||
                    element.time_attendance?.[0]?.closing_morning_haft
                ).format('HH:mm')}
              </DateContent>
            </div>
          );
        }
        if (
          element.time_attendance?.[0]?.night_shift === 1 &&
          element?.time_attendance?.[0]?.date &&
          dayjs(element?.time_attendance?.[0].date).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD') &&
          key === ETimeShiftKeys.NIGHT_SHIFT
        ) {
          return (
            <div className="table-header-day-content">
              <DateContent>{dayjs().format('YYYY/MM/DD')}</DateContent>
              <DateContent>
                {dayjs(
                  element.time_attendance?.[0]?.closing_night_shift_salary ||
                    element.time_attendance?.[0]?.closing_afternoon_haft
                ).format('HH:mm')}
              </DateContent>
            </div>
          );
        }
      }
      if (
        dataCompanySettingSalaryDetail?.is_time_closing === ESettingTimer.SETTING_TIMER &&
        dateTime &&
        dateTime >= dayjs().format('YYYY-MM-DD HH:mm:ss')
      ) {
        return (
          <>
            {dateTime && (
              <CountDownDate date={dateTime} text={'確定まで'} salaryType={element?.salary_type} setStateHour={temp} />
            )}
          </>
        );
      }
      if (dataCompanySettingSalaryDetail?.is_time_closing === ESettingTimer.SETTING_TIMER) {
        if (key === ETimeShiftKeys.DAY_SHIFT) {
          if (
            (element.time_attendance?.[0]?.day_shift === 0 &&
              element.time_attendance?.[0]?.date < dayjs().format('YYYY-MM-DD')) ||
            (element.time_attendance?.length === 0 &&
              dayjs(currentDate).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD'))
          ) {
            return (
              <Confirmed style={{ marginLeft: '2.7rem', alignItems: 'center' }}>
                ({CONST_ATTENDANCE_COMPANY.CONFIRMED} )
              </Confirmed>
            );
          }
        }
        if (key === ETimeShiftKeys.NIGHT_SHIFT) {
          if (
            (element.time_attendance?.[0]?.night_shift === 0 &&
              element.time_attendance?.[0]?.date < dayjs().format('YYYY-MM-DD')) ||
            (element.time_attendance?.length === 0 &&
              dayjs(currentDate).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD'))
          ) {
            return (
              <Confirmed style={{ marginLeft: '2.7rem', alignItems: 'center' }}>
                ({CONST_ATTENDANCE_COMPANY.CONFIRMED} )
              </Confirmed>
            );
          }
        }
      }
      if (
        dataCompanySettingSalaryDetail?.is_time_closing === ESettingTimer.SETTING_TIMER &&
        dateTime &&
        dateTime < dayjs().format('YYYY-MM-DD HH:mm:ss')
      ) {
        return <Confirmed className="attendance-detail">({CONST_ATTENDANCE_COMPANY.CONFIRMED} )</Confirmed>;
      }
      return '';
    }
  };

  return (
    <AttendanceTableWrapper>
      <AttendanceHeaderTable
        currentDate={currentDate}
        isLoadingSettingSalary={isLoadingCompanySettingSalaryDetail}
        isTimeClosing={dataCompanySettingSalaryDetail?.is_time_closing}
        attendanceDetailData={attendanceDetailData}
      />
      {attendanceDetailData?.map(
        (element: IStaff, index: number) =>
          (element?.time_attendance?.length ||
            !element.staff_disconnect_time ||
            getDayjsByTimeZone(element.staff_disconnect_time).format('YYYY-MM-DD') >=
              currentDate.format('YYYY-MM-DD')) && (
            <>
              <AttendanceHeaderTableWrapper
                key={String(element.staff_id)}
                isLastItem={index === attendanceDetailData.length - 1}
              >
                <NameTable width="17rem">
                  <TableName
                    onClick={() => toDetailMember(element.staff_id)}
                    className={`${isStaffDisconnect(element) ? 'staff-disconnect' : ''}`}
                    color={renderColorBackground(element.salary_type)}
                  >
                    <Tooltip title={element.name} placement="topLeft">
                      {element.name}
                    </Tooltip>
                    <div>
                      <span className="staff-code">{element.code}</span>
                    </div>
                  </TableName>
                </NameTable>
                <StatusItem element={element?.time_attendance[0]} type={element?.salary_type} marginTop="1.5rem" />
                {element.salary_type === ECompanyStaffSalaryType.HOUR_SALARY && (
                  <>
                    <AttendanceForHour
                      isRootAdmin={isRootAdmin}
                      currentDate={currentDate}
                      element={element?.time_attendance[0]}
                      cycleCompanySettingSalary={cycleCompanySettingSalary}
                      changeTime={changeTime}
                      oneMember={false}
                      staffId={element.staff_id}
                      dataCompanySettingSalaryDetail={dataCompanySettingSalaryDetail}
                      stateHour={stateHour}
                      isTimeClosing={dataCompanySettingSalaryDetail?.is_time_closing}
                      isOwner={isOwner}
                    />

                    <div style={{ marginLeft: '1rem' }}>
                      <div
                        className={`box-timer`}
                        style={{ display: 'flex', marginBottom: '1.25rem', marginTop: '0.875rem', width: '16rem' }}
                      >
                        {dataCompanySettingSalaryDetail?.is_time_closing === ESettingTimer.SETTING_TIMER &&
                          getTimeCountDown(
                            element?.time_attendance[0]?.is_time_morning_working_time,
                            element?.time_attendance[0]?.closing_morning_working_time,
                            element,
                            ETimeShiftKeys.DAY_SHIFT
                          )}
                        {dataCompanySettingSalaryDetail?.is_time_closing === ESettingTimer.SETTING_STATIC &&
                          renderStaticSettingHourNoon(
                            currentDate,
                            ETimeShiftFormat.NOON,
                            element?.time_attendance[0]?.closing_morning_working_time
                          )}
                      </div>
                      <div
                        className={`box-timer`}
                        style={{
                          display: 'flex',
                          marginBottom: '1.25rem',
                          marginTop: `${
                            element?.time_attendance[0]?.closing_afternoon_working_time &&
                            !element?.time_attendance[0]?.closing_morning_working_time &&
                            dataCompanySettingSalaryDetail?.is_time_closing === ESettingTimer.SETTING_TIMER &&
                            element?.time_attendance[0]?.closing_afternoon_working_time >
                              dayjs().format('YYYY-MM-DD HH:mm:ss')
                              ? '0.875rem'
                              : '0.875rem'
                          }`,
                          width: '16rem',
                        }}
                      >
                        {dataCompanySettingSalaryDetail?.is_time_closing === ESettingTimer.SETTING_TIMER &&
                          getTimeCountDown(
                            element?.time_attendance[0]?.is_time_afternoon_working_time,
                            element?.time_attendance[0]?.closing_afternoon_working_time,
                            element,
                            ETimeShiftKeys.NIGHT_SHIFT
                          )}
                        {dataCompanySettingSalaryDetail?.is_time_closing === ESettingTimer.SETTING_STATIC &&
                          renderStaticSettingHourNight(
                            currentDate,
                            ETimeShiftFormat.NIGHT,
                            element?.time_attendance[0]?.closing_afternoon_working_time
                          )}
                      </div>
                    </div>
                  </>
                )}
                {element.salary_type === ECompanyStaffSalaryType.MONTHLY_SALARY && (
                  <>
                    <div style={{ marginLeft: '6rem' }}>
                      <ActiveItemComponent
                        element={element}
                        title={CONST_ATTENDANCE_COMPANY.NOON}
                        color={colors.dayShift}
                        shift={ETimeShiftKeys.DAY_SHIFT}
                        isForceInActive={disableBtnTimekeeping(
                          currentDate.format('YYYY-MM-DD'),
                          element?.time_attendance[0]?.closing_leave,
                          cycleCompanySettingSalary.start_date,
                          isRootAdmin,
                          isOwner
                        )}
                      />
                    </div>
                    <div className={`box-timer`}>
                      {getTimeCountDown(
                        element?.time_attendance[0]?.is_time_day_shift,
                        element?.time_attendance[0]?.closing_day_shift_salary,
                        element,
                        ETimeShiftKeys.DAY_SHIFT
                      )}
                    </div>
                    <div className={`box-timer`} style={{ marginRight: '3rem' }}></div>
                    <ActiveItemComponent
                      element={element}
                      title={CONST_ATTENDANCE_COMPANY.NIGHT}
                      color={colors.nightShift}
                      shift={ETimeShiftKeys.NIGHT_SHIFT}
                      isForceInActive={disableBtnTimekeeping(
                        currentDate.format('YYYY-MM-DD'),
                        element?.time_attendance[0]?.closing_leave,
                        cycleCompanySettingSalary.start_date,
                        isRootAdmin,
                        isOwner
                      )}
                    />
                    <div className={`box-timer`}>
                      {getTimeCountDown(
                        element?.time_attendance[0]?.is_time_night_shift,
                        element?.time_attendance[0]?.closing_night_shift_salary,
                        element,
                        ETimeShiftKeys.NIGHT_SHIFT
                      )}
                    </div>
                  </>
                )}
                {element.salary_type === ECompanyStaffSalaryType.DAILY_SALARY && (
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        display: 'flex',
                        marginRight: `${
                          element?.time_attendance[0]?.day_shift === ETimeShift.ACTIVITY ? '6.5rem' : '10.5rem'
                        }`,
                      }}
                    >
                      <div
                        style={{
                          marginLeft: `${
                            element?.time_attendance[0]?.day_shift === ETimeShift.ACTIVITY ? '0rem' : '4rem'
                          }`,
                        }}
                      >
                        <ActiveItemComponent
                          element={element}
                          title={CONST_ATTENDANCE_COMPANY.NOON}
                          color={colors.dayShift}
                          shift={ETimeShiftKeys.DAY_SHIFT}
                          isForceInActive={disableBtnTimekeeping(
                            currentDate.format('YYYY-MM-DD'),
                            element?.time_attendance[0]?.closing_leave,
                            cycleCompanySettingSalary.start_date,
                            isRootAdmin,
                            isOwner
                          )}
                        />
                      </div>
                      {element?.time_attendance[0]?.day_shift === ETimeShift.ACTIVITY ? (
                        <div>
                          <ActiveItemComponent
                            element={element}
                            title={CONST_ATTENDANCE_COMPANY.HAFT}
                            color={colors.leaveShift}
                            shift={ETimeShiftKeys.MORNING_HALF}
                            isForceInActive={
                              currentDate.format('YYYY-MM-DD') < cycleCompanySettingSalary.start_date || !isOwner
                            }
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      {element?.time_attendance[0]?.closing_morning_haft ? (
                        <div className={`box-timer`}>
                          {getTimeCountDown(
                            element?.time_attendance[0]?.is_time_morning_haft,
                            element?.time_attendance[0]?.closing_morning_haft,
                            element,
                            ETimeShiftKeys.DAY_SHIFT
                          )}
                        </div>
                      ) : (
                        <div className={`box-timer`}>
                          {getTimeCountDown(
                            element?.time_attendance[0]?.is_time_day_shift,
                            element?.time_attendance[0]?.closing_day_shift_salary,
                            element,
                            ETimeShiftKeys.DAY_SHIFT
                          )}
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        marginLeft: `${
                          element?.time_attendance[0]?.night_shift === ETimeShift.ACTIVITY ? '-1rem' : '-4.7rem'
                        }`,
                      }}
                    >
                      <div
                        style={{
                          marginLeft: `${
                            element?.time_attendance[0]?.night_shift === ETimeShift.ACTIVITY ? '0rem' : '7.5rem'
                          }`,
                        }}
                      >
                        <ActiveItemComponent
                          element={element}
                          title={CONST_ATTENDANCE_COMPANY.NIGHT}
                          color={colors.nightShift}
                          shift={ETimeShiftKeys.NIGHT_SHIFT}
                          isForceInActive={disableBtnTimekeeping(
                            currentDate.format('YYYY-MM-DD'),
                            element?.time_attendance[0]?.closing_leave,
                            cycleCompanySettingSalary.start_date,
                            isRootAdmin,
                            isOwner
                          )}
                        />
                      </div>
                      {element?.time_attendance[0]?.night_shift === ETimeShift.ACTIVITY ? (
                        <div>
                          <ActiveItemComponent
                            element={element}
                            title={CONST_ATTENDANCE_COMPANY.HAFT}
                            color={colors.leaveShift}
                            shift={ETimeShiftKeys.AFTERNOON_HALF}
                            isForceInActive={
                              currentDate.format('YYYY-MM-DD') < cycleCompanySettingSalary.start_date || !isOwner
                            }
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      {element?.time_attendance[0]?.closing_afternoon_haft ? (
                        <div className={`box-timer`}>
                          {getTimeCountDown(
                            element?.time_attendance[0]?.is_time_afternoon_haft,
                            element?.time_attendance[0]?.closing_afternoon_haft,
                            element,
                            ETimeShiftKeys.NIGHT_SHIFT
                          )}
                        </div>
                      ) : (
                        <div className={`box-timer`}>
                          {getTimeCountDown(
                            element?.time_attendance[0]?.is_time_night_shift,
                            element?.time_attendance[0]?.closing_night_shift_salary,
                            element,
                            ETimeShiftKeys.NIGHT_SHIFT
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </AttendanceHeaderTableWrapper>
            </>
          )
      )}
      <ModalCommon
        isOpen={isShowConfirm}
        setIsOpen={() => setIsShowConfirm(false)}
        onClickOk={handleOkConfirm}
        onClickCancel={handleCancelConfirm}
        onCancel={handleCancelConfirm}
        txtOK={CONST_COMMON.CHANGE}
        txtCancel={CONST_COMMON.CANCEL}
        _className="confirm-company-pair confirm-attendance"
        btnCancelColor={colors.atomicTangerine}
      >
        <ModalContainer>
          <ModalContent>
            <div className="attendance-date-txt">
              {currentDate.format('MM月DD日')}　　{shiftTxt(confirmChanging)}
            </div>
            <div className="attendance-confirm-txt">{textConfirm}</div>
          </ModalContent>
        </ModalContainer>
      </ModalCommon>
    </AttendanceTableWrapper>
  );
};
